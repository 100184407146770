import React, { useEffect, useMemo, useState } from 'react';
import { DateRange } from 'react-date-range';
import { lightFormat } from 'date-fns';
import '../../../styles/datepicker.css';
import darkTheme from '../../../styles/theme';
import { FromTo } from '../../../store/reducers/reports';
import ReactResizeDetector from 'react-resize-detector';
import { WINDOW_WIDTH_PHONE_LANDSCAPE } from '../../ResponsiveProvider/ResponsiveProvider';

function toDateRangeRangeFormat({ from, to }: FromTo): Dates {
    return {
        startDate: new Date(`${from}T00:00:00`),
        endDate: new Date(`${to}T00:00:00`),
    };
}

function DateSelectorCalendar({ from, to, onChange, sideBySideLarge }: Props) {
    const [localRange, setLocalRange] = useState(toDateRangeRangeFormat({ from, to }));
    useEffect(() => {
        setLocalRange(toDateRangeRangeFormat({ from, to }));
    }, [from, to]);

    const ranges = useMemo(() => {
        return [localRange];
    }, [localRange]);

    const additionalProps: any = {};
    if (sideBySideLarge) {
        additionalProps.direction = 'horizontal';
    }

    return (
        <ReactResizeDetector
            handleWidth
            render={({ width }) => {
                if (width <= WINDOW_WIDTH_PHONE_LANDSCAPE) {
                    additionalProps.direction = 'vertical';
                }
                return (
                    <DateRange
                        months={2}
                        ranges={ranges}
                        rangeColors={[darkTheme.primaryBlue]}
                        monthDisplayFormat="MMMM yyyy"
                        onChange={({ range1 }: { range1: Dates }) => {
                            setLocalRange(range1 as any);
                            if (range1.endDate === range1.startDate) {
                                // End date has not been selected yet, so don't persist to redux yet.
                                return;
                            }
                            // End date has been selected, so persist to redux.
                            onChange({
                                from: lightFormat(range1.startDate, 'yyyy-MM-dd'),
                                to: lightFormat(range1.endDate, 'yyyy-MM-dd'),
                            });
                        }}
                        {...additionalProps}
                    />
                );
            }}
        />
    );
}

interface Dates {
    startDate: Date;
    endDate: Date;
}

interface Props extends FromTo {
    onChange: (FromTo) => void;
    sideBySideLarge?: boolean;
}

export default DateSelectorCalendar;
