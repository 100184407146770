import React, { useMemo, useState } from 'react';
import pluralize from 'pluralize';
import { Title } from '../components/CaseManagement/common';
import Paginate2 from '../components/Pagination/Paginate2';
import styled from '@emotion/styled';
import useEffectUnlessFiltersSame from '../components/hooks/useEffectUnlessFiltersSame';
import { useDispatch } from 'react-redux';
import { useSelector } from '../store';
import SelectableItemGridContainer from '../components/SelectableItemGridContainer';
import BoxCardWithIconAndName from '../components/BoxCardWithIconAndName';
import { faDoorClosed } from '@fortawesome/pro-regular-svg-icons';
import { match, Redirect, Route, Switch } from 'react-router';
import LocationEditPage from './LocationEditPage';
import { Link } from 'react-router-dom';
import { RouteParams } from '../values/types';
import { PrimaryButtonLink } from '../components/Button/Button';
import { filter, slice } from 'lodash-es';
import { useLayoutCustomization } from '../context/LayoutContext';
import { useSearch } from '../utils/filter';
import { PAGE_SIZE } from '../values/appConfig';
import { loadLocations, locationsDataSelector } from '../store/reducers/locations';
import BigSpinner from '../components/BigSpinner/BigSpinner';
import { facilityConfigSelector } from '../store/reducers/facilityConfig';

const Container = styled.div`
    padding: 3rem;
`;

function LocationsPage({ match }: Props) {
    useLayoutCustomization({ search: true });
    const searchTerm = useSearch();
    const dispatch = useDispatch();
    const { isStandalone, isCentralOfficeAdmin } = useSelector(facilityConfigSelector);
    const locations = useSelector(locationsDataSelector);
    const [currentPage, setCurrentPage] = useState(1);
    const canEditLocations = isStandalone && isCentralOfficeAdmin;

    useEffectUnlessFiltersSame(() => {
        dispatch(loadLocations());
    });

    let locationsFiltered = useMemo(() => {
        return searchTerm
            ? filter(locations, l => l.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
            : locations;
    }, [locations, searchTerm]);

    const resultCount = locationsFiltered?.length;

    const page = useMemo(() => {
        return slice(locationsFiltered, (currentPage - 1) * PAGE_SIZE, PAGE_SIZE * currentPage);
    }, [currentPage, locationsFiltered]);

    if (!locations) {
        return <BigSpinner />;
    }

    return (
        <Container>
            <Switch>
                <Route
                    path={[`${match.path}/edit/:locationId`, `${match.path}/create`]}
                    render={props =>
                        canEditLocations ? (
                            <LocationEditPage match={props.match} previousUrl={match.url} />
                        ) : (
                            <Redirect to={match.url} />
                        )
                    }
                />
                <Route>
                    {searchTerm ? (
                        <Title>
                            {resultCount} search ${pluralize('result', resultCount)} for '
                            {searchTerm}'...
                        </Title>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Title>Locations</Title>
                            <div style={{ flex: 1 }} />
                            {canEditLocations && (
                                <PrimaryButtonLink to={`${match.path}/create`}>
                                    + Add New Location
                                </PrimaryButtonLink>
                            )}
                        </div>
                    )}
                    <SelectableItemGridContainer>
                        {page.map(v => {
                            const box = (
                                <BoxCardWithIconAndName
                                    key={v.id}
                                    selected={false}
                                    name={v.name}
                                    icon={faDoorClosed}
                                />
                            );
                            return canEditLocations ? (
                                <Link key={v.id} to={`${match.path}/edit/${v.id}`}>
                                    {box}
                                </Link>
                            ) : (
                                box
                            );
                        })}
                    </SelectableItemGridContainer>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Paginate2
                            totalCount={locations.length}
                            pageSize={PAGE_SIZE}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </Route>
            </Switch>
        </Container>
    );
}

interface Props {
    match: match<RouteParams>;
}

export default LocationsPage;
