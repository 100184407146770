import React from 'react';
import styled from '@emotion/styled';
import ResizeDetector from 'react-resize-detector';
import WizardStep, { wizardGray } from './WizardStep';
import { remToNumber } from '../../utils/css';
import { blue5 } from '../../styles/theme';
import { formatPercent } from '../../utils/numbers';

const Container = styled.div`
    width: 100%;
`;

const linePadding = '1rem';
const lineHeight = '0.3rem';
const halfLineHeight = remToNumber(lineHeight) / 2;

const LineContainer = styled.div`
    padding: ${linePadding};
`;

const Line = styled.div`
    position: relative;
    height: ${lineHeight};
    background-color: ${wizardGray};
`;

const FilledLine = styled(Line)`
    background-color: ${blue5};
`;

const steps = 5;
const stepArray = Array(steps)
    .fill(null)
    .map((_, index) => index + 1);

function Wizard({ currentStep, lastStep, routes = [] }: Props) {
    const _lastStep = Math.max(lastStep || 0, currentStep)
    return (
        <Container>
            <LineContainer>
                <ResizeDetector handleWidth children={({ width = 0 }) => {
                    return (<Line>
                        <FilledLine style={{width: formatPercent((_lastStep - 1) / (steps - 1))}} />
                        {stepArray
                            .map((step, stepIndex) =>
                                <WizardStep
                                    currentStep={currentStep}
                                    lastStep={_lastStep}
                                    route={routes[stepIndex]}
                                    key={stepIndex}
                                    step={step}
                                    left={steps > 1 ? (width / (steps - 1)) * stepIndex : 0}
                                    top={halfLineHeight}
                                />)
                        }
                    </Line>)
                }} />
            </LineContainer>
        </Container>
    );
}

interface Props {
    currentStep: number,
    lastStep?: number,
    routes?: string[],
}

export default Wizard;
