import React, { CSSProperties, useMemo } from 'react';
import Select from '../Select';
import { createNextState } from '@reduxjs/toolkit';
import styled from '@emotion/styled';
import SortableOrFilterableColumn from './SortableOrFilterableColumn';
import { HeaderConfigItem } from '../DataTableSimple';
import { SortingFiltering } from './sortFilter';

const Td = styled.td`
    padding: 1rem;
`;

export default function DataTableHeadPart<T>({
    headerConfig,
    headerTdStyle,
    sortingFiltering,
    showFilters,
}: Props<T>) {
    const {
        currentSortState,
        filteredDataSettingsState,
        setFilteredDataSettings,
        filteredDataSettingsByKey,
        possibleFilterValuesByKey,
    } = sortingFiltering;

    const [currentSort, setCurrentSort] = currentSortState;

    const headerCells = useMemo(() => {
        return headerConfig.map(
            (
                {
                    label,
                    textAlign,
                    headerTdStyle: columnHeaderTdStyle = {},
                    key,
                    sortable,
                    filterable,
                    defaultSortDescending,
                },
                index
            ) => {
                return (
                    <Td
                        style={{
                            textAlign,
                            ...headerTdStyle,
                            ...columnHeaderTdStyle,
                        }}
                        key={key ?? index}
                    >
                        {sortable ? (
                            <SortableOrFilterableColumn
                                columnKey={key!}
                                sortObject={
                                    sortable
                                        ? {
                                              defaultDescending: !!defaultSortDescending,
                                              currentSort,
                                              setCurrentSort,
                                          }
                                        : undefined
                                }
                                filterObject={
                                    filterable
                                        ? {
                                              filteredDataState: filteredDataSettingsState,
                                              isFilterOpen: !!filteredDataSettingsByKey[key!],
                                          }
                                        : undefined
                                }
                                content={label}
                            />
                        ) : (
                            label
                        )}
                    </Td>
                );
            }
        );
    }, [
        currentSort,
        filteredDataSettingsByKey,
        filteredDataSettingsState,
        headerConfig,
        headerTdStyle,
        setCurrentSort,
    ]);

    return useMemo(() => {
        const filterCells = headerConfig.map(
            (
                {
                    label,
                    textAlign,
                    headerTdStyle: columnHeaderTdStyle = {},
                    key,
                    sortable,
                    filterable,
                    defaultSortDescending,
                },
                index
            ) => {
                return (
                    <td style={{ padding: '0.5rem' }} key={key ?? index}>
                        {key && possibleFilterValuesByKey[key] ? (
                            <Select
                                isMulti
                                options={possibleFilterValuesByKey[key].map(possibleValue => ({
                                    label: possibleValue,
                                    value: possibleValue,
                                }))}
                                value={filteredDataSettingsByKey[key] ?? []}
                                onChange={values => {
                                    setFilteredDataSettings(filteredData =>
                                        createNextState(filteredData, draft => {
                                            const entry = draft.find(
                                                filteredDatum => filteredDatum.key === key
                                            );
                                            if (entry) {
                                                entry.values = values;
                                            } else {
                                                draft.push({ key, values });
                                            }
                                        })
                                    );
                                }}
                            />
                        ) : null}
                    </td>
                );
            }
        );

        return (
            <thead>
                <tr>{headerCells}</tr>
                {showFilters && <tr>{filterCells}</tr>}
            </thead>
        );
    }, [
        filteredDataSettingsByKey,
        headerCells,
        headerConfig,
        possibleFilterValuesByKey,
        setFilteredDataSettings,
        showFilters,
    ]);
}

interface Props<T> {
    headerConfig: HeaderConfigItem<T>[];
    headerTdStyle: CSSProperties;
    sortingFiltering: SortingFiltering<T>;
    showFilters?: boolean;
}
