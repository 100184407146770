import React, { useCallback, useMemo } from 'react';
import darkTheme, { ThemeProps } from '../../../../../styles/theme';
import ResizeDetector from 'react-resize-detector';
import { css } from '../../../../../utils/ide';
import styled from '@emotion/styled';
import { get, without } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkSquareIcon } from '../../../../../styles/fontLibrary';
import { isIE } from '../../../../../utils/css';
import Grid from '../../../../Grid/Grid';
import { useStateOrExternal } from '../../../../hooks/useStateOrExternal';
import { patchFilterItem } from '../../../../../store/actions/reports';
import { useAppDispatch, useSelector } from '../../../../../store';
import Icon from '../../../../Icon';
import { IconNameKey } from '../../../../../values/appConfig';

const gap = '1rem';

const CheckboxGrid = styled(Grid)`
    font-size: 0.9rem;
`;
CheckboxGrid.defaultProps = {
    gridGap: gap,
    columns: 2,
};

const CheckboxContainer = styled.div(
    ({ checked, theme }: ThemeProps & { checked?: boolean }) => css`
        box-sizing: border-box;
        height: 7rem;
        color: ${theme.primaryLight};
        border: 0.1rem solid ${theme.primaryLight};
        border-radius: 1rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        //noinspection CssOverwrittenProperties
        justify-content: space-evenly;
        //noinspection CssOverwrittenProperties
        justify-content: space-around;
        position: relative;
        cursor: pointer;
        text-align: center;
        padding: 0.25rem;

        ${checked &&
        css`
            border-width: 0.2rem;
            border-color: ${theme.primaryBlue};
            color: ${theme.primaryBlue};
            font-weight: bold;
        `};
    `
);

function Checkbox({ icon, name, checked, toggleCheck, ...restProps }) {
    return (
        <CheckboxContainer onClick={toggleCheck} checked={checked} {...restProps}>
            {checked && (
                <FontAwesomeIcon
                    icon={checkSquareIcon}
                    style={{
                        position: 'absolute',
                        top: '0.75rem',
                        right: '0.75rem',
                        fontSize: '1.5rem',
                    }}
                />
            )}
            <Icon icon={icon} name={name} height="3em" />
            <span style={{ width: '100%' }}>{name}</span>
        </CheckboxContainer>
    );
}

function InnerIconCheckboxSelector<T extends string>({ checkboxConfig, path }: Props<T>) {
    const filter = useSelector(state => get(state.reports.filters, path ?? ''));
    const dispatch = useAppDispatch();

    const stateOptions = useMemo(() => {
        if (!path) {
            // Use "pretend" local state.
            return {
                initialState: [],
            };
        }
        return {
            initialState: [],
            getState() {
                return filter || [];
            },
            setState(values) {
                dispatch(patchFilterItem(path, values));
            },
        };
    }, [dispatch, filter, path]);

    const [checkeds, setCheckeds] = useStateOrExternal(stateOptions);
    const toggleChecked = useCallback(
        name => {
            setCheckeds(checkeds.includes(name) ? without(checkeds, name) : [...checkeds, name]);
        },
        [checkeds, setCheckeds]
    );

    return (
        <ResizeDetector
            handleWidth
            render={() => (
                <div style={{ marginBottom: isIE ? `-${gap}` : undefined }}>
                    <div style={{ height: '2rem' }} />
                    <div
                        style={{
                            backgroundColor: darkTheme.primaryLight,
                            opacity: 0.56,
                            height: 1,
                        }}
                    />
                    <div style={{ height: '2rem' }} />

                    <CheckboxGrid>
                        {checkboxConfig.map(({ icon, name, key }) => (
                            <Checkbox
                                key={key}
                                icon={icon}
                                name={name}
                                checked={checkeds.includes(key)}
                                toggleCheck={() => toggleChecked(key)}
                            />
                        ))}
                    </CheckboxGrid>
                </div>
            )}
        />
    );
}

interface Props<T> {
    checkboxConfig: IconNameKey<T>[];
    path?: string;
}

export default InnerIconCheckboxSelector;
