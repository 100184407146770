import React, { useLayoutEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { RouteLeafNode, VisibleMenuItem } from './router/routeConfig';
import { useSelector } from './store';
import { facilityConfigSelector } from './store/reducers/facilityConfig';

const ContentRoutes = ({ routeConfig }: Props) => {
    const location = useLocation();
    // Scroll to top if path changes. https://stackoverflow.com/a/63042864/1381550
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const facilityKey = useSelector(facilityConfigSelector).facilityKey;

    const _routes = useMemo(() => {
        return routeConfig.map(v => (
            <Route
                key={`${v.path}-${JSON.stringify(v.params)}-${facilityKey}`}
                path={v.path}
                exact={v.path === '/'}
                component={v.component}
                render={
                    !v.component
                        ? () => <Redirect key={v.path} from={v.path} to={v.redirect} />
                        : void 0
                }
            />
        ));
    }, [facilityKey, routeConfig]);

    const fallbackRoute = useMemo(() => {
        // Find the first non-redirect route and have that be the fallback.
        const route = routeConfig.find(r => r.component) as VisibleMenuItem | undefined;
        if (route) {
            return <Redirect to={route.path} />;
        }

        return null;
    }, [routeConfig]);

    return (
        <Switch>
            {_routes}
            {fallbackRoute}
        </Switch>
    );
};

interface Props {
    routeConfig: RouteLeafNode[];
}

export default ContentRoutes;
