import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import Layout from './components/Layout/Layout';
import './styles/app.css';
import styled from '@emotion/styled';
import globalKeycloak from './utils/keycloak';
import { identifyUserIdAndReportPageVisit } from './utils/mixpanel';
import { captureMessage } from '@sentry/browser';

const Container = styled.div`
    font-family: ${({ theme }) => theme.fontFamily};
    input {
        font-family: ${({ theme }) => theme.fontFamily};
    }
    background: ${props => props.theme.backgroundMediumContrast};
    h1 {
        margin-top: 0;
    }
`;

// 20 minutes.
const refreshTokenIntervalTime = 20 * 60 * 1000;

// Converts a keycloak's legacy promise into a native promise.
function promisifyKeycloakResult(keycloakResult) {
    return new Promise((resolve, reject) => {
        keycloakResult.success(resolve).error(reject);
    });
}

function App() {
    const [keycloak, setKeycloak] = useState(null);
    const [isAuthenciated, setAuthenciated] = useState(false);
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        const init = globalKeycloak.init({
            onLoad: 'login-required',
            // Must be false while the keycloak server version is less than 9.0.0.
            // https://issues.redhat.com/browse/KEYCLOAK-12125
            checkLoginIframe: false,
        });
        let intervalId;
        init.success(async authenticated => {
            const keycloak = globalKeycloak;
            const profile = await promisifyKeycloakResult(keycloak.loadUserProfile());
            setProfile(profile);
            setKeycloak(keycloak);
            setAuthenciated(authenticated);
            intervalId = setInterval(() => {
                // Keep refreshing the token every 20 minutes, because the refresh token
                // expires after 30 seconds.
                keycloak.updateToken(-1);
            }, refreshTokenIntervalTime);
            identifyUserIdAndReportPageVisit(keycloak.subject).then(
                mixpanel => {
                    const { family_name, given_name, email } = keycloak.tokenParsed;
                    mixpanel.people.set({
                        $first_name: given_name,
                        $last_name: family_name,
                        $email: email,
                    });
                },
                () => {
                    if (process.env.NODE_ENV === 'production') {
                        captureMessage("Couldn't identify user in mixpanel.");
                    }
                }
            );
        });
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <Container className="App">
            {keycloak && isAuthenciated && profile ? <Layout keycloakProfile={profile} /> : null}
        </Container>
    );
}

export default withRouter(App);
