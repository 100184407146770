import { useEffect, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from '../../store';
import usePrevious from './usePrevious';
import { useSearch } from '../../utils/filter';
import { facilityConfigSelector } from '../../store/reducers/facilityConfig';

/**
 * useEffect, but does not run if the global filters did not change since last time.
 * Also no need to provide the dependencies.
 *
 * @param callback
 */
export default function useEffectUnlessFiltersSame(callback) {
    const search = useSearch();
    const stateFilters = useSelector(state => state.reports).filters;
    const filters = useMemo(() => ({ ...stateFilters, search }), [stateFilters, search]);
    const facilityConfig = useSelector(facilityConfigSelector);
    const facilityKey = facilityConfig.facilityKey;
    const prevFilters = usePrevious(filters);
    const prevFacilityKey = usePrevious(facilityKey);

    useEffect(() => {
        if (facilityKey !== prevFacilityKey || !prevFilters || !isEqual(filters, prevFilters)) {
            callback();
        }
    }, [callback, filters, prevFilters, facilityKey, prevFacilityKey]);
}
