import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import CasesBox from './CaseManagementDashboardSubPage/CasesBox';
import ActiveDiseases from './CaseManagementDashboardSubPage/ActiveDiseases';
import { Title } from './common';
import { PrimaryButtonLink } from '../Button/Button';
import { useAppDispatch, useSelector } from '../../store';
import PendingCases from '../DiseaseCase/PendingCases';
import {
    currentFacilityDataSelector,
    currentFacilityReset,
    loadCurrentFacility,
} from '../../store/reducers/currentFacility';
import BigSpinner from '../BigSpinner/BigSpinner';
import { routes } from '../../router/routes';
import { facilityConfigSelector } from '../../store/reducers/facilityConfig';

const Container = styled.div`
    padding: 1rem 0;

    h2 {
        font-size: 2rem;
    }
`;

const DashboardContainer = styled.div``;

function CaseManagementDashboardSubPage() {
    const dispatch = useAppDispatch();

    const { facilityKey } = useSelector(facilityConfigSelector);

    useEffect(() => {
        if (!facilityKey) {
            return;
        }
        dispatch(loadCurrentFacility());
        return () => {
            dispatch(currentFacilityReset());
        };
    }, [dispatch, facilityKey]);

    const currentFacilityData = useSelector(currentFacilityDataSelector);
    const facilityStatistics = currentFacilityData?.statistics;

    return (
        <Container>
            <DashboardContainer>
                <PendingCases />

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Title>Case Management Dashboard</Title>
                    <div style={{ flex: 1 }} />
                    <PrimaryButtonLink to={routes.diseaseCaseCreate.fullPath}>
                        + Log a Case
                    </PrimaryButtonLink>
                </div>
                {facilityStatistics ? (
                    <div style={{ display: 'flex' }}>
                        <CasesBox
                            personType="resident"
                            total={facilityStatistics.residentsTotal}
                            positiveCases={facilityStatistics.positiveCasesResidentsCount}
                            exposedPeople={facilityStatistics.exposedResidentsCount}
                            pendingIsolation={facilityStatistics.pendingIsolationResidentsCount}
                            pendingQuarantine={facilityStatistics.pendingQuarantineResidentsCount}
                        />
                        <CasesBox
                            personType="caregiver"
                            total={0}
                            positiveCases={0}
                            exposedPeople={0}
                            pendingIsolation={0}
                            pendingQuarantine={0}
                        />
                    </div>
                ) : (
                    <BigSpinner />
                )}
                <div style={{ height: '2rem' }} />
                <ActiveDiseases />
            </DashboardContainer>
        </Container>
    );
}

export default CaseManagementDashboardSubPage;
