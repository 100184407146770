import styled from '@emotion/styled';

export const GroupLabel = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
`;

export const InputComponentsContainer = styled.div`
    display: flex;
    align-items: center;

    > *:not(:first-of-type) {
        margin-left: 1rem;
    }
`;
