import React, { useEffect, useMemo } from 'react';
import Engage360Chart, { colorizeLabelValues } from '../../components/Chart/Engage360Chart';
import ChartTitle from '../../components/Engage360/ChartTitle';
import ChannelRank from '../../components/Engage360/TV/ChannelRank';
import { useAppDispatch, useSelector } from '../../store';
import {
    engageChannelsTopWatchedAmendedSelector,
    loadEngageChannelsTopWatched,
} from '../../store/reducers/engageChannelsTopWatched';
import { FullPageBigSpinner } from '../../components/BigSpinner/BigSpinner';
import { take, takeRight } from 'lodash-es';
import { engage360FiltersSelector } from '../../store/globalSelectors';
import ConditionalFlex from '../../components/ConditionalFlex';

export default function TvPage() {
    const dispatch = useAppDispatch();
    const engage360Filters = useSelector(engage360FiltersSelector);
    useEffect(() => {
        dispatch(loadEngageChannelsTopWatched(engage360Filters));
    }, [dispatch, engage360Filters]);

    const { amendedData: topWatched } = useSelector(engageChannelsTopWatchedAmendedSelector);

    const chartData = useMemo(() => {
        return topWatched
            ? topWatched.map(v => ({
                  value: v.watches,
                  label: v.callSign,
              }))
            : [];
    }, [topWatched]);

    // Top watched capped to the number of bars that realistically would fit at a time.
    const topWatchedCapped = useMemo(() => {
        const columnCount = 10;
        const mostWatched = colorizeLabelValues(take(chartData, columnCount));
        return {
            mostWatched: mostWatched,
            leastWatched: colorizeLabelValues(
                takeRight(chartData, columnCount).reverse(),
                mostWatched.length
            ),
        };
    }, [chartData]);

    if (!topWatched) {
        return <FullPageBigSpinner />;
    }

    return (
        <div>
            <ConditionalFlex>
                <div style={{ flex: 1, overflow: 'hidden' }}>
                    <ChartTitle>Most Accessed</ChartTitle>
                    <Engage360Chart
                        sideLabel="# of occurrences"
                        name="Occurrences"
                        data={topWatchedCapped.mostWatched}
                    />
                </div>
                <div style={{ flex: 1, overflow: 'hidden' }}>
                    <ChartTitle>Least Accessed</ChartTitle>
                    <Engage360Chart
                        sideLabel="# of occurrences"
                        name="Occurrences"
                        data={topWatchedCapped.leastWatched}
                    />
                </div>
            </ConditionalFlex>
            <ChannelRank channelRank={topWatched} />
        </div>
    );
}
