import React, { useCallback, useEffect, useMemo } from 'react';
import { match, useHistory } from 'react-router';
import { RouteParams } from '../values/types';
import { useAppDispatch, useSelector } from '../store';
import BigSpinner from '../components/BigSpinner/BigSpinner';
import { Title } from '../components/CaseManagement/common';
import Input from '../components/Input/Input';
import { useForm } from 'react-hook-form';
import FieldErrors from '../components/FieldErrors';
import { BigButton, ButtonLink, PrimaryButton } from '../components/Button/Button';
import axiosInstance, { URI_LOCATIONS } from '../axios';
import { displaySuccess } from '../utils/toast';
import { handleError } from '../utils/errorHandling';
import PageBox from '../components/PageBox';
import { loadLocations, locationsDataSelector } from '../store/reducers/locations';
import { facilityConfigSelector } from '../store/reducers/facilityConfig';

function LocationEditPage(props: Props) {
    const { errors, getValues, handleSubmit, register, setValue } = useForm({
        defaultValues: {
            name: '',
        },
    });

    const { facilityKey } = useSelector(facilityConfigSelector);

    const dispatch = useAppDispatch();
    const locationId = Number(props.match.params.locationId);
    const locations = useSelector(locationsDataSelector);
    const history = useHistory();
    const location = useMemo(() => {
        if (!locationId) {
            return;
        }
        return locations?.find(v => v.id === locationId);
    }, [locationId, locations]);

    const afterWrite = useCallback(() => {
        dispatch(loadLocations()); // Reload locations.
        history.push(props.previousUrl);
    }, [dispatch, history, props.previousUrl]);

    const onDelete = useCallback(async () => {
        if (!window.confirm('Are you sure you want to delete this location?')) {
            return false;
        }
        try {
            await axiosInstance.delete(`${URI_LOCATIONS}/${locationId}`);
            displaySuccess('Successfully deleted the location.');
            afterWrite();
        } catch (e) {
            handleError(e);
        }
    }, [afterWrite, locationId]);

    const onSubmit = useCallback(async () => {
        try {
            const uri = locationId ? `${URI_LOCATIONS}/${locationId}` : URI_LOCATIONS;
            const method = locationId ? 'PUT' : 'POST';
            await axiosInstance.request({
                url: uri,
                method,
                data: { ...getValues(), facilityKey },
            });
            displaySuccess('You have successfully saved this location.');
            afterWrite();
        } catch (e) {
            handleError(e);
        }
    }, [afterWrite, facilityKey, getValues, locationId]);

    useEffect(() => {
        setValue('name', location ? location.name : '');
    }, [location, setValue]);

    if (!locations) {
        // The locations just haven't loaded yet.
        return <BigSpinner />;
    }

    if (locationId && !location) {
        // Couldn't find this location id among the list of locations. Go back.
        history.push(props.previousUrl);
        return null;
    }

    return (
        <div>
            <Title style={{ textAlign: 'center' }}>{locationId ? 'Edit' : 'Create'} Location</Title>
            <PageBox>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Input name="name" ref={register} placeholder="Name" />
                    <FieldErrors errors={errors.name} />
                    <div style={{ height: '3rem' }} />
                    <div style={{ textAlign: 'center' }}>
                        <PrimaryButton type="submit">{locationId ? 'Update' : 'Add'}</PrimaryButton>
                        {locationId ? (
                            <BigButton
                                style={{ marginLeft: '3rem' }}
                                flavor="danger"
                                onClick={onDelete}
                            >
                                Delete
                            </BigButton>
                        ) : null}
                        <ButtonLink
                            style={{ marginLeft: '3rem' }}
                            flavor="link"
                            to={props.previousUrl}
                        >
                            Cancel
                        </ButtonLink>
                    </div>
                </form>
            </PageBox>
        </div>
    );
}

interface Props {
    match: match<RouteParams>;
    previousUrl: string;
}

export default LocationEditPage;
