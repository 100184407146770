import { Dispatch, SetStateAction, useState } from 'react';

/**
 * Abstraction around useState() that lets you optionally provide a way to get/set the state
 * externally rather than locally.
 *
 * @param options {?object}
 * @param options.initialState {any} The initial local state.
 * @param options.getState {?function} Customization of how to get the state.
 * @param options.setState {?function} Customization of how to set the state.
 * @returns {[unknown, function]}
 */
export function useStateOrExternal<T>(options: UseStateOrExternalConfig<T>) {
    let [state, setState] = useState<T>(options.initialState);

    if (options.getState) {
        state = options.getState(state);
    }
    const externalSetState = options.setState;
    if (externalSetState) {
        setState = stateOrCallback =>
            externalSetState(
                typeof stateOrCallback !== 'function'
                    ? stateOrCallback
                    : (stateOrCallback as (prevState: T) => T)(state)
            );
    }
    return [state, setState];
}

interface UseStateOrExternalConfig<T> {
    initialState: T | (() => T);
    getState?: (state?: T) => T;
    setState?: Dispatch<SetStateAction<T>>;
}
