/**
 * Actions that affect more than one reducer.
 */

import { AppDispatch } from '../index';
import { diseaseCasesReset, loadDiseaseCases } from '../reducers/diseaseCases';
import {
    diseaseCasesNeedAttentionReset,
    loadDiseaseCasesNeedAttention,
} from '../reducers/diseaseCasesNeedAttention';
import { diseasesReset, loadDiseases } from '../reducers/diseases';

export function loadAllDiseaseCases() {
    return (dispatch: AppDispatch) => {
        dispatch(loadDiseases());
        dispatch(loadDiseaseCases());
        dispatch(loadDiseaseCasesNeedAttention());
    };
}

export function resetAllDiseaseCases() {
    return (dispatch: AppDispatch) => {
        dispatch(diseasesReset());
        dispatch(diseaseCasesReset());
        dispatch(diseaseCasesNeedAttentionReset());
    };
}
