import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { red } from '../../styles/theme';
import { KeycloakAttributes } from '../Layout/Layout';
import { logout, logoutUrl } from '../../utils/keycloak';
import styled from '@emotion/styled';

const A = styled.a`
    text-decoration: underline;

    :hover {
        text-decoration: none;
    }
`;

function NoFacilitiesFoundWarning(props: Props) {
    const onLogoutClick = useCallback(event => {
        event.preventDefault();
        logout();
    }, []);

    const textRows: string[] = [];
    if (props.keycloakAttributes.centralOfficeUuid) {
        textRows.push(
            `Central Office UUID: ${props.keycloakAttributes.centralOfficeUuid.join(', ')}`
        );
    }
    if (props.keycloakAttributes.communityId) {
        textRows.push(`Community ID: ${props.keycloakAttributes.communityId.join(', ')}`);
    }
    if (props.keycloakAttributes.engageCommunityId) {
        textRows.push(
            `Engage360 Community ID: ${props.keycloakAttributes.engageCommunityId.join(', ')}`
        );
    }

    if (!textRows.length) {
        textRows.push(
            'No access attribute was found for your user. Either they are missing, or their keys were mistyped.'
        );
    }

    textRows.unshift('No facilities found that your user has access to.');
    textRows.push('');
    textRows.push('Please contact support.');

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                height: '100%',
            }}
        >
            <FontAwesomeIcon icon={faTimes} color={red} size="10x" />
            <span style={{ whiteSpace: 'pre' }}>{textRows.join('\n')}</span>
            <br />
            <A href={logoutUrl} onClick={onLogoutClick}>
                Log in as another user.
            </A>
        </div>
    );
}

type Props = {
    keycloakAttributes: KeycloakAttributes;
};

export default NoFacilitiesFoundWarning;
