import { Action, combineReducers, configureStore, Middleware } from '@reduxjs/toolkit';
import contactTracingReducer from './reducers/contactTracing';
import facilities from './reducers/facilities';
import dashboardReducer from './reducers/dashboard';
import reportsReducer from './reducers/reports';
import headers from './middlewares/headers';
import api from '@bit/gstanto.treehouse_tech.api';
import {
    TypedUseSelectorHook,
    useDispatch,
    useSelector as reactReduxUseSelector,
} from 'react-redux';
import bulkUploadPossibleFields from './reducers/bulkUploadPossibleFields';
import bulkUploadHeadings from './reducers/bulkUploadHeadings';
import bulkUploadImport from './reducers/bulkUploadImport';
import diseases from './reducers/diseases';
import diseaseShow from './reducers/diseaseShow';
import diseaseCases from './reducers/diseaseCases';
import diseaseCaseShow from './reducers/diseaseCaseShow';
import contactTracingResults from './reducers/contactTracingResults';
import diseaseCasesFiltered from './reducers/diseaseCasesFiltered';
import diseaseCasesNeedAttention from './reducers/diseaseCasesNeedAttention';
import currentFacility from './reducers/currentFacility';
import reportsDiseases from './reducers/reportsDiseases';
import persons from './reducers/persons';
import locations from './reducers/locations';
import facilityConfig from './reducers/facilityConfig';
import engageChannelsTopWatched from './reducers/engageChannelsTopWatched';
import engageActivitySignups from './reducers/engageActivitySignups';
import engageRepairs from './reducers/engageRepairs';
import engageRepairsSummary from './reducers/engageRepairsSummary';
import engageActivityUtilization from './reducers/engageActivityUtilization';
import engageRepairsByRoomAndIssue from './reducers/engageRepairsByRoomAndIssue';
import engageResidents from './reducers/engageResidents';
import engageActivitySummary from './reducers/engageActivitySummary';
import engageActivityByHourAndWeek from './reducers/engageActivityByHourAndWeek';

const rootReducer = combineReducers({
    bulkUploadHeadings,
    bulkUploadImport,
    bulkUploadPossibleFields,
    contactTracing: contactTracingReducer,
    contactTracingResults,
    currentFacility,
    dashboard: dashboardReducer,
    diseaseCases,
    diseaseCasesFiltered,
    diseaseCaseShow,
    diseaseCasesNeedAttention,
    diseases,
    diseaseShow,
    engageActivityByHourAndWeek,
    engageActivitySignups,
    engageActivitySummary,
    engageActivityUtilization,
    engageChannelsTopWatched,
    engageRepairs,
    engageRepairsByRoomAndIssue,
    engageRepairsSummary,
    engageResidents,
    facilities,
    facilityConfig,
    locations,
    persons,
    reports: reportsReducer,
    reportsDiseases,
});

const store = configureStore({
    reducer: rootReducer,
    // This middleware syntax is for correct thunk typing.
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(
            headers as Middleware<(action: Action<unknown>) => void, RootState>,
            api as Middleware<(action: Action<unknown>) => void, RootState>
        ),
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

// Correctly typed hooks.
export const useSelector: TypedUseSelectorHook<RootState> = reactReduxUseSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
