import React, { useCallback, useMemo, useState } from 'react';
import FiltersItems from '../FiltersItems';
import { toggleEntityFilterItem } from '../../../../store/actions/reports';
import InnerIconCheckboxSelector from './InnerIconCheckboxSelector/InnerIconCheckboxSelector';
import { CheckboxWithLabel } from '../../../CheckboxWithLabel/CheckboxWithLabel';
import { useAppDispatch, useSelector } from '../../../../store';
import { IconNameKey } from '../../../../values/appConfig';
import { reportsSelector } from '../../../../store/reducers/reports';
import Input from '../../../Input/Input';

export interface CheckboxOption {
    id: number | string;
    name: string;
}

export type InnerCheckboxConfigs = Array<{
    value: string;
    checkboxConfig: IconNameKey<string>[];
}>;

function useCheckbox(filterKey: string, options: CheckboxOption[] | null = null) {
    const dispatch = useAppDispatch();
    const onClick = useCallback(
        event => {
            dispatch(
                toggleEntityFilterItem(
                    filterKey,
                    JSON.parse(event.currentTarget.closest('[data-value]').dataset.value)
                )
            );
        },
        [dispatch, filterKey]
    );

    const reportsState = useSelector(reportsSelector);
    const filter = reportsState.filters[filterKey];
    const optionsInStore = reportsState[filterKey];
    const selectedIds = useMemo(() => {
        return filter ?? [];
    }, [filter]);

    if (!options) {
        options = optionsInStore;
    }
    const selectedNames = useMemo(() => {
        return options
            ? options
                  .filter(({ id }) => selectedIds.includes(id))
                  .map(({ name }) => name)
                  .join(', ')
            : '';
    }, [options, selectedIds]);

    if (!options) {
        throw new Error('Options could not be found.');
    }

    return { options, selectedNames, onClick, selectedIds };
}

/**
 * @param filterKey {string|string[]} String for a filter key to use (redux reducer/state), or
 *  an array of strings for fake data.
 * @param options {?array} Override of the options to use if they shouldn't come from the store.
 * @param innerCheckboxConfigs {array}
 */
function FilterCheckboxType({
    title,
    filterKey,
    options: _options,
    innerCheckboxConfigs = [],
    hasSearch,
    ...restProps
}: Props) {
    const { options, selectedNames, onClick, selectedIds } = useCheckbox(filterKey, _options);
    const [search, setSearch] = useState('');

    const innerCheckboxConfig = innerCheckboxConfigs.find(({ value }) =>
        selectedIds.includes(value)
    );

    const filteredOptions = useMemo(() => {
        if (!search) {
            return options;
        }
        return options.filter(v => v.name.toLowerCase().indexOf(search.toLowerCase()) >= 0);
    }, [options, search]);

    return (
        <FiltersItems
            title={title}
            selectedText={selectedNames}
            {...restProps}
            render={() => (
                <>
                    {hasSearch && (
                        <Input
                            style={{ marginTop: '0.5rem' }}
                            placeholder="Search..."
                            value={search}
                            autoFocus
                            onChange={evt => setSearch(evt.currentTarget.value)}
                        />
                    )}

                    {filteredOptions.map(item => {
                        const isActive = selectedIds.find(a => a === item.id) !== undefined;
                        return (
                            <CheckboxWithLabel
                                dataValue={item.id}
                                key={item.id}
                                name={item.name}
                                label={item.name}
                                checked={isActive}
                                onChange={onClick}
                            />
                        );
                    })}

                    {innerCheckboxConfig && (
                        <InnerIconCheckboxSelector
                            checkboxConfig={innerCheckboxConfig.checkboxConfig}
                        />
                    )}
                </>
            )}
        />
    );
}

interface Props {
    title: string;
    filterKey: string;
    options?: CheckboxOption[];
    innerCheckboxConfigs?: InnerCheckboxConfigs;
    hasSearch?: boolean;
}

export default FilterCheckboxType;
