import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { timesIcon } from '../../styles/fontLibrary';
import FilterCheckboxType from '../DataTable/Filters/FilterTypes/FilterCheckboxType';
import { OverlayComponentProps } from '../Overlay/Overlay';
import { H2 } from '../../styles/shared';
import { useSelector } from '../../store';
import { facilitiesDataSelector } from '../../store/reducers/facilities';

function CaseManagementReportsFilters({ close }: OverlayComponentProps) {
    const facilities = useSelector(facilitiesDataSelector);
    const facilitiesAsCheckboxOptions = useMemo(() => {
        return facilities
            ? facilities.map(facility => ({
                  id: facility.id,
                  name: facility.name,
              }))
            : [];
    }, [facilities]);

    return (
        <>
            <div style={{ position: 'relative' }}>
                <FontAwesomeIcon
                    icon={timesIcon}
                    className="clickable"
                    onClick={close}
                    style={{ position: 'absolute', right: 0, top: '0.25rem' }}
                />
            </div>
            <H2>Filters</H2>
            <FilterCheckboxType
                title="Facility"
                filterKey="facilityIds"
                options={facilitiesAsCheckboxOptions}
            />
        </>
    );
}

export default CaseManagementReportsFilters;
