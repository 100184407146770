import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Box } from '../../styles/shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { routeForSelectPerson } from '../../pages/ContactTracing/contactTracingRoutes';

const Title = styled.div`
    font-size: 2rem;
    font-weight: bold;
`;

const Container = styled(Box)`
    height: 20rem;
    display: flex;
    flex-direction: column;
    //noinspection CssOverwrittenProperties
    justify-content: space-evenly;
    //noinspection CssOverwrittenProperties
    justify-content: space-around;
    cursor: pointer;
`;

function BigIconClickableBox({icon, title, onClick, selected}: Props) {
    return (
        <Link to={routeForSelectPerson.path} onClick={onClick}>
            <Container selected={selected}>
                <div><FontAwesomeIcon icon={icon} size="10x" /></div>
                <Title>{title}</Title>
            </Container>
        </Link>
    );
}

interface Props {
    icon: IconProp,
    title: string,
    onClick: () => void,
    selected?: boolean,
}

export default BigIconClickableBox;
