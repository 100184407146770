import * as actionTypes from './actionTypes';
import { stringify } from 'qs';
import { filterQuerySelector } from './reports';

export function getDashboard() {
    return async (dispatch, getState) => {
        const queryObj = filterQuerySelector(getState());
        const query = stringify(queryObj);

        dispatch({
            type: actionTypes.GET_DASHBOARD_START,
            meta: {
                api: {
                    method: 'GET',
                    endpoint: `/dashboard?${query}`,
                },
            },
        });
    };
}
