/**
 * Calculates the peak shifts based on alarm totals by hours and days of the week.
 *
 * @param {number[][]} alarmTotalsByHoursAndDaysOfWeek
 * @return {number[]}
 */
export function calculatePeakShiftsByAlarmTotalsByHoursAndDaysOfWeek(
    alarmTotalsByHoursAndDaysOfWeek
) {
    return alarmTotalsByHoursAndDaysOfWeek.reduce((acc, item, index) => {
        const sumOfWeek = item.reduce((acc, item) => item + acc, 0);
        acc[Math.floor(index / 8)] += sumOfWeek;
        return acc;
    }, [0, 0, 0]);
}
