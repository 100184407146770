import React, { useEffect, useMemo } from 'react';
import { useLayoutCustomization } from '../context/LayoutContext';
import { useAppDispatch, useSelector } from '../store';
import { castManagementReportsFiltersSelector } from '../store/globalSelectors';
import BigSpinner from '../components/BigSpinner/BigSpinner';
import ActiveDisease from '../components/CaseManagement/CaseManagementDashboardSubPage/ActiveDisease';
import { facilitiesDataSelector } from '../store/reducers/facilities';
import {
    loadReportsReportsDiseases,
    reportsDiseasesReset,
    reportsDiseasesSelector,
} from '../store/reducers/reportsDiseases';

function CaseManagementReportsPage() {
    useLayoutCustomization({ filters: 'case-management' });
    const dispatch = useAppDispatch();
    const filters = useSelector(castManagementReportsFiltersSelector);

    const facilitiesData = useSelector(facilitiesDataSelector);
    const availableFacilityIds = useMemo(() => {
        return facilitiesData ? facilitiesData.map(v => v.id) : [];
    }, [facilitiesData]);

    const facilityIds = useMemo(() => {
        return filters.facilityIds.length ? filters.facilityIds : availableFacilityIds;
    }, [availableFacilityIds, filters.facilityIds]);

    useEffect(() => {
        dispatch(loadReportsReportsDiseases(facilityIds));
        return () => {
            dispatch(reportsDiseasesReset());
        };
    }, [dispatch, facilityIds]);

    const fromTo = useMemo(() => {
        return { from: filters.from, to: filters.to };
    }, [filters.from, filters.to]);

    const { waiting, data } = useSelector(reportsDiseasesSelector);

    if (waiting || !data) {
        return <BigSpinner />;
    }

    return (
        <div style={{ padding: '3rem' }}>
            {data.map(v => (
                <ActiveDisease key={v.uuid} disease={v} facilityIds={facilityIds} fromTo={fromTo} />
            ))}
        </div>
    );
}

export default CaseManagementReportsPage;
