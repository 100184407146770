import React, { InputHTMLAttributes, useMemo } from 'react';
import { blue5 } from '../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkCircleIcon, circleIcon } from '../../styles/fontLibrary';
import styled from '@emotion/styled';

const UncheckedIcon = styled(FontAwesomeIcon)`
    font-size: 1.6rem;
    margin-right: 0.8rem;
    opacity: 0.56;
    pointer-events: none;
    cursor: pointer;
`;

const CheckedIcon = styled(UncheckedIcon)`
    opacity: 1;
`;

const StyledRadio = styled.input`
    position: absolute;
    opacity: 0;
    left: -10000px;

    &:checked ~ ${UncheckedIcon} {
        display: none;
    }
    &:not(:checked) ~ ${CheckedIcon} {
        display: none;
    }
`;

function Radio({ selected, value, style = {}, color = blue5, register, ...restProps }: RadioProps) {
    const active = useMemo(() => {
        if (selected === void 0) {
            return selected;
        }
        return selected === value;
    }, [selected, value]);

    return (
        <>
            {/* Hidden input for accessibility */}
            <StyledRadio
                type="radio"
                checked={active}
                value={value}
                ref={register}
                {...restProps}
            />
            <UncheckedIcon icon={circleIcon} color="white" />
            <CheckedIcon icon={checkCircleIcon} color={color} />
        </>
    );
}

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
    selected?: any;
    value: any;
    color?: string;
    register?: any;
}

export default Radio;
