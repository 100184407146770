import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { URI_DISEASES } from '../../axios';
import { Disease } from '../../values/types';
import { SelectOption } from '../../components/UnstyledSelect';
import { keyBy } from 'lodash-es';

const slice = createFetchSlice('diseases', {}, getInitialState<Disease[]>());

export function loadDiseases() {
    return slice.load({
        uri: URI_DISEASES,
        stealth: true,
    });
}

const diseases = slice.reducer;
const diseasesSelector = slice.selector;
const diseasesReset = slice.actions.reset;

export { diseasesSelector, diseasesReset };

export const diseasesDataSelector = createSelector(diseasesSelector, v => v.data);

export const diseasesAsSelectOptionsSelector = createSelector(diseasesDataSelector, diseases => {
    const ret: Array<SelectOption<string | null>> = [
        {
            label: '(Select a disease)',
            value: null,
        },
    ];

    if (!diseases) {
        return ret;
    }

    return [...ret, ...diseases.map(v => ({ label: v.name, value: v.uuid }))];
});

export const diseasesByUuidSelector = createSelector(diseasesDataSelector, (diseases): {
    [diseaseUuid: string]: Disease | undefined;
} => keyBy(diseases || {}, 'uuid'));

export default diseases;
