import { without } from 'lodash-es';
import { stringify } from 'qs';
import { amendFilterQueryByResponseTimes, getSearchFromLocation } from '../../utils/filter';
import * as actionTypes from './actionTypes';
import { RESET_REPORTS } from './actionTypes';

export const getAlarmsData = () => {
    return {
        type: actionTypes.GET_ALARMS_DATA_START,
        meta: {
            api: {
                method: 'GET',
                endpoint: '/raw',
            },
        },
    };
};

export const getAlarms = () => {
    return {
        type: actionTypes.GET_ALARMS_START,
        meta: {
            api: {
                method: 'GET',
                endpoint: '/alarms',
            },
        },
    };
};

export const responseTimeRange = [0, 10];
export const elapsedTimeRange = [0, 120];

/**
 * Gets the base query object with the filters applied.
 * @param state
 * @return {[string, string]}
 */
export function filterQuerySelector(state) {
    const { filters } = state.reports;
    const { currentPage, pageCount, from, to, sortBy, sortByDirection } = filters;
    const query = {
        from,
        to,
        sortBy: { [sortBy]: sortByDirection },
        page: currentPage,
        per_page: pageCount,
    };
    // The filter query depends on these properties from reports.filters.
    const filterEntityTypes = [
        'locations',
        'alarms',
        'sources',
        'domains',
        'alarmTypes',
        'openedShifts',
        'caregivers',
        'search',
        'hasDisposition',
        'hasDispositionNote',
    ];
    filterEntityTypes.forEach(entityType => {
        const filter = filters[entityType];
        if (filter && ((Array.isArray(filter) && filter.length) || filter !== '')) {
            // Apply the filter of this entity type.
            query[entityType] = filter;
        }
    });
    amendFilterQueryByResponseTimes(filters, query);
    return query;
}

export const getAlarmsReport = () => (dispatch, getState) => {
    const queryString = stringify({
        ...filterQuerySelector(getState()),
        search: getSearchFromLocation(window.location),
    });

    return dispatch({
        type: actionTypes.GET_ALARMS_REPORT_START,
        meta: {
            api: {
                method: 'GET',
                endpoint: `/alarms/report?${queryString}`,
            },
        },
    });
};

export const getAlarmDetailReport = id => (dispatch, getState) => {
    const { active } = getState().reports;
    if (active && active.id === id) {
        dispatch({ type: actionTypes.CLOSE_ALARM_REPORT_DETAIL });
        dispatch({ type: actionTypes.CLEAR_ALARM_REPORT_DETAIL });
    } else {
        return dispatch({
            type: actionTypes.GET_ALARM_DETAIL_REPORT_START,
            id,
            meta: {
                api: {
                    method: 'GET',
                    endpoint: `/alarms/detailed_report?alarmId=${id}`,
                },
            },
        });
    }
};

export const setFilter = (type, value) => {
    switch (type) {
        case 'page':
            return {
                type: actionTypes.SET_PAGE_NUMBER,
                page: value,
            };
        case 'pageCount': {
            return {
                type: actionTypes.SET_PAGE_COUNT,
                count: value,
            };
        }
        case 'filters': {
            return {
                type: actionTypes.SET_FILTERS,
                filters: value,
            };
        }
        case 'sort': {
            return {
                type: actionTypes.SET_SORTING,
                sortBy: value,
            };
        }
        default:
            throw new Error(`Unknown type: ${type}`);
    }
};

/**
 * Toggle locations/alarms
 */
export const toggleEntityFilterItem = (type, valueToToggle) => {
    return (dispatch, getState) => {
        const values = getState().reports.filters[type];

        const newValues = !values.includes(valueToToggle)
            ? [...values, valueToToggle]
            : without(values, valueToToggle);

        return dispatch(patchFilterItem(type, newValues));
    };
};

/**
 * Sets a key of one of the filters to the given value.
 */
export const patchFilterItem = (type, value) => {
    return {
        type: actionTypes.PATCH_FILTERS,
        payload: { [type]: value },
    };
};

export const resetReports = () => {
    return { type: RESET_REPORTS };
};
