import styled from '@emotion/styled';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'emotion-theming';
import { isEmpty } from 'lodash-es';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLayoutCustomization } from '../../../context/LayoutContext';
import { fromToSelector } from '../../../store/reducers/reports';
import {
    calendarIcon,
    fileDownloadIcon,
    filterIcon,
    sortAmountDownIcon,
    timesCircleIcon,
} from '../../../styles/fontLibrary';
import { formatDateTextMonth } from '../../../utils/date';
import { css } from '../../../utils/ide';
import CaseManagementReportsFilters from '../../CaseManagement/CaseManagementReportsFilters';
import FiltersContainer from '../../DataTable/Filters/FiltersContainer';
import OverlayFilterDateSelector from '../../DateSelector/OverlayFilterDateSelector';
import Engage360Filters from '../../Engage360/Engage360Filters';
import { height, useOverlay } from '../../Overlay/Overlay';
import { useResponsive } from '../../ResponsiveProvider/ResponsiveProvider';
import SearchInput from '../../SearchInput/SearchInput';
import SortOverlay from '../../SortOverlay/SortOverlay';

const Container = styled.div`
    @media print {
        display: none;
    }
`;

const InnerContainer = styled.div(
    ({ theme }) => css`
        background: ${theme.backgroundSecondary};
        box-sizing: border-box;
        height: ${height};
        padding: 0.5rem 2.8rem 0.5rem 2rem;
        display: flex;
        align-items: center;
    `
);

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)(
    ({ theme }) => css`
        font-size: 2.3rem;
        margin-left: 1rem;
        color: ${theme.primary};
        outline: 0;
    `
);
StyledFontAwesomeIcon.defaultProps = {
    className: 'clickable',
    tabIndex: 0,
    fixedWidth: true,
};

function OverlayIcon({ overlay, icon, title }) {
    return (
        <StyledFontAwesomeIcon
            icon={!overlay.isOpen ? icon : timesCircleIcon}
            title={title}
            onClick={overlay.open}
        />
    );
}

function SearchAndFiltersBar() {
    const filtersOverlay = useOverlay(FiltersContainer);
    const caseManagementFiltersOverlay = useOverlay(CaseManagementReportsFilters);
    const engage360FiltersOverlay = useOverlay(Engage360Filters);
    const datesOverlay = useOverlay(OverlayFilterDateSelector);
    const sortOverlay = useOverlay(SortOverlay);
    const layoutCustomization = useLayoutCustomization();
    const { singleColumnLayout } = useResponsive();

    const fromTo = useSelector(fromToSelector);
    const [displayFrom, displayTo] = useMemo(() => {
        return fromTo.map(dateString => formatDateTextMonth(dateString));
    }, [fromTo]);

    const overlays = useMemo(() => {
        return {
            alarms: filtersOverlay,
            'case-management': caseManagementFiltersOverlay,
            engage360: engage360FiltersOverlay,
        };
    }, [caseManagementFiltersOverlay, engage360FiltersOverlay, filtersOverlay]);

    const theme = useTheme();
    return !isEmpty(layoutCustomization) ? (
        <Container>
            <div style={{ position: !singleColumnLayout ? 'relative' : 'static' }}>
                {datesOverlay.overlayElement}
                {filtersOverlay.overlayElement}
                {caseManagementFiltersOverlay.overlayElement}
                {engage360FiltersOverlay.overlayElement}
                {sortOverlay.overlayElement}
            </div>
            <InnerContainer>
                {layoutCustomization.search && <SearchInput />}
                <div style={{ flex: 1 }} />
                {layoutCustomization.filters && (
                    <div
                        style={{ display: 'flex', alignItems: 'center' }}
                        className="clickable"
                        onClick={datesOverlay.open}
                    >
                        <FontAwesomeIcon
                            icon={!datesOverlay.isOpen ? calendarIcon : timesCircleIcon}
                            pull="left"
                            color="white"
                            style={{ fontSize: '1.5em', marginRight: '1rem' }}
                            fixedWidth
                        />
                        <span style={{ color: 'white' }}>{displayFrom}</span>
                        <FontAwesomeIcon icon={faArrowRight} style={{ margin: '1rem' }} />
                        <span style={{ color: 'white' }}>{displayTo}</span>
                    </div>
                )}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '25rem',
                        justifyContent: 'flex-end',
                    }}
                >
                    {layoutCustomization.filters && (
                        <OverlayIcon
                            icon={filterIcon}
                            title="Filters"
                            overlay={overlays[layoutCustomization.filters]}
                        />
                    )}
                    {layoutCustomization.sorting && (
                        <OverlayIcon
                            icon={sortAmountDownIcon}
                            title="Sort By"
                            overlay={sortOverlay}
                        />
                    )}
                    {layoutCustomization.getDownloadUrl && (
                        <span
                            tabIndex={0}
                            className="clickable"
                            onClick={() =>
                                window.location.assign(layoutCustomization.getDownloadUrl())
                            }
                        >
                            <StyledFontAwesomeIcon
                                color={theme.primary}
                                icon={fileDownloadIcon}
                                title="Download Report"
                            />
                        </span>
                    )}
                </div>
            </InnerContainer>
        </Container>
    ) : null;
}

export default SearchAndFiltersBar;
