import React from 'react';
import { red } from '../../../styles/theme';
import styled from '@emotion/styled';
import ActiveDisease from './ActiveDisease';
import { PrimaryButtonLink } from '../../Button/Button';
import { useSelector } from '../../../store';
import { diseasesSelector } from '../../../store/reducers/diseases';
import BigSpinner from '../../BigSpinner/BigSpinner';
import { routes } from '../../../router/routes';
import { facilityConfigSelector } from '../../../store/reducers/facilityConfig';

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled.h2`
    color: ${red};
`;

function ActiveDiseases() {
    const { isCentralOfficeAdmin } = useSelector(facilityConfigSelector);
    const { waiting, data } = useSelector(diseasesSelector);

    if (waiting || !data) {
        return <BigSpinner />;
    }

    return (
        <div>
            <TitleContainer>
                <Title>Diseases ({data.length})</Title>
                <div style={{ flex: 1 }} />
                {isCentralOfficeAdmin && (
                    <PrimaryButtonLink to={routes.diseaseCreate.fullPath}>
                        + Add Disease
                    </PrimaryButtonLink>
                )}
            </TitleContainer>
            {data.map(v => (
                <ActiveDisease key={v.uuid} disease={v} />
            ))}
        </div>
    );
}

export default ActiveDiseases;
