import { PartialPerson } from '../values/types';

/**
 * Get the initials of a person.
 */
export function getInitials(person: PartialPerson) {
    if (person.secondName && person.name) {
        return person.name[0].toUpperCase() + person.secondName[0].toUpperCase();
    }
    if (person.name) {
        const nameComponents = person.name.trim().split(/\s+/g);
        let ret = nameComponents[0][0].toUpperCase();
        if (nameComponents.length > 1) {
            // First letter of last word in name.
            ret += nameComponents[nameComponents.length - 1][0].toUpperCase();
        }
        return ret;
    }
    return '';
}
