import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { URI_BULK_UPLOAD } from '../../axios';
import { createFormData } from '../../utils/form';
import { SetError } from '../../values/types';
import { BulkUploadFormJustFile } from '../../pages/BulkUploadPage';

const slice = createFetchSlice('bulkUploadHeadings', {}, getInitialState<string[]>());

export function loadBulkUploadHeadings(file: File, setError: SetError<BulkUploadFormJustFile>) {
    return slice.load({
        uri: `${URI_BULK_UPLOAD}/headings`,
        setError,
        config: {
            method: 'POST',
            data: createFormData({ file }),
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    });
}

const bulkUploadHeadings = slice.reducer;
const bulkUploadHeadingsSelector = slice.selector;
const bulkUploadHeadingsReset = slice.actions.reset;

export { bulkUploadHeadingsSelector, bulkUploadHeadingsReset };

export const bulkUploadHeadingsDataSelector = createSelector(
    bulkUploadHeadingsSelector,
    v => v.data
);

export default bulkUploadHeadings;
