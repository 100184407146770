import React, { CSSProperties, useMemo } from 'react';
import UnstyledSelect, { UnstyledSelectProps } from './UnstyledSelect';
import { StylesConfig } from 'react-select';
import { wizardGray } from './Wizard/WizardStep';
import theme from '../styles/theme';
import { inputHeight } from './Input/Input';

/**
 * A styled version of UnstyledSelect.
 */
function Select<T>(props: Props<T>) {
    const styles = useMemo(() => {
        const styles: StylesConfig = {
            control: provided => ({
                ...provided,
                backgroundColor: props.backgroundColor ?? 'transparent',
                borderColor: wizardGray,
                borderRadius: '0.5rem',
                padding: '0 0.15rem',
                height: props.height ?? inputHeight,
            }),
            indicatorSeparator: provided => ({
                ...provided,
                display: 'none',
            }),
            menu: provided => ({
                ...provided,
                backgroundColor: theme.background,
            }),
            option: (provided, state) => ({
                ...provided,
                ...(state.isFocused || state.isSelected ? { backgroundColor: theme.gray200 } : {}),
                display: 'flex',
                alignItems: 'center',
                ':active': { backgroundColor: theme.gray300 },
            }),
            singleValue: provided => ({
                ...provided,
                color: wizardGray,
                display: 'flex',
                alignItems: 'center',
            }),
            multiValue: provided => ({
                ...provided,
                background: 'none',
            }),
            multiValueLabel: provided => ({
                ...provided,
                color: theme.gray800,
                background: theme.gray300,
            }),
            multiValueRemove: provided => ({
                ...provided,
                color: theme.gray800,
                background: theme.gray400,
                ':hover': {
                    backgroundColor: theme.gray600,
                    color: theme.gray300,
                },
            }),
            input: provided => ({
                ...provided,
                color: wizardGray,
            }),
        };
        return styles;
    }, [props.backgroundColor, props.height]);

    return <UnstyledSelect styles={styles} {...props} />;
}

type Props<T> = UnstyledSelectProps<T> & {
    height?: CSSProperties['height'];
    backgroundColor?: CSSProperties['backgroundColor'];
};

export default Select;
