import React from 'react';
import { H2 } from '../../styles/shared';
import { timesIcon } from '../../styles/fontLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterDateSelector from './FilterDateSelector';

/**
 * Date Selector that shows up as an overlay for filtering alarms.
 */
const OverlayFilterDateSelector = ({ close }: Props) => {
    return (
        <div>
            <div style={{ position: 'relative' }}>
                <FontAwesomeIcon
                    icon={timesIcon}
                    className="clickable"
                    onClick={close}
                    style={{ position: 'absolute', right: 0, top: '0.25rem' }}
                />
            </div>
            <H2>Range</H2>
            <FilterDateSelector close={close} />
        </div>
    );
};

interface Props {
    close: () => void;
}

export default OverlayFilterDateSelector;
