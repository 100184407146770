import React from 'react';
import ChartTitle from '../ChartTitle';
import { styled } from '../../../styles/theme';
import { Box } from '../../../styles/shared';
import { ActivitySummary } from '../../../values/types';
import { formatStatisticNumber } from '../../../utils/numbers';
import {
    WINDOW_WIDTH_MEDIUM_DESKTOP,
    WINDOW_WIDTH_PHONE_LANDSCAPE,
} from '../../ResponsiveProvider/ResponsiveProvider';

const Container = styled.div`
    width: 100%;
    overflow-x: auto;
`;

const BoxContainer = styled(Box)`
    display: inline-block;
    padding: 0.5rem 0 2rem 0;
`;

const Table = styled.table`
    text-align: center;

    td {
        padding: 0.5rem 5rem;
        width: 10rem;
    }

    thead {
        opacity: 0.56;
    }

    tbody {
        font-weight: bold;
        font-size: 3.5rem;
    }

    tfoot {
        font-weight: bold;
    }

    @media screen and (max-width: ${WINDOW_WIDTH_MEDIUM_DESKTOP}px) {
        box-sizing: border-box;
        width: 100%;

        td {
            padding: 0.5rem 2rem;
            width: auto;
        }
    }

    @media screen and (max-width: ${WINDOW_WIDTH_PHONE_LANDSCAPE}px) {
        tbody {
            font-size: 2rem;
        }
    }
`;

export default function ActivityAttendance({ data }: Props) {
    return (
        <div>
            <ChartTitle>Summary</ChartTitle>
            <Container>
                <BoxContainer>
                    <Table>
                        <thead>
                            <tr>
                                <td />
                                <td />
                                <td />
                                <td>An average of</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{formatStatisticNumber(data.totalRegistrations)}</td>
                                <td>
                                    {formatStatisticNumber(
                                        data.ratioOfResidentsRegistered,
                                        'percent'
                                    )}
                                </td>
                                <td>
                                    {formatStatisticNumber(
                                        data.ratioOfActivitiesWithRegistrations,
                                        'percent'
                                    )}
                                </td>
                                <td>{formatStatisticNumber(data.registrationsPerResident)}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>Total Registrations</td>
                                <td>Of All Residents Registered</td>
                                <td>Activities has Registrations</td>
                                <td>Registrations per Resident</td>
                            </tr>
                        </tfoot>
                    </Table>
                </BoxContainer>
            </Container>
        </div>
    );
}

type Props = {
    data: ActivitySummary;
};
