/**
 * Gives you groups of page numbers to display that you can separate with "..." to not have to
 * display too many page numbers.
 * @param totalPages
 */
import {range} from './numbers';

export function groupPages(totalPages, currentPage = 1) {
    const ret = [];

    if (totalPages <= 15) {
        return [range(1, totalPages)];
    }

    const currentPageNearBeginning = currentPage <= 5;
    const currentPageNearEnd = currentPage >= totalPages - 4;

    // Let's determine the starting and ending page group ranges.
    // They are at least the first and last three, but if the current page is "near"
    // one of the edges (meaning being most two pages away from the base edge range),
    // then the edge range should extend to include the current page and its neighboring pages too.
    const startingRange = range(
        1,
        currentPageNearBeginning ? Math.max(3, currentPage + 1) : 3
    );
    const endingRange = range(
        currentPageNearEnd ? Math.min(totalPages - 2, currentPage - 1) : totalPages - 2,
        totalPages
    );

    // Determine the middle "island" of pages.
    // If the current page is in an edge range...
    const middlePage = startingRange.includes(currentPage) || endingRange.includes(currentPage) ?
        // ... the "middle" island should be in the middle.
        Math.round((startingRange[startingRange.length - 1] + endingRange[0] - 1) / 2) :
        // ... otherwise the middle island should be around the current page.
        currentPage;

    const middleRange = range(middlePage - 1, middlePage + 1);

    ret.push(startingRange);
    ret.push(middleRange);
    ret.push(endingRange);

    return ret;
}
