import React, { useCallback, useEffect, useMemo } from 'react';
import { Title } from '../components/CaseManagement/common';
import { PersonInfo } from '../values/appConfig';
import { match, useHistory } from 'react-router';
import { BulkUploadField } from '../values/types';
import theme, { yellow } from '../styles/theme';
import Button, {
    bigButtonCss,
    ButtonLabel,
    ButtonLink,
    PrimaryButton,
    primaryButtonDefaultProps,
    PrimaryButtonLink,
} from '../components/Button/Button';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useSelector } from '../store';
import {
    BulkUploadPossibleField,
    bulkUploadPossibleFieldsDataSorted,
    bulkUploadPossibleFieldsReset,
    bulkUploadPossibleFieldsSelector,
    loadBulkUploadPossibleFields,
} from '../store/reducers/bulkUploadPossibleFields';
import Alert from '../components/Alert';
import BigSpinner, { FullPageBigSpinner } from '../components/BigSpinner/BigSpinner';
import FieldErrors from '../components/FieldErrors';
import {
    bulkUploadHeadingsReset,
    bulkUploadHeadingsSelector,
    loadBulkUploadHeadings,
} from '../store/reducers/bulkUploadHeadings';
import { SelectOption } from '../components/UnstyledSelect';
import Select from '../components/Select';
import { ReactComponent as ArrowLong } from '../assets/img/arrow-long.svg';
import { invert, keyBy, mapValues } from 'lodash-es';
import { BULK_UPLOAD_FAILURE } from '../utils/errorHandling';
import ModalBox from '../components/ModalBox';
import BulkUploadFailures from '../components/BulkUploadFailures';
import {
    bulkUploadImportReset,
    bulkUploadImportSelector,
    loadBulkUploadImport,
} from '../store/reducers/bulkUploadImport';
import BulkUploadResult from '../components/BulkUploadResult';
import { displayError } from '../utils/toast';
import { createFormData } from '../utils/form';
import { unwrapResult } from '@reduxjs/toolkit';
import PageBox from '../components/PageBox';
import { loadPersons } from '../store/reducers/persons';
import { routes } from '../router/routes';
import { generatePath } from 'react-router-dom';
import { PersonCreateOrEditRouteParams } from '../router/caseManagementRoutes';

const FileUploadButton = styled(ButtonLabel)(() => bigButtonCss);
FileUploadButton.defaultProps = { ...primaryButtonDefaultProps, flavor: 'highlighted' };

// The bulk upload form with just the file field.
export interface BulkUploadFormJustFile {
    file: null | FileList;
}
export interface BulkUploadForm extends BulkUploadFormJustFile {
    mappings: {
        [fieldKey in BulkUploadField]?: string | null;
    };
}

const defaultValues: BulkUploadForm = {
    file: null,
    mappings: {},
};

const FileNameContainer = styled.div`
    width: 40rem;
    border: 0.1rem solid ${theme.primaryLight};
    color: ${theme.primaryLight};
    border-radius: 0.5rem;
    padding: 2rem;
    margin-left: 3rem;
`;

const Table = styled.table`
    th,
    td {
        padding: 1rem;
        vertical-align: middle;
    }

    thead {
        th {
            font-size: 1.5rem;
        }
    }

    tbody {
        color: ${theme.primaryLight};
    }
`;

/**
 * https://xd.adobe.com/view/8c31d2e4-2258-485c-578d-f4e4085c88d3-fa00/screen/32ec6533-f257-4a2e-ba64-51c10740d87d
 */
function BulkUploadPage(props: Props) {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {
        errors,
        handleSubmit,
        register,
        watch,
        getValues,
        setError,
        setValue,
        unregister,
        formState,
    } = useForm({
        defaultValues: defaultValues,
        mode: 'onChange', // to make formState.isValid checking more reliable.
    });
    const watched = watch(['file', 'mappings']);
    useEffect(() => {
        dispatch(loadBulkUploadPossibleFields(props.personInfo.key));
        return () => {
            dispatch(bulkUploadPossibleFieldsReset());
            dispatch(bulkUploadImportReset());
        };
    }, [dispatch, props.personInfo.key]);

    // Clear the headings when leaving the page.
    useEffect(() => {
        return () => {
            dispatch(bulkUploadHeadingsReset());
        };
    }, [dispatch]);

    const assembleBulkUploadFormData = useCallback(async () => {
        const { file: fileList, ...restValues } = getValues();
        const file = fileList![0];
        try {
            await file.slice(0, 1).arrayBuffer();
        } catch (e) {
            displayError(
                'The uploaded file could not be read. Have you saved changes to it? If you, please upload it again.'
            );
            setValue('file', null);
            throw e;
        }
        return createFormData({
            ...restValues,
            type: props.personInfo.key,
            file: file,
        });
    }, [getValues, props.personInfo.key, setValue]);

    const onFileSelected = useCallback(async () => {
        dispatch(bulkUploadHeadingsReset());
        const file = getValues().file?.[0];
        if (!file) {
            return;
        }
        dispatch(loadBulkUploadHeadings(file, setError));
    }, [dispatch, getValues, setError]);

    // Step 1: grab the possible fields to map to (before upload).
    const possibleFieldsState = useSelector(bulkUploadPossibleFieldsSelector);
    // Step 2: after uploading the spreadsheet, grab the heading columns.
    const headingsState = useSelector(bulkUploadHeadingsSelector);
    // Step 3: after mapping the spreadsheet headings to fields, upload to import.
    const importState = useSelector(bulkUploadImportSelector);

    const sortedPossibleFields = useSelector(bulkUploadPossibleFieldsDataSorted);

    const anyWaiting = useMemo(() => {
        return possibleFieldsState.waiting || headingsState.waiting || importState.waiting;
    }, [headingsState.waiting, importState.waiting, possibleFieldsState.waiting]);

    const allOptions: SelectOption<string | null>[] = useMemo(() => {
        if (!headingsState.data) {
            return [];
        }

        return (
            headingsState.data
                // Map to the select option format.
                .map(heading => ({
                    value: heading,
                    label: heading,
                }))
        );
    }, [headingsState.data]);

    const mapHeadingToField = useMemo(() => {
        const ret = invert(watched.mappings);
        return ret as { [heading: string]: BulkUploadField };
    }, [watched.mappings]);

    const mapFieldKeyToField = useMemo(() => {
        return possibleFieldsState.data
            ? (keyBy(possibleFieldsState.data, 'key') as Partial<
                  Record<BulkUploadField, BulkUploadPossibleField>
              >)
            : {};
    }, [possibleFieldsState.data]);

    const mapHeadingToFieldLabel = useMemo(() => {
        return mapValues(mapHeadingToField, fieldKey => mapFieldKeyToField[fieldKey]?.label) as {
            [heading: string]: string | undefined;
        };
    }, [mapFieldKeyToField, mapHeadingToField]);

    // Register the dynamic mappings form fields.
    useEffect(() => {
        const possibleFieldsData = possibleFieldsState.data;
        if (!possibleFieldsData) {
            return;
        }

        // We need to manually register the fields.
        // This has to do with the fact that the way the way we map headings to db fields on the FE
        // is inverted compared to how the API expects we send in the mapping.
        for (const possibleField of possibleFieldsData) {
            register(`mappings.${possibleField.key}`, {
                required: possibleField.required
                    ? `You must map the "${possibleField.label}" field.`
                    : false,
            });
        }
        return () => {
            for (const possibleField of possibleFieldsData) {
                unregister(`mappings.${possibleField.key}`);
            }
        };
    }, [possibleFieldsState.data, register, unregister]);

    useEffect(() => {
        const possibleFieldsData = possibleFieldsState.data;
        if (!possibleFieldsData) {
            return;
        }
        if (!headingsState.data) {
            return;
        }

        const newHeadings = headingsState.data;
        // Need to use getValues() to avoid an infinite useEffect() loop.
        const currentMappingValues = getValues().mappings;
        const mapHeadingToField = invert(currentMappingValues);

        for (const [heading, field] of Object.entries(mapHeadingToField)) {
            if (!newHeadings.includes(heading)) {
                setValue(`mappings.${field}`, null);
            }
        }
    }, [getValues, headingsState.data, possibleFieldsState.data, setValue]);

    // These are the options that should be shown as selectable.
    // This includes a null-option, but does not include "taken" options.
    const possibleOptions = useMemo(() => {
        return [
            {
                label: '(Please select a mapping)',
                value: null,
            },
            {
                label: '(None)',
                value: null,
            },
            // Do not show ones that are already taken.
            ...allOptions.filter(
                ({ value }) => value === null || !watched.mappings[mapHeadingToField[value]]
            ),
        ];
    }, [allOptions, mapHeadingToField, watched.mappings]);

    const submitActive = useMemo(() => {
        return (
            // Possible fields to map have been loaded.
            possibleFieldsState.data &&
            !importState.waiting &&
            // Headings of the uploaded spreadsheet has been loaded.
            headingsState.data &&
            !formState.isSubmitting &&
            // Allow submitting once with the form being invalid to trigger errors to show up
            // so that the user would know what to change before submitting again.
            (!formState.submitCount || formState.isValid)
        );
    }, [
        formState.isSubmitting,
        formState.isValid,
        formState.submitCount,
        headingsState.data,
        importState.waiting,
        possibleFieldsState.data,
    ]);

    const onSubmit = useCallback(async () => {
        if (!submitActive) {
            return;
        }
        try {
            const formData = await assembleBulkUploadFormData();
            await dispatch(loadBulkUploadImport(formData, setError)).then(unwrapResult);
            // Reload list of people.
            dispatch(loadPersons());
        } catch (e) {
            // Already handled.
            return;
        }
    }, [assembleBulkUploadFormData, dispatch, setError, submitActive]);

    const closeValidateModal = useCallback(() => dispatch(bulkUploadImportReset()), [dispatch]);

    if (possibleFieldsState.error) {
        return <Alert>Could not load the field mappings.</Alert>;
    }

    if (possibleFieldsState.waiting || !possibleFieldsState.data) {
        return <FullPageBigSpinner />;
    }

    return (
        <div>
            <div style={{ textAlign: 'center' }}>
                <Title style={{ marginBottom: '1rem' }}>Bulk Upload</Title>
                <div style={{ fontStyle: 'italic', color: theme.primaryLight }}>
                    Select file and map fields.
                </div>
                <div style={{ height: '3rem' }} />
            </div>
            <PageBox>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                        <FileUploadButton htmlFor="bulkFileUpload">
                            Browse and select file
                        </FileUploadButton>
                        <input
                            type="file"
                            ref={register({ required: 'You must select a file to upload.' })}
                            name="file"
                            id="bulkFileUpload"
                            accept=".xls,.xlsx"
                            hidden
                            onChange={onFileSelected}
                        />
                        <FileNameContainer>
                            {watched.file?.[0]?.name || <em>No file selected.</em>}
                        </FileNameContainer>
                        <div style={{ flex: 1 }} />
                    </div>
                    <FieldErrors errors={errors.file} />
                    {headingsState.waiting && <BigSpinner />}
                    {headingsState.data && sortedPossibleFields && (
                        <div>
                            <div style={{ height: '3rem' }} />
                            <Table>
                                <thead>
                                    <tr>
                                        <th style={{ width: '30rem', textAlign: 'left' }}>
                                            Case Management Information
                                        </th>
                                        <th style={{ width: '10rem' }}>&nbsp;</th>
                                        <th style={{ width: '30rem' }}>
                                            Map to your Spreadsheet Column...
                                        </th>
                                    </tr>
                                    <tr>
                                        <td
                                            colSpan={3}
                                            style={{
                                                fontWeight: 'bold',
                                                fontStyle: 'italic',
                                                color: yellow,
                                            }}
                                        >
                                            Items marked with * are required
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedPossibleFields.map((field, index) => {
                                        const value = watched.mappings[field.key];
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {field.label}
                                                    {field.required ? ' *' : ''}
                                                </td>
                                                <td>
                                                    <ArrowLong style={{ height: '2rem' }} />
                                                </td>
                                                <td>
                                                    <Select
                                                        options={possibleOptions}
                                                        value={
                                                            value ? { value, label: value } : null
                                                        }
                                                        onChange={v => {
                                                            setValue(`mappings.${field.key}`, v, {
                                                                shouldValidate: true,
                                                            });
                                                        }}
                                                    />
                                                    <FieldErrors
                                                        errors={errors.mappings?.[field.key]}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    )}
                    <div style={{ height: '3rem' }} />
                    <div style={{ textAlign: 'center' }}>
                        <PrimaryButton type="submit" disabled={!submitActive} waiting={anyWaiting}>
                            Complete Upload
                        </PrimaryButton>
                        <ButtonLink
                            style={{ marginLeft: '3rem' }}
                            flavor="link"
                            to={props.parentRouteMatch.url}
                        >
                            Cancel
                        </ButtonLink>
                    </div>
                </form>
            </PageBox>
            <ModalBox
                isOpen={importState.error?.type === BULK_UPLOAD_FAILURE}
                onRequestClose={closeValidateModal}
            >
                <div>
                    {importState.error?.type === BULK_UPLOAD_FAILURE && (
                        <BulkUploadFailures
                            failuresByRow={importState.error.data}
                            mapHeadingToFieldLabel={mapHeadingToFieldLabel}
                        />
                    )}
                    <div style={{ height: '3rem' }} />
                    <div style={{ textAlign: 'center' }}>
                        <PrimaryButton flavor="highlighted" onClick={closeValidateModal}>
                            Close
                        </PrimaryButton>
                    </div>
                </div>
            </ModalBox>
            <ModalBox
                isOpen={!!importState.data}
                onRequestClose={() => history.push(props.parentRouteMatch.url)}
            >
                <div>
                    {importState.data && (
                        <BulkUploadResult personInfo={props.personInfo} result={importState.data} />
                    )}
                </div>

                <div style={{ height: '3rem' }} />
                <div style={{ textAlign: 'center' }}>
                    <PrimaryButtonLink
                        type="button"
                        to={generatePath(routes.persons.fullPath, props.parentRouteMatch.params)}
                    >
                        Go to {props.personInfo.namePlural}
                    </PrimaryButtonLink>
                    <Button
                        style={{ marginLeft: '3rem' }}
                        flavor="link"
                        onClick={() => dispatch(bulkUploadImportReset())}
                    >
                        Close
                    </Button>
                </div>
            </ModalBox>
        </div>
    );
}

interface Props {
    personInfo: PersonInfo;
    parentRouteMatch: match<PersonCreateOrEditRouteParams>;
}

export default BulkUploadPage;
