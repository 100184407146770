import { ErrorOption } from 'react-hook-form/dist/types/errors';
import { FieldName } from 'react-hook-form';

/**
 * Adds validations coming from the API to the react-hook-form.
 *
 * https://www.carlrippon.com/react-hook-form-server-validation/
 */
export function addServerErrors<T>(
    errors: { [P in keyof T]?: string[] },
    setError: (fieldName: FieldName<T>, error: ErrorOption) => void
) {
    return Object.keys(errors).forEach(key => {
        setError(key as FieldName<T>, {
            type: 'server',
            message: errors[key as keyof T]!.join('. '),
        });
    });
}

const valuesToSkip = [undefined, null];

/**
 * https://gist.github.com/ghinda/8442a57f22099bdb2e34
 */
export function createFormData(object: Object, form?: FormData, namespace?: string): FormData {
    const formData = form || new FormData();
    for (let property in object) {
        if (valuesToSkip.includes(object[property])) {
            continue;
        }
        const formKey = namespace ? `${namespace}[${property}]` : property;
        if (object[property] instanceof Date) {
            formData.append(formKey, object[property].toISOString());
        } else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
            createFormData(object[property], formData, formKey);
        } else if (typeof object[property] === 'boolean') {
            // Laravel does not accept true/false as strings. Change them to string numbers instead.
            formData.append(formKey, object[property] ? '1' : '0');
        } else {
            formData.append(formKey, object[property]);
        }
    }
    return formData;
}
