import React from 'react';
import { PersonInfo } from '../values/appConfig';
import { match, Route, Switch } from 'react-router';
import PersonShowPage from './PersonShowPage';
import ManualPersonEditPage from './ManualPersonEditPage';
import { routes } from '../router/routes';
import { PersonSingleRouteParams, PersonsRouteParams } from '../router/caseManagementRoutes';

function PersonPage({ personInfo, match, parentRouteMatch }: Props) {
    return (
        <Switch>
            <Route
                path={`${match.path}/${routes.personsEdit.relativePath}`}
                render={() => (
                    <ManualPersonEditPage personInfo={personInfo} parentRouteMatch={match} />
                )}
            />
            <Route
                render={() => (
                    <PersonShowPage personInfo={personInfo} parentRouteMatch={parentRouteMatch} />
                )}
            />
        </Switch>
    );
}

interface Props {
    personInfo: PersonInfo;
    // Base /residents or /staff route.
    parentRouteMatch: match<PersonsRouteParams>;
    // E.g. /residents/321
    match: match<PersonSingleRouteParams>;
}

export default PersonPage;
