import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { Engage360Filters, LabelValue } from '../../values/types';

const slice = createFetchSlice('engageActivityUtilization', {}, getInitialState<LabelValue[]>());

export function loadEngageActivityUtilization(filters: Engage360Filters, options = {}) {
    return slice.load({
        uri: `/activities/utilization`,
        config: { params: filters },
        ...options,
    });
}

const engageActivityUtilization = slice.reducer;
const engageActivityUtilizationSelector = slice.selector;
const engageActivityUtilizationReset = slice.actions.reset;

export { engageActivityUtilizationSelector, engageActivityUtilizationReset };

export const engageActivityUtilizationDataSelector = createSelector(
    engageActivityUtilizationSelector,
    v => v.data
);

export default engageActivityUtilization;
