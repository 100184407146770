import React, {useEffect, useState} from 'react';
import {string} from 'prop-types';
import styled from '@emotion/styled';
import {css} from '../../../utils/ide';
import {format, parse} from 'date-fns';
import {calendarIcon, arrowRightIcon} from '../../../styles/fontLibrary';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const sidePaddingSize = '1.5rem';

const Container = styled.div(({theme}) => css`
    width: 22.5rem;
    margin: 0 auto;
    position: relative;
    border: 2px solid ${theme.primaryLight};
    box-sizing: border-box;
    height: 2.8rem;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    padding-left: ${sidePaddingSize};
`);

const RightArrow = styled(FontAwesomeIcon)`
    margin: 0 1.75rem;
    opacity: 0.56;
`;
RightArrow.defaultProps = {
    icon: arrowRightIcon,
};

function convertDateString(dateString) {
    return format(parse(dateString, 'yyyy-MM-dd', 0), 'MMM d, yyyy');
}

function DateInput({from, to}) {
    const [[displayFrom, displayTo], setDisplayRange] = useState(['', '']);
    useEffect(() => {
        setDisplayRange([convertDateString(from), convertDateString(to)]);
    }, [from, to]);

    return (
        <Container>
            <span style={{flex: 1}} />
            <span>{displayFrom}</span>
            <RightArrow />
            <span>{displayTo}</span>
            <span style={{flex: 1}} />
            <span style={{width: sidePaddingSize}} />
            <FontAwesomeIcon icon={calendarIcon} style={{marginRight: '0.5rem', fontSize: '1.75em'}} />
        </Container>
    );
}

DateInput.propTypes = {
    from: string,
    to: string,
};

export default DateInput;
