import React, { useCallback } from 'react';
import FiltersItems from '../FiltersItems';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkCircleIcon, circleIcon } from '../../../../styles/fontLibrary';
import { blue5 } from '../../../../styles/theme';
import styled from '@emotion/styled';
import InnerIconCheckboxSelector from './InnerIconCheckboxSelector/InnerIconCheckboxSelector';
import { IconNameKey } from '../../../../values/appConfig';
import { useAppDispatch, useSelector } from '../../../../store';
import { get } from 'lodash-es';
import { patchFilterItem } from '../../../../store/actions/reports';

const Option = styled.div`
    margin-top: 14px;
    display: flex;
    cursor: pointer;
`;

/**
 * A radio type filter that can reveal checkbox options depending on which radio value is selected.
 *
 * @param title
 * @param outerPath The store path within state.reports.filters with the selected radio value.
 * @param path The store path within state.reports.filters with the selected inner checkbox value.
 * @param filterKey String for a filter key to use (redux reducer/state), or
 *  an array of strings for fake data.
 * @param innerCheckboxConfigs An array that describes that depending on which radio value is
 *  selected, which checkbox-with-icon options should show up.
 * @returns {*}
 * @constructor
 */
function FilterRadioType({
    title,
    options: _options,
    outerPath,
    path,
    innerCheckboxConfigs = [],
    ...restProps
}: Props) {
    const dispatch = useAppDispatch();
    const selected = useSelector(state => get(state.reports.filters, outerPath ?? ''));
    const setSelected = useCallback(
        (value: string | null) => {
            dispatch(patchFilterItem(outerPath, value));
        },
        [dispatch, outerPath]
    );

    const options = [..._options, { value: null, label: 'All' }];
    const selectedNames =
        (selected ? options.find(({ value }) => value === selected)?.label : null) ?? null;

    const innerCheckboxConfig = innerCheckboxConfigs.find(({ value }) => value === selected);

    return (
        <FiltersItems
            title={title}
            selectedText={selectedNames}
            {...restProps}
            render={() => (
                <>
                    {options.map(item => {
                        const isActive = selected === item.value;
                        return (
                            <Option
                                key={item.value}
                                data-value={item.value}
                                onClick={() => setSelected(item.value)}
                            >
                                <FontAwesomeIcon
                                    icon={isActive ? checkCircleIcon : circleIcon}
                                    style={{
                                        fontSize: '1.6rem',
                                        marginRight: '0.8rem',
                                        opacity: isActive ? 1 : 0.56,
                                    }}
                                    color={isActive ? blue5 : 'inherit'}
                                />
                                <div style={{ marginTop: '0.25rem' }}>{item.label}</div>
                            </Option>
                        );
                    })}

                    {innerCheckboxConfig && path && (
                        <InnerIconCheckboxSelector
                            path={path}
                            checkboxConfig={innerCheckboxConfig.checkboxConfig}
                        />
                    )}
                </>
            )}
        />
    );
}

type Props = {
    title: string;
    outerPath: string;
    path?: string;
    options: Array<{ label: string; value: string }>;
    innerCheckboxConfigs?: Array<{ value: any; checkboxConfig: IconNameKey<string>[] }>;
};

export default FilterRadioType;
