import React, { useEffect } from 'react';
import { ContactTracingTitle, ContinueButton, TitledBox } from './common';
import Radio from '../../components/Radio/Radio';
import styled from '@emotion/styled';
import { remToNumber } from '../../utils/css';
import { routeForContactTracingResults } from './contactTracingRoutes';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { setContactTracingContactLevel } from '../../store/actions/contactTracing';
import { useSelector } from '../../store';
import { embeddedContactTracingLevels } from '../../values/appConfig';
import { routes } from '../../router/routes';

const Label = styled.label`
    margin: 1rem auto;
    display: block;
    width: 20rem;
    cursor: pointer;
    font-weight: bold;
`;

const radioSize = '1.6rem';
const radioOffset = (remToNumber(radioSize) - remToNumber('1rem')) / 2;

const Container = styled.div`
    margin: auto;
    width: 30rem;
`;

function ChooseContactLevelPage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const contactTracing = useSelector(state => state.contactTracing);
    const { idToTrack, contactLevel } = contactTracing;

    useEffect(() => {
        if (!idToTrack) {
            history.push(routes.contactTracing.fullPath);
            return;
        }
    }, [history, idToTrack]);

    const onContinue = () => {
        history.push(routeForContactTracingResults.path);
    };

    return (
        <Container>
            <ContactTracingTitle>Choose a contact level</ContactTracingTitle>
            <div>These can be adjusted later as needed</div>
            <div style={{ height: '2rem' }} />
            <TitledBox title="Maximum Contact Level">
                <div>
                    {embeddedContactTracingLevels.map(({ level, label, color }) => (
                        <Label style={{ color }} key={level}>
                            <Radio
                                selected={contactLevel}
                                value={level}
                                color={color}
                                onChange={() => dispatch(setContactTracingContactLevel(level))}
                                style={{ position: 'relative', top: radioOffset }}
                            />
                            <span style={{ color }}>{label}</span>
                        </Label>
                    ))}
                </div>
            </TitledBox>
            <div style={{ height: '2rem' }} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ContinueButton onClick={onContinue} />
            </div>
        </Container>
    );
}

export default ChooseContactLevelPage;
