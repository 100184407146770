import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { FacilityWithStatistics } from '../../values/types';

const slice = createFetchSlice('currentFacility', {}, getInitialState<FacilityWithStatistics>());

/**
 * Statistics for current facility.
 * @param options
 */

export function loadCurrentFacility(options = {}) {
    return slice.load({
        uri: `/facilities/current?showStats=1`,
        ...options,
    });
}

const currentFacility = slice.reducer;
const currentFacilitySelector = slice.selector;
const currentFacilityReset = slice.actions.reset;

export { currentFacilitySelector, currentFacilityReset };

export const currentFacilityDataSelector = createSelector(currentFacilitySelector, v => v.data);

export default currentFacility;
