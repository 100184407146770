import React, { useCallback } from 'react';
import ResizeDetector from 'react-resize-detector';
import { WINDOW_WIDTH_PHONE_LANDSCAPE } from '../ResponsiveProvider/ResponsiveProvider';
import { ReactComponent as LockImage } from '../../img/lock.svg';
import { logout, logoutUrl } from '../../utils/keycloak';

function NoFacilityAccessWarning() {
    const onLogoutClick = useCallback(event => {
        event.preventDefault();
        logout();
    }, []);

    return (
        <div>
            <ResizeDetector
                handleWidth
                handleHeight
                targetDomEl={document.body}
                render={({ width, height }) => {
                    const imageSize =
                        width < WINDOW_WIDTH_PHONE_LANDSCAPE || height < 600 ? 300 : 'auto';
                    return (
                        <div>
                            <LockImage
                                style={{
                                    marginBottom: '2rem',
                                    width: imageSize,
                                    height: imageSize,
                                }}
                            />
                        </div>
                    );
                }}
            />
            You do not have access to view this information.
            <br />
            <a href={logoutUrl} onClick={onLogoutClick}>
                Log in as another user.
            </a>
        </div>
    );
}

export default NoFacilityAccessWarning;
