import React, { useLayoutEffect, useMemo } from 'react';
import { PersonType } from '../values/types';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router';
import { personTypeInfoMap } from '../values/appConfig';
import styled from '@emotion/styled';
import { Title } from '../components/CaseManagement/common';
import BulkUploadOrManualEntryBox from '../components/BulkUploadOrManualEntryBox';
import { faFileExcel, faKeyboard, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { generatePath, Link } from 'react-router-dom';
import theme from '../styles/theme';
import ManualPersonEditPage from './ManualPersonEditPage';
import BulkUploadPage from './BulkUploadPage';
import { routes } from '../router/routes';
import { PersonsRouteParams } from '../router/caseManagementRoutes';
import { useSelector } from '../store';
import { facilityConfigSelector } from '../store/reducers/facilityConfig';

const Container = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        font-size: 2rem;
        color: white;
        margin: 0;
    }
`;

const BoxesContainer = styled.div`
    display: flex;

    > * {
        :not(:first-of-type) {
            margin-left: 2rem;
        }
    }
`;

const StyledLink = styled(Link)`
    color: ${theme.primaryBlue};
`;

const TemplateDownloadLink = styled.a`
    display: block;
    padding: 1rem;
    color: white;
`;

function PersonCreatePage() {
    const routeMatch = useRouteMatch<PersonsRouteParams>();
    const history = useHistory();
    const { isCentralOfficeAdmin } = useSelector(facilityConfigSelector);

    const personType: PersonType =
        routeMatch.params.personType === 'staff' ? 'caregiver' : 'resident';

    const personInfo = personTypeInfoMap[personType];
    const manualCreatePath = generatePath(routes.personsCreateManual.fullPath, routeMatch.params);
    const bulkUploadPath = useMemo(() => {
        return generatePath(routes.personsBulkUpload.fullPath, routeMatch.params);
    }, [routeMatch.params]);

    useLayoutEffect(() => {
        if (!isCentralOfficeAdmin) {
            // Go straight to the manual creation path, because if you are not a
            // central office admin, you can only CRUD manually.
            history.replace(manualCreatePath);
        }
    }, [isCentralOfficeAdmin, history, manualCreatePath]);

    return (
        <Switch>
            <Route
                path={`${routeMatch.path}/${routes.personsCreateManual.relativePath}`}
                render={() => (
                    <ManualPersonEditPage personInfo={personInfo} parentRouteMatch={routeMatch} />
                )}
            />
            <Route
                path={`${routeMatch.path}/${routes.personsBulkUpload.relativePath}`}
                render={() =>
                    isCentralOfficeAdmin ? (
                        <BulkUploadPage personInfo={personInfo} parentRouteMatch={routeMatch} />
                    ) : (
                        <Redirect to={routeMatch.url} />
                    )
                }
            />
            <Route
                render={() => (
                    <Container>
                        <Title>Add {personInfo.nameOptionalPlural}</Title>

                        <h2>Choose method</h2>

                        <div style={{ height: '3rem' }} />

                        <BoxesContainer>
                            <div>
                                <BulkUploadOrManualEntryBox
                                    to={bulkUploadPath}
                                    title="Bulk Upload"
                                    bodyText="Select file from your computer, upload and map columns to data field."
                                    lowerLeftIcon={faUpload}
                                    lowerRightIcons={[faFileExcel]}
                                />
                                <TemplateDownloadLink
                                    href={`/files/Sentrics Bulk Upload Template for ${personInfo.namePlural}.xls`}
                                >
                                    <strong>Not sure what to include?</strong>
                                    <br />
                                    Click here to download the template.
                                </TemplateDownloadLink>
                            </div>
                            <div>
                                <BulkUploadOrManualEntryBox
                                    to={manualCreatePath}
                                    title="Manual Entry"
                                    bodyText={`Quickly enter data for each ${
                                        personType === 'resident' ? 'resident' : 'staff member'
                                    }.`}
                                    lowerLeftIcon={faKeyboard}
                                />
                            </div>
                        </BoxesContainer>

                        <div style={{ height: '10rem' }} />

                        <div style={{ width: '55rem', textAlign: 'left' }}>
                            <StyledLink
                                to={generatePath(routes.persons.fullPath, routeMatch.params)}
                            >
                                Cancel
                            </StyledLink>
                        </div>
                    </Container>
                )}
            />
        </Switch>
    );
}

export default PersonCreatePage;
