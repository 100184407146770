import React from 'react';
import {H2} from '../../styles/shared';
import {XAxis, Bar, Legend, YAxis, CartesianGrid, Line, ComposedChart} from 'recharts';
import {array, number} from 'prop-types';
import darkTheme, {blue2, blue3, red} from '../../styles/theme';
import {format, parse} from 'date-fns';
import {createTooltip} from '../Recharts/Recharts';

function dayIndexToDayName(dayIndex) {
    return format(parse(dayIndex + 1, 'i', 0), 'iiii')
}

const colors = ['#00A1FF', '#0075B9', '#3474C0', blue3, blue2, '#6CC9FF', '#22AEFF'];

function AlarmActivityByHour({data, width}) {
    const showDaysOfWeek = width >= 600;

    const bars = showDaysOfWeek ? colors.map((color, index) => <Bar
        key={index}
        dataKey={`values.${index}`}
        name={dayIndexToDayName(index)}
        fill={color}
    />).reverse() : [<Bar key="total" dataKey="total" name="Total Alarms" fill="url(#colorUv)" />];

    const legendItemsCount = bars.length + (showDaysOfWeek ? 1 : 0);
    const lastIndex = legendItemsCount - 1;

    return (
        <div>
            <H2>Alarm Activity by Hour</H2>
            <div style={{height: '1rem'}} />
            <ComposedChart
                data={data}
                width={width}
                height={Math.max(300, width / 5)}
                barGap={'2%'}
                barCategoryGap={'2%'}
                reverseStackOrder
            >
                <defs>
                    <linearGradient
                        id="colorUv"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="100%"
                        spreadMethod="reflect"
                    >
                        {colors.map((color, index) => <stop key={index} offset={index} stopColor={color} />)}
                    </linearGradient>
                </defs>
                <XAxis
                    dataKey="date"
                    stroke={darkTheme.primaryLight}
                    axisLine={false}
                    tickLine={false}
                    tick={{fontSize: '0.8rem', transform: 'translate(0, 10)'}}
                />
                <YAxis
                    stroke={darkTheme.primaryLight}
                    width={30}
                    axisLine={false}
                    tickLine={false}
                    tick={{fontSize: '0.8rem'}}
                />
                {createTooltip()}
                <CartesianGrid strokeWidth={0.5} vertical={false}/>
                <Legend
                    wrapperStyle={{
                        paddingTop: '1rem'
                    }}
                    formatter={(value, _,  index) => (
                        <span
                            style={{
                                fontSize: '0.8rem',
                                color: darkTheme.primaryLight,
                                marginRight: index !== lastIndex ? '2.5rem' : 0,
                            }}
                        >{value}</span>
                    )}
                />

                {bars}

                {showDaysOfWeek && <Line
                    type="monotone"
                    dataKey="average"
                    name="Average"
                    stroke={red}
                    strokeWidth={3}
                    dot={false}
                    formatter={(value) => new Intl.NumberFormat('en').format(value)}
                />}
            </ComposedChart>
            <div style={{height: '3rem'}} />
        </div>
    );
}

AlarmActivityByHour.propTypes = {
    data: array,
    width: number,
};

export default AlarmActivityByHour;
