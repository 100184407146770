export type RouteDefinition = {
    relativePath: string;
};
type Route = {
    relativePath: string;
    fullPath: string;
};

const rootPath = {
    relativePath: '',
    fullPath: '',
};

function normalizeRouteDefinition(routeDefinition: RouteDefinition | string) {
    return typeof routeDefinition === 'string'
        ? { relativePath: routeDefinition }
        : routeDefinition;
}

export function defineRoute(routeDefinition: RouteDefinition | string) {
    return extendRoute(rootPath, routeDefinition);
}

export function extendRoute(toExtend: Route, routeDefinition: RouteDefinition | string) {
    const normalizedRouteDefinition = normalizeRouteDefinition(routeDefinition);
    return {
        ...normalizedRouteDefinition,
        fullPath: `${toExtend.fullPath}/${normalizedRouteDefinition.relativePath}`,
        extend(routeDefinition: RouteDefinition | string) {
            return extendRoute(this, routeDefinition);
        },
    };
}
