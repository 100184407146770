import React, { useMemo } from 'react';
import { ActivitiesByHourAndWeek } from '../../../values/types';
import ChartTitle from '../ChartTitle';
import Engage360Chart, { ChartDatum } from '../../Chart/Engage360Chart';
import { useTheme } from '../../../styles/theme';
import { partition, unzip } from 'lodash-es';
import { formatHour } from '../../../utils/date';
import ConditionalFlex from '../../ConditionalFlex';

export default function ActivityByHourAndWeek({ data }: Props) {
    const color = useTheme().primaryBlue;

    const byWeekData = useMemo(() => {
        const formatter = new Intl.DateTimeFormat(undefined, { weekday: 'long' });
        const ret = data.map((hourlyData, dayOfWeekIndex) => ({
            label: formatter.format(new Date(2012, 0, 1 + dayOfWeekIndex)),
            value: hourlyData.reduce((acc, current) => current + acc, 0),
            color,
        }));

        // Move Sunday to the end of the list.
        ret.push(ret.shift()!);

        return ret;
    }, [color, data]);

    const byHourData = useMemo(() => {
        const hourly = unzip(data).map((weeklyData, hourIndex) => ({
            label: hourIndex,
            value: weeklyData.reduce((acc, current) => current + acc, 0),
            color,
        }));

        const [peakHours, offPeakHours] = partition(
            hourly,
            ({ label }) => label >= 7 && label <= 19
        );

        // Unity all off peak hours to a new "other" chart item.
        const otherHours = offPeakHours.reduce(
            (acc, chartData) => ({
                ...chartData,
                value: acc.value + chartData.value,
            }),
            { value: 0 }
        );

        const ret = peakHours.map(chartData => ({
            ...chartData,
            label: formatHour(chartData.label) as string,
        })) as ChartDatum[];

        // Non-peak hours as a single bar.
        if (otherHours.value > 0) {
            ret.push({ ...otherHours, label: 'Other' });
        }

        return ret;
    }, [color, data]);

    return (
        <div>
            <ConditionalFlex>
                <div style={{ flex: 1, overflow: 'hidden' }}>
                    <ChartTitle>Popularity by Days of the Week</ChartTitle>
                    <Engage360Chart data={byWeekData} barLabels />
                </div>
                <div style={{ flex: 1, overflow: 'hidden' }}>
                    <ChartTitle>Popularity by Time of Day</ChartTitle>
                    <Engage360Chart data={byHourData} barLabels />
                </div>
            </ConditionalFlex>
        </div>
    );
}

interface Props {
    data: ActivitiesByHourAndWeek;
}
