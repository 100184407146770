import React, { useMemo } from 'react';
import { Disease, DiseaseCase, Person } from '../../values/types';
import { useSelector } from '../../store';
import styled from '@emotion/styled';
import PersonPhoto from '../PersonPhoto';
import { diseasesByUuidSelector } from '../../store/reducers/diseases';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faExclamationTriangle,
    faProjectDiagram,
    faVirus,
} from '@fortawesome/pro-regular-svg-icons';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { isDiseaseCaseExposure } from '../../values/appConfig';
import { formatDate } from '../../utils/date';
import { personsByIdSelector } from '../../store/reducers/persons';
import { routes } from '../../router/routes';

const FlexCenter = styled.div`
    display: flex;
    align-items: center;
`;

function PendingCase({ diseaseCase }: Props) {
    const history = useHistory();
    const personByIdMap = useSelector(personsByIdSelector);
    const diseaseByIdMap = useSelector(diseasesByUuidSelector);
    const person: Person | undefined = personByIdMap?.[diseaseCase.PersonKey];
    const disease: Disease | undefined = diseaseByIdMap[diseaseCase.diseaseUuid];
    const link = useMemo(() => {
        return generatePath(routes.diseaseCaseSingle.fullPath, {
            diseaseCaseUuid: diseaseCase.uuid,
        });
    }, [diseaseCase.uuid]);
    const isExposure = useMemo(() => {
        return isDiseaseCaseExposure(diseaseCase);
    }, [diseaseCase]);

    return (
        <>
            <tr className="clickable" onClick={() => history.push(link)}>
                <td>
                    <FlexCenter>
                        <PersonPhoto
                            size="2rem"
                            photo={person?.profilePhoto?.smallUrl}
                            style={{ marginRight: '1rem' }}
                        />
                        <strong>{person?.fullName ?? 'Unknown'}</strong>
                    </FlexCenter>
                </td>
                <td>
                    <FlexCenter>
                        <FontAwesomeIcon
                            icon={isExposure ? faExclamationTriangle : faVirus}
                            size="2x"
                            style={{ opacity: 0.5, marginRight: '1rem' }}
                        />
                        <strong style={{ color: 'white', fontSize: '1.5rem' }}>
                            {disease?.name ?? 'Unknown'}
                        </strong>
                    </FlexCenter>
                </td>
                <td>
                    <span style={{ opacity: 0.5 }}>
                        {formatDate(diseaseCase.exposedAt ?? diseaseCase.createdAt)}
                    </span>
                </td>
                <td>
                    <FlexCenter style={{ justifyContent: 'center' }}>
                        <FontAwesomeIcon
                            icon={faProjectDiagram}
                            size="2x"
                            style={{ marginRight: '1rem' }}
                        />
                        <Link
                            to={link}
                            style={{ color: 'white' }}
                            onClick={evt => evt.stopPropagation()}
                        >
                            <strong>Perform Contact Tracing & Protocol</strong>
                        </Link>
                    </FlexCenter>
                </td>
            </tr>
            <tr className="marginBottom">
                <td colSpan={5} />
            </tr>
        </>
    );
}

interface Props {
    diseaseCase: DiseaseCase;
}

export default PendingCase;
