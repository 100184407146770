import React from 'react';
import ReactDOM from 'react-dom';
import './utils/polyfill';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from 'emotion-theming';
import { Provider } from 'react-redux';
import 'normalize.css';
import store from './store';
import AppBoundary from './AppBoundary';
import './styles/fontLibrary';
import ResponsiveProvider from './components/ResponsiveProvider/ResponsiveProvider';
import { init as sentryInit } from '@sentry/browser';
import { initMixpanel } from './utils/mixpanel';
import { Router } from 'react-router';
import history from './utils/history';
import darkTheme from './styles/theme';
import ReactModal from 'react-modal';

if (process.env.REACT_APP_SENTRY_DSN) {
    sentryInit({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

initMixpanel();

if (window.Cypress) {
    window.store = store;
}

const app = (
    <Provider store={store}>
        <Router history={history}>
            <ThemeProvider theme={darkTheme}>
                <AppBoundary>
                    <ResponsiveProvider>
                        <App />
                    </ResponsiveProvider>
                </AppBoundary>
            </ThemeProvider>
        </Router>
    </Provider>
);

const rootElement = document.getElementById('root');
// For accessibility (or rather so that that dang warning in the console would quit bugging me).
ReactModal.setAppElement(rootElement);

ReactDOM.render(app, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
