import React, { useState, useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { searchIcon } from '../../styles/fontLibrary';
import styled from '@emotion/styled';
import { css } from '../../utils/ide';
import { useResponsive } from '../ResponsiveProvider/ResponsiveProvider';
import { debounce } from 'lodash-es';
import { parse, stringify } from 'qs';
import { useHistory } from 'react-router';
import { useSearch } from '../../utils/filter';

const SearchInputContainer = styled.label(
    ({ theme }) => css`
        display: inline-flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0 1rem;
        height: 3.2rem;
        border-radius: 1.5rem;
        background: ${theme.backgroundMediumContrast};
        border: 0;
        cursor: text;
    `
);

const Input = styled.input(
    ({ theme, singleColumnLayout }) => css`
        background-color: transparent;
        width: ${singleColumnLayout ? '15rem' : '23rem'};
        border: 0;
        outline: 0;
        margin-left: 0.75em;
        color: ${theme.primaryLight};
        opacity: 0.56;
        ::placeholder {
            color: ${theme.primaryLight};
        }
    `
);

function SearchInput() {
    const history = useHistory();
    const searchValue = useSearch();
    const [search, setSearch] = useState(searchValue);
    const setSearchInQuery = useCallback(
        debounce(str => {
            const location = { ...history.location };
            const oldQueryString = location.search;
            const { search: oldSearch, ...query } = parse(oldQueryString, {
                ignoreQueryPrefix: true,
            });
            if (str) {
                query.search = str;
            }
            let newQueryString = stringify(query, { addQueryPrefix: true });
            if (newQueryString === oldQueryString) {
                return;
            }

            history.push({ ...location, search: newQueryString, hash: '' });
        }, 500),
        []
    );
    useEffect(() => {
        setSearchInQuery(search);
    }, [search, setSearchInQuery]);

    const { singleColumnLayout } = useResponsive();
    return (
        <SearchInputContainer>
            <FontAwesomeIcon icon={searchIcon} style={{ fontSize: '1.5em' }} />
            <Input
                placeholder="Search"
                singleColumnLayout={singleColumnLayout}
                value={search}
                onChange={evt => setSearch(evt.target.value)}
            />
        </SearchInputContainer>
    );
}

export default SearchInput;
