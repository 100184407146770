import { BulkUploadValidateFailure, BulkUploadValidateFailureByRow } from '../values/types';

/**
 * Converts the bulk upload validation error format coming from the API to group the errors by row.
 */
export function convertBulkUploadFailuresToFailuresByRow(
    errors: BulkUploadValidateFailure[]
): BulkUploadValidateFailureByRow[] {
    const ret: BulkUploadValidateFailureByRow[] = [];
    // This is to make it easier to append to the fieldErrors array by referencing the row.
    const byRow: { [row: number]: BulkUploadValidateFailureByRow } = {};

    for (const failure of errors) {
        const { row, ...fieldError } = failure;
        if (!byRow[row]) {
            const ofRow = { row, fieldErrors: [] };
            byRow[row] = ofRow;
            ret.push(ofRow);
        }
        byRow[row].fieldErrors.push(fieldError);
    }
    // Sort by row number.
    ret.sort((failureRow1, failureRow2) => failureRow1.row - failureRow2.row);

    return ret;
}
