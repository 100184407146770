import React from 'react';
import RepairsSummary from '../../components/Engage360/Repairs/RepairsSummary';
import { RepairsByRoomAndIssue } from '../../components/Engage360/Repairs/RepairsByRoomAndIssue';
import RepairsTablePage from './RepairsTablePage';

export default function RepairsPage() {
    return (
        <div>
            <RepairsSummary />
            <div style={{ height: '3rem' }} />
            <RepairsByRoomAndIssue />

            <RepairsTablePage />
        </div>
    );
}
