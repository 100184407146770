import React, { useCallback, useEffect, useMemo, useState } from 'react';
import pluralize from 'pluralize';
import { Title } from '../components/CaseManagement/common';
import Paginate2 from '../components/Pagination/Paginate2';
import { PrimaryButtonLink } from '../components/Button/Button';
import CasesOrExposedTable from '../components/CasesOrExposedTable';
import SelectableItemGridContainer from '../components/SelectableItemGridContainer';
import PersonBox from './ContactTracing/SelectResident/PersonBox';
import { useSelector } from '../store';
import { PAGE_SIZE, PersonInfo } from '../values/appConfig';
import { Link } from 'react-router-dom';
import { createLinkToPerson } from '../router/routeConfig';
import styled from '@emotion/styled';
import { filter, slice, uniqBy } from 'lodash-es';
import { useLayoutCustomization } from '../context/LayoutContext';
import { useSearch } from '../utils/filter';
import { diseaseCasesDataSelector } from '../store/reducers/diseaseCases';
import { personsByPersonTypeSelector } from '../store/reducers/reports';
import { DiseaseCaseWithTests } from '../values/types';
import { CheckboxWithLabel } from '../components/CheckboxWithLabel/CheckboxWithLabel';
import { gray600 } from '../styles/theme';
import { useForm } from 'react-hook-form';
import Select from '../components/Select';
import { diseasesByUuidSelector } from '../store/reducers/diseases';
import { hasPositiveTests, isComplete, isInQuarantine } from '../components/CasesDataTable';
import { personsByIdSelector } from '../store/reducers/persons';
import { generatePath, useRouteMatch } from 'react-router';
import { routes } from '../router/routes';
import { PersonsRouteParams } from '../router/caseManagementRoutes';
import { facilityConfigSelector } from '../store/reducers/facilityConfig';
import BigSpinner from '../components/BigSpinner/BigSpinner';

const Container = styled.div`
    h2 {
        font-size: 2rem;
        color: white;
    }
`;

function PersonsListPage({ personInfo }: Props) {
    useLayoutCustomization({ search: true });
    const searchTerm = useSearch();

    const { setValue, register, watch } = useForm<{
        diseaseFilter: string[];
        hasPositiveTests: '1' | '';
        isComplete: '1' | '';
        isInQuarantine: '1' | '';
    }>({
        defaultValues: {
            diseaseFilter: [],
            hasPositiveTests: '',
            isComplete: '',
            isInQuarantine: '',
        },
    });
    register('diseaseFilter');
    const watched = watch(['diseaseFilter', 'hasPositiveTests', 'isComplete', 'isInQuarantine']);

    const { isStandalone } = useSelector(facilityConfigSelector);
    const personType = personInfo.key;
    const diseaseCases = useSelector(diseaseCasesDataSelector);
    const diseaseByUuid = useSelector(diseasesByUuidSelector);
    const personByIdMap = useSelector(personsByIdSelector);
    const [currentPage, setCurrentPage] = useState(1);
    const isResident = personType === 'resident';
    const peopleByPersonType = useSelector(personsByPersonTypeSelector);
    const persons = isResident ? peopleByPersonType.resident : peopleByPersonType.caregiver;
    const restPersonsTitle = isResident ? `All ${personInfo.namePlural}` : personInfo.namePlural;
    const match = useRouteMatch<PersonsRouteParams>();

    const filterDiseaseCasesByPersonType = useCallback(
        (diseaseCases: DiseaseCaseWithTests[] | null) => {
            if (!diseaseCases) {
                return [];
            }
            return diseaseCases.filter(
                v => personByIdMap?.[v.PersonKey ?? '']?.isCaregiver !== isResident
            );
        },
        [isResident, personByIdMap]
    );

    const diseaseCasesForPersonType = useMemo(() => {
        return filterDiseaseCasesByPersonType(diseaseCases);
    }, [diseaseCases, filterDiseaseCasesByPersonType]);

    const diseaseList = useMemo(
        () =>
            uniqBy(diseaseCasesForPersonType, 'diseaseUuid').map(
                diseaseCase => diseaseByUuid[diseaseCase.diseaseUuid]
            ),
        [diseaseByUuid, diseaseCasesForPersonType]
    );

    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm]);

    let personsFiltered = useMemo(() => {
        return searchTerm
            ? filter(persons, l => {
                  return (
                      l.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
                      l.secondName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
                  );
              })
            : persons;
    }, [persons, searchTerm]);

    const pageSearch = useMemo(() => {
        return slice(personsFiltered, (currentPage - 1) * PAGE_SIZE, PAGE_SIZE * currentPage);
    }, [currentPage, personsFiltered]);

    const pageAll = useMemo(() => {
        return slice(persons, (currentPage - 1) * PAGE_SIZE, PAGE_SIZE * currentPage);
    }, [persons, currentPage]);

    // if search is used, apply pagination to search results, otherwise to "all" list
    const page = searchTerm ? pageSearch : pageAll;
    const pageCount = searchTerm ? personsFiltered.length : persons.length;

    const diseaseOptionsList = diseaseList.map(disease => ({
        value: disease?.uuid,
        label: disease ? disease.name : 'NO_NAME',
    }));

    const diseaseCasesFiltered = useMemo(() => {
        let res = diseaseCasesForPersonType;
        if (watched.diseaseFilter.length) {
            res = res.filter(diseaseCase => {
                return watched.diseaseFilter.includes(diseaseCase.diseaseUuid);
            });
        }
        if (watched.hasPositiveTests) {
            res = res.filter(diseaseCase => hasPositiveTests(diseaseCase));
        }
        if (watched.isComplete) {
            res = res.filter(diseaseCase => isComplete(diseaseCase));
        }
        if (watched.isInQuarantine) {
            res = res.filter(diseaseCase => isInQuarantine(diseaseCase));
        }
        return res;
    }, [
        diseaseCasesForPersonType,
        watched.diseaseFilter,
        watched.hasPositiveTests,
        watched.isComplete,
        watched.isInQuarantine,
    ]);

    if (!personByIdMap) {
        return <BigSpinner />;
    }

    return (
        <div>
            {searchTerm ? (
                <Container>
                    <Title>
                        {pageCount} search {pluralize('result', pageCount)} for '{searchTerm}'...
                    </Title>
                </Container>
            ) : (
                <Container>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Title>
                            Case Management - {personInfo.namePlural} ({persons.length} Total)
                        </Title>
                        <div style={{ flex: 1 }} />
                        {isStandalone && (
                            <PrimaryButtonLink
                                to={generatePath(routes.personsCreate.fullPath, match.params)}
                            >
                                + Add New {personInfo.name}
                            </PrimaryButtonLink>
                        )}
                    </div>
                    {isResident && (
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    color: gray600,
                                }}
                            >
                                <div style={{ width: '30rem', marginRight: '1.2rem' }}>
                                    <Select
                                        isMulti
                                        placeholder="Filter diseases..."
                                        options={diseaseOptionsList}
                                        value={watched.diseaseFilter}
                                        onChange={v => {
                                            setValue('diseaseFilter', v);
                                        }}
                                    />
                                </div>
                                <CheckboxWithLabel
                                    name="hasPositiveTests"
                                    label="Had Positive test(s)"
                                    register={register}
                                />
                                <CheckboxWithLabel
                                    name="isInQuarantine"
                                    label="In Quarantine/Isolation"
                                    register={register}
                                />
                                <CheckboxWithLabel
                                    name="isComplete"
                                    label="Closed"
                                    register={register}
                                />
                            </div>
                            <CasesOrExposedTable
                                personType={personType}
                                diseaseCases={diseaseCasesFiltered}
                            />
                            <div style={{ height: '2rem' }} />
                        </div>
                    )}
                    <h2>
                        {restPersonsTitle} ({persons.length})
                    </h2>
                </Container>
            )}
            <SelectableItemGridContainer>
                {page.map(v => (
                    <Link key={v.id} to={createLinkToPerson(v)}>
                        <PersonBox key={v.id} selected={false} person={v} />
                    </Link>
                ))}
            </SelectableItemGridContainer>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Paginate2
                    totalCount={pageCount}
                    pageSize={PAGE_SIZE}
                    setCurrentPage={setCurrentPage}
                />
            </div>
        </div>
    );
}

interface Props {
    personInfo: PersonInfo;
}

export default PersonsListPage;
