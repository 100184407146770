import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from '@emotion/styled';
import {faChevronLeft, faChevronRight} from '@fortawesome/pro-solid-svg-icons';

const Container = styled.div`
    background: ${({theme}) => theme.background};
    height: 20px;
    opacity: ${props => props.disabled ? 0 : 1};
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    border-radius: 50%;
    box-shadow: ${props => props.theme.shadow4};
    ${props => !props.disabled ? 'cursor: pointer;' : null};
    user-select: none;
    &:hover {
        background: ${props => props.theme.gray50};
        color: ${props => props.theme.primary};
    }
`;

const PaginateArrow = (props) => {
    const {disabled, onClick, left} = props;
    const icon = left ? faChevronLeft : faChevronRight;
    return (
        <Container onClick={disabled ? null : onClick} disabled={props.disabled}>
            <FontAwesomeIcon icon={icon} />
        </Container>
    )
};

export default PaginateArrow;
