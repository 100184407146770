import React, { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { identity } from 'lodash-es';
import styled from '@emotion/styled';
import theme from '../styles/theme';

const Container = styled.div`
    margin: 1rem 0;
    color: ${theme.error};
`;

function FieldErrors(props: Props) {
    const fieldErrors = useMemo(() => {
        return (Array.isArray(props.errors) ? props.errors : [props.errors]).filter(
            identity
        ) as FieldError[];
    }, [props.errors]);

    if (!fieldErrors.length) {
        return null;
    }

    return (
        <Container>
            {fieldErrors.map((fieldError, index) => (
                <div key={index}>{fieldError.message}</div>
            ))}
        </Container>
    );
}

interface Props {
    errors?: FieldError | (FieldError | undefined)[];
}

export default FieldErrors;
