import React, { useCallback, useMemo, useState } from 'react';
import Button, { PrimaryButton } from '../Button/Button';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import FieldErrors from '../FieldErrors';
import { useSelector } from '../../store';
import Select from '../Select';
import { displaySuccess } from '../../utils/toast';
import { handleError } from '../../utils/errorHandling';
import { ContactLevel, DiseaseCaseSingle } from '../../values/types';
import ModalBox from '../ModalBox';
import { createExposures } from '../../api/exposures';
import { GroupLabel, InputComponentsContainer } from '../Form/formCommon';
import Input from '../Input/Input';
import styled from '@emotion/styled';
import { wizardGray } from '../Wizard/WizardStep';
import { SelectOption } from '../UnstyledSelect';
import { formatISODateWithT, getHourOptionsSelect, getMinuteOptionsSelect } from '../../utils/date';
import { add, parseISO } from 'date-fns';
import { personsByIdSelector, residentsAsArraySelector } from '../../store/reducers/persons';
import { locationsDataSelector } from '../../store/reducers/locations';
import { MediumSpinner } from '../BigSpinner/BigSpinner';

type ExposureForm = {
    personKey: number | null;
    exposedAt: string | null;
    exposureLocationKey: number | null;
    exposureDurationMinutes: number;
};

const Spacer = styled.div`
    height: 3rem;
`;

function minutesToHoursMinutes(minutes: number) {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;

    return { minutes: m.toString(), hours: h.toString() };
}

function DirectContactsTable({
    diseaseCase,
    rootDiseaseCaseUuid,
    onAfterCreateExposures,
    minOverlapMinutes,
}: Props) {
    const form = useForm<ExposureForm>({
        defaultValues: {
            personKey: null,
            exposedAt: null,
            exposureDurationMinutes: minOverlapMinutes,
            exposureLocationKey: null,
        },
    });
    const diseaseCaseUuid = diseaseCase.uuid;
    const { control, errors, handleSubmit, register, setError, setValue, watch } = form;
    const [addDirectContactModalOpen, setAddDirectContactModalOpen] = useState(false);
    const [saving, setSaving] = useState(false);

    const residents = useSelector(residentsAsArraySelector);
    const personByIdMap = useSelector(personsByIdSelector);
    const availablePersonOptions = useMemo(() => {
        return residents.map(({ id, fullName }) => ({ label: fullName, value: id }));
    }, [residents]);

    const locations = useSelector(locationsDataSelector);
    const locationOptions = useMemo(() => {
        return (
            locations &&
            locations.map(
                ({ id, name }): SelectOption<number> => ({
                    value: id,
                    label: name,
                })
            )
        );
    }, [locations]);

    register('exposureDurationMinutes', {
        min: {
            value: minOverlapMinutes,
            message: `The amount of overlap is less than the minimum overlap set for this disease: ${minOverlapMinutes} minute(s).`,
        },
    });
    const watched = watch(['exposureDurationMinutes']);

    const { hours: overlapHours, minutes: overlapMinutes } = useMemo(() => {
        return watched.exposureDurationMinutes
            ? minutesToHoursMinutes(watched.exposureDurationMinutes)
            : { hours: null, minutes: null };
    }, [watched.exposureDurationMinutes]);

    const onSubmit: SubmitHandler<ExposureForm> = useCallback(
        async ({ exposedAt, exposureDurationMinutes, ...values }) => {
            try {
                setSaving(true);
                await createExposures(rootDiseaseCaseUuid, [
                    {
                        ...values,
                        parentUuid: diseaseCaseUuid,
                        contactLevel: ((diseaseCase.rootContactLevel ?? 0) + 1) as ContactLevel,
                        exposedAt: exposedAt ? formatISODateWithT(parseISO(exposedAt)) : null,
                        exposureDurationSeconds: exposureDurationMinutes * 60,
                    },
                ]);
                displaySuccess('You have successfully created a new exposure.');
                setAddDirectContactModalOpen(false);
                onAfterCreateExposures();
            } catch (e) {
                handleError(e, { setError });
            } finally {
                setSaving(false);
            }
        },
        [
            diseaseCase.rootContactLevel,
            diseaseCaseUuid,
            onAfterCreateExposures,
            rootDiseaseCaseUuid,
            setError,
        ]
    );

    const onRequestClose = () => setAddDirectContactModalOpen(false);

    const nowPlusOneHour = add(new Date(), { hours: 1 });

    return (
        <>
            <div style={{ height: '1rem' }} />

            <PrimaryButton onClick={() => setAddDirectContactModalOpen(true)}>
                + Add Direct Contact
            </PrimaryButton>

            <ModalBox
                isOpen={addDirectContactModalOpen}
                onRequestClose={onRequestClose}
                style={{ color: wizardGray }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <GroupLabel style={{ color: 'white' }}>Add Direct Contact</GroupLabel>
                    <div style={{ width: '40rem' }}>
                        <Controller
                            control={control}
                            rules={{ required: 'The exposed person is required.' }}
                            name="personKey"
                            render={({ onChange, onBlur, value }) => (
                                <Select
                                    onBlur={onBlur}
                                    placeholder="Select The Direct Contact Subject..."
                                    value={
                                        value && {
                                            value,
                                            // Take the label from the mapping,
                                            // because the dropdown options do not
                                            // include already selected items.
                                            label: `${
                                                personByIdMap?.[value]?.fullName ?? '(Unknown)'
                                            }`,
                                        }
                                    }
                                    options={availablePersonOptions}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </div>
                    <FieldErrors errors={[errors?.personKey]} />
                    <Spacer />
                    <GroupLabel>Date of Contact</GroupLabel>
                    <InputComponentsContainer>
                        <div style={{ width: '30rem' }}>
                            <Input
                                type="datetime-local"
                                name="exposedAt"
                                max={formatISODateWithT(nowPlusOneHour)}
                                ref={register}
                            />
                        </div>
                    </InputComponentsContainer>
                    <FieldErrors errors={[errors.exposedAt]} />

                    <Spacer />

                    <GroupLabel>Overlap</GroupLabel>
                    <InputComponentsContainer>
                        <div style={{ width: '8rem' }}>
                            <Select
                                options={getHourOptionsSelect()}
                                value={overlapHours}
                                placeholder={'Hours...'}
                                onChange={v => {
                                    setValue(
                                        'exposureDurationMinutes',
                                        parseInt(v) * 60 + parseInt(overlapMinutes || ''),
                                        {
                                            shouldValidate: true,
                                        }
                                    );
                                }}
                            />
                        </div>
                        <div style={{ width: '8rem' }}>
                            <Select
                                options={getMinuteOptionsSelect()}
                                value={overlapMinutes}
                                placeholder={'Minutes...'}
                                onChange={v => {
                                    setValue(
                                        'exposureDurationMinutes',
                                        parseInt(overlapHours || '') * 60 + parseInt(v),
                                        {
                                            shouldValidate: true,
                                        }
                                    );
                                }}
                            />
                        </div>
                    </InputComponentsContainer>
                    <FieldErrors errors={[errors.exposureDurationMinutes]} />

                    <Spacer />

                    <GroupLabel>Location</GroupLabel>
                    <InputComponentsContainer>
                        <div style={{ width: '20rem' }}>
                            {locationOptions ? (
                                <Controller
                                    name="exposureLocationKey"
                                    control={control}
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Select
                                            name={name}
                                            onBlur={onBlur}
                                            options={locationOptions}
                                            value={value}
                                            onChange={v => onChange(v)}
                                        />
                                    )}
                                />
                            ) : (
                                <MediumSpinner />
                            )}
                        </div>
                    </InputComponentsContainer>
                    <FieldErrors errors={errors.exposureLocationKey} />

                    <Spacer />

                    <div>
                        Isolation and quarantine will show as pending for those exposed until
                        confirmed by care staff.
                    </div>

                    <Spacer />

                    <div style={{ textAlign: 'center' }}>
                        <PrimaryButton type="submit" waiting={saving}>
                            Add Contact & Designate Exposed
                        </PrimaryButton>
                        <Button
                            style={{ marginLeft: '3rem' }}
                            flavor="link"
                            onClick={onRequestClose}
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            </ModalBox>
        </>
    );
}

interface Props {
    diseaseCase: DiseaseCaseSingle;
    rootDiseaseCaseUuid: string;
    onAfterCreateExposures: () => void;
    minOverlapMinutes: number;
}

export default DirectContactsTable;
