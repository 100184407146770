import { elapsedTimeRange, responseTimeRange } from '../store/actions/reports';
import { useCallback, useMemo } from 'react';
import { parse, stringify } from 'qs';
import { useHistory } from 'react-router';
import { LocationDescriptorObject } from 'history';
import { cloneDeep, get, set, unset } from 'lodash-es';

interface FilterResponseTimeInput {
    responseTimeMinutes?: number[];
    elapsedTimeMinutes?: number[];
}

type ResponseTimeSeconds = (number | null)[];

interface FilterResponseTimeOutput {
    responseTimeSeconds?: ResponseTimeSeconds;
}

// Use state bound to the query.
export function useQueryState(key: string) {
    const history = useHistory();
    const queryObj = useMemo(() => {
        return parse(history.location.search || '', { ignoreQueryPrefix: true });
    }, [history.location.search]);
    const value = useMemo(() => {
        const value = get(queryObj, key);

        // Ensure only string or null values.
        if (typeof value !== 'string') {
            return null;
        }

        return value;
    }, [key, queryObj]);
    const setValue = useCallback(
        (value: string | number | null) => {
            const newQueryObj = cloneDeep(queryObj);
            if (value !== null) {
                set(newQueryObj, key, value);
            } else {
                unset(newQueryObj, key);
            }
            history.push(
                history.location.pathname + stringify(newQueryObj, { addQueryPrefix: true })
            );
        },
        [history, key, queryObj]
    );

    return [value, setValue] as [typeof value, typeof setValue];
}

export function getSearchFromLocation(location: LocationDescriptorObject) {
    return parse(location.search || '', { ignoreQueryPrefix: true }).search?.toString() || '';
}

export function useSearch() {
    const history = useHistory();
    return useMemo(() => {
        return getSearchFromLocation(history.location);
    }, [history.location]);
}

/**
 * Sets the appropriate response time filter on the query depending on the selected response time
 * and elapsed time ranges.
 *
 * Note that the difference between the two is that if there is _any_ response time set, then that
 * should mean that only closed alarms should be listed.
 *
 * @param filter
 * @param query
 */
export function amendFilterQueryByResponseTimes(
    filter: FilterResponseTimeInput,
    query: FilterResponseTimeOutput
) {
    if (!('responseTimeMinutes' in filter || 'elapsedTimeMinutes' in filter)) {
        return;
    }

    let responseTimeSeconds: ResponseTimeSeconds = [null, null];

    if (filter.responseTimeMinutes) {
        // Convert minutes to seconds.
        responseTimeSeconds = filter.responseTimeMinutes
            // Multiply by 60 to get the seconds. If any value is above the range,
            // then treat any that are past the max range as NULL to express infinity.
            .map(value => (value <= responseTimeRange[1] ? value * 60 : null));

        if (responseTimeSeconds[1] === null && responseTimeSeconds[0]) {
            // If anything from the response time is set other than max range,
            // then make sure only closed events are listed by setting the response time limit
            // to an arbitrarily high number.
            responseTimeSeconds[1] = 100000000;
        }
    }

    if (filter.elapsedTimeMinutes) {
        // Convert minutes to seconds.
        const elapsedTimeSeconds = filter.elapsedTimeMinutes
            // Multiply by 60 to get the seconds. If any value is above the range,
            // then treat any that are past the max range as NULL to express infinity.
            .map(value => (value <= elapsedTimeRange[1] ? value * 60 : null));

        // Apply the stricter intersection between the two filter values.
        if (
            responseTimeSeconds[0] === null ||
            Number(elapsedTimeSeconds[0]) > responseTimeSeconds[0]
        ) {
            responseTimeSeconds[0] = elapsedTimeSeconds[0];
        }
        if (
            responseTimeSeconds[1] === null ||
            Number(elapsedTimeSeconds[1]) < responseTimeSeconds[1]
        ) {
            responseTimeSeconds[1] = elapsedTimeSeconds[1];
        }
    }

    query.responseTimeSeconds = responseTimeSeconds;

    return;
}
