import React from 'react';
import {useSelector} from 'react-redux';
import {lightFormat} from 'date-fns';
import {H2} from '../../styles/shared';
import ValueCard from '../ValueCard/ValueCard';
import {number} from 'prop-types';
import ValueCardGrid from '../../ValueCardGrid/ValueCardGrid';

function PeakShiftsAndHours({width, ...props}) {
    const dashboardData = useSelector(state => state.dashboard.data);
    const peakShiftsData = dashboardData.peakShifts;

    const peakShifts = [
        ['12 AM - 8 AM', peakShiftsData[0]],
        ['8 AM - 4 PM', peakShiftsData[1]],
        ['4 PM - 12 AM', peakShiftsData[2]],
    ];
    const peakHours = dashboardData.peakHours.map(({group: hour, count}) => [
        lightFormat(new Date(
            2012,
            6,
            6,
            hour,
            0,
            0,
        ), 'h a'),
        count,
    ]);

    const showSideBySide = width >= 1400;
    return (
        <div style={{display: showSideBySide ? 'flex' : 'block'}} {...props}>
            <div style={{flex: 675, marginRight: showSideBySide ? '4.4rem' : 0, marginBottom: '2rem'}}>
                <H2>Peak Hours</H2>
                <ValueCardGrid columns={3}>
                    {peakHours.map(([label, eventCount]) => {
                        return <ValueCard
                            key={label}
                            style={{fontSize: '2.8rem'}}
                            topContent={label}
                            bottomContent={<div style={{opacity: 0.56}}>{eventCount} Alarms</div>}
                        />;
                    })}
                </ValueCardGrid>
            </div>
            <div style={{flex: 864, marginBottom: '2rem'}}>
                <H2>Shift Peaks</H2>
                <ValueCardGrid columns={3}>
                    {peakShifts.map(([label, eventCount]) => {
                        return <ValueCard
                            key={label}
                            style={{fontSize: '2.8rem'}}
                            topContent={(
                                <span>
                                    {eventCount}{' '}
                                    <span style={{fontSize: '1.6rem'}}>alarms</span>
                                </span>
                            )}
                            bottomContent={<div style={{opacity: 0.56}}>{label}</div>}
                        />;
                    })}
                </ValueCardGrid>
            </div>
        </div>
    );
}

PeakShiftsAndHours.propTypes = {
    width: number,
};

export default PeakShiftsAndHours;
