import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { userCircleIcon } from '../../styles/fontLibrary';
import styled from '@emotion/styled';
import { Box } from '../../styles/shared';
import {
    faMapMarkerAlt,
    faUserClock,
    faUserMd,
    faUsers,
    faVirus,
} from '@fortawesome/pro-regular-svg-icons';
import { wizardGray } from '../../components/Wizard/WizardStep';
import DataTable, { DatatableProps, HeaderConfig, Td } from '../../components/DataTable/DataTable';
import { embeddedContactTracingLevels } from '../../values/appConfig';
import { DataTableTr } from '../../components/DataTable/shared';
import ContactTracingResultExpanded, {
    getPictureOfContact,
} from '../../components/ContactTracingResultExpanded/ContactTracingResultExpanded';
import { WINDOW_WIDTH_PHONE_LANDSCAPE } from '../../components/ResponsiveProvider/ResponsiveProvider';
import { useAppDispatch, useSelector } from '../../store';
import { useHistory } from 'react-router';
import { AmendedContact, HasId } from '../../values/types';
import { sum } from 'lodash-es';
import { humanizeElapsedSecondsRoundedToMinutes } from '../../utils/date';
import {
    contactTracingResultsDataAmendedSelector,
    contactTracingResultsReset,
    contactTracingResultsSelector,
    loadContactTracingResults,
} from '../../store/reducers/contactTracingResults';
import ContactTracingLoading from '../../components/ContactTracing/ContactTracingLoading';
import { personsDataAsArraySelector } from '../../store/reducers/persons';
import { routes } from '../../router/routes';

const H1 = styled.h1`
    color: white;
    font-size: 2rem;
`;

const Container = styled.div`
    text-align: left;
    padding: 3rem;

    @media screen and (max-width: ${WINDOW_WIDTH_PHONE_LANDSCAPE}px) {
        padding: 2rem;
    }
`;

export const H2 = styled.h2`
    margin-top: 0;
    font-size: 1.5rem;
`;

const TopBox = styled(Box)`
    min-height: 8rem;

    :not(:first-of-type) {
        margin-left: 2rem;
    }

    @media screen and (max-width: ${WINDOW_WIDTH_PHONE_LANDSCAPE}px) {
        :not(:first-of-type) {
            margin-left: 0;
            margin-top: 1rem;
        }
    }
`;

const TopFlexContainer = styled.div`
    display: flex;
    width: 100%;
    text-align: center;
    color: ${wizardGray};
    align-items: center;
    justify-content: center;

    @media screen and (max-width: ${WINDOW_WIDTH_PHONE_LANDSCAPE}px) {
        display: block;
    }
`;

const LargeText = styled.span`
    font-size: 3rem;
    font-weight: bold;
`;

const MediumText = styled.span`
    font-size: 1.25rem;
    line-height: 1.25;
    font-weight: bold;
`;

const ContactGroupTitle = styled.h2`
    margin-top: 4rem;
    font-size: 2rem;
    color: white;
    text-align: center;
`;

const flexColumnStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
};

export const headerConfig: Array<HeaderConfig<AmendedContact>> = [
    {
        label: '',
        getValue(contact) {
            return <span style={{ fontSize: '2.2rem' }}>{getPictureOfContact(contact)}</span>;
        },
        textAlign: 'right',
        tdStyle: { width: '6rem', paddingLeft: '0.3rem', paddingRight: 0 },
    },
    {
        label: 'Contact',
        getValue(v) {
            return v.person?.fullName ?? '(Unknown)';
        },
        format: 'string',
        textAlign: 'left',
    },
    {
        label: 'Dwell Time Overlap',
        getValue: v => humanizeElapsedSecondsRoundedToMinutes(v.overlapSeconds),
    },
    {
        label: 'Location',
        getValue: v => v.overlaps[0].location?.name ?? '(Unknown)',
        format: 'string',
    },
    { label: 'Time', getValue: 'overlaps.0.startDate', format: 'date' },
    {
        label: 'Type',
        getValue({ person }) {
            return !person?.isCaregiver
                ? { icon: faUsers, name: 'Resident' }
                : { icon: faUserMd, name: 'Caregiver' };
        },
        format: 'icon-and-name',
    },
];

//@ts-ignore
const StyledDataTable: (
    props: DatatableProps<AmendedContact>
) => ReturnType<typeof DataTable> = styled(DataTable)`
    border-spacing: 0 1rem;
`;

const contactLevelLabelMap: { [key: number]: string } = {};
for (const contactLevel of embeddedContactTracingLevels) {
    contactLevelLabelMap[contactLevel.level] = contactLevel.label;
}

function average(nums: number[]) {
    return nums.length ? sum(nums) / nums.length : 0;
}

function ContactTracingResultsPage() {
    const [activeRow, setActiveRow] = useState(null as null | string | number);
    const contactTracing = useSelector(state => state.contactTracing);
    const people = useSelector(personsDataAsArraySelector);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { idToTrack, contactLevel, dateRange, quickFilters } = contactTracing;
    const contactTracingResultsState = useSelector(contactTracingResultsSelector);
    const resultsAmended = useSelector(contactTracingResultsDataAmendedSelector);

    const getExpandedRowComponent = useCallback(
        (rowData: AmendedContact, activeData: HasId | null, columns: number, width: number) => {
            return activeData?.id === rowData.id ? (
                <DataTableTr onClick={() => setActiveRow(null)}>
                    <Td className="first-td-radius" colSpan={columns} style={{ width }}>
                        <ContactTracingResultExpanded rowData={rowData} />
                    </Td>
                </DataTableTr>
            ) : null;
        },
        []
    );

    useEffect(() => {
        if (!idToTrack) {
            history.push(routes.contactTracing.fullPath);
            return;
        }
    }, [history, idToTrack]);

    const doLoadContactTracingResults = useCallback(() => {
        dispatch(
            loadContactTracingResults(idToTrack as number, dateRange, quickFilters, contactLevel)
        );
    }, [contactLevel, dateRange, dispatch, idToTrack, quickFilters]);

    useEffect(() => {
        if (!idToTrack) {
            return;
        }
        (async () => {
            await doLoadContactTracingResults();
        })();

        return () => {
            dispatch(contactTracingResultsReset());
        };
    }, [dispatch, doLoadContactTracingResults, idToTrack]);

    const statistics = useMemo(() => {
        const exposureTimes: Array<number> = [];
        type LocationNameWithOccurrences = { name: string; occurrences: number };
        const locationsById: { [key: number]: LocationNameWithOccurrences } = {};
        const locations: Array<LocationNameWithOccurrences> = [];

        const ret = {
            caregiver: 0,
            resident: 0,
            total: 0,
            averageExposure: 'N/A',
            mostPopularLocation: '',
        };
        if (!contactTracingResultsState.data) {
            return ret;
        }
        for (const contactLevelObj of resultsAmended) {
            for (const contact of contactLevelObj.contacts) {
                if (contact.person?.isCaregiver) {
                    ++ret.caregiver;
                } else {
                    ++ret.resident;
                }
                for (const { overlapSeconds, location } of contact.overlaps) {
                    exposureTimes.push(overlapSeconds);
                    const locationId = location?.id ?? 0;
                    if (!locationsById[locationId]) {
                        const locationNameWithOccurrences = {
                            name: location?.name || '(Unknown Name)',
                            occurrences: 0,
                        };
                        locationsById[locationId] = locationNameWithOccurrences;
                        locations.push(locationNameWithOccurrences);
                    }
                    ++locationsById[locationId].occurrences;
                }
            }
        }
        ret.total = ret.caregiver + ret.resident;
        if (exposureTimes.length) {
            ret.averageExposure = String(
                humanizeElapsedSecondsRoundedToMinutes(average(exposureTimes))
            );
        }
        if (locations.length) {
            ret.mostPopularLocation = locations.sort(
                (a, b) => b.occurrences - a.occurrences
            )[0].name;
        }
        return ret;
    }, [contactTracingResultsState.data, resultsAmended]);

    const subjectName = useMemo(() => {
        return people.find(({ id }) => id === idToTrack)?.fullName;
    }, [idToTrack, people]);

    if (!contactTracingResultsState.data) {
        return (
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ContactTracingLoading
                    error={contactTracingResultsState.error}
                    reload={doLoadContactTracingResults}
                />
            </div>
        );
    }

    return (
        <Container>
            <H1>
                Contact Results for {subjectName}{' '}
                <FontAwesomeIcon
                    icon={userCircleIcon}
                    size="2x"
                    style={{ verticalAlign: 'middle' }}
                />
            </H1>
            <H2>Summary</H2>
            <TopFlexContainer>
                <TopBox style={{ flex: 3, display: 'flex', height: '100%', color: wizardGray }}>
                    <div
                        style={{
                            flex: 3,
                            borderRight: `2px solid ${wizardGray}`,
                            color: '#db5b70',
                            ...flexColumnStyle,
                        }}
                    >
                        <div>
                            <FontAwesomeIcon
                                icon={faVirus}
                                style={{ fontSize: '3rem', marginRight: '2rem' }}
                            />
                            <LargeText>{statistics.total}</LargeText>
                        </div>
                        <div style={{ fontWeight: 'bold' }}>At Potential Risk</div>
                    </div>
                    <div style={{ flex: 2, ...flexColumnStyle, alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faUsers} style={{ fontSize: '3rem' }} />
                        <span>{statistics.resident}</span>
                    </div>
                    <div style={{ flex: 2, ...flexColumnStyle, alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faUserMd} style={{ fontSize: '3rem' }} />
                        <span>{statistics.caregiver}</span>
                    </div>
                </TopBox>
                <TopBox style={{ flex: 2, ...flexColumnStyle }}>
                    <div>
                        <FontAwesomeIcon
                            icon={faUserClock}
                            color={wizardGray}
                            style={{ fontSize: '3rem', marginRight: '2rem' }}
                        />
                        <LargeText>{statistics.averageExposure}</LargeText>
                    </div>
                    <div style={{ color: 'white', fontWeight: 'bold' }}>Average Exposure Time</div>
                </TopBox>
                <TopBox style={{ flex: 2, ...flexColumnStyle }}>
                    <div>
                        <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            color={wizardGray}
                            style={{
                                fontSize: '3rem',
                                marginRight: '2rem',
                                verticalAlign: 'middle',
                            }}
                        />
                        <div
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                verticalAlign: 'middle',
                                height: '3em',
                                textAlign: 'left',
                            }}
                        >
                            <MediumText>{statistics.mostPopularLocation || 'N/A'}</MediumText>
                        </div>
                    </div>
                    <div style={{ color: 'white', fontWeight: 'bold' }}>
                        Most Popular Contact Location
                    </div>
                </TopBox>
            </TopFlexContainer>

            {resultsAmended.map((levelAndContacts, index) => (
                <div key={index}>
                    <ContactGroupTitle>
                        {contactLevelLabelMap[levelAndContacts.contactLevel]}
                    </ContactGroupTitle>

                    {levelAndContacts.contacts.length ? (
                        <StyledDataTable
                            data={levelAndContacts.contacts}
                            headerConfig={headerConfig}
                            getExpandedRowComponent={getExpandedRowComponent}
                            changeActive={setActiveRow}
                            active={activeRow !== null ? { id: activeRow } : null}
                        />
                    ) : (
                        <div style={{ textAlign: 'center', fontSize: '1.75rem' }}>
                            There are no {contactLevelLabelMap[index + 1]} results.
                        </div>
                    )}
                </div>
            ))}
        </Container>
    );
}

export default ContactTracingResultsPage;
