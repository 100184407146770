/**
 * Context that lets the main content area customize what should appear in the side and top bars.
 */

import React, { createContext, useState, useContext, useEffect } from 'react';
import { useDeepCompareMemo } from '../components/hooks/deepCompareHooks';

type CustomizationObj = Partial<{
    filters: 'alarms' | 'case-management' | 'engage360';
    getDownloadUrl?: () => string;
    sorting: boolean;
    search: boolean;
}>;

type ContextType = {
    layoutCustomization: CustomizationObj;
    setLayoutCustomization: (customizationObj: CustomizationObj) => void;
};

const Context = createContext<ContextType>({
    layoutCustomization: {},
    setLayoutCustomization() {},
});

export function useLayoutCustomization(customizationObj?: CustomizationObj) {
    const context = useContext(Context);
    const customization = useDeepCompareMemo(() => {
        return customizationObj;
    }, [customizationObj]);
    useEffect(() => {
        if (!customization) {
            return;
        }
        context.setLayoutCustomization(customization);
        return () => {
            context.setLayoutCustomization({});
        };
    }, [context, customization]);

    return context.layoutCustomization;
}

export const LayoutProvider = ({ children }) => {
    const [layoutCustomization, setLayoutCustomization] = useState({} as CustomizationObj);

    const usersContext = {
        layoutCustomization,
        setLayoutCustomization,
    };

    // pass the value in provider and return
    return <Context.Provider value={usersContext}>{children}</Context.Provider>;
};

export const { Consumer: LayoutConsumer } = Context;
