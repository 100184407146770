export type ITreeItem<T> = {
    item: T;
    children: ITreeItem<T>[];
};

type IItemKey = string | number;

export type TreeMap<T> = { [elId: string]: ITreeItem<T> };

/**
 * Creates a tree structure out of a flat list.
 * An object is returned with the tree and an object mapping ids to nodes.
 *
 * @property hasParentCondition An optional additional condition that lets you restrict what
 *  counts as an item that has a parent besides it having a parent ID.
 *
 * Attila's modified version of this StackOverflow result:
 * https://stackoverflow.com/a/60638395/1381550
 */
export function createTreeWithMap<T>(
    flatList: T[],
    idKey: IItemKey,
    parentKey: IItemKey,
    hasParentCondition: (item: T) => boolean = () => true
): { tree: ITreeItem<T>[]; map: TreeMap<T> } {
    const tree: ITreeItem<T>[] = [];

    // hash table.
    const map: TreeMap<T> = {};
    flatList.forEach(el => {
        const elId: IItemKey = el[idKey];

        map[elId] = { item: el, children: [] };
    });

    // also you can use Object.values(mapping).forEach(...
    // but if you have element which was nested more than one time
    // you should iterate flatList again:
    flatList.forEach((elem: T) => {
        const mappedElem = map[elem[idKey]];

        if (elem[parentKey] && hasParentCondition(elem)) {
            map[elem[parentKey]].children.push(mappedElem);
        } else {
            tree.push(mappedElem);
        }
    });

    return { tree, map };
}

/**
 * Flattens a T tree (array) back into a flat array of T, but making sure children recursively
 * come right after their parents.
 *
 * @param tree
 */
export function flattenTree<T>(tree: ITreeItem<T>[]): T[] {
    const ret: T[] = [];
    for (const treeNode of tree) {
        ret.push(treeNode.item);
        if (treeNode.children.length) {
            const childItems = flattenTree(treeNode.children);
            for (const child of childItems) {
                ret.push(child);
            }
        }
    }
    return ret;
}
