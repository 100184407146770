import axiosInstance, { URI_DISEASE_CASES } from '../axios';
import { ContactLevel } from '../values/types';

export type ExposureInput = {
    contactLevel: ContactLevel;
    exposedAt?: string | null;
    parentUuid?: string | null;
    personKey?: number | null;
    exposureLocationKey?: number | null;
    exposureDurationSeconds?: number | null;
};

export async function createExposures(diseaseCaseUuid: string, exposures: ExposureInput[]) {
    if (exposures.length < 1) {
        throw new Error('You must designate at least one exposure.');
    }
    return axiosInstance.post(`${URI_DISEASE_CASES}/${diseaseCaseUuid}/exposures`, {
        exposures,
    });
}
