import React, { useEffect } from 'react';
import { useAppDispatch, useSelector } from '../../store';
import PendingCase from './PendingCase';
import styled from '@emotion/styled';
import theme, { red } from '../../styles/theme';
import {
    diseaseCasesNeedAttentionDataSelector,
    loadDiseaseCasesNeedAttention,
} from '../../store/reducers/diseaseCasesNeedAttention';
import BigSpinner from '../BigSpinner/BigSpinner';

const borderRadius = '1rem';

const Table = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    color: white;

    tr {
    }

    td {
        padding: 1rem;
        background-color: ${red};
        vertical-align: middle;
    }

    td:first-of-type {
        border-top-left-radius: ${borderRadius};
        border-bottom-left-radius: ${borderRadius};
    }

    td:last-of-type {
        background-color: ${theme.primaryBlue};
        border-top-right-radius: ${borderRadius};
        border-bottom-right-radius: ${borderRadius};
    }

    .marginBottom td {
        background-color: transparent;
        height: 1rem;
        padding: 0;
    }
`;

function PendingCases() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(loadDiseaseCasesNeedAttention());
    }, [dispatch]);

    const pendingCases = useSelector(diseaseCasesNeedAttentionDataSelector);

    if (!pendingCases) {
        return <BigSpinner />;
    }

    return (
        <div>
            <Table>
                <tbody>
                    {pendingCases.map(diseaseCase => (
                        <PendingCase key={diseaseCase.uuid} diseaseCase={diseaseCase} />
                    ))}
                </tbody>
            </Table>
            {pendingCases.length ? <div style={{ height: '3rem' }} /> : null}
        </div>
    );
}

export default PendingCases;
