import React, {useMemo} from 'react';
import {node} from 'prop-types';
import ValueCard from '../ValueCard/ValueCard';
import {H2} from '../../styles/shared';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getOwnerObjByType, getAlarmTypeByKey} from '../../values/appConfig';
import {take} from 'lodash-es';

function MostFrequentCallers({ data }) {

    const items = useMemo(() => take(data.filter(d => d.group), 5).map(({group, subjectType}) => {
        const subjectObj = getOwnerObjByType(subjectType);
        return ({
            name: subjectObj ? group : 'System',
            icon: subjectObj?.icon || getAlarmTypeByKey('system').icon,
        });
    }), [data]);

    return (
        <div>
            <H2>Most Frequent Callers</H2>
            <ValueCard
                topContent={items.map(({icon}) => icon && <FontAwesomeIcon
                    icon={icon}
                    key={Math.random()}
                />)}
                bottomContent={items.map(({name}, index) =>
                    <span
                        style={{opacity: 0.56, fontWeight: 'normal', fontSize: '1rem'}}
                        key={index}
                    >{name}</span>)}
            /></div>
    );
}

MostFrequentCallers.propTypes = {
    children: node,
};

export default MostFrequentCallers;
