import { styled } from '../../styles/theme';
import { css } from '../../utils/ide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/pro-regular-svg-icons';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export const Title = styled.h1(
    ({ theme }) => css`
        margin: 0;
        font-size: 1.75rem;
        font-weight: bold;
        color: ${theme.primaryLight};
    `
);

export const TablePageContainer = styled.div`
    padding: 2rem 3rem;
`;

const PrintContainer = styled(Link)(
    ({ theme }) => css`
        width: 6rem;
        padding-left: 3rem;
        display: flex;
        align-items: center;
        color: ${theme.primaryLight};
        font-weight: bold;
        text-transform: uppercase;
    `
);

type PrintControlProps = { label: ReactNode; to: string };

export function PrintControl({ label, to }: PrintControlProps) {
    return (
        <PrintContainer className="clickable" to={to}>
            <FontAwesomeIcon icon={faPrint} style={{ fontSize: '2rem' }} pull="left" />
            {label}
        </PrintContainer>
    );
}
