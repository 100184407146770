import * as actionTypes from '../actions/actionTypes';
import {take} from 'lodash-es';
import {lightFormat, parseISO} from "date-fns";
import {calculatePeakShiftsByAlarmTotalsByHoursAndDaysOfWeek} from '../../utils/dashboard';

const initialState = {
    data: null,
    loading: true,
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DASHBOARD_START: {
            return {...state, loading: true};
        }

        case actionTypes.GET_DASHBOARD_SUCCESS: {
            const byType = {};
            const data = action.results.data;
            let total = 0;
            for (const {group, count} of data.alarmsCountByType.counts) {
                byType[group] = count;
                total += count;
            }
            total += data.alarmsCountByType.outOfGroups;

            const alarmBarChartData = data.alarmsCountByHourAndDayOfWeek.map((
                dayOfWeekDataOfHour,
                hour
            ) => {
                const values = [...dayOfWeekDataOfHour];
                // Since index 0 is Sunday from the API,
                // let's shift and push to make index 0 Monday instead.
                values.push(values.shift());
                const total = values.reduce((acc, cur) => acc + cur, 0);
                return {
                    date: lightFormat(parseISO(`2019-11-06T${String(hour).padStart(
                        2,
                        '0'
                    )}:00:00`), 'h a'),
                    values,
                    total,
                    average: total / values.length
                };
            });
            const peakHours = take(
                alarmBarChartData
                    .map(({total}, hour) => ({count: total, group: hour}))
                    .sort((hour1, hour2) => Math.sign(hour2.count - hour1.count)),
                3,
            );

            const peakShifts = calculatePeakShiftsByAlarmTotalsByHoursAndDaysOfWeek(
                data.alarmsCountByHourAndDayOfWeek
            );

            const domainCounts = data.alarmsCountByDomain.counts.reduce((acc, curr) => {
                return {...acc, [curr.group]: curr.count};
            }, {})

            return {
                ...state,
                loading: false,
                error: null,
                data: {
                    totalAlarmCount: total,
                    alarmCountsByType: byType,
                    alarmCountsByTypeArray: data.alarmsCountByType.counts,
                    openAlarms: data.alarmsCountByType?.outOfGroups,
                    domainCounts,
                    alarmBarChartData,
                    peakShifts,
                    peakHours,
                    alarmCountsByResponseTime: data.alarmsCountByResponseTime,
                    responseTimeStatistic: data.responseTimeStatistic,
                    overFiveMinutesRatio: data.responseTimeStatistic.overFiveMinutesCount
                        / total,
                    residentVsSystem: data.residentVsSystem,
                    mostCommonDispositions: data.mostCommonDispositions,
                    mostFrequentCallers: data.mostFrequentCallers,
                },
            };
        }

        case actionTypes.GET_DASHBOARD_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }

        default:
            return state;
    }
};

export default reducer;
