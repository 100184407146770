import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Title } from '../components/CaseManagement/common';
import Input from '../components/Input/Input';
import { useFieldArray, useForm } from 'react-hook-form';
import Button, { BigButton, ButtonLink, PrimaryButton } from '../components/Button/Button';
import FieldErrors from '../components/FieldErrors';
import axiosInstance, { ResponseEnvelope, URI_DISEASES } from '../axios';
import { displaySuccess } from '../utils/toast';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { handleError, NOT_FOUND } from '../utils/errorHandling';
import { DiseasePageRouteParams } from './DiseasePage';
import { useAppDispatch, useSelector } from '../store';
import {
    diseaseShowReset,
    diseaseShowSelector,
    loadDiseaseShow,
} from '../store/reducers/diseaseShow';
import BigSpinner from '../components/BigSpinner/BigSpinner';
import { ContactLevel, ContactLevelLabel, Disease, InfoSource } from '../values/types';
import { GroupLabel, InputComponentsContainer } from '../components/Form/formCommon';
import PageBox from '../components/PageBox';
import { loadDiseases } from '../store/reducers/diseases';
import Select from '../components/Select';
import { contactLevels } from '../values/appConfig';
import { CheckboxWithLabel } from '../components/CheckboxWithLabel/CheckboxWithLabel';
import { cloneDeep, filter, find } from 'lodash-es';
import ProtocolStepsCreation, {
    EditProtocolStep,
} from '../components/ProtocolSteps/ProtocolStepsCreation';
import { features } from '../values/env';
import { routes } from '../router/routes';

const Spacer = styled.div`
    height: 3rem;
`;

const InputTable = styled.table`
    text-align: left;

    th {
        width: 15rem;
        padding-left: 10rem;
        padding-right: 3rem;
    }

    td:nth-of-type(1) {
        width: 10rem;
    }

    td.unit {
        padding-left: 1rem;
    }
`;

const Textarea = styled(Input)`
    width: 30rem;
    height: 5rem;
`;
Textarea.defaultProps = {
    as: 'textarea',
};

function newInfoSource(): InfoSource {
    return {
        displayName: '',
        url: '',
    };
}

function newProtocolStep(): EditProtocolStep {
    return {
        title: '',
        description: '',
    };
}

function assembleShowPageUrl(id: string) {
    return generatePath(routes.diseaseSingle.fullPath, {
        disease: id,
    });
}

const maxContactLevel: { value: ContactLevel; label: ContactLevelLabel }[] = [];
for (const contactLevel of contactLevels) {
    maxContactLevel.push({
        value: contactLevel.level,
        label: contactLevel.label,
    });
}

const specialInstructionsMaxLength = 45;

function DiseaseEditPage({ backUri }: Props) {
    const match = useRouteMatch<Partial<DiseasePageRouteParams>>();
    const diseaseId = match.params.disease;
    const history = useHistory();
    const dispatch = useAppDispatch();

    const form = useForm();
    const {
        control,
        errors,
        handleSubmit,
        getValues,
        register,
        setError,
        setValue,
        reset,
        watch,
    } = form;
    const infoSourcesFieldArray = useFieldArray<InfoSource>({
        control,
        name: 'infoSources',
    });
    const protocolStepsFieldArray = useFieldArray<EditProtocolStep, 'fieldId'>({
        control,
        name: 'protocolSteps',
        keyName: 'fieldId',
    });
    register('maxContactLevel');
    const watched = watch(['maxContactLevel', 'specialInstructions']);

    useEffect(() => {
        if (!diseaseId) {
            return;
        }
        dispatch(loadDiseaseShow(diseaseId));
        return () => {
            dispatch(diseaseShowReset());
        };
    }, [diseaseId, dispatch]);
    const { waiting, data, error } = useSelector(diseaseShowSelector);

    useEffect(() => {
        if (error?.type === NOT_FOUND) {
            history.push(routes.caseManagement.fullPath);
        }
    }, [error, history]);

    // Preload fields for editing.
    useEffect(() => {
        if (!diseaseId || !data) {
            return;
        }

        setHasProtocolSteps(!!data?.protocolSteps.length);

        // symptoms: set checkbox based on step and pop step
        if (find(data.protocolSteps, { hasListOfSymptoms: true })) {
            const dataMutable = cloneDeep(data);
            setTrackSymptoms(true);
            dataMutable.protocolSteps = filter(data.protocolSteps, step => {
                return step.hasListOfSymptoms !== true;
            });
            reset(dataMutable);
        } else {
            reset(data);
        }
    }, [data, diseaseId, reset]);

    const [saving, setSaving] = useState(false);
    const [hasProtocolSteps, setHasProtocolSteps] = useState(false);
    const [trackSymptoms, setTrackSymptoms] = useState(false);

    function toggleProtocolSteps() {
        if (hasProtocolSteps) {
            // empty array when unchecking
            protocolStepsFieldArray.remove();
        } else if (!protocolStepsFieldArray.fields.length) {
            // start with one empty step when checking
            protocolStepsFieldArray.append(newProtocolStep());
        }

        setHasProtocolSteps(!hasProtocolSteps);
    }

    function toggleTrackSymptoms() {
        setTrackSymptoms(!trackSymptoms);
    }

    const onDelete = useCallback(async () => {
        if (!window.confirm('Are you sure you want to delete this disease?')) {
            return false;
        }
        try {
            await axiosInstance.delete(`${URI_DISEASES}/${diseaseId}`);
            displaySuccess('Successfully deleted the disease.');
            history.push(routes.caseManagement.fullPath);
        } catch (e) {
            handleError(e);
        }
    }, [diseaseId, history]);

    const onSubmit = useCallback(async () => {
        try {
            setSaving(true);
            const values = getValues();

            if (!values.protocolSteps) {
                // backend way to delete an existing array
                values.protocolSteps = [];
            }

            // add a hidden last step for symptoms
            if (trackSymptoms) {
                const symptomsHiddenStep: EditProtocolStep = {
                    title: '[Track symptoms]',
                    description: '[Track symptoms]',
                    hasListOfSymptoms: true,
                };

                values.protocolSteps.push(symptomsHiddenStep);
            }

            // add `positionInList` for backend
            values.protocolSteps.map((step, i) => {
                step.positionInList = i + 1;
                return true;
            });

            const response = await axiosInstance.request<ResponseEnvelope<Disease>>({
                url: diseaseId ? `${URI_DISEASES}/${diseaseId}` : URI_DISEASES,
                method: diseaseId ? 'PUT' : 'POST',
                data: values,
            });
            displaySuccess('You have successfully saved the disease.');
            dispatch(loadDiseases()); // Reload list of diseases.
            if (!diseaseId) {
                // Go to the newly created show page.
                history.push(assembleShowPageUrl(response.data.data.uuid));
            }
        } catch (e) {
            handleError(e, { setError });
        } finally {
            setSaving(false);
        }
    }, [diseaseId, dispatch, getValues, history, setError, trackSymptoms]);

    if (waiting) {
        return <BigSpinner />;
    }

    return (
        <PageBox>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Title style={{ textAlign: 'center' }}>
                    {diseaseId ? 'Edit' : 'Create'} Disease
                </Title>
                <GroupLabel>Disease Name</GroupLabel>
                <InputComponentsContainer>
                    <div style={{ width: '20rem' }}>
                        <Input name="name" ref={register} placeholder="Disease Name" />
                        <FieldErrors errors={errors.name} />
                    </div>
                </InputComponentsContainer>
                <Spacer />
                <GroupLabel>Contact Tracing Exposure Parameters</GroupLabel>
                <InputTable>
                    <tbody>
                        <tr>
                            <th>Max Level Contact</th>
                            <td>
                                <div style={{ width: '15rem' }}>
                                    <Select
                                        options={maxContactLevel}
                                        value={watched.maxContactLevel}
                                        onChange={v =>
                                            setValue('maxContactLevel', v, {
                                                shouldValidate: true,
                                            })
                                        }
                                    />
                                </div>
                                <FieldErrors errors={errors.maxContactLevel} />
                            </td>
                            <td />
                        </tr>
                        <tr>
                            <th>Contagious Period - Prior</th>
                            <td>
                                <Input
                                    type="number"
                                    name="contagiousPeriodPriorDays"
                                    ref={register}
                                />
                                <FieldErrors errors={errors.contagiousPeriodPriorDays} />
                            </td>
                            <td className="unit">days</td>
                        </tr>
                        <tr>
                            <th>Contagious Period - Post</th>
                            <td>
                                <Input
                                    type="number"
                                    name="contagiousPeriodPostDays"
                                    ref={register}
                                />
                                <FieldErrors errors={errors.contagiousPeriodPostDays} />
                            </td>
                            <td className="unit">days</td>
                        </tr>
                        <tr>
                            <th>Trailing Contacts</th>
                            <td>
                                <Input type="number" name="trailingContactMinutes" ref={register} />
                                <FieldErrors errors={errors.trailingContactMinutes} />
                            </td>
                            <td className="unit">minutes</td>
                        </tr>
                        <tr>
                            <th>Minimum Overlap</th>
                            <td>
                                <Input type="number" name="minOverlapMinutes" ref={register} />
                                <FieldErrors errors={errors.minOverlapMinutes} />
                            </td>
                            <td className="unit">minutes</td>
                        </tr>
                    </tbody>
                </InputTable>

                <Spacer />
                <GroupLabel>Protocols</GroupLabel>
                <InputTable>
                    <tbody>
                        <tr>
                            <th>Quarantine Timeframe</th>
                            <td>
                                <Input
                                    type="number"
                                    name="quarantineTimeframeDays"
                                    ref={register}
                                />
                                <FieldErrors errors={errors.quarantineTimeframeDays} />
                            </td>
                            <td className="unit">days</td>
                            <td />
                        </tr>
                        <tr>
                            <th>Special Instructions</th>
                            <td colSpan={3}>
                                <Textarea
                                    name="specialInstructions"
                                    ref={register}
                                    maxLength={specialInstructionsMaxLength}
                                />
                                <div>
                                    Characters left:{' '}
                                    {Math.max(
                                        0,
                                        specialInstructionsMaxLength -
                                            (watched.specialInstructions ?? '').length
                                    )}
                                </div>
                                <FieldErrors errors={errors.specialInstructions} />
                            </td>
                        </tr>
                        <tr>
                            <th>Note</th>
                            <td colSpan={3}>
                                <Textarea name="note" ref={register} />
                                <FieldErrors errors={errors.note} />
                            </td>
                            <td />
                        </tr>
                        <tr>
                            <th>Info Sources</th>
                            <td colSpan={3}>
                                {infoSourcesFieldArray.fields.map(
                                    ({ id, displayName, url }, index) => {
                                        const infoSourceErrors = errors?.infoSources?.[index];
                                        return (
                                            <div key={id}>
                                                <InputComponentsContainer
                                                    style={{ marginBottom: '0.5rem' }}
                                                >
                                                    <div>
                                                        <Input
                                                            ref={register()}
                                                            name={`infoSources[${index}].url`}
                                                            placeholder="URL"
                                                            defaultValue={url}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Input
                                                            ref={register()}
                                                            name={`infoSources[${index}].displayName`}
                                                            placeholder="Name"
                                                            defaultValue={displayName}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Button
                                                            onClick={() =>
                                                                infoSourcesFieldArray.remove(index)
                                                            }
                                                            style={{ width: '5rem' }}
                                                        >
                                                            remove
                                                        </Button>
                                                    </div>
                                                </InputComponentsContainer>
                                                <FieldErrors
                                                    errors={[
                                                        infoSourceErrors?.url,
                                                        infoSourceErrors?.displayName,
                                                    ]}
                                                />
                                            </div>
                                        );
                                    }
                                )}
                                <Button
                                    onClick={() => infoSourcesFieldArray.append(newInfoSource())}
                                >
                                    + Add Info Source
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </InputTable>

                <Spacer />
                <GroupLabel>Protocol Steps</GroupLabel>
                <CheckboxWithLabel
                    name="hasProtocolSteps"
                    label="Protocol Questionnaire"
                    checked={hasProtocolSteps}
                    onChange={toggleProtocolSteps}
                />

                {hasProtocolSteps && (
                    <ProtocolStepsCreation
                        form={form}
                        Textarea={Textarea}
                        protocolStepsFieldArray={protocolStepsFieldArray}
                        newProtocolStep={newProtocolStep}
                    />
                )}

                <CheckboxWithLabel
                    name="contactTracingRequired"
                    label="Contact Tracing Required"
                    register={register}
                />

                <CheckboxWithLabel
                    name="trackSymptoms"
                    label="Track Symptoms"
                    checked={trackSymptoms}
                    onChange={toggleTrackSymptoms}
                />

                <Spacer />
                <div style={{ textAlign: 'center' }}>
                    <PrimaryButton type="submit" waiting={saving}>
                        {diseaseId ? 'Update' : 'Add'}
                    </PrimaryButton>
                    {diseaseId && features.debug ? (
                        <BigButton
                            style={{ marginLeft: '3rem' }}
                            flavor="danger"
                            onClick={onDelete}
                        >
                            Delete
                        </BigButton>
                    ) : null}
                    <ButtonLink style={{ marginLeft: '3rem' }} flavor="link" to={backUri}>
                        Cancel
                    </ButtonLink>
                </div>
            </form>
        </PageBox>
    );
}

interface Props {
    backUri: string;
}

export default DiseaseEditPage;
