import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { red } from '../../../styles/theme';
import { DiseaseSingle } from '../../../values/types';

const Inner = styled.div`
    display: flex;
`;

interface SideProps {
    upperNumber: number;
    upperText: string;
    lowerNumber: number;
    lowerText: string;
}

function Side({ upperNumber, upperText, lowerNumber, lowerText }: SideProps) {
    return (
        <div style={{ flex: 1, fontSize: '1.25rem' }}>
            <div style={{ fontWeight: 'bold', color: upperNumber ? red : 'inherit' }}>
                <span style={{ fontSize: '4rem' }}>{upperNumber}</span> <span>{upperText}</span>
            </div>
            <div style={{ color: upperNumber ? red : 'inherit' }}>
                <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{lowerNumber}</span>{' '}
                <span>{lowerText}</span>
            </div>
        </div>
    );
}

function DiseaseSummary({ style, disease }: Props) {
    return (
        <div style={style}>
            <h2>Summary</h2>
            <div style={{ height: '1rem' }} />
            <Inner>
                <Side
                    upperNumber={disease.statistics.positiveCasesCount}
                    upperText="Active Cases"
                    lowerNumber={disease.statistics.pendingIsolationCount}
                    lowerText="Pending Isolation"
                />
                <Side
                    upperNumber={disease.statistics.exposedCount}
                    upperText="Total Exposures"
                    lowerNumber={disease.statistics.pendingQuarantineCount}
                    lowerText="Pending Quarantine"
                />
            </Inner>
        </div>
    );
}

interface Props {
    style: CSSProperties;
    disease: DiseaseSingle;
}

export default DiseaseSummary;
