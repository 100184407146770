import React from 'react';
import { Box } from '../styles/shared';
import styled from '@emotion/styled';
import { wizardGray } from './Wizard/WizardStep';

const StyledBox = styled(Box)`
    padding: 3rem;
    color: ${wizardGray};
`;

/**
 * Black box covering a page.
 */
function PageBox(props) {
    return <StyledBox {...props} />;
}

export default PageBox;
