import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { timesIcon } from '../../styles/fontLibrary';
import { OverlayComponentProps } from '../Overlay/Overlay';
import { H2 } from '../../styles/shared';
import FilterCheckboxType from '../DataTable/Filters/FilterTypes/FilterCheckboxType';
import { useSelector } from '../../store';
import { engageResidentsDataSelector } from '../../store/reducers/engageResidents';

export default function Engage360Filters({ close }: OverlayComponentProps) {
    const engageResidents = useSelector(engageResidentsDataSelector);
    const engageResidentsAsCheckboxOptions = useMemo(() => {
        return engageResidents ? engageResidents.map(v => ({ id: v.uuid, name: v.name })) : [];
    }, [engageResidents]);
    return (
        <>
            <div style={{ position: 'relative' }}>
                <FontAwesomeIcon
                    icon={timesIcon}
                    className="clickable"
                    onClick={close}
                    style={{ position: 'absolute', right: 0, top: '0.25rem' }}
                />

                <H2>Filters</H2>
                <FilterCheckboxType
                    title="Residents"
                    filterKey="engageResidentUuids"
                    options={engageResidentsAsCheckboxOptions}
                    hasSearch
                />
            </div>
        </>
    );
}
