import React from 'react';
import styled from '@emotion/styled';
import {H2} from '../../styles/shared';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {circleIcon, chevronCircleDownIcon, chevronCircleUpIcon} from '../../styles/fontLibrary';
import {useSelector, useDispatch} from 'react-redux';
import theme from '../../styles/theme';
import {setFilter} from '../../store/actions';

const Container = styled.div`
    width: 25rem;
`;

const sortConfig = [
    {label: 'Location', key: 'area'},
    {label: 'Opened', key: 'creationTime'},
    {label: 'Response', key: 'responseTime'},
];

const SortRow = styled.div`
    display: flex;
    align-items: center;
    padding: 0.4rem 0;
    font-weight: bold;
    cursor: pointer;
`;

function SortOverlay() {
    const {sortBy, sortByDirection} = useSelector(state => state.reports.filters);
    const dispatch = useDispatch();

    return (
        <Container>
            <H2>Sort By</H2>

            <div style={{marginLeft: '2rem'}}>
                {sortConfig.map(({label, key}) => (
                    <SortRow key={key} onClick={() => dispatch(setFilter('sort', key))}>
                        <FontAwesomeIcon
                            icon={sortBy !== key ?
                                circleIcon :
                                sortByDirection === 'DESC' ?
                                    chevronCircleDownIcon :
                                    chevronCircleUpIcon
                            }
                            style={{
                                fontSize: '2.5rem', ...(sortBy !== key ?
                                    {opacity: 0.56} :
                                    {color: theme.primaryBlue}),
                            }}
                        />
                        <span style={{opacity: 0.56, marginLeft: '1rem'}}>{label}</span>
                    </SortRow>
                ))}
            </div>
        </Container>
    );
}

export default SortOverlay;
