import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes, { bool, node } from 'prop-types';
import HoverMenu from '../HoverMenu/HoverMenu';
import { userCircleIcon } from '../../styles/fontLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const fillFromMode = props => (props.mode === 'light' ? props.theme.primary : 'white');

const Container = styled.div`
    display: inline-block;
    align-items: center;
    position: relative;
    cursor: pointer;
    color: ${fillFromMode};
    .name {
        font-size: 14px;
        margin-bottom: 4px;
    }
    .email {
        font-weight: 400;
        font-size: 12px;
    }
`;

const UserMenu = props => {
    const { light } = props;

    const [open, setOpen] = useState(false);
    return (
        <Container
            data-cy="profile-closed"
            onClick={() => setOpen(true)}
            mode={light ? 'light' : 'dark'}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="user-icon-container">
                    <FontAwesomeIcon icon={userCircleIcon} style={{ fontSize: '2.8rem' }} />
                </div>
            </div>
            <HoverMenu
                open={open}
                close={event => {
                    if (event) event.stopPropagation();
                    setOpen(false);
                }}
            >
                <div style={{ lineHeight: '1.4em' }}>{props.children}</div>
            </HoverMenu>
        </Container>
    );
};

UserMenu.propTypes = {
    light: bool,
    name: PropTypes.string,
    email: PropTypes.string,
    children: node,
};

export default UserMenu;
