import React, { CSSProperties, ReactNode, useEffect } from 'react';
import { HasId } from '../values/types';
import styled from '@emotion/styled';
import theme from '../styles/theme';
import { uniq } from 'lodash-es';
import DataTableBodyPart from './DataTableSimple/DataTableBodyPart';
import { useSortingFiltering } from './DataTableSimple/sortFilter';
import DataTableHeadPart from './DataTableSimple/DataTableHeadPart';

type HeaderConfigItemBase<T> = {
    label?: ReactNode;
    getValue: (item: T, key: number) => PrimitiveValue;
    formatValue?: (value: PrimitiveValue) => ReactNode;
    textAlign?: CSSProperties['textAlign'];
    tdStyle?: CSSProperties;
    headerTdStyle?: CSSProperties;

    defaultSortDescending?: boolean;
};

export type PrimitiveValue = string | number;

type HeaderConfigItemWithoutKey<T> = HeaderConfigItemBase<T> & {
    key?: undefined;
    sortable?: false;
    filterable?: false;
};

// Columns that are filterable or sortable require a key.
export type HeaderConfigItemWithKey<T> = HeaderConfigItemBase<T> & {
    key: string;
    sortable?: boolean | ((item: T) => PrimitiveValue);
    filterable?: boolean;
};

export type HeaderConfigItem<T> = HeaderConfigItemWithoutKey<T> | HeaderConfigItemWithKey<T>;

const Container = styled.div`
    color: ${theme.gray600};
`;

const Table = styled.table`
    width: 100%;
    box-sizing: border-box;
    padding: 1rem;
    border-collapse: collapse;
`;

/**
 * Data table introduced in Engage 360.
 */
export default function DataTableSimple<
    T extends HasId<IdProperty>,
    IdProperty extends string = 'id'
>({
    data,
    waiting,
    headerConfig,
    tdStyle = {},
    headerTdStyle = {},
    idProperty = 'id' as IdProperty,
    showFilters,
}: Props<T, IdProperty>) {
    useEffect(() => {
        for (const headerConfigItem of headerConfig) {
            if (headerConfigItem.sortable && headerConfigItem.key == null) {
                throw new Error("A header that's sortable must have a key.");
            }
        }

        const keyStrings = headerConfig.map(v => v.key).filter(v => v !== undefined);
        if (uniq(keyStrings).length !== keyStrings.length) {
            throw new Error('There are duplicate keys in the header config.');
        }
    }, [headerConfig]);

    const sortingFiltering = useSortingFiltering({ headerConfig, data });
    const { sortedData } = sortingFiltering;

    return (
        <Container>
            <Table>
                <DataTableHeadPart
                    headerConfig={headerConfig}
                    headerTdStyle={headerTdStyle}
                    sortingFiltering={sortingFiltering}
                    showFilters={showFilters}
                />
                <DataTableBodyPart
                    sortedData={sortedData}
                    headerConfig={headerConfig}
                    getKey={row => (idProperty && row ? row[idProperty] : Math.random())}
                    waiting={waiting}
                    tdStyle={tdStyle}
                />
            </Table>
        </Container>
    );
}

interface Props<T extends HasId<IdProperty>, IdProperty extends string = 'id'> {
    data: T[] | null;
    waiting?: boolean; // Indicate loading of data.
    tdStyle?: CSSProperties;
    headerTdStyle?: CSSProperties;
    headerConfig: HeaderConfigItem<T>[];
    idProperty?: IdProperty | null;
    showFilters?: boolean;
}
