import React, { InputHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { blue5 } from '../../styles/theme';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CheckedIcon = styled(FontAwesomeIcon)`
    font-size: 1.6rem;
    margin-right: 0.8rem;
`;

const UncheckedIcon = styled(CheckedIcon)``;

const StyledCheckbox = styled.input`
    position: absolute;
    opacity: 0;
    left: -10000px;

    &:checked ~ ${CheckedIcon} {
        display: none;
    }
    &:not(:checked) ~ ${UncheckedIcon} {
        display: none;
    }
`;

function Checkbox({ register, ...restProps }: CheckboxProps) {
    return (
        <>
            {/* Hidden input for accessibility */}
            <StyledCheckbox type="checkbox" ref={register} {...restProps} />
            <CheckedIcon icon={faSquare} style={{ opacity: 0.56 }} />
            <UncheckedIcon icon={faCheckSquare} color={blue5} />
        </>
    );
}

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    register?: any;
}

export default Checkbox;
