import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { wizardGray } from '../Wizard/WizardStep';

const hoverColor = ({ error, theme }) => (error ? theme.error : theme.primary);

export const inputHeight = '3.35rem';

const Container = styled.input`
    width: 100%;
    height: ${inputHeight};
    padding: 1rem;
    box-sizing: border-box;
    background: none;
    box-shadow: none;
    &::placeholder {
        color: ${props => props.theme.gray400};
    }
    color: ${wizardGray};
    border: solid;
    border-radius: 0.5rem;
    border: ${({ noBorder, theme, error }) =>
        noBorder ? 0 : `${theme.inputBorderWidth}px solid ${error ? theme.error : wizardGray}`};
    &:focus {
        outline: none;
        border-color: ${hoverColor};
    }

    // https://stackoverflow.com/a/62162805/1381550
    &::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }
`;

const InnerInput = (props, ref) => {
    return <Container ref={ref} data-cy={props.cy} {...props} />;
};
const Input = React.forwardRef(InnerInput);

InnerInput.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    noBorder: PropTypes.bool,
    error: PropTypes.bool,
    cy: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    ref: PropTypes.object,
    type: PropTypes.string,
};

export default Input;
