import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { BulkUploadField, PersonType } from '../../values/types';
import { URI_BULK_UPLOAD } from '../../axios';

export type BulkUploadPossibleField = {
    key: BulkUploadField;
    label: string;
    required: boolean;
};

const slice = createFetchSlice(
    'bulkUploadPossibleFields',
    {},
    getInitialState<BulkUploadPossibleField[]>()
);

export function loadBulkUploadPossibleFields(type: PersonType) {
    return slice.load({
        uri: `${URI_BULK_UPLOAD}?type=${type}`,
    });
}

const bulkUploadPossibleFields = slice.reducer;
const bulkUploadPossibleFieldsSelector = slice.selector;
const bulkUploadPossibleFieldsReset = slice.actions.reset;

export { bulkUploadPossibleFieldsSelector, bulkUploadPossibleFieldsReset };

export const bulkUploadPossibleFieldsDataSelector = createSelector(
    bulkUploadPossibleFieldsSelector,
    v => v.data
);

// Sorts required fields to go first.
export const bulkUploadPossibleFieldsDataSorted = createSelector(
    bulkUploadPossibleFieldsDataSelector,
    data => {
        if (!data) {
            return data;
        }
        return [...data].sort((a, b) => Number(b.required) - Number(a.required));
    }
);

export default bulkUploadPossibleFields;
