import React, { forwardRef } from 'react';
import { faSortDown, faSortUp } from '@fortawesome/pro-regular-svg-icons';
import { faTimesSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import Input from '../Input/Input';
import FieldErrors from '../FieldErrors';
import Button from '../Button/Button';
import { Controller } from 'react-hook-form';
import { ProtocolStep } from '../../values/types';
import { UseFieldArrayMethods, UseFormMethods } from 'react-hook-form/dist/types';

export type EditProtocolStep = Partial<ProtocolStep> & { title: string; description: string };

interface Props {
    form: UseFormMethods<Record<string, any>>;
    Textarea: any;
    protocolStepsFieldArray: UseFieldArrayMethods<EditProtocolStep, 'fieldId'>;
    newProtocolStep: () => EditProtocolStep;
}

function ProtocolStepsCreation(props: Props) {
    const { form, newProtocolStep, protocolStepsFieldArray, Textarea } = props;
    const { control, errors, register } = form;

    function moveUp(index) {
        if (index === 0) return;
        protocolStepsFieldArray.swap(index, index - 1);
    }

    function moveDown(index) {
        if (index === protocolStepsFieldArray.fields.length - 1) return;
        protocolStepsFieldArray.swap(index, index + 1);
    }

    // for Tippy.js to work
    const RemoveItemIcon = forwardRef((props: { index: number }, ref: any) => {
        return (
            <FontAwesomeIcon
                forwardedRef={ref}
                icon={faTimesSquare}
                size="2x"
                onClick={() => protocolStepsFieldArray.remove(props.index)}
                title="Remove item"
                style={{
                    position: 'absolute',
                    top: '-0.1rem',
                    right: '-0.1rem',
                    padding: '0.1rem',
                    cursor: 'pointer',
                }}
            />
        );
    });

    return (
        <>
            <div>
                {protocolStepsFieldArray.fields.map((field, index) => {
                    const { id, fieldId, title, description } = field;
                    const protocolStepsErrors = errors?.protocolSteps?.[index];
                    const namePrefix = `protocolSteps[${index}].`;
                    const itemsCount = protocolStepsFieldArray.fields.length;
                    return (
                        <div
                            key={fieldId}
                            style={{
                                display: 'flex',
                                marginTop: '1rem',
                            }}
                        >
                            {/* Register the ID as number|undefined */}
                            <Controller
                                control={control}
                                name={`${namePrefix}id`}
                                defaultValue={id}
                                as={() => null}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    margin: '0 5rem',
                                }}
                            >
                                <div>
                                    <FontAwesomeIcon
                                        icon={faSortUp}
                                        size="2x"
                                        onClick={() => moveUp(index)}
                                        style={{
                                            padding: '1rem 1rem 0 1rem',
                                            marginBottom: '1rem',
                                            cursor: index === 0 ? 'not-allowed' : 'pointer',
                                            opacity: index === 0 ? '0.5' : '1',
                                        }}
                                    />
                                </div>
                                <div>
                                    <FontAwesomeIcon
                                        icon={faSortDown}
                                        size="2x"
                                        onClick={() => moveDown(index)}
                                        style={{
                                            padding: '0 1rem 1rem 1rem',
                                            cursor:
                                                itemsCount - 1 === index
                                                    ? 'not-allowed'
                                                    : 'pointer',
                                            opacity: itemsCount - 1 === index ? '0.5' : '1',
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <span
                                        style={{
                                            position: 'absolute',
                                            left: '-1.5rem',
                                            top: '1.1rem',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {index + 1}.
                                    </span>
                                    <Input
                                        ref={register()}
                                        type="text"
                                        style={{ width: '20rem' }}
                                        name={`${namePrefix}title`}
                                        placeholder="Title"
                                        defaultValue={title}
                                    />
                                    <Tippy theme="light-border" content="Remove item">
                                        <RemoveItemIcon index={index} />
                                    </Tippy>
                                </div>
                                <Textarea
                                    ref={register()}
                                    name={`${namePrefix}description`}
                                    style={{ marginTop: '0.5rem', width: '40rem' }}
                                    placeholder="Description"
                                    defaultValue={description}
                                />
                                <FieldErrors
                                    errors={[
                                        protocolStepsErrors?.title,
                                        protocolStepsErrors?.description,
                                    ]}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
            <Button
                style={{ margin: '1rem 0 0 7.5rem' }}
                onClick={() => protocolStepsFieldArray.append(newProtocolStep())}
            >
                + Add Step
            </Button>
        </>
    );
}

export default ProtocolStepsCreation;
