/**
 * For creating common ajax request type slices (for reducers) that fetch data.
 */

import {
    CaseReducer,
    createAsyncThunk,
    createSlice,
    PayloadAction,
    SliceCaseReducers,
    ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';
import api, { ResponseEnvelope } from '../../axios';
import { AxiosRequestConfig } from 'axios';
import { RootState } from '../index';
import {
    CustomErrorHandler,
    handleError,
    NormalizedError,
    normalizeError,
} from '../../utils/errorHandling';
import { SetError } from '../../values/types';

async function requestData<T>(uri, config = {}) {
    const response = await api.request<ResponseEnvelope<T>>({
        url: uri,
        method: 'GET',
        ...config,
    });
    return response.data;
}

type LoadOptions<Form = unknown> = {
    uri: string;
    stealth?: boolean;
    config?: AxiosRequestConfig;
    setError?: SetError<any>;
    customErrorHandler?: CustomErrorHandler<Form>;
};

export type MetaPagination = {
    current_page: number;
    from: number;
    last_page: number;
    per_page: number;
    to: number;
    total: number;
};

export interface GenericFetchState<T> {
    data: T | null;
    waiting: boolean;
    error: NormalizedError | null;
    meta: null | MetaPagination;
}

export function getInitialState<T>(initialData: T | null = null): GenericFetchState<T> {
    return {
        waiting: false,
        error: null,
        data: initialData,
        meta: null,
    };
}

/**
 * You can find the created reducer in the returned object's "reducer" property.
 *
 * @param name {string} The name of the reducer.
 * @param reducers {Object=} Reducers to create in addition to the default ones.
 * @param initialState Use this for typescript types.
 * @returns Object
 */
export function createFetchSlice<
    State extends GenericFetchState<unknown>,
    Reducers extends SliceCaseReducers<State>
>(name: string, reducers: ValidateSliceCaseReducers<State, Reducers>, initialState: State) {
    type T = State['data'];

    /**
     * This is the main exposed function; this is what needs to be dispatched to load the data.
     * It also handles the error automatically.
     */
    const load = createAsyncThunk(
        `${name}/load`,
        async (options: LoadOptions, { rejectWithValue }) => {
            const { uri, config } = options;
            try {
                return await requestData<T>(uri, config);
            } catch (error) {
                const normalizedError = handleError(error, {
                    setError: options.setError,
                    customHandler: options.customErrorHandler,
                });
                return rejectWithValue(normalizedError);
            }
        }
    );

    // Updates data and meta.
    const successCaseReducer: CaseReducer<State, PayloadAction<ResponseEnvelope<T>>> = (
        state,
        { payload }
    ) => {
        return {
            ...state,
            data: payload.data,
            waiting: false,
            meta: payload.meta ?? null,
        };
    };

    const slice = createSlice({
        name,
        initialState,
        reducers: {
            reset: () => initialState,
            // Updates just data.
            success: (state, { payload }: PayloadAction<T>) => ({
                ...state,
                data: payload,
                waiting: false,
            }),
            ...reducers,
        },
        extraReducers: builder => {
            builder.addCase(load.pending, (state, action) => {
                state.error = null;
                if (!action.meta.arg.stealth) {
                    state.waiting = true;
                }
            });
            builder.addCase(load.fulfilled, successCaseReducer);
            builder.addCase(load.rejected, (state, { payload }) => {
                state.error = normalizeError(payload);
                state.waiting = false;
            });
        },
    });

    const selector: (state: RootState) => State = state => state[name];

    return {
        ...slice,
        load,
        selector,
    };
}
