import React, { useEffect, useMemo } from 'react';
import { DiseaseCase, DiseaseCaseSingle, Person } from '../../values/types';
import { useAppDispatch, useSelector } from '../../store';
import {
    diseaseCasesFilteredAsTreeWithMapSelector,
    diseaseCasesFilteredDataByContactLeveSelector,
    diseaseCasesFilteredReset,
    diseaseCasesFilteredSelector,
    loadDiseaseCasesFiltered,
} from '../../store/reducers/diseaseCasesFiltered';
import BigSpinner from '../BigSpinner/BigSpinner';
import { flattenTree } from '../../utils/treeBuilder';
import ExistingDirectContactsTable from './ExistingDirectContactsTable';
import { contactLevelInfoMap } from '../../values/appConfig';
import { facilityConfigSelector } from '../../store/reducers/facilityConfig';

export type ExposureAndExposedPerson = DiseaseCase & {
    exposedPerson?: Person;
};

export default function ExistingDirectContactsTables({ diseaseCase }: Props) {
    const dispatch = useAppDispatch();
    const { isContactTracingEnabled } = useSelector(facilityConfigSelector);
    const rootDiseaseCaseUuid = diseaseCase.rootContactDiseaseCaseUuid ?? diseaseCase.uuid;

    useEffect(() => {
        dispatch(loadDiseaseCasesFiltered('rootContactDiseaseCaseUuid', rootDiseaseCaseUuid));
        return () => {
            dispatch(diseaseCasesFilteredReset());
        };
    }, [dispatch, rootDiseaseCaseUuid]);

    return isContactTracingEnabled ? (
        <YesContactTracingExposures />
    ) : (
        <NoContactTracingExposures diseaseCase={diseaseCase} />
    );
}

interface Props {
    diseaseCase: DiseaseCaseSingle;
}

function NoContactTracingExposures({ diseaseCase }: Props) {
    const diseaseCasesTreeWithMap = useSelector(diseaseCasesFilteredAsTreeWithMapSelector);
    const exposuresState = useSelector(diseaseCasesFilteredSelector);

    const exposureTree = useMemo(() => {
        if (!diseaseCasesTreeWithMap) {
            return [];
        }

        if (!diseaseCase.rootContactDiseaseCaseUuid) {
            // This disease case _is_ the root disease case. Use the root tree nodes' items as the
            // items, because the root disease case doesn't actually appear in the tree.
            return diseaseCasesTreeWithMap.tree;
        } else if (diseaseCasesTreeWithMap.map[diseaseCase.uuid]) {
            return diseaseCasesTreeWithMap.map[diseaseCase.uuid].children;
        }
        return [];
    }, [diseaseCase.rootContactDiseaseCaseUuid, diseaseCase.uuid, diseaseCasesTreeWithMap]);

    const flattenedExposureTree = useMemo(() => {
        return flattenTree(exposureTree);
    }, [exposureTree]);

    if (!flattenedExposureTree.length) {
        return null;
    }

    if (exposuresState.waiting || !exposuresState.data) {
        return <BigSpinner />;
    }

    return (
        <ExistingDirectContactsTable
            baseContactLevel={diseaseCase.rootContactLevel}
            flattenedExposureTree={flattenedExposureTree}
        />
    );
}

function YesContactTracingExposures() {
    const exposuresByContactLevel = useSelector(diseaseCasesFilteredDataByContactLeveSelector);

    const tables = useMemo(() => {
        if (!exposuresByContactLevel) {
            return;
        }
        return exposuresByContactLevel.map(contactLevelAndExposures => {
            const contactLevel = contactLevelAndExposures.contactLevel;
            return (
                <div key={contactLevel}>
                    <h3>{contactLevelInfoMap[contactLevel].label}</h3>

                    <ExistingDirectContactsTable
                        // Since with embedded we do not have parent information for exposures,
                        // (only root information), _don't_ indent the table rows with 2nd, 3rd etc.
                        // This can be done by setting the contact level to be equal to the
                        // exposures'.
                        baseContactLevel={contactLevel}
                        flattenedExposureTree={contactLevelAndExposures.exposures}
                    />
                </div>
            );
        });
    }, [exposuresByContactLevel]);

    if (!exposuresByContactLevel) {
        return <BigSpinner />;
    }

    return <div>{tables}</div>;
}
