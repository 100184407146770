import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { URI_DISEASE_CASES } from '../../axios';
import { DiseaseCaseSingle } from '../../values/types';
import { NOT_FOUND } from '../../utils/errorHandling';

const slice = createFetchSlice('diseaseCaseShow', {}, getInitialState<DiseaseCaseSingle>());

/**
 * @param uuid
 * @param doNotHandleNotFound If TRUE, then "not found" errors should not be handled
 *  i.e. no error toasts or sentry logging.
 */
export function loadDiseaseCaseShow(uuid: string, doNotHandleNotFound?: boolean) {
    return slice.load({
        uri: `${URI_DISEASE_CASES}/${uuid}`,
        customErrorHandler: doNotHandleNotFound
            ? normalizedError => normalizedError.type === NOT_FOUND
            : undefined,
    });
}

const diseaseCaseShow = slice.reducer;
const diseaseCaseShowSelector = slice.selector;
const diseaseCaseShowReset = slice.actions.reset;
const diseaseCaseShowSuccess = slice.actions.success;

export { diseaseCaseShowSelector, diseaseCaseShowReset, diseaseCaseShowSuccess };

export const diseaseCaseShowDataSelector = createSelector(diseaseCaseShowSelector, v => v.data);

export default diseaseCaseShow;
