import React from 'react';
import BoxCardWithIconAndName from '../../../components/BoxCardWithIconAndName';
import { ProfilePhoto } from '../../../values/types';
import PersonPhoto from '../../../components/PersonPhoto';
import SubjectPicture from '../../../components/SubjectPicture';

function PersonBox({ person, selected }: Props) {
    return (
        <BoxCardWithIconAndName
            selected={selected}
            leftNode={
                person.profilePhoto ? (
                    <PersonPhoto size="2rem" photo={person.profilePhoto.smallUrl} />
                ) : (
                    <span style={{ fontSize: '2rem' }}>
                        <SubjectPicture
                            subject={{
                                polymorphicType: 'person',
                                id: person.id,
                                name: person.fullName,
                            }}
                        />
                    </span>
                )
            }
            name={person.fullName}
        />
    );
}

interface Props {
    person: {
        id: number;
        fullName: string;
        profilePhoto?: ProfilePhoto | null;
    };
    selected: boolean;
}

export default PersonBox;
