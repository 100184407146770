import axios from '../../axios';

const headers = () => next => action => {
    if (action.meta && action.meta.api) {
        return next({...action, apiClient: axios});
    } else {
        return next(action);
    }
};

export default headers;
