import styled from '@emotion/styled';
import { css } from '../../utils/ide';

export const height = '4rem';
export const rowBorderRadius = '0.75rem';

export const DataTableTr = styled.tr(
    ({ theme }) => css`
        color: ${theme.primaryLight};
        height: ${height};
        box-sizing: border-box;
        td {
            padding: 0.1rem 1rem;
        }
        td.first-td-radius {
            border-bottom-left-radius: ${rowBorderRadius};
            border-top-left-radius: ${rowBorderRadius};
        }
        td:last-of-type {
            border-bottom-right-radius: ${rowBorderRadius};
            border-top-right-radius: ${rowBorderRadius};
        }
    `
);
