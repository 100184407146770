import { faClipboardCheck } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find } from 'lodash-es';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import axiosInstance, { ResponseEnvelope, URI_DISEASE_CASES } from '../../axios';
import { useAppDispatch } from '../../store';
import { loadAllDiseaseCases } from '../../store/actions/global';
import { diseaseCaseShowSuccess } from '../../store/reducers/diseaseCaseShow';
import { gray700, gray800, green, red } from '../../styles/theme';
import { formatDate } from '../../utils/date';
import { handleError } from '../../utils/errorHandling';
import { displaySuccess } from '../../utils/toast';
import { DiseaseCaseSingle, DiseaseSingle, Symptom } from '../../values/types';
import { PrimaryButtonLink } from '../Button/Button';
import { CheckboxWithLabel } from '../CheckboxWithLabel/CheckboxWithLabel';
import ProtocolContactTracing from '../DiseaseCase/ProtocolContactTracing';
import FieldErrors from '../FieldErrors';
import { routes } from '../../router/routes';

interface Props {
    disease: DiseaseSingle;
    diseaseCase: DiseaseCaseSingle;
}

function hasSymptoms(symptomsIn: Map<number, boolean>): boolean {
    for (let [, isChecked] of symptomsIn) {
        if (isChecked) {
            return true;
        }
    }
    return false;
}

function ProtocolStepsCompletion(props: Props) {
    const { disease, diseaseCase } = props;
    const dispatch = useAppDispatch();
    const nbStepProtocol = diseaseCase.contactTracingRequired
        ? disease.protocolSteps.length + 1
        : disease.protocolSteps.length;

    const symptoms = useMemo(() => {
        const found = find(disease.protocolSteps, { hasListOfSymptoms: true });

        return found ? (found.symptoms as Symptom[]) : [];
    }, [disease.protocolSteps]);

    // pre-fill checkboxes
    const stepsIn = useMemo(() => {
        const stepsIn = new Map<number, boolean>();
        disease.protocolSteps.forEach(step => {
            const found = find(diseaseCase.protocolCompletion, { protocolStepId: step.id });
            const stepId = step.id || 0;
            stepsIn.set(stepId, !!found);
        });
        return stepsIn;
    }, [disease.protocolSteps, diseaseCase.protocolCompletion]);

    const symptomsIn = useMemo(() => {
        const symptomsIn = new Map<number, boolean>();
        symptoms.forEach(symptom => {
            const found = find(diseaseCase.symptoms, { symptomId: symptom.id });
            symptomsIn.set(symptom.id, !!found);
        });
        return symptomsIn;
    }, [diseaseCase.symptoms, symptoms]);

    const [hasNoSymptoms, setHasNoSymptoms] = useState(!hasSymptoms(symptomsIn));

    const { control, errors, getValues, register, setError, setValue } = useForm({
        defaultValues: {
            steps: stepsIn as any, // weird typing issue
            symptoms: symptomsIn,
            contactTracingCompleted: diseaseCase.contactTracingCompleted,
        },
    });

    const uncheckAllSymptoms = (symptomsIn: Map<number, boolean>, isChecked: boolean) => {
        if (isChecked && hasSymptoms(symptomsIn)) {
            setHasNoSymptoms(true);
            symptomsIn.forEach((v, symptomId) => {
                symptomsIn.set(symptomId, false);
            });
        }
        return symptomsIn;
    };

    const save = useCallback(async () => {
        // safety measure to prevent a completed disease case from being updated
        if (diseaseCase.protocolCompletedAt) {
            return;
        }

        const stepsIn = getValues('steps');
        const stepsOut: { protocolStepId: number }[] = [];
        const symptomsIn = getValues('symptoms') || [];

        const symptomsOut: number[] = [];
        const contactTracingCompleted = getValues('contactTracingCompleted');

        for (let [id, isChecked] of stepsIn) {
            if (isChecked) {
                stepsOut.push({
                    protocolStepId: id,
                });
            }
        }
        for (let [id, isChecked] of symptomsIn) {
            if (isChecked) {
                symptomsOut.push(id);
            }
        }

        const values = {
            steps: stepsOut,
            symptoms: symptomsOut,
            contactTracingCompleted: contactTracingCompleted,
        };

        try {
            const response = await axiosInstance.put<ResponseEnvelope<DiseaseCaseSingle>>(
                `${URI_DISEASE_CASES}/${diseaseCase.uuid}/protocol-completion`,
                values
            );
            dispatch(diseaseCaseShowSuccess(response.data.data));
            dispatch(loadAllDiseaseCases());

            if (response.data.data.protocolCompletedAt) {
                displaySuccess('Disease case completed. Questionnaire now locked.');
            } else {
                displaySuccess('Protocol Questionnaire saved');
            }
        } catch (e) {
            handleError(e, { setError });
        }
    }, [diseaseCase.protocolCompletedAt, diseaseCase.uuid, dispatch, getValues, setError]);

    const markContactTracingAsCompleted = useCallback(() => {
        setValue('contactTracingCompleted', true);
        save();
    }, [save, setValue]);

    return (
        <>
            <h3 style={{ color: red }}>
                {nbStepProtocol ? (
                    <>
                        <FontAwesomeIcon
                            size="1x"
                            icon={faClipboardCheck}
                            style={{ marginRight: '0.5rem' }}
                        />
                        {nbStepProtocol} Step Protocol - {disease.name}
                    </>
                ) : null}
                {diseaseCase.protocolCompletedAt ? (
                    <span style={{ color: green }}>
                        <FontAwesomeIcon
                            size="1x"
                            icon={faCheckCircle}
                            style={{ marginLeft: '1rem' }}
                        />
                        <span style={{ fontWeight: 'normal' }}>
                            &nbsp;Completed {formatDate(diseaseCase.protocolCompletedAt)}
                        </span>
                    </span>
                ) : (
                    ''
                )}
            </h3>
            <Controller
                name="steps"
                control={control}
                register={register}
                render={({ onChange, value }) => (
                    <>
                        {disease.protocolSteps.map((step, i) => {
                            const stepId = step.id || 0;
                            return (
                                <div key={step.id}>
                                    <CheckboxWithLabel
                                        defaultChecked={value.get(stepId)}
                                        disabled={!!diseaseCase.protocolCompletedAt}
                                        label={
                                            `${i + 1}. ` +
                                            (!step.hasListOfSymptoms
                                                ? step.title
                                                : 'Gather Symptom Data')
                                        }
                                        onChange={evt => {
                                            const stepId = step.id || 0;

                                            const newValue: Map<number, boolean> = new Map(value);
                                            newValue.set(stepId, evt.currentTarget.checked);
                                            onChange(newValue);
                                            save();
                                        }}
                                        style={{ fontWeight: 'bold', color: gray700 }}
                                    />
                                    {!step.hasListOfSymptoms ? (
                                        <div
                                            style={{
                                                color: gray800,
                                                margin: '0.5rem 0 1.2rem 2.2rem',
                                            }}
                                        >
                                            {step.description}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            );
                        })}
                    </>
                )}
            />
            <FieldErrors errors={errors?.steps} />

            {symptoms.length ? (
                <div style={{ margin: '0.5rem 0 0 2.1rem' }}>
                    <Controller
                        name="symptoms"
                        control={control}
                        register={register}
                        render={({ onChange, value }) => (
                            <>
                                <CheckboxWithLabel
                                    checked={hasNoSymptoms}
                                    disabled={!!diseaseCase.protocolCompletedAt}
                                    label={<em>no symptoms</em>}
                                    onChange={evt => {
                                        let newValue: Map<number, boolean> = new Map(value);
                                        newValue = uncheckAllSymptoms(
                                            newValue,
                                            evt.currentTarget.checked
                                        );
                                        onChange(newValue);
                                        save();
                                    }}
                                    style={{ margin: '0', color: gray800 }}
                                />
                                {symptoms.map(symptom => {
                                    return (
                                        <div key={symptom.id}>
                                            <CheckboxWithLabel
                                                checked={value.get(symptom.id)}
                                                disabled={!!diseaseCase.protocolCompletedAt}
                                                label={symptom.name}
                                                onChange={evt => {
                                                    const newValue: Map<number, boolean> = new Map(
                                                        value
                                                    );
                                                    newValue.set(
                                                        symptom.id,
                                                        evt.currentTarget.checked
                                                    );
                                                    onChange(newValue);
                                                    setHasNoSymptoms(!hasSymptoms(newValue));
                                                    save();
                                                }}
                                                style={{ margin: '0', color: gray800 }}
                                            />
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    />
                </div>
            ) : (
                ''
            )}

            {diseaseCase.contactTracingRequired ? (
                <>
                    <CheckboxWithLabel
                        name="contactTracingCompleted"
                        register={register}
                        disabled={!!diseaseCase.protocolCompletedAt}
                        label={disease.protocolSteps.length + 1 + '. Perform Contact Tracing'}
                        onChange={() => {
                            save();
                        }}
                        style={{ fontWeight: 'bold', color: gray700 }}
                    />
                    <div
                        style={{
                            color: gray800,
                            margin: '0.5rem 0 1.2rem 2.2rem',
                        }}
                    >
                        List of people the person has been in contact with in the last{' '}
                        {disease.contagiousPeriodPriorDays} days.
                    </div>

                    <div style={{ margin: '2rem 0 0 2.4rem' }}>
                        <ProtocolContactTracing
                            markContactTracingAsCompleted={markContactTracingAsCompleted}
                            disease={disease}
                            diseaseCase={diseaseCase}
                        />
                    </div>
                </>
            ) : (
                ''
            )}

            {diseaseCase.protocolCompletedAt ? (
                <PrimaryButtonLink to={`${routes.caseManagement.fullPath}`} style={{ top: '1rem' }}>
                    &lt; Go Back To Dashboard
                </PrimaryButtonLink>
            ) : (
                ''
            )}
        </>
    );
}

export default ProtocolStepsCompletion;
