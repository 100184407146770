import React from 'react';
import Grid from '../../components/Grid/Grid';
import BigIconClickableBox from '../../components/BigIconClickableBox/BigIconClickableBox';
import { faUserMd, faUsers } from '@fortawesome/pro-light-svg-icons';
import { ContactTracingTitle } from './common';
import { useDispatch } from 'react-redux';
import { setContactTracingTrace } from '../../store/actions/contactTracing';
import { WhatTrace } from '../../store/reducers/contactTracing';
import { useSelector } from '../../store';

const boxConfig: Array<{icon, title, trace: WhatTrace}> = [
    {icon: faUsers, title: 'Residents', trace: 'residents'},
    {icon: faUserMd, title: 'Staff', trace: 'caregivers'},
]

function SelectTracePage() {
    const dispatch = useDispatch();
    const selectedTrace = useSelector(state => state.contactTracing.whatTrace);

    return (
        <div>
            <ContactTracingTitle>Select what you want to trace</ContactTracingTitle>
            <Grid columns={boxConfig.length} gridGap="3rem">
                {boxConfig.map(({ icon, title, trace }) => <BigIconClickableBox
                    selected={selectedTrace === trace}
                    key={trace}
                    icon={icon}
                    title={title}
                    onClick={() => dispatch(setContactTracingTrace(trace))}
                />)}
            </Grid>
        </div>
    );
}

export default SelectTracePage;
