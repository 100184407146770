import SelectTracePage from './SelectTracePage';
import SelectPersonPage from './SelectPersonPage';
import SelectDateRangePage from './SelectDateRangePage';
import ApplyQuickFiltersPage from './ApplyQuickFiltersPage';
import ChooseContactLevelPage from './ChooseContactLevelPage';
import ContactTracingResultsPage from './ContactTracingResultsPage';
import { routes } from '../../router/routes';

export const routeForSelectTrace = {
    path: routes.contactTracing.fullPath,
    component: SelectTracePage,
    wizardStep: 1,
};
export const routeForSelectPerson = {
    path: routes.contactTracingSelectPerson.fullPath,
    component: SelectPersonPage,
    wizardStep: 2,
};
export const routeForSelectDateRange = {
    path: routes.contactTracingSelectDateRange.fullPath,
    component: SelectDateRangePage,
    wizardStep: 3,
};
export const routeForApplyQuickFilters = {
    path: routes.contactTracingApplyQuickFilters.fullPath,
    component: ApplyQuickFiltersPage,
    wizardStep: 4,
};
export const routeForChooseContactLevel = {
    path: routes.contactTracingChooseContactLevel.fullPath,
    component: ChooseContactLevelPage,
    wizardStep: 5,
};
export const routeForContactTracingResults = {
    path: routes.contactTracingResults.fullPath,
    component: ContactTracingResultsPage,
    wizardStep: null,
};

const contactTracingRoutes = [
    routeForSelectTrace,
    routeForSelectPerson,
    routeForSelectDateRange,
    routeForApplyQuickFilters,
    routeForChooseContactLevel,
];

export default contactTracingRoutes;
