import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { personTypeInfoMap } from '../values/appConfig';
import useEffectUnlessFiltersSame from '../components/hooks/useEffectUnlessFiltersSame';
import { useAppDispatch, useSelector } from '../store';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router';
import PersonsListPage from './PersonsListPage';
import PersonCreatePage from './PersonCreatePage';
import { diseasesReset, loadDiseases } from '../store/reducers/diseases';
import { diseaseCasesReset, loadDiseaseCases } from '../store/reducers/diseaseCases';
import PersonPage from './PersonPage';
import { loadPersons } from '../store/reducers/persons';
import { routes } from '../router/routes';
import { PersonsRouteParams } from '../router/caseManagementRoutes';
import { facilityConfigSelector } from '../store/reducers/facilityConfig';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 3rem;
`;

function PersonsPage() {
    const dispatch = useAppDispatch();
    const routeMatch = useRouteMatch<PersonsRouteParams>();
    const personType = routeMatch.params.personType === 'staff' ? 'caregiver' : 'resident';
    const personInfo = personTypeInfoMap[personType];
    const { isStandalone } = useSelector(facilityConfigSelector);

    useEffectUnlessFiltersSame(() => {
        dispatch(loadPersons());
        dispatch(loadDiseases());
        // TODO: switch to using per-person filtered endpoint when available.
        dispatch(loadDiseaseCases());
        return () => {
            dispatch(diseasesReset());
            dispatch(diseaseCasesReset());
        };
    });

    // Routes for viewing/adding/editing people.
    const _routes = useMemo(() => {
        const ret: (RouteProps & { path: string })[] = [
            ...(isStandalone
                ? [
                      {
                          path: `${routeMatch.path}/${routes.personsCreate.relativePath}`,
                          render: () => <PersonCreatePage />,
                      },
                  ]
                : []),
            {
                path: `${routeMatch.path}/${routes.personsSingle.relativePath}`,
                render: ({ match }) => (
                    <PersonPage
                        personInfo={personInfo}
                        match={match}
                        parentRouteMatch={routeMatch}
                    />
                ),
            },
        ];
        return ret;
    }, [isStandalone, personInfo, routeMatch]);

    return (
        <Container>
            <Switch>
                {_routes.map(routeProps => (
                    <Route key={routeProps.path} {...routeProps} />
                ))}
                <Route render={() => <PersonsListPage personInfo={personInfo} />} />
            </Switch>
        </Container>
    );
}

export default PersonsPage;
