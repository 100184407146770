import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {css} from '../../utils/ide';
import {timesIcon} from '../../styles/fontLibrary';
import {Box} from '../../styles/shared';

const Container = styled(Box)(({theme}) => css`
    width: 220px;
    padding: 0;
    background: ${theme.background};
    z-index: 200;
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    cursor: default;
    text-align: left;
`);

const CloseContainer = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    path {
        color: ${props => props.theme.gray400};
    }
    &:hover {
        path {
            color: ${props => props.theme.gray600};
        }
    }
`;

const HoverMenu = (props) => {
    const conRef = useRef(null);

    const handleClick = e => {
        if (!conRef.current.contains(e.target)) {
            props.close();
        }
    };

    useEffect(() => {
        if (!props.open) return;
        document.addEventListener('mousedown', handleClick);
        return (() => document.removeEventListener('mousedown', handleClick));
    });

    if (!props.open) return null;

    return (
        <Container
            ref={conRef}
            data-cy={props.cy}>
            <CloseContainer onClick={props.close}>
                <FontAwesomeIcon icon={timesIcon} />
            </CloseContainer>
            {props.children}
        </Container>
    )
};

HoverMenu.propTypes = {
    open: PropTypes.bool,
    close: PropTypes.func.isRequired,
    cy: PropTypes.string
};

export default HoverMenu;
