import React, { ReactNode } from 'react';
import { Box as _Box } from '../styles/shared';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const Box = styled(_Box)`
    display: flex;
    text-align: left;
    padding-left: 2rem;
    align-items: center;
`;

function BoxCardWithIconAndName({ name, selected, icon, leftNode }: Props) {
    return (
        <Box selected={selected}>
            {leftNode && <div style={{ width: '2rem', height: '2rem' }}>{leftNode}</div>}
            {icon && <FontAwesomeIcon icon={icon} size="2x" />}
            <div style={{ flex: 1, marginLeft: '1rem' }}>{name}</div>
        </Box>
    );
}

interface Props {
    name?: string;
    selected: boolean;
    leftNode?: ReactNode;
    icon?: IconProp;
}

export default BoxCardWithIconAndName;
