import { DiseaseCase, Location, Person } from '../values/types';

export function formatIsolationLocationName(
    person: Person | undefined,
    personQuarantineLocation: Location | undefined
) {
    if (personQuarantineLocation) {
        return personQuarantineLocation.name;
    }

    if (!person) {
        return 'N/A';
    }

    if (!person.quarantineLocationKey) {
        return 'No isolation/quarantine location';
    }

    return 'Unknown';
}

export function formatIsolationOrQuarantine(diseaseCase: DiseaseCase) {
    return diseaseCase.status === 'exposure' ||
        (diseaseCase.status === 'closed' && diseaseCase.isExposure)
        ? 'Quarantine'
        : 'Isolation';
}
