/**
 * Alternative disease case reducer that fetches disease cases by root contact disease case uuid.
 * The returned disease cases have parent and root disease case uuid information on them.
 */
import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { URI_DISEASE_CASES } from '../../axios';
import { ContactLevel, DiseaseCase, DiseaseCaseWithParent } from '../../values/types';
import { stringify } from 'qs';
import { createTreeWithMap } from '../../utils/treeBuilder';

const slice = createFetchSlice(
    'diseaseCasesFiltered',
    {},
    // When calling the endpoint to fetch the disease cases while providing
    // 'rootContactDiseaseCaseUuid' or 'parentUuid' as a filter, then both of those properties
    // become part of the API response.
    getInitialState<DiseaseCaseWithParent[]>()
);

type Property = 'rootContactDiseaseCaseUuid' | 'personKey';

export function loadDiseaseCasesFiltered(property: Property, value: string) {
    const uri = `${URI_DISEASE_CASES}?include=tests&${stringify({
        filter: { [property]: value },
    })}`;

    return slice.load({
        uri,
    });
}

const diseaseCasesFiltered = slice.reducer;
const diseaseCasesFilteredSelector = slice.selector;
const diseaseCasesFilteredReset = slice.actions.reset;

export { diseaseCasesFilteredSelector, diseaseCasesFilteredReset };

export const diseaseCasesFilteredDataSelector = createSelector(
    diseaseCasesFilteredSelector,
    v => v.data
);

type ContactLevelAndExposures = { contactLevel: ContactLevel; exposures: DiseaseCase[] };

// Used for embedded exposures tables.
export const diseaseCasesFilteredDataByContactLeveSelector = createSelector(
    diseaseCasesFilteredDataSelector,
    diseaseCasesData => {
        if (!diseaseCasesData) {
            return diseaseCasesData;
        }
        const ret: ContactLevelAndExposures[] = [];
        const retByContactLevel: { [contactLevel in ContactLevel]?: ContactLevelAndExposures } = {};

        for (const exposure of diseaseCasesData) {
            const contactLevel = exposure.rootContactLevel;
            if (!contactLevel) {
                continue;
            }
            if (!retByContactLevel[contactLevel]) {
                const newObj = { contactLevel, exposures: [] };
                retByContactLevel[contactLevel] = newObj;
                ret.push(newObj);
            }
            retByContactLevel[contactLevel]!.exposures.push(exposure);
        }
        // Sort by contact level, ascending.
        ret.sort((v1, v2) => v1.contactLevel - v2.contactLevel);

        return ret;
    }
);

export const diseaseCasesFilteredAsTreeWithMapSelector = createSelector(
    diseaseCasesFilteredDataSelector,
    diseaseCasesData =>
        diseaseCasesData &&
        createTreeWithMap(
            diseaseCasesData,
            'uuid',
            'parentUuid',
            // If a disease case's parent is the same as their root, then treat those tree nodes
            // as root nodes, because the root disease case doesn't actually appear in the tree.
            diseaseCase => diseaseCase.parentUuid !== diseaseCase.rootContactDiseaseCaseUuid
        )
);

export default diseaseCasesFiltered;
