import React, { useEffect, useMemo } from 'react';
import Wizard from '../../components/Wizard/Wizard';
import styled from '@emotion/styled';
import { Route, Switch, useLocation } from 'react-router';
import { reportError } from '../../utils/errorHandling';
import contactTracingRoutes, { routeForContactTracingResults } from './contactTracingRoutes';
import ContactTracingResultsPage from './ContactTracingResultsPage';
import { useDispatch } from 'react-redux';
import { resetContactTracing } from '../../store/actions/contactTracing';
import { WINDOW_WIDTH_PHONE_LANDSCAPE } from '../../components/ResponsiveProvider/ResponsiveProvider';
import { useSelector } from '../../store';

const Container = styled.div`
    padding: 4rem 10rem;
    text-align: center;

    @media screen and (max-width: ${WINDOW_WIDTH_PHONE_LANDSCAPE}px) {
        padding: 1rem 2rem;
    }
`;

const WizardContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 0 10rem 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: ${WINDOW_WIDTH_PHONE_LANDSCAPE}px) {
        padding: 1rem 1rem 2rem;
    }
`;

function ContactTracing() {
    let { pathname } = useLocation();
    const dispatch = useDispatch();
    const lastStep = useSelector(state => state.contactTracing.lastStep);

    const wizardRoutes = useMemo(() => {
        return contactTracingRoutes.map(({ path }) => path);
    }, []);

    const wizardStep = useMemo(() => {
        if (pathname === '/contact-tracing/results') return 1;
        for (const route of contactTracingRoutes) {
            if (route.path === pathname) {
                return route.wizardStep;
            }
        }

        reportError('Could not find the wizard step.');
        return 1;
    }, [pathname]);

    useEffect(() => {
        return () => {
            dispatch(resetContactTracing());
        };
    }, [dispatch]);

    if (pathname === routeForContactTracingResults.path) {
        return <ContactTracingResultsPage />;
    }

    return (
        <Container>
            {wizardStep !== null && (
                <WizardContainer>
                    <Wizard currentStep={wizardStep} lastStep={lastStep} routes={wizardRoutes} />
                </WizardContainer>
            )}
            <Switch>
                {contactTracingRoutes.map(({ path, component }) => (
                    <Route key={path} path={path} exact component={component} />
                ))}
            </Switch>
        </Container>
    );
}

export default ContactTracing;
