import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { Engage360Filters, ActivitySummary } from '../../values/types';

const slice = createFetchSlice('engageActivitySummary', {}, getInitialState<ActivitySummary>());

export function loadEngageActivitySummary(filters: Engage360Filters, options = {}) {
    return slice.load({
        uri: `/activities/summary`,
        config: {
            params: filters,
        },
        ...options,
    });
}

const engageActivitySummary = slice.reducer;
const engageActivitySummarySelector = slice.selector;
const engageActivitySummaryReset = slice.actions.reset;

export { engageActivitySummarySelector, engageActivitySummaryReset };

export const engageActivitySummaryDataSelector = createSelector(
    engageActivitySummarySelector,
    v => v.data
);

export default engageActivitySummary;
