import React from 'react';
import ActivitySignup from './ActivitySignup';
import { styled } from '../../../styles/theme';
import { TablePageContainer, Title } from '../engage360Common';
import { useSelector } from '../../../store';
import { engageActivitySignupsSelector } from '../../../store/reducers/engageActivitySignups';
import BigSpinner from '../../BigSpinner/BigSpinner';
import { Paginate2ByMeta } from '../../Pagination/Paginate2';

const SignupsContainer = styled.div`
    > div:not(:last-of-type) {
        border-bottom: 1px solid #505051;
    }
`;

type Props = {
    setPage: (page: number) => void;
    page: number | string;
};

export default function ActivitiesSignups({ setPage, page }: Props) {
    const { data, meta, waiting } = useSelector(engageActivitySignupsSelector);

    return (
        <TablePageContainer>
            <Title>Activity Sign Ups</Title>
            <Paginate2ByMeta meta={meta} onPageChange={setPage} page={page} />
            {data && !waiting ? (
                <SignupsContainer>
                    {data.map(activity => (
                        <ActivitySignup key={activity.uuid} item={activity} />
                    ))}
                </SignupsContainer>
            ) : (
                <BigSpinner tall />
            )}
            <Paginate2ByMeta meta={meta} onPageChange={setPage} page={page} />
        </TablePageContainer>
    );
}
