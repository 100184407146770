import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import keycloak, { logout } from './utils/keycloak';
import { trimEnd } from 'lodash-es';
import { getCookie } from './utils/browser';
import { MetaPagination } from './store/sliceCreators/fetchSliceCreator';

const devApi = 'https://dev.api.sentrics.ttgda.com';

export const base =
    process.env.NODE_ENV === 'test'
        ? 'http://test.com'
        : trimEnd(process.env.REACT_APP_API_ENDPOINT || devApi, '/');

const axiosInstance = axios.create({
    baseURL: `${base}/api`,
});

axiosInstance.interceptors.request.use(function (config) {
    config.headers.Authorization = 'Bearer ' + keycloak.token;
    config.headers.Accept = 'application/json';

    // Forward xdebug cookie to the API.
    const xdebugSessionValue = getCookie('XDEBUG_SESSION');
    if (xdebugSessionValue) {
        if (!config.params) {
            config.params = {};
        }
        config.params.XDEBUG_SESSION_START = xdebugSessionValue;
    }

    return config;
});

createAuthRefreshInterceptor(axiosInstance, () => {
    // Maybe the token just expired and we need to refresh it. Let's try that.
    return new Promise((resolve, reject) => {
        // If the token can't be updated within 5 seconds, consider the token refresh a failure.
        setTimeout(reject, 5000);
        keycloak.updateToken(0).success(resolve).error(reject);
    }).catch(() => {
        logout();
    });
});

export const URI_CONTACT_TRACING = '/contact-tracing';
export const URI_PEOPLE = '/people';
export const URI_BULK_UPLOAD = '/people-upload';
export const URI_LOCATIONS = '/locations';
export const URI_DISEASES = '/diseases';
export const URI_DISEASE_CASES = '/disease-cases';
export const URI_CHARTS = '/charts';
export const URI_FACILITIES = '/facilities';

export type ResponseEnvelope<T> = {
    data: T;
    meta?: MetaPagination;
};

export default axiosInstance;
