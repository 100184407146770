import React from 'react';
import { NormalizedError } from '../../utils/errorHandling';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import theme, { red } from '../../styles/theme';
import BigSpinner from '../BigSpinner/BigSpinner';

function ContactTracingLoading({ error, reload }: Props) {
    return (
        <>
            {error ? (
                <div>
                    <FontAwesomeIcon icon={faTimes} color={red} size="10x" />
                </div>
            ) : (
                <BigSpinner />
            )}
            <div
                style={{
                    marginTop: '2rem',
                    fontSize: '1.5rem',
                    color: theme.primaryLight,
                    textAlign: 'center',
                }}
            >
                {error ? (
                    <>
                        {error.message}
                        <br />
                        <span className="link-style" onClick={() => reload()}>
                            Try again?
                        </span>
                    </>
                ) : (
                    'Tracing Contacts... This may take a while...'
                )}
            </div>
        </>
    );
}

interface Props {
    error: NormalizedError | null;
    reload: () => void;
}

export default ContactTracingLoading;
