import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ReactRouterTo } from '../values/types';
import { Box } from '../styles/shared';
import styled from '@emotion/styled';
import theme from '../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const StyledBox = styled(Box)`
    display: flex;
    flex-direction: column;
    background-color: ${theme.primaryBlue};
    color: white;
    padding: 2rem;
    font-weight: bold;
    height: 14rem;
    width: 28rem;
`;

const Title = styled.div`
    font-size: 1.5rem;
    margin-bottom: 1rem;
`;

const BottomIconsContainer = styled.div`
    display: flex;
    align-items: flex-end;

    svg:not(:first-of-type) {
        margin-left: 0.5rem;
    }
`;

function BulkUploadOrManualEntryBox({
    title,
    bodyText,
    lowerLeftIcon,
    lowerRightIcons,
    to,
}: Props) {
    let ret = (
        <StyledBox>
            <Title>{title}</Title>
            <div>{bodyText}</div>
            <div style={{ height: '1rem' }} />
            <div style={{ flex: 1 }} />
            <BottomIconsContainer>
                <FontAwesomeIcon icon={lowerLeftIcon} size="4x" />
                <div style={{ flex: 1 }} />
                {lowerRightIcons &&
                    lowerRightIcons.map((v, i) => <FontAwesomeIcon size="2x" key={i} icon={v} />)}
            </BottomIconsContainer>
        </StyledBox>
    );

    if (to) {
        ret = <Link to={to}>{ret}</Link>;
    }
    return <div>{ret}</div>;
}

interface Props {
    to?: ReactRouterTo;
    title: string;
    bodyText: string;
    lowerLeftIcon: IconProp;
    lowerRightIcons?: IconProp[];
}

export default BulkUploadOrManualEntryBox;
