import React, { ReactNode, useMemo } from 'react';
import { DiseaseCase } from '../values/types';
import CurrentCasesExposuresBox from './Box/CurrentCasesExposuresBox';
import DiseaseBox from './DiseaseBox';
import { GappedFlex } from '../styles/shared';
import { isIE } from '../utils/css';

/**
 * Shows the number of active cases, and lists diseases for a person.
 */
function DiseaseCasesAndCount({ count, diseaseCases, additionalBoxes, isExposures }: Props) {
    const [diseaseBoxesInside, diseaseBoxesOutside] = useMemo(() => {
        const diseaseBoxes = diseaseCases.map(diseaseCase => (
            <DiseaseBox
                key={diseaseCase.uuid}
                diseaseCase={diseaseCase}
                containerStyle={{
                    gridColumnStart: 2,
                    width: isIE ? '45rem' : undefined,
                    marginBottom: isIE ? '2rem' : undefined,
                }}
            />
        ));
        return isIE ? [diseaseBoxes[0], diseaseBoxes.slice(1)] : [diseaseBoxes, []];
    }, [diseaseCases]);

    return (
        <>
            <GappedFlex>
                <CurrentCasesExposuresBox
                    casesOrExposed={isExposures ? 'exposed' : 'cases'}
                    amount={count ?? diseaseCases.length}
                />
                {diseaseBoxesInside}
                {additionalBoxes}
            </GappedFlex>
            {diseaseBoxesOutside}
        </>
    );
}

interface Props {
    count?: number;
    diseaseCases: DiseaseCase[];
    additionalBoxes?: ReactNode;
    isExposures?: boolean;
}

export default DiseaseCasesAndCount;
