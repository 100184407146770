import styled from '@emotion/styled';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { fireSmokeIcon, medkitIcon, serverIcon } from '../../styles/fontLibrary';
import { H2 } from '../../styles/shared';
import theme from '../../styles/theme';
import { css } from '../../utils/ide';
import ValueCardGrid from '../../ValueCardGrid/ValueCardGrid';
import ValueCard from '../ValueCard/ValueCard';

const IconContainer = styled.div(
    ({ theme }) => css`
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        font-size: 0.75em;
        margin-top: 0.2em;
        color: ${theme.primary};
    `
);

function AlarmTotals() {
    const dashboardData = useSelector(state => state.dashboard.data);

    const openAlarms = {
        title: 'Open Alarms',
        icon: faBell,
        value: dashboardData.openAlarms,
        color: dashboardData.openAlarms ? theme.error : null,
    };

    const eCalls = {
        title: 'Emergency Calls',
        icon: medkitIcon,
        value: dashboardData?.domainCounts['Emergency call'] || 0,
    };

    const smokes = {
        title: 'Smoke Alarms',
        icon: fireSmokeIcon,
        value: dashboardData.alarmCountsByType['Smoke detector'] || 0,
    };

    const systems = {
        title: 'System Alarms',
        icon: serverIcon,
        value: dashboardData?.domainCounts['System'] || 0,
    };

    const keyAlerts = [openAlarms, eCalls, smokes, systems];

    return (
        <div>
            <H2>Alarm Totals</H2>
            <ValueCardGrid columns={2}>
                {keyAlerts.map(alert => (
                    <ValueCard
                        {...alert}
                        key={alert.title}
                        topContent={
                            <>
                                <IconContainer style={{ width: '30%', justifyContent: 'center' }}>
                                    <FontAwesomeIcon icon={alert.icon} />
                                </IconContainer>
                                <div>{alert.value}</div>
                            </>
                        }
                        bottomContent={alert.title}
                    />
                ))}
            </ValueCardGrid>
        </div>
    );
}

export default AlarmTotals;
