import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { ActivitiesByHourAndWeek, Engage360Filters } from '../../values/types';

const slice = createFetchSlice(
    'engageActivityByHourAndWeek',
    {},
    getInitialState<ActivitiesByHourAndWeek>()
);

export function loadEngageActivityByHourAndWeek(filters: Engage360Filters, options = {}) {
    return slice.load({
        uri: `/activities/signups/by-hour-and-week`,
        config: { params: filters },
        ...options,
    });
}

const engageActivityByHourAndWeek = slice.reducer;
const engageActivityByHourAndWeekSelector = slice.selector;
const engageActivityByHourAndWeekReset = slice.actions.reset;

export { engageActivityByHourAndWeekSelector, engageActivityByHourAndWeekReset };

export const engageActivityByHourAndWeekDataSelector = createSelector(
    engageActivityByHourAndWeekSelector,
    v => v.data
);

export default engageActivityByHourAndWeek;
