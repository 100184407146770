import React from 'react';
import { match, Redirect, Route, Switch, useRouteMatch } from 'react-router';
import DiseaseEditPage from './DiseaseEditPage';
import DiseasePage from './DiseasePage';
import { routes } from '../router/routes';
import { useSelector } from '../store';
import { facilityConfigSelector } from '../store/reducers/facilityConfig';

function DiseasesPage({ caseManagementUri, parentMatch }: Props) {
    const match = useRouteMatch();
    const { isCentralOfficeAdmin } = useSelector(facilityConfigSelector);

    return (
        <Switch>
            <Route
                path={routes.diseaseCreate.fullPath}
                render={() =>
                    isCentralOfficeAdmin ? (
                        <DiseaseEditPage backUri={caseManagementUri} />
                    ) : (
                        <Redirect to={parentMatch.url} />
                    )
                }
            />
            <Route
                path={`${match.path}/${routes.diseaseSingle.relativePath}`}
                component={DiseasePage}
            />
            <Route render={() => 'Not found.'} />
        </Switch>
    );
}

interface Props {
    caseManagementUri: string;
    parentMatch: match;
}

export default DiseasesPage;
