import ChartTitle from '../ChartTitle';
import Engage360Chart, { useColorizeLabelValues } from '../../Chart/Engage360Chart';
import React, { useEffect } from 'react';
import { useAppDispatch, useSelector } from '../../../store';
import {
    engageActivityUtilizationSelector,
    loadEngageActivityUtilization,
} from '../../../store/reducers/engageActivityUtilization';
import BigSpinner from '../../BigSpinner/BigSpinner';
import { engage360FiltersSelector } from '../../../store/globalSelectors';

export default function LocationUtilization() {
    const dispatch = useAppDispatch();
    const engage360Filters = useSelector(engage360FiltersSelector);
    useEffect(() => {
        dispatch(loadEngageActivityUtilization(engage360Filters));
    }, [dispatch, engage360Filters]);
    const { data } = useSelector(engageActivityUtilizationSelector);
    const chartData = useColorizeLabelValues(data);

    if (!data) {
        return <BigSpinner />;
    }

    return (
        <div>
            <ChartTitle>Location Utilization</ChartTitle>
            <Engage360Chart sideLabel="# of Activities" name="Activities" data={chartData} />
        </div>
    );
}
