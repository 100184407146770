import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { URI_DISEASES } from '../../axios';
import { DiseaseSingle } from '../../values/types';

const slice = createFetchSlice('diseaseShow', {}, getInitialState<DiseaseSingle>());

export function loadDiseaseShow(uuid: string) {
    return slice.load({
        uri: `${URI_DISEASES}/${uuid}`,
    });
}

const diseaseShow = slice.reducer;
const diseaseShowSelector = slice.selector;
const diseaseShowReset = slice.actions.reset;

export { diseaseShowSelector, diseaseShowReset };

export const diseaseShowDataSelector = createSelector(diseaseShowSelector, v => v.data);

export default diseaseShow;
