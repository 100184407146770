import { useCallback } from 'react';
import { base } from '../axios';
import { useSelector } from '../store';
import { facilityConfigSelector } from '../store/reducers/facilityConfig';
import keycloak from './keycloak';

export function useApi() {
    const { facilityKey } = useSelector(facilityConfigSelector);
    const getBaseQuery = useCallback(() => {
        const token = keycloak.token;
        const baseQuery = {};
        baseQuery.facilityKey = facilityKey;
        if (token) {
            baseQuery.token = token;
        }
        return baseQuery;
    }, [facilityKey]);

    return { apiBaseUrl: base, getBaseQuery };
}
