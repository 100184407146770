import * as actionTypes from '../actions/actionTypes';
import subDays from 'date-fns/subDays';
import { formatISODate } from '../../utils/date';
import { keyBy, pick } from 'lodash-es';
import { Location, Person, PersonType } from '../../values/types';
import { createSelector } from '@reduxjs/toolkit';
import {
    personsDataAsArraySelector,
    personsSelector,
    residentsAsArraySelector,
    staffAsArraySelector,
} from './persons';
import { locationsDataSelector } from './locations';
import { parseISO } from 'date-fns';

export interface FromTo {
    from: string;
    to: string;
}

const endDateString = localStorage.getItem('endDate');
const endDate = endDateString ? parseISO(endDateString) : new Date();

const initialState = {
    data: [],
    loading: false,
    error: null,
    alarms: [],
    retrieved: {
        alarms: false,
        locations: false,
    },
    active: null as null | { loading: boolean; data?: any; id: number; open: boolean },
    filters: {
        pageCount: 100,
        currentPage: 1,
        locations: [],
        sortBy: 'creationTime',
        sortByDirection: 'DESC',
        alarms: [],
        caregivers: [],
        sources: [],
        openedShifts: [],
        search: '',
        from: formatISODate(subDays(endDate, 14)),
        to: formatISODate(endDate),
        facilityIds: [] as number[],
        domains: [] as string[],
        hasDisposition: null,
        hasDispositionNote: null,
        domainRadioOption: null,
        typeRadioOption: null,
        engageResidentUuids: [] as string[],
    },
    pages: {
        lastPage: null,
        total: null,
    },
    meta: {
        total: null,
        to: null,
        per_page: null,
        last_page: null,
    },
};

const reducer = (state = initialState, action): typeof initialState => {
    switch (action.type) {
        case actionTypes.RESET_REPORTS: {
            return initialState;
        }

        case actionTypes.GET_ALARMS_REPORT_START: {
            return {
                ...state,
                loading: true,
                data: [],
            };
        }

        case actionTypes.GET_ALARMS_REPORT_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }

        case actionTypes.GET_ALARMS_REPORT_SUCCESS: {
            const { data: dataFromApi, meta } = action.results;
            const data = dataFromApi.map(datum => ({
                ...datum,
                name: datum.subject ? datum.subject.name : 'Unknown',
            }));
            const { last_page, total } = meta;
            return {
                ...state,
                loading: false,
                data,
                filters: {
                    ...state.filters,
                },
                pages: {
                    lastPage: last_page,
                    total: total,
                },
                meta,
            };
        }

        case actionTypes.GET_ALARM_DETAIL_REPORT_START:
            // Show event row as loading.
            return { ...state, active: { loading: true, id: action.id, open: true } };

        case actionTypes.GET_ALARM_DETAIL_REPORT_SUCCESS:
            return {
                ...state,
                active: { loading: false, data: action.results.data, id: action.id, open: true },
            };

        case actionTypes.CLOSE_ALARM_REPORT_DETAIL: {
            return { ...state, active: { ...(state.active as any), open: false } };
        }
        case actionTypes.CLEAR_ALARM_REPORT_DETAIL: {
            return { ...state, active: null };
        }

        case actionTypes.GET_ALARMS_START: {
            return { ...state, retrieved: { ...state.retrieved, alarms: false } };
        }

        case actionTypes.GET_ALARMS_SUCCESS: {
            return { ...state, alarms: action.results.data };
        }

        case actionTypes.SET_FILTERS: {
            return {
                ...state,
                filters: { ...state.filters, ...pick(action.filters, ['from', 'to']) },
            };
        }

        case actionTypes.PATCH_FILTERS: {
            return { ...state, filters: { ...state.filters, ...action.payload } };
        }

        case actionTypes.SET_PAGE_NUMBER: {
            return { ...state, filters: { ...state.filters, currentPage: action.page } };
        }

        case actionTypes.SET_PAGE_COUNT: {
            return { ...state, filters: { ...state.filters, pageCount: action.count } };
        }

        case actionTypes.SET_SORTING: {
            const flipOrder = { ASC: 'DESC', DESC: 'ASC' };
            const oppositeDirection = ['creationTime', 'closeTime'];
            const { sortBy } = action;
            const sortByDirection =
                sortBy === state.filters.sortBy
                    ? flipOrder[state.filters.sortByDirection]
                    : oppositeDirection.find(d => d === sortBy)
                    ? 'DESC'
                    : 'ASC';

            return { ...state, filters: { ...state.filters, sortBy, sortByDirection } };
        }

        default:
            return state;
    }
};

export default reducer;

export const reportsSelector = createSelector(
    rootState => rootState.reports,
    personsDataAsArraySelector,
    residentsAsArraySelector,
    staffAsArraySelector,
    locationsDataSelector,
    (reportsState, people, residents, caregivers, locations) => ({
        // Have all the data of the reports reducer...
        ...reportsState,
        // And merge in data that used to be part of the reports state in the past, but now
        // are part of newer reducers.
        people,
        residents,
        caregivers,
        locations: locations ?? [],
    })
);

export const reportsFiltersSelector = createSelector(reportsSelector, reports => reports.filters);

export const personsWaitingSelector = createSelector(personsSelector, state => {
    return state.waiting || !state.data;
});

export const locationsByIdSelector = createSelector(reportsSelector, (reportsState): {
    [locationId: number]: Location | undefined;
} => {
    return keyBy(reportsState.locations, 'id');
});

export const personsByPersonTypeSelector = createSelector(
    residentsAsArraySelector,
    staffAsArraySelector,
    (residents, staff): Record<PersonType, Person[]> => {
        return {
            resident: residents,
            caregiver: staff,
        };
    }
);

const fromSelector = createSelector(reportsSelector, s => s.filters.from);
const toSelector = createSelector(reportsSelector, s => s.filters.to);

export const fromToSelector = createSelector(
    fromSelector,
    toSelector,
    (from, to) => [from, to] as [string, string]
);

export type ReportsType = ReturnType<typeof reducer>;
