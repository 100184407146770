import React, { useMemo } from 'react';
import { ReactComponent as DefaultLogo } from '../../assets/img/logos/ensure360.svg';
import UserMenu from '../UserMenu/UserMenu';
import styled from '@emotion/styled';
import HoverMenuItem from '../HoverMenu/HoverMenuItem';
import { resetMixpanel } from '../../utils/mixpanel';
import {
    keycloakClientId,
    keycloakRealm,
    keycloakUrl,
    logout,
    logoutUrl,
} from '../../utils/keycloak';
import { css } from '../../utils/ide';
import darkTheme, { ThemeProps } from '../../styles/theme';
import { remToNumber } from '../../utils/css';
import { Facility } from '../../values/types';
import Select from '../Select';
import { matchPath, useLocation } from 'react-router';
import { routes } from '../../router/routes';
import { useTopRouteAndGroupMatch } from '../../router/routeConfig';
import { sortBy } from 'lodash-es';

const Container = styled.div`
    box-sizing: border-box;
    background: ${({ theme }: ThemeProps) => theme.background};
    box-shadow: ${(props: ThemeProps) => props.theme.shadow1};
    padding: 0.6rem 2.8rem 0.6rem 1.6rem;
    display: flex;
    align-items: center;
    position: relative;

    @media print {
        display: none;
    }
`;

const ReportingText = styled.span(
    () => css`
        flex: 1;
        text-transform: uppercase;
        font-weight: bold;
        margin-left: 0.8rem;
        letter-spacing: 0.25em;
    `
);

const TopMenu = ({ facilities, currentFacilityKey, setCurrentFacilityKey }: Props) => {
    const changePasswordUrl = `${keycloakUrl}/realms/${keycloakRealm}/account?referrer=${keycloakClientId}`;
    const location = useLocation();
    const selectOptions = useMemo(() => {
        const centralOfficeOptionsByUuid: Record<
            string,
            Array<{ label: string; value: number }>
        > = {};
        const noCentralOfficeFacilityOptions: Array<{ label: string; value: number | null }> = [];
        let centralOfficeGroups: Array<{
            label: string;
            options: Array<{ label: string; value: number | null }>;
        }> = [];

        for (const facility of facilities) {
            const option = { label: facility.name, value: facility.id };

            if (!facility.centralOffice) {
                noCentralOfficeFacilityOptions.push(option);
            } else {
                if (!centralOfficeOptionsByUuid[facility.centralOffice.uuid]) {
                    const centralOfficeOptions = [];
                    const centralOfficeGroup = {
                        label: facility.centralOffice.name,
                        options: centralOfficeOptions,
                    };
                    centralOfficeGroups.push(centralOfficeGroup);
                    centralOfficeOptionsByUuid[facility.centralOffice.uuid] = centralOfficeOptions;
                    centralOfficeGroups.push();
                }
                centralOfficeOptionsByUuid[facility.centralOffice.uuid].push(option);
            }
        }

        // Sort groups by name.
        centralOfficeGroups = sortBy(centralOfficeGroups, group => group.label);

        let ret: typeof centralOfficeGroups = [
            {
                label: '(Info)',
                options: [{ label: '(Select a Facility to view...)', value: null }],
            },
            ...centralOfficeGroups,
            ...(noCentralOfficeFacilityOptions.length
                ? [
                      {
                          label: '(No Central Office)',
                          options: noCentralOfficeFacilityOptions,
                      },
                  ]
                : []),
        ];

        // Sort facility in each group.
        for (const group of ret) {
            group.options = sortBy(group.options, 'label');
        }

        return ret;
    }, [facilities]);

    const onReportsPage = useMemo(() => {
        return matchPath(location.pathname, routes.caseManagementReports.fullPath);
    }, [location.pathname]);

    const topRouteAndGroupMatch = useTopRouteAndGroupMatch();

    const Logo = useMemo(() => {
        return topRouteAndGroupMatch?.group?.GroupImage ?? DefaultLogo;
    }, [topRouteAndGroupMatch]);

    const title = useMemo(() => {
        return (
            (topRouteAndGroupMatch?.routeLeafNode.component &&
                topRouteAndGroupMatch.routeLeafNode.label) ??
            'Reporting'
        );
    }, [topRouteAndGroupMatch]);

    return (
        <Container>
            <Logo
                width={remToNumber('10rem')}
                fill={darkTheme.primaryLight}
                style={{ color: 'white' }}
            />
            <ReportingText>{title}</ReportingText>
            <div style={{ flex: 1 }}>
                {facilities.length > 1 && !onReportsPage ? (
                    <div style={{ width: '20rem', marginLeft: '1rem' }}>
                        <Select
                            value={currentFacilityKey}
                            height="2.8rem"
                            options={selectOptions}
                            onChange={facilityKey =>
                                facilityKey && setCurrentFacilityKey(facilityKey)
                            }
                        />
                    </div>
                ) : null}
            </div>
            <div style={{ flex: 1 }} />
            <UserMenu light>
                <div style={{ padding: '16px 0' }}>
                    <HoverMenuItem cy="link-change-password" href={changePasswordUrl}>
                        Change Password
                    </HoverMenuItem>
                    <HoverMenuItem
                        cy="link-logout"
                        href={logoutUrl}
                        onClick={evt => {
                            evt.preventDefault();
                            resetMixpanel();
                            logout();
                        }}
                    >
                        Logout
                    </HoverMenuItem>
                </div>
            </UserMenu>
        </Container>
    );
};

interface Props {
    facilities: Facility[];
    currentFacilityKey: number | undefined;
    setCurrentFacilityKey: (facilityKey: number) => void;
}

export default TopMenu;
