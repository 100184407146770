import React, { CSSProperties, useMemo } from 'react';
import DataTableSimple, { HeaderConfigItem } from '../../DataTableSimple';
import { css } from '../../../utils/ide';
import { styled } from '../../../styles/theme';
import { convertHex } from '../../../utils/css';
import { PrintControl } from '../engage360Common';
import { ActivityWithRegistrations, EngageRegistrationWithResident } from '../../../values/types';
import { formatDateTime } from '../../../utils/date';
import { generatePath } from 'react-router-dom';
import { routes } from '../../../router/routes';
import { ButtonLink } from '../../Button/Button';
import ConditionalFlex from '../../ConditionalFlex';

const InnerContainer = styled(ConditionalFlex)`
    padding: 1rem 4rem;
`;

const SignupSmallTable = styled.table(
    ({ theme }) => css`
        font-weight: bold;
        text-align: left;
        color: ${convertHex(theme.primaryLight, 0.56)};

        @media print {
            color: inherit;
        }

        th,
        td {
            padding: 0.5rem;
        }
    `
);

const GoBackContainer = styled.div`
    margin-bottom: 1rem;

    @media print {
        display: none;
    }
`;

export default function ActivitySignup({ item, print }: Props) {
    const printPath = useMemo(() => {
        return generatePath(routes.engage360ActivitiesPrint.fullPath, { activityUuid: item.uuid });
    }, [item.uuid]);

    const headerConfig: Array<HeaderConfigItem<EngageRegistrationWithResident>> = useMemo(() => {
        return [
            {
                label: '#',
                getValue: (_, index) => index + 1,
                headerTdStyle: { width: '4rem' },
            },
            {
                label: 'Resident',
                key: 'resident',
                getValue: r => r.resident.name,
                sortable: true,
            },
            // Uncomment if needed to debug.
            //{
            //    label: 'Date',
            //    getValue: r => formatDateTime(r.date),
            //},
            ...(print
                ? [
                      {
                          label: 'Attended',
                          textAlign: 'center' as CSSProperties['textAlign'],
                          tdStyle: { width: '5rem' },
                          getValue: () => '',
                          formatValue: () => (
                              <div
                                  style={{
                                      display: 'inline-block',
                                      height: 15,
                                      width: 15,
                                      border: '1px solid black',
                                      backgroundColor: 'white',
                                  }}
                              />
                          ),
                      },
                      {
                          label: 'Comments',
                          textAlign: 'center' as CSSProperties['textAlign'],
                          tdStyle: { width: '20rem' },
                          getValue: () => '',
                          formatValue: () => <div />,
                      },
                  ]
                : []),
        ];
    }, [print]);

    const registrationsForDataTable = useMemo(() => {
        return item.registrations && item.registrations.map(v => ({ ...v, id: v.resident.uuid }));
    }, [item.registrations]);

    return (
        <div>
            {print && (
                <GoBackContainer>
                    <div style={{ marginBottom: '1rem' }}>
                        You can print this page to keep track of attendance.
                    </div>
                    <ButtonLink to={routes.engage360Activities.fullPath}>
                        Back to Activities
                    </ButtonLink>
                </GoBackContainer>
            )}
            <InnerContainer>
                <div style={{ minWidth: '18rem', paddingRight: '3rem', paddingTop: '0.5rem' }}>
                    <SignupSmallTable>
                        <tbody>
                            <tr>
                                <th>Activity:</th>
                                <td>{item.name}</td>
                            </tr>
                            <tr>
                                <th>Time:</th>
                                <td>{formatDateTime(item.startedAt)}</td>
                            </tr>
                            <tr>
                                <th>Room:</th>
                                <td>{item.place}</td>
                            </tr>
                        </tbody>
                    </SignupSmallTable>
                </div>
                <div style={{ flex: 1 }}>
                    <DataTableSimple
                        data={registrationsForDataTable}
                        tdStyle={
                            print ? { borderBottom: '1px solid black', padding: '3px' } : undefined
                        }
                        headerConfig={headerConfig}
                    />
                </div>
                <div style={{ display: print ? 'none' : undefined }}>
                    <PrintControl label="Print Attendance Form" to={printPath} />
                </div>
            </InnerContainer>
        </div>
    );
}

interface Props {
    item: ActivityWithRegistrations;
    print?: boolean;
}
