import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { match, Route, Switch } from 'react-router';
import CaseManagementDashboardSubPage from '../../components/CaseManagement/CaseManagementDashboardSubPage';
import DiseasesPage from '../DiseasesPage';
import DiseaseCaseEditPage from '../DiseaseCaseEditPage';
import { useAppDispatch } from '../../store';
import { loadDiseases } from '../../store/reducers/diseases';
import { loadDiseaseCases } from '../../store/reducers/diseaseCases';
import DiseaseCasePage from '../DiseaseCasePage';
import { routes } from '../../router/routes';

const Container = styled.main`
    padding: 3rem;

    h2 {
        font-size: 2rem;
    }
`;

function CaseManagementPage({ match }: Props) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(loadDiseases());
        // TODO: use statistics endpoints for statistics and graphs when they become available.
        dispatch(loadDiseaseCases());
    }, [dispatch]);

    return (
        <Container>
            <Switch>
                <Route
                    path={`${match.path}/${routes.diseases.relativePath}`}
                    render={() => (
                        <DiseasesPage caseManagementUri={match.url} parentMatch={match} />
                    )}
                />
                <Route
                    path={`${match.path}/${routes.diseaseCaseCreate.relativePath}`}
                    component={DiseaseCaseEditPage}
                />
                <Route
                    path={`${match.path}/${routes.diseaseCaseSingle.relativePath}`}
                    component={DiseaseCasePage}
                />
                <Route component={CaseManagementDashboardSubPage} />
            </Switch>
        </Container>
    );
}

interface Props {
    match: match;
}

export default CaseManagementPage;
