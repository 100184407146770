import { convertHex } from '../utils/css';
import emotionStyled, { CreateStyled } from '@emotion/styled';
import { useTheme as emotionUseTheme } from 'emotion-theming';

const highlight = '#2684ff';
const primary = '#9A9999';
const primaryLight = '#F0EFEF';
const primaryMd = '#2065bc';
const primaryDark = '#005f83';
const primaryBlue = '#009edd';
const error = '#cc2300';
const disabled = '#88b3c6';
export const gray50 = '#050505';
export const gray100 = '#212121';
export const gray200 = '#424242';
export const gray300 = '#616161';
export const gray400 = '#757575';
export const gray500 = '#9E9E9E';
export const gray600 = '#BDBDBD';
export const gray700 = '#E0E0E0';
export const gray800 = '#EEEEEE';
export const gray900 = '#F5F5F5';
const fontFamily = "'Montserrat', sans-serif";
const boldFontCss = 'font-weight: 700;';
const background = '#1F1F1F';
const backgroundSecondary = '#2C2B2C';
const backgroundSecondaryOpacity = convertHex('#2C2B2C', 0.56);
const backgroundMediumContrast = '#454446';

export const blue2 = '#309CE0';
export const blue3 = '#3D71F6';
export const blue4 = '#83D1F6';
export const blue5 = '#009EDD';
export const purple = '#9546FA';
export const green = '#37f36a';
export const red = '#D75E70';
export const orange = '#ff7e47';
export const yellow = '#F1DB8B';
export const lime = '#00ff8a';
export const redOrange = '#ff2a04';

const theme = {
    primary,
    primaryLight,
    primaryDark,
    primaryMd,
    highlight,
    error,
    disabled,
    gray50,
    gray100,
    gray200,
    gray300,
    gray400,
    gray500,
    gray600,
    gray700,
    gray800,
    gray900,
    inputBorderWidth: 1.5,
    inputBorderColor: primary,
    /* Shadow 1dp */
    shadow1:
        '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.10)',
    /* Shadow 4dp */
    shadow4:
        '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.10)',
    /* Shadow 6dp */
    shadow6:
        '0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.10)',
    fontFamily,
    boldFontCss,
    background,
    backgroundSecondary,
    backgroundSecondaryOpacity,
    backgroundMediumContrast,
    primaryBlue,
};

export type Theme = typeof theme;

// Themed styled(). This should be preferred to import over the styled function provided by emotion.
export const styled: CreateStyled<Theme> = emotionStyled;

// For emotion-styled.
export type ThemeProps = { theme: Theme };

export const useTheme: () => Theme = emotionUseTheme;

export default theme;
