import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Box } from '../../../styles/shared';
import { red } from '../../../styles/theme';
import { faExclamationTriangle, faVirus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disease, PersonType, personTypes } from '../../../values/types';
import { personTypeInfoMap } from '../../../values/appConfig';
import { wizardGray } from '../../Wizard/WizardStep';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { generatePath, Link } from 'react-router-dom';
import { formatDate } from '../../../utils/date';
import { formatStatisticNumberForPersonType } from '../../../utils/numbers';
import CasesVsQuarantinedChart from '../../Chart/CasesVsQuarantinedChart';
import { FromTo } from '../../../store/reducers/reports';
import Icon from '../../Icon';
import { routes } from '../../../router/routes';

const Container = styled.div`
    display: block;
    margin-top: 1rem;
`;

const FlexColumnApart = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

function PersonCount({ personType, amount }: { personType: PersonType; amount: number }) {
    return (
        <FlexColumnApart
            style={{
                width: '7rem',
                textAlign: 'center',
                alignItems: 'center',
                color: amount ? red : 'inherit',
            }}
        >
            <Icon
                icon={personTypeInfoMap[personType].icon}
                name={personTypeInfoMap[personType].name}
                height="3.5rem"
            />
            <span style={{ fontSize: '1.75rem' }}>
                {formatStatisticNumberForPersonType(amount, personType)}
            </span>
        </FlexColumnApart>
    );
}

const LinkDisease = styled(Link)`
    > *:not(:first-of-type) {
        border-left: 0.2rem solid ${wizardGray};
        padding-left: 2rem;
    }
    > *:not(:last-of-type) {
        padding-right: 2rem;
    }
`;

function formatNumber(num: number | undefined) {
    if (!num && num !== 0) {
        return '?';
    }
    return num;
}

function IconNumberText({
    icon,
    amount,
    text,
}: {
    icon: IconProp;
    amount: number | undefined;
    text: string;
}) {
    return (
        <FlexColumnApart
            style={{ color: amount ? red : 'inherit', fontWeight: 'bold', textAlign: 'center' }}
        >
            <span
                style={{
                    fontSize: '2rem',
                }}
            >
                <FontAwesomeIcon
                    icon={icon}
                    style={{ fontSize: '3.5rem', opacity: 0.56, marginRight: '1rem' }}
                />{' '}
                <span style={{ fontSize: '4rem' }}>{formatNumber(amount)}</span>
            </span>
            <div style={{ flex: 1 }} />
            <span>{text}</span>
        </FlexColumnApart>
    );
}

function ActiveDisease({ disease, facilityIds, fromTo }: Props) {
    const caseStatistics = disease.statistics;

    const to = useMemo(() => {
        return generatePath(routes.diseaseSingle.fullPath, {
            disease: disease.uuid,
        });
    }, [disease.uuid]);

    return (
        <Container>
            <Box
                style={{
                    padding: '2rem 2rem 1rem 2rem',
                    fontSize: '1.25rem',
                    color: wizardGray,
                }}
            >
                <LinkDisease
                    to={to}
                    style={{
                        display: 'flex',
                        height: '8rem',
                    }}
                >
                    <div style={{ flex: 2, display: 'flex' }}>
                        <FlexColumnApart>
                            <span
                                style={{
                                    color: red,
                                    fontSize: '2rem',
                                    fontWeight: 'bold',
                                }}
                            >
                                {disease.name}
                            </span>
                            <div style={{ flex: 1 }} />
                            <span>Since: {formatDate(disease.createdAt)}</span>
                        </FlexColumnApart>
                        <div style={{ flex: 1 }} />
                        <IconNumberText
                            icon={faVirus}
                            amount={caseStatistics.positiveCasesCount}
                            text="Active Cases"
                        />
                    </div>
                    <div style={{ flex: 3, display: 'flex' }}>
                        {personTypes.map(personType => (
                            <PersonCount
                                key={personType}
                                personType={personType}
                                amount={
                                    personType === 'resident'
                                        ? caseStatistics.positiveCasesCount
                                        : 0
                                }
                            />
                        ))}
                        <div style={{ flex: 1 }} />
                        <IconNumberText
                            icon={faExclamationTriangle}
                            amount={caseStatistics.exposedCount}
                            text="Total Exposures"
                        />
                    </div>
                    <div style={{ display: 'flex' }}>
                        {personTypes.map(personType => (
                            <PersonCount
                                key={personType}
                                personType={personType}
                                amount={personType === 'resident' ? caseStatistics.exposedCount : 0}
                            />
                        ))}
                    </div>
                </LinkDisease>
                <div style={{ height: '2rem' }} />
                <CasesVsQuarantinedChart
                    diseaseUuid={disease.uuid}
                    facilityIds={facilityIds}
                    fromTo={fromTo}
                />
            </Box>
        </Container>
    );
}

interface Props {
    disease: Disease;
    facilityIds?: Number[];
    fromTo?: FromTo;
}

export default ActiveDisease;
