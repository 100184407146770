import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../../store/actions';
import { elapsedTimeRange, responseTimeRange } from '../../../store/actions/reports';
import { loadLocations } from '../../../store/reducers/locations';
import { loadPersons } from '../../../store/reducers/persons';
import { timesIcon } from '../../../styles/fontLibrary';
import { H2 } from '../../../styles/shared';
import { alarmDomainIcons, alarmTypes } from '../../../values/appConfig';
import FilterCheckboxType from './FilterTypes/FilterCheckboxType';
import FilterRadioType from './FilterTypes/FilterRadioType';
import FilterRangeType from './FilterTypes/FilterRangeType';

const openedTimeFilters = [
    {
        id: 1,
        name: 'Shift 1',
    },
    {
        id: 2,
        name: 'Shift 2',
    },
    {
        id: 3,
        name: 'Shift 3',
    },
];

const sourceOptions = [
    { id: 'person', name: 'People' },
    { id: 'location', name: 'Places' },
    { id: 'asset', name: 'Assets' },
];

export const domains = sortBy(
    alarmDomainIcons.map(alarmType => ({ key: alarmType.name, ...alarmType })),
    'name'
);

const optionsWithSome = [{ label: 'Some', value: 'Some' }];

const dispositionNoteOptions = [{ label: 'With Notes', value: '1' }];

const FiltersContainer = ({ close }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.getAlarms());
        dispatch(loadLocations());
        dispatch(loadPersons());
    }, [dispatch]);

    return (
        <>
            <div style={{ position: 'relative' }}>
                <FontAwesomeIcon
                    icon={timesIcon}
                    className="clickable"
                    onClick={close}
                    style={{ position: 'absolute', right: 0, top: '0.25rem' }}
                />
            </div>
            <H2>Filters</H2>
            <FilterCheckboxType title="Topic" filterKey="sources" options={sourceOptions} />
            <FilterRadioType
                title="Domain"
                options={optionsWithSome}
                outerPath="domainRadioOption"
                innerCheckboxConfigs={[{ value: 'Some', checkboxConfig: domains }]}
                path="domains"
            />
            <FilterRadioType
                title="Type"
                options={optionsWithSome}
                outerPath="typeRadioOption"
                innerCheckboxConfigs={[{ value: 'Some', checkboxConfig: alarmTypes }]}
                path="alarmTypes"
            />
            <FilterCheckboxType title="Location" filterKey="locations" hasSearch />
            <FilterCheckboxType title="Alarms" filterKey="alarms" />
            <FilterRadioType
                title="Note"
                options={dispositionNoteOptions}
                outerPath="hasDispositionNote"
            />
            <FilterCheckboxType
                title="Opened Time"
                filterKey="openedShifts"
                options={openedTimeFilters}
            />
            <FilterRangeType
                title="Response Time"
                filterKey="responseTimeMinutes"
                range={responseTimeRange}
            />
            <FilterCheckboxType title="Caregiver" filterKey="caregivers" hasSearch />
            <FilterRangeType
                title="Elapsed Time"
                filterKey="elapsedTimeMinutes"
                range={elapsedTimeRange}
            />
        </>
    );
};

FiltersContainer.propTypes = {
    close: PropTypes.func,
};

export default FiltersContainer;
