import React, {cloneElement} from 'react';
import styled from '@emotion/styled';
import {css} from '../../utils/ide';
import {node, number, object, oneOfType, string} from 'prop-types';
import ResizeDetector from 'react-resize-detector';
import {remToNumber, calculateColumnWidths, isIE} from '../../utils/css';
import {cloneDeep} from 'lodash-es';
import {WINDOW_WIDTH_PHONE_LANDSCAPE} from '../ResponsiveProvider/ResponsiveProvider';

const Container = styled.div(({gridTemplateColumns}) => css`
    display: grid;
    grid-template-columns: ${gridTemplateColumns};
    
    @media screen and (max-width: ${WINDOW_WIDTH_PHONE_LANDSCAPE}px) {
        grid-template-columns: 1fr;
    }
`);

/**
 * Grid where you can provide the columns, and it works in IE11 as well.
 * grid-template-columns and grid-gap need to be provided via props to be able to access them.
 *
 * @param children
 * @param columns {number}
 * @param gridGap {string|number}
 * @param style {object}
 * @returns {*}
 */
function Grid({children, columns = 1, gridGap, style = {}}) {
    if (!gridGap) {
        gridGap = 0;
    }
    const gridTemplateColumns = Array(columns).fill('1fr').join(' ');

    // For all the good browsers.
    if (!isIE) {
        return <Container style={{gridGap, ...style}} gridTemplateColumns={gridTemplateColumns}>{children}</Container>
    }

    // For IE11.
    return (
        <ResizeDetector style={style} handleWidth render={({width}) => {
            const widthPerChild = calculateColumnWidths(width, columns, remToNumber(gridGap));
            children = children.map((child, index) => {
                if (!width) {
                    return child;
                }
                const styleCopy = cloneDeep(child.props.style || {});
                styleCopy.width = widthPerChild;
                styleCopy.float = 'left';
                if ((index + 1) % columns) {
                    styleCopy.marginRight = gridGap;
                }
                styleCopy.marginBottom = gridGap;

                return cloneElement(child, {style: styleCopy, children: child.props.children});
            });

            return (
                <div style={{clear: 'both'}}>{children}</div>
            )
        }} />
    );
}

Grid.propTypes = {
    children: node,
    columns: number,
    gridGap: oneOfType([number, string]),
    style: object,
};

export default Grid;
