import {
    RESET_CONTACT_TRACING,
    SET_CONTACT_TRACING_CONTACT_LEVEL,
    SET_CONTACT_TRACING_DATE_RANGE,
    SET_CONTACT_TRACING_ID,
    SET_CONTACT_TRACING_QUICK_FILTERS_MINIMUM_OVERLAP,
    SET_CONTACT_TRACING_QUICK_FILTERS_TO_INCLUDE,
    SET_CONTACT_TRACING_QUICK_FILTERS_TRAILING_CONTACTS,
    SET_CONTACT_TRACING_QUICK_FILTERS_TRAILING_CONTACTS_MINUTES,
    SET_CONTACT_TRACING_TRACE,
} from './actionTypes';
import { WhatTrace } from '../reducers/contactTracing';
import { ContactLevel, IncludableContact } from '../../values/types';
import { FromTo } from '../reducers/reports';

export function resetContactTracing() {
    return { type: RESET_CONTACT_TRACING };
}

export function setContactTracingTrace(payload: WhatTrace) {
    return { type: SET_CONTACT_TRACING_TRACE, payload };
}

export function setContactTracingId(payload: number) {
    return { type: SET_CONTACT_TRACING_ID, payload };
}

export function setContactTracingDateRange(payload: FromTo) {
    return { type: SET_CONTACT_TRACING_DATE_RANGE, payload };
}

export function setContactTracingQuickFiltersToInclude(payload: Array<IncludableContact>) {
    return { type: SET_CONTACT_TRACING_QUICK_FILTERS_TO_INCLUDE, payload };
}

export function setContactTracingQuickFiltersMinimumOverlap(payload: number) {
    return { type: SET_CONTACT_TRACING_QUICK_FILTERS_MINIMUM_OVERLAP, payload };
}

export function setContactTracingQuickFiltersTrailingContacts(payload: boolean) {
    return { type: SET_CONTACT_TRACING_QUICK_FILTERS_TRAILING_CONTACTS, payload };
}

export function setContactTracingQuickFiltersTrailingContactsMinutes(payload: number | null) {
    return { type: SET_CONTACT_TRACING_QUICK_FILTERS_TRAILING_CONTACTS_MINUTES, payload };
}

export function setContactTracingContactLevel(payload: ContactLevel) {
    return { type: SET_CONTACT_TRACING_CONTACT_LEVEL, payload };
}
