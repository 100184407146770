import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { Person } from '../../values/types';
import { keyBy } from 'lodash-es';

const slice = createFetchSlice('persons', {}, getInitialState<Person[]>());

export function loadPersons(options = {}) {
    return slice.load({
        uri: `/people`,
        ...options,
    });
}

const persons = slice.reducer;
const personsSelector = slice.selector;
const personsReset = slice.actions.reset;

export { personsSelector, personsReset };

export const personsDataSelector = createSelector(personsSelector, v => v.data);

export const residentsSelector = createSelector(
    personsDataSelector,
    personsData => personsData && personsData.filter(v => !v.isCaregiver)
);

export const staffSelector = createSelector(
    personsDataSelector,
    personsData => personsData && personsData.filter(v => v.isCaregiver)
);

export const personsByIdSelector = createSelector(personsDataSelector, (items): null | {
    [personId: number]: Person | undefined;
} => {
    return items && keyBy(items, 'id');
});

export const personsDataAsArraySelector = createSelector(personsDataSelector, items => items ?? []);
export const residentsAsArraySelector = createSelector(residentsSelector, items => items ?? []);
export const staffAsArraySelector = createSelector(staffSelector, items => items ?? []);

export default persons;
