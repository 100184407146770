import React, { ReactNode } from 'react';
import ModalBox from '../ModalBox';
import { green, red } from '../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import styled from '@emotion/styled';

const TitleLine = styled.div`
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-weight: bold;
`;

interface ResultBodyProps {
    success: boolean;
    text: string;
    titleText?: string;
}

function ResultBody({ success, titleText, text }: ResultBodyProps) {
    return (
        <div style={{ textAlign: 'center' }}>
            {<TitleLine style={{ color: success ? undefined : red }}>{titleText}</TitleLine>}

            {text && <div style={{ marginBottom: '2rem' }}>{text}</div>}

            <div style={{ textAlign: 'center', marginTop: '3rem' }}>
                <FontAwesomeIcon
                    icon={success ? faCheck : faTimes}
                    color={success ? green : red}
                    size="10x"
                />
            </div>
        </div>
    );
}

export default function ResultModal({
    buttons,
    isOpen,
    onRequestClose,
    success,
    text,
    titleText,
}: Props) {
    return (
        <ModalBox isOpen={isOpen} onRequestClose={onRequestClose}>
            <div>
                <ResultBody success={success} text={text} titleText={titleText} />
            </div>

            <div style={{ height: '3rem' }} />
            <div style={{ textAlign: 'center' }}>{buttons}</div>
        </ModalBox>
    );
}

interface Props extends ResultBodyProps {
    isOpen: boolean;
    onRequestClose?: () => void;
    buttons: ReactNode;
}
