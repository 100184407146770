import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import ErrorOnPage from './components/ErrorOnPage/ErrorOnPage';
import { NO_FACILITY_ACCESS } from './utils/errorHandling';
import styled from '@emotion/styled';
import { debuggingEnabled } from './values/env';
import NoFacilityAccessWarning from './components/ErrorOnPage/NoFacilityAccessWarning';

// List of errors that should not be reported to Sentry.
const expectedErrors = [NO_FACILITY_ACCESS];

export const ExpectedErrorContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 2;
`;

class AppBoundary extends Component {
    state = {
        error: null,
        eventId: null,
    };

    componentDidCatch(error, errorInfo) {
        const isExpectedError = expectedErrors.includes(error?.error);
        this.setState({ error, isExpectedError });
        if (isExpectedError) {
            // Do not report it to Sentry.
            return;
        }
        Sentry.withScope(scope => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.error && !prevState.error && !debuggingEnabled) {
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }
    }

    render() {
        if (this.state.error) {
            if (this.state.isExpectedError) {
                let content = this.state.error.message;
                if (this.state.error.error === NO_FACILITY_ACCESS) {
                    content = <NoFacilityAccessWarning />;
                }

                return <ExpectedErrorContainer>{content}</ExpectedErrorContainer>;
            }
            return (
                <div>
                    <ErrorOnPage
                        show
                        text="Oops. Something went wrong. Technical support has been notified."
                    />
                    <button
                        onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
                    >
                        Report feedback
                    </button>
                </div>
            );
        }

        return this.props.children;
    }
}

export default AppBoundary;
