import React, { ReactNode, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountDown, faSortAmountDownAlt } from '@fortawesome/pro-regular-svg-icons';
import styled from '@emotion/styled';
import { FilterObject, SortObject } from './sortFilter';

const SortableOrFilterableSpan = styled.span`
    .filter {
        display: none;
    }

    &:hover .filter {
        display: inline-block;
    }
`;

export default function SortableOrFilterableColumn({
    content,
    columnKey,
    sortObject,
}: {
    filterable?: boolean;
    content?: ReactNode;
    columnKey: string;
    sortObject?: SortObject;
    filterObject?: FilterObject;
}) {
    // Go in order between sort by: default order, other order, nothing.
    const doSort = useCallback(() => {
        if (!sortObject) {
            return;
        }

        const { defaultDescending, currentSort, setCurrentSort } = sortObject;

        if (!currentSort || currentSort[0] !== columnKey) {
            setCurrentSort([columnKey, defaultDescending]);
        } else if (currentSort[1] === defaultDescending) {
            setCurrentSort([columnKey, !defaultDescending]);
        } else {
            setCurrentSort(null);
        }
    }, [columnKey, sortObject]);

    return (
        <SortableOrFilterableSpan className="clickable">
            <span onClick={doSort}>
                {content}{' '}
                {sortObject?.currentSort?.[0] === columnKey ? (
                    <FontAwesomeIcon
                        className="sort"
                        icon={sortObject.currentSort[1] ? faSortAmountDown : faSortAmountDownAlt}
                    />
                ) : null}
            </span>
        </SortableOrFilterableSpan>
    );
}
