import styled from '@emotion/styled';
import { css } from '../utils/ide';
import darkTheme from './theme';
import theme from './theme';
import { Link } from 'react-router-dom';
import { CSSProperties } from 'react';

export const H2 = styled.h2`
    margin-top: 0;
    font-size: 1.5rem;
`;

export const boxStyle: CSSProperties = {
    borderRadius: '1rem',
    background: darkTheme.background,
    color: 'white',
    boxSizing: 'border-box',
    padding: '1rem 0.5rem',
    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.4)',
    border: 0,
};

type BoxProps = {
    selected?: boolean;
    theme: any;
};

const boxCss = css`
    border-radius: 1rem;
    box-sizing: border-box;
    padding: 1rem 0.5rem;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
    background: ${theme.background};
    color: white;
`;

// Standard black background box with curved edges and a shadow.
export const Box = styled.div<BoxProps>(
    ({ theme, selected }: BoxProps) => css`
        ${boxCss}
        ${selected
            ? css`
                  background: ${theme.highlight};
                  color: ${theme.background};
              `
            : null}
    `
);

export const BoxLink = styled(Link)(() => boxCss);

export const PropertyBox = styled(Box)`
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    justify-content: space-around;
    text-align: center;
`;

export const EditBox = styled(PropertyBox)`
    background: linear-gradient(to bottom, ${theme.primaryBlue}, #2075bc);
    color: white;
`;

export const GappedFlex = styled.div`
    display: flex;
    height: 8rem;

    @supports (display: grid) {
        display: grid;
        height: auto;
        grid-template-columns: 1fr 3fr 1.5fr 1fr 1fr;
        grid-gap: 2rem;
        grid-auto-rows: 8rem;
    }

    > * {
        :not(:first-of-type) {
            margin-left: 2rem;

            @supports (display: grid) {
                margin-left: 0;
            }
        }
    }
`;
