import React from 'react';
import styled from '@emotion/styled';
import Checkbox, { CheckboxProps } from './Checkbox';

const Label = styled.label`
    margin-top: 14px;
    margin-right: 1rem;
    cursor: pointer;
    display: table;
`;

export function CheckboxWithLabel(props: Props) {
    const { label, id, style, ...restProps } = props;

    return (
        <Label
            data-value={props.dataValue !== undefined ? JSON.stringify(props.dataValue) : undefined}
            style={style}
        >
            <Checkbox {...restProps} />
            <span style={{ marginTop: '0.25rem', display: 'table-cell', verticalAlign: 'middle' }}>
                {label}
            </span>
        </Label>
    );
}

interface Props extends CheckboxProps {
    label: string | JSX.Element | number;
    dataValue?: string | number;
}
