import React from 'react';
import styled from '@emotion/styled';
import AlignedText from './Timeline/AlignedText';
import {formatDateTime, humanizeElapsedSeconds} from '../../utils/date';
import ResizeDetector from 'react-resize-detector';
import {formatPercent} from '../../utils/numbers';
import {getDispositionByType} from '../../values/appConfig';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const MiddleAlignDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GrayText = styled(MiddleAlignDiv)`
    opacity: 0.56;
`;

const WhiteText = styled(MiddleAlignDiv)`
    font-weight: bold;
`;

const containerWidth = '36.4rem';

const Container = styled.div`
    width: 50rem;
    height: 18rem;
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ContainerInner = styled.div`
    width: ${containerWidth};
    height: 7.4rem;
    position: relative;
`;

export function getTimelineByData(data, alarmData, additionalProps) {
    function getLeafByEvent(event) {
        let grayText = event.closing ? 'Closed' : 'Accepted';
        let name;
        if (event.person?.id) {
            grayText = `${grayText} By`;
            name = event.person.name;
        }

        return (
            <>
                <GrayText>{grayText}</GrayText>
                {name && <WhiteText>{name}</WhiteText>}
                <WhiteText>{humanizeElapsedSeconds(event.secondsSinceOpen)}</WhiteText>
            </>
        );
    }

    const components = [];

    components.push(
        data[0] && <>
            <GrayText>Created</GrayText>
            <WhiteText>{formatDateTime(data[0].creationTime)}</WhiteText>
        </>
    );

    if (data[1]) {
        components.push(getLeafByEvent(data[1]));
    }

    if (alarmData.dispositionType) {
        const disposition = getDispositionByType(alarmData.dispositionType);
        components.push(
            <>
                <GrayText>Disposition</GrayText>
                <WhiteText><FontAwesomeIcon
                    icon={disposition.icon}
                    size="2x"
                    style={{marginRight: '0.5rem'}}
                />{disposition.name}</WhiteText>
            </>
        )
    }

    for (let i = 2; data[i]; i++) {
        components.push(getLeafByEvent(data[i]));
    }

    if (!alarmData.closeTime) {
        // Leave an empty spot for closing.
        components.push(null);
    }

    return <Timeline components={components} {...additionalProps} />;
}

/**
 * Timeline figure of creation, dispatchment, acceptance, closure etc of an alarm.
 *
 * Items show up alternating between the top and bottom of the timeline,
 * starting from the left and working their way to the right.
 *
 * @param data
 * @param components
 * @returns {*}
 * @constructor
 */
function Timeline({components}) {
    const lastIndex = components.length - 1;

    const lines = [];
    const wrappedComponents = [];

    // SVG sizes.
    const maxX = 727;
    const maxY = 146;
    const midY = maxY / 2 + 1;

    for (const [index, component] of Object.entries(components)) {
        const isAtBottom = index % 2;
        const ratioFromLeft = lastIndex <= 0 ? 0 : index / lastIndex;
        const x = (maxX - 1) * ratioFromLeft + 1;

        wrappedComponents.push(
            <AlignedText
                key={index}
                position={{
                    [isAtBottom ? 'bottom' : 'top']: 0,
                    left: formatPercent(ratioFromLeft),
                }}
            >{component}</AlignedText>,
        );

        if (!component) {
            // Don't put the timeline there if the component is empty.
            continue;
        }

        lines.push(<line key={index} y2={isAtBottom ? maxY : 0} x2={x} y1={midY} x1={x} strokeWidth="2" />);
    }

    return <Container><ContainerInner>
        <ResizeDetector handleWidth render={({width}) => width ? (
            <svg viewBox={`0 0 ${maxX + 1} ${maxY + 1}`} xmlns="http://www.w3.org/2000/svg">
                <style dangerouslySetInnerHTML={{__html: `
                    line {
                        stroke: currentColor;
                    }
                `}}>
                </style>
                <g>
                    <title>background</title>
                    <rect fill="none" id="canvas_background" height="148" width="718" />
                </g>
                <g>
                    <line y2="74" x2="736" y1="74" x1="-9" strokeWidth="2"/>

                    {lines}
                </g>
            </svg>
        ) : <div />} />
        {wrappedComponents}
    </ContainerInner></Container>;
}

export default Timeline;

