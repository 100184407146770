import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { Engage360Filters, RepairByIssueAndPlace } from '../../values/types';

const slice = createFetchSlice(
    'engageRepairsByRoomAndIssue',
    {},
    getInitialState<RepairByIssueAndPlace[]>()
);

export function loadEngageRepairsByRoomAndIssue(filters: Engage360Filters, options = {}) {
    return slice.load({
        uri: `/repairs/by-room-and-issue`,
        config: {
            params: filters,
        },
        ...options,
    });
}

const engageRepairsByRoomAndIssue = slice.reducer;
const engageRepairsByRoomAndIssueSelector = slice.selector;
const engageRepairsByRoomAndIssueReset = slice.actions.reset;

export { engageRepairsByRoomAndIssueSelector, engageRepairsByRoomAndIssueReset };

export const engageRepairsByRoomAndIssueDataSelector = createSelector(
    engageRepairsByRoomAndIssueSelector,
    v => v.data
);

export default engageRepairsByRoomAndIssue;
