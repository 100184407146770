import React, { CSSProperties, useMemo } from 'react';
import styled from '@emotion/styled';
import { Box } from '../styles/shared';
import { red } from '../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from '../store';
import { diseasesByUuidSelector } from '../store/reducers/diseases';
import { DiseaseCase } from '../values/types';
import { faVirus } from '@fortawesome/pro-regular-svg-icons';
import { generatePath, Link, useRouteMatch } from 'react-router-dom';
import { isDiseaseCaseExposure } from '../values/appConfig';
import { formatDate } from '../utils/date';
import { routes } from '../router/routes';

const Container = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${red};
    color: white;
    font-weight: bold;
`;

const Flex = styled.div`
    display: flex;
`;

/**
 * A box saying what disease a person has.
 */
function DiseaseBox({ diseaseCase, containerStyle }: Props) {
    const { diseaseUuid, uuid } = diseaseCase;
    const diseaseByUuidMap = useSelector(diseasesByUuidSelector);
    const disease = diseaseByUuidMap[diseaseUuid];
    const diseaseName = disease?.name ?? 'N/A';
    const routeMatch = useRouteMatch();
    const to = useMemo(() => {
        return generatePath(routes.diseaseCaseSingle.fullPath, {
            diseaseCaseUuid: uuid,
        });
    }, [uuid]);
    // If we're already on the route the component would link to, then don't make it a link.
    const isAlreadyOnTargetRoute = useMemo(() => {
        return routeMatch.url === to;
    }, [routeMatch.url, to]);

    let ret = (
        <Container style={isAlreadyOnTargetRoute ? containerStyle : undefined}>
            <Flex style={{ flexDirection: 'column' }}>
                <FontAwesomeIcon
                    icon={faVirus}
                    size="3x"
                    style={{ opacity: 0.5, marginBottom: '1rem' }}
                />
                <span>{isDiseaseCaseExposure(diseaseCase) ? 'Exposure' : 'Active Case'}</span>
            </Flex>
            <Flex style={{ flexDirection: 'column', marginLeft: '2rem' }}>
                <span style={{ fontSize: '3rem', marginBottom: '0.5rem' }}>{diseaseName}</span>
                <span style={{ fontWeight: 'normal', opacity: 0.5, fontSize: '1.5rem' }}>
                    {diseaseCase.exposedAt
                        ? `Exposed: ${formatDate(diseaseCase.exposedAt)}`
                        : `Created: ${formatDate(diseaseCase.createdAt)}`}
                </span>
            </Flex>
        </Container>
    );

    if (!isAlreadyOnTargetRoute) {
        return (
            <Link to={to} style={{ display: 'block', ...(containerStyle ?? {}) }}>
                {ret}
            </Link>
        );
    }

    return ret;
}

interface Props {
    diseaseCase: DiseaseCase;
    containerStyle?: CSSProperties;
}

export default DiseaseBox;
