import React from 'react';
import BarLoader from '../BarLoader/BarLoader';
import { Td } from './DataTable';
import { DataTableTr } from './shared';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { userCircleIcon, faDoorOpen } from '../../styles/fontLibrary';
import { getTimelineByData } from './Timeline';
import { css } from '../../utils/ide';
import { getAlarmDomainByKey, getAlarmTypeByKey } from '../../values/appConfig';
import * as PropTypes from 'prop-types';

const minHeight = '0.2rem';

const InnerColumnContainer = styled.div(
    ({ singleColumn }) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;

        ${singleColumn &&
        css`
            flex-direction: column;

            /* Add horizontal gap between items when in single column mode. */
            > *:not(:last-child) {
                margin-bottom: 2rem;
            }
        `}
    `
);

const LabelValueTable = styled.table`
    vertical-align: middle;
    margin-left: 2rem;

    th,
    td {
        padding: 0.7rem 0.8rem;
    }
    th {
        font-weight: normal;
        opacity: 0.56;
        text-align: right;
    }
    td {
        font-weight: bold;
        text-align: left;
    }

    .iconTd {
        padding-left: 1rem;
        padding-right: 0;
        text-align: center;
    }
`;

const TypeOrSourceIcon = props => {
    return (
        <div style={{ display: 'inline-block', width: '2rem' }}>
            <FontAwesomeIcon size="2x" {...props} />
        </div>
    );
};

function InnerExpanded({ events, listItemData, singleColumn, data }) {
    const preAlarmType = getAlarmTypeByKey(data.alarmType);
    const alarmType = {
        ...preAlarmType,
        name: preAlarmType.name === 'Unknown' ? data.alarmType : preAlarmType.name,
    };
    const alarmDomain = getAlarmDomainByKey(data.domain);
    const alarmSubjectIcon =
        listItemData.subject?.polymorphicType === 'location' ? faDoorOpen : userCircleIcon;

    return (
        <InnerColumnContainer singleColumn={singleColumn}>
            <div style={{ width: '22rem' }}>
                <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                    <FontAwesomeIcon icon={alarmSubjectIcon} style={{ fontSize: '4.4rem' }} />
                    <div style={{ height: '1rem' }} />
                    <div style={{ fontWeight: 'bold' }}>{listItemData.name}</div>
                </div>

                <LabelValueTable>
                    <tbody>
                        <tr>
                            <th>Location</th>
                            <td colSpan={2}>{listItemData.area}</td>
                        </tr>
                        <tr>
                            <th>Type</th>
                            <td className="iconTd">
                                <TypeOrSourceIcon icon={alarmType.icon} />
                            </td>
                            <td>{alarmType.name}</td>
                        </tr>
                        <tr>
                            <th>Domain</th>
                            <td className="iconTd">
                                <TypeOrSourceIcon icon={alarmDomain.icon} />
                            </td>
                            <td>{alarmDomain.name}</td>
                        </tr>
                    </tbody>
                </LabelValueTable>
            </div>
            {getTimelineByData(events, listItemData, { singleColumn: singleColumn })}
            <div style={{ width: '13rem' }}>
                {data.dispositionNote ? `“${data.dispositionNote}”` : ''}
            </div>
        </InnerColumnContainer>
    );
}

InnerExpanded.propTypes = {
    singleColumn: PropTypes.bool,
    listItemData: PropTypes.object,
    // listItemData: PropTypes.arrayOf(PropTypes.any),
    data: PropTypes.any,
    data1: PropTypes.any,
};
/**
 * @param show {boolean}
 * @param loading {boolean}
 * @param listItemData {array} Data of the list view item.
 * @param data {object} Detailed alarm data.
 * @param colSpan
 * @param onClick {function}
 * @param width {number} The available width as reported from outside.
 * @param trExtraAttributes {object} Extra attributes for the <tr> tag.
 * @returns {*}
 * @constructor
 */
const ExpandedTable = ({
    show,
    loading,
    listItemData,
    data,
    colSpan,
    onClick,
    width,
    trExtraAttributes,
}) => {
    const showAndReady = show && !loading && data;
    const singleColumn = width < 1000;
    const events = data?.events;

    return (
        <>
            {showAndReady && (
                <DataTableTr
                    {...trExtraAttributes}
                    style={{ height: !singleColumn ? '22rem' : 'auto' }}
                    onClick={onClick}
                >
                    <Td
                        className="first-td-radius"
                        colSpan={colSpan}
                        style={{ padding: '1.5rem 1rem' }}
                    >
                        {events?.length && (
                            <InnerExpanded
                                singleColumn={singleColumn}
                                listItemData={listItemData}
                                data={data}
                                events={events}
                            />
                        )}
                    </Td>
                </DataTableTr>
            )}
            <tr style={{ height: minHeight }} onClick={onClick}>
                <td colSpan={colSpan}>
                    <BarLoader show={loading && show} />
                </td>
            </tr>
        </>
    );
};

export default ExpandedTable;
