import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { URI_BULK_UPLOAD } from '../../axios';
import { SetError } from '../../values/types';
import { BulkUploadForm } from '../../pages/BulkUploadPage';

const slice = createFetchSlice('bulkUploadImport', {}, getInitialState<BulkUploadImportResult>());

export type BulkUploadImportResult = {
    rowsProcessed: number;
    rowsFailed: number;
};

/**
 * Does the actual Bulk Upload.
 */
export function loadBulkUploadImport(formData: FormData, setError: SetError<BulkUploadForm>) {
    return slice.load({
        uri: `${URI_BULK_UPLOAD}/import`,
        setError,
        config: {
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    });
}

const bulkUploadImport = slice.reducer;
const bulkUploadImportSelector = slice.selector;
const bulkUploadImportReset = slice.actions.reset;

export { bulkUploadImportSelector, bulkUploadImportReset };

export const bulkUploadImportDataSelector = createSelector(bulkUploadImportSelector, v => v.data);

export default bulkUploadImport;
