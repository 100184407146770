import React, { useMemo } from 'react';
import { DiseaseCaseWithTests, PersonType } from '../values/types';
import CasesDataTable, { DataTableTitle } from './CasesDataTable';
import { red } from '../styles/theme';

function CasesOrExposedTable({ personType, diseaseCases, forSinglePerson }: Props) {
    const title = useMemo(
        () => (
            <DataTableTitle style={{ color: diseaseCases.length ? red : undefined }}>
                History ({diseaseCases.length})
            </DataTableTitle>
        ),
        [diseaseCases.length]
    );

    return (
        <div>
            <CasesDataTable
                data={diseaseCases}
                personType={personType}
                forSinglePerson={!!forSinglePerson}
                title={title}
            />
        </div>
    );
}

interface Props {
    personType: PersonType;
    diseaseCases: DiseaseCaseWithTests[];
    forSinglePerson?: boolean;
}

export default CasesOrExposedTable;
