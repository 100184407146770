import { PersonType } from '../values/types';
import { Random } from './random';

export function formatPercent(percent) {
    return `${Math.floor(percent * 1000) / 10}%`;
}

export function formatNumber(num: number) {
    return Intl.NumberFormat().format(num);
}

/**
 * Generates a non-secure number hash from a string.
 * https://stackoverflow.com/a/8831937/1381550
 *
 * @param str
 */
export function simpleStringHash(str) {
    let hash = 0;
    if (str.length === 0) {
        return hash;
    }
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

export function randomWithSeed(min, max, seed) {
    if (typeof seed === 'string') {
        seed = seed = simpleStringHash(seed);
    }

    return new Random(seed).nextInt32([min, max]);
}

/**
 * Returns a range from {lower} to {upper}, inclusively. e.g. range(2, 4) returns [2, 3, 4].
 * @param lower {number}
 * @param upper {number}
 * @return {number[]}
 * TODO: use lodash range instead
 */
export function range(lower, upper) {
    return Array(upper - lower + 1)
        .fill(undefined)
        .map((_, index) => lower + index);
}

export function formatStatisticNumberForPersonType(num: number | null, personType: PersonType) {
    if (num === 0 && personType === 'caregiver') {
        // While there's no support for cases for caregivers, show '--' when a number is 0.
        return '--';
    }

    return Number.isNaN(num) || num === null ? 'N/A' : String(num);
}

export type NumberFormat = 'number' | 'percent';

export function formatStatisticNumber(value: number | null, dataType?: 'number' | 'percent') {
    if (value === null) {
        return 'N/A';
    }

    if (dataType === 'percent') {
        return formatPercent(value);
    }
    return Intl.NumberFormat(void 0, { maximumSignificantDigits: 2 }).format(value);
}
