import React from 'react';
import { match, Redirect, Route, Switch } from 'react-router';
import DiseaseShowPage from '../components/CaseManagement/DiseaseShowPage';
import DiseaseEditPage from './DiseaseEditPage';
import { routes } from '../router/routes';
import { useSelector } from '../store';
import { facilityConfigSelector } from '../store/reducers/facilityConfig';

function DiseasePage({ match }: Props) {
    const { isCentralOfficeAdmin } = useSelector(facilityConfigSelector);

    return (
        <Switch>
            <Route
                path={`${match.path}/${routes.diseaseEdit.relativePath}`}
                render={() =>
                    isCentralOfficeAdmin ? (
                        <DiseaseEditPage backUri={match.url} />
                    ) : (
                        <Redirect to={match.url} />
                    )
                }
            />
            <Route component={DiseaseShowPage} />
        </Switch>
    );
}

export type DiseasePageRouteParams = {
    disease: string;
};

interface Props {
    match: match<DiseasePageRouteParams>;
}

export default DiseasePage;
