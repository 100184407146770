import React from 'react';
import { UseState } from '../../values/thirdPartyTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { useTheme } from '../../styles/theme';

export default function ShowFiltersToggle({ showFiltersState }: Props) {
    const theme = useTheme();
    const [showFilters, setShowFilters] = showFiltersState;
    return (
        <div
            className="clickable"
            tabIndex={0}
            onClick={() => setShowFilters(isTrue => !isTrue)}
            style={{ color: theme.primaryLight }}
        >
            {showFilters ? 'Hide' : 'Show'} Table Filters
            <FontAwesomeIcon icon={faFilter} pull="right" />
        </div>
    );
}

interface Props {
    showFiltersState: UseState<boolean>;
}
