import React, { ReactNode, useCallback, useMemo } from 'react';
import { TableRowActingAsMargin, Td } from '../DataTable/DataTable';
import { DataTableTr } from '../DataTable/shared';
import { generatePath, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import PersonPhoto from '../PersonPhoto';
import { maxContactLevel } from '../../values/appConfig';
import { formatDateTime, humanizeElapsedSeconds } from '../../utils/date';
import { ContactLevel, DiseaseCase } from '../../values/types';
import styled from '@emotion/styled';
import { wizardGray } from '../Wizard/WizardStep';
import { useSelector } from '../../store';
import { locationsByIdSelector } from '../../store/reducers/reports';
import { personsByIdSelector } from '../../store/reducers/persons';
import { routes } from '../../router/routes';

function createLinkForDiseaseCase(diseaseCaseUuid) {
    return generatePath(routes.diseaseCaseSingle.fullPath, {
        diseaseCaseUuid,
    });
}

const IndentTd = styled(Td)`
    width: 5rem;
    border-radius: 0;
    background-color: transparent;
    color: ${wizardGray};
    font-weight: bold;
    font-size: 1.5rem;

    tr:hover & {
        // Override parent style to not set color to the background.
        background-color: transparent;
    }
`;

const indentTexts = {
    0: '2nd',
    1: '3rd',
    2: '4th',
};

function ExistingDirectContactsTableRow({ exposure, baseContactLevel }: Props) {
    const personIdMap = useSelector(personsByIdSelector);
    const exposedPerson = personIdMap?.[exposure.PersonKey ?? ''];

    const history = useHistory();
    const formattedDate = useMemo(() => {
        return formatDateTime(new Date(exposure.exposedAt ?? exposure.createdAt));
    }, [exposure.createdAt, exposure.exposedAt]);
    const locationIdMap = useSelector(locationsByIdSelector);

    // These <td>s are meant to act as indents for the table to make it look like a tree.
    const indentTds = useMemo(() => {
        const ret: ReactNode[] = [];
        const length = (exposure.rootContactLevel ?? 0) - (baseContactLevel ?? 0) - 1;
        for (let i = 0; i < length; i++) {
            let text = '';
            if (i === length - 1) {
                // 2nd/3rd/4th text at the last part of the indent.
                // https://xd.adobe.com/view/8c31d2e4-2258-485c-578d-f4e4085c88d3-fa00/screen/00b42d62-44b0-41a1-8ac5-a6374d571ae0/
                text = indentTexts[i];
            }
            ret.push(<IndentTd key={i}>{text}</IndentTd>);
        }
        return ret;
    }, [baseContactLevel, exposure.rootContactLevel]);

    const link = useMemo(() => createLinkForDiseaseCase(exposure.uuid), [exposure.uuid]);

    const onDirectContactClick = useCallback(() => {
        history.push(link);
    }, [history, link]);

    return (
        <>
            <DataTableTr className="clickable" onClick={onDirectContactClick}>
                {indentTds}
                <Td
                    // For each indent td missing, increase the colspan to make up for it.
                    colSpan={maxContactLevel - indentTds.length}
                    className="first-td-radius"
                >
                    <Link
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            color: 'inherit',
                        }}
                        to={link}
                    >
                        <PersonPhoto
                            photo={exposedPerson?.profilePhoto?.smallUrl}
                            size="2rem"
                            style={{ marginRight: '1rem' }}
                        />{' '}
                        {exposedPerson?.fullName ?? '(Unknown)'}
                    </Link>
                </Td>
                <Td>{formattedDate}</Td>
                <Td>{humanizeElapsedSeconds(exposure.exposureDurationSeconds)}</Td>
                <Td>{locationIdMap[exposure.exposureLocationKey ?? 0]?.name ?? 'N/A'}</Td>
            </DataTableTr>
            <TableRowActingAsMargin />
        </>
    );
}

interface Props {
    exposure: DiseaseCase;
    baseContactLevel: ContactLevel | null;
}

export default ExistingDirectContactsTableRow;
