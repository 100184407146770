/**
 * All disease cases that need attention and should show up as alerts.
 */
import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { URI_DISEASE_CASES } from '../../axios';
import { DiseaseCase } from '../../values/types';
import { stringify } from 'qs';

const slice = createFetchSlice('diseaseCasesNeedAttention', {}, getInitialState<DiseaseCase[]>());

export function loadDiseaseCasesNeedAttention() {
    return slice.load({
        uri: `${URI_DISEASE_CASES}?${stringify({
            filter: { needAttention: 1 },
        })}`,
        stealth: true,
    });
}

const diseaseCasesNeedAttention = slice.reducer;
const diseaseCasesNeedAttentionSelector = slice.selector;
const diseaseCasesNeedAttentionReset = slice.actions.reset;

export { diseaseCasesNeedAttentionSelector, diseaseCasesNeedAttentionReset };

export const diseaseCasesNeedAttentionDataSelector = createSelector(
    diseaseCasesNeedAttentionSelector,
    v => v.data
);

export default diseaseCasesNeedAttention;
