import React from 'react';
import { match, Route, Switch } from 'react-router';
import DiseaseCaseShowPage from './DiseaseCaseShowPage';
import DiseaseCaseEditPage from './DiseaseCaseEditPage';
import { routes } from '../router/routes';

function DiseaseCasePage({ match }: Props) {
    return (
        <Switch>
            <Route
                path={`${match.path}/${routes.diseaseCaseEdit.relativePath}`}
                component={DiseaseCaseEditPage}
            />
            <Route component={DiseaseCaseShowPage} />
        </Switch>
    );
}

interface Props {
    match: match;
}

export default DiseaseCasePage;
