import React, { useCallback, useEffect, useState } from 'react';
import {
    DiseaseCaseSingle,
    DiseaseSingle,
    IncludableContactResident,
    IncludableContactStaff,
} from '../../values/types';
import Button, { PrimaryButton } from '../Button/Button';
import { useAppDispatch, useSelector } from '../../store';
import {
    contactTracingResultsDataAmendedSelector,
    contactTracingResultsReset,
    contactTracingResultsSelector,
    loadContactTracingResults,
} from '../../store/reducers/contactTracingResults';
import { subDays } from 'date-fns';
import { formatISODate } from '../../utils/date';
import { contactLevelInfoMap, embeddedContactTracingMaxLevel } from '../../values/appConfig';
import ContactTracingLoading from '../ContactTracing/ContactTracingLoading';
import SelectableItemGridContainer from '../SelectableItemGridContainer';
import PersonBox from '../../pages/ContactTracing/SelectResident/PersonBox';
import { createExposures, ExposureInput } from '../../api/exposures';
import { handleError } from '../../utils/errorHandling';
import { displayError } from '../../utils/toast';

function EmbeddedContactTracing({ disease, diseaseCase, onAfterDesignatedExposed }: Props) {
    const dispatch = useAppDispatch();

    const { waiting, error, data } = useSelector(contactTracingResultsSelector);
    const tracedContacts = useSelector(contactTracingResultsDataAmendedSelector);

    const [designatingExposures, setDesignatingExposures] = useState(false);

    useEffect(() => {
        dispatch(contactTracingResultsReset());
        return () => {
            dispatch(contactTracingResultsReset());
        };
    }, [dispatch]);

    const traceContacts = useCallback(() => {
        const now = new Date();
        const fromTo = {
            from: formatISODate(subDays(now, disease.contagiousPeriodPriorDays)),
            to: formatISODate(now),
        };
        dispatch(
            loadContactTracingResults(
                diseaseCase.PersonKey,
                fromTo,
                {
                    toInclude: [IncludableContactResident, IncludableContactStaff],
                    minimumTimeOverlap: disease.minOverlapMinutes,
                    trailingContacts: false,
                    trailingContactsMinutes: 0,
                },
                disease.maxContactLevel <= embeddedContactTracingMaxLevel
                    ? disease.maxContactLevel
                    : embeddedContactTracingMaxLevel
            )
        );
    }, [
        disease.contagiousPeriodPriorDays,
        disease.maxContactLevel,
        disease.minOverlapMinutes,
        diseaseCase.PersonKey,
        dispatch,
    ]);

    const designateExposures = useCallback(async () => {
        if (!tracedContacts) {
            displayError('Contacts have not been traced.');
            return;
        }
        setDesignatingExposures(true);
        const exposures: ExposureInput[] = [];
        for (const { contactLevel, contacts } of tracedContacts) {
            for (const contact of contacts) {
                if (!contact.person) {
                    continue;
                }
                exposures.push({
                    contactLevel,
                    exposedAt: contact.overlaps[0].startDate,
                    personKey: contact.person.id,
                    exposureLocationKey: contact.overlaps[0].location?.id ?? null,
                    exposureDurationSeconds: contact.overlapSeconds,
                });
            }
        }
        try {
            await createExposures(diseaseCase.uuid, exposures);
            // No need for the success toast since there is a success modal instead.
            onAfterDesignatedExposed(exposures.length);
        } catch (e) {
            handleError(e);
        } finally {
            setDesignatingExposures(false);
        }
    }, [diseaseCase.uuid, onAfterDesignatedExposed, tracedContacts]);

    return (
        <div>
            {waiting && <ContactTracingLoading error={error} reload={traceContacts} />}

            {tracedContacts &&
                tracedContacts.map(levelAndContacts => (
                    <div key={levelAndContacts.contactLevel} style={{ marginBottom: '3rem' }}>
                        <h3>{contactLevelInfoMap[levelAndContacts.contactLevel].label}</h3>

                        <SelectableItemGridContainer>
                            {levelAndContacts.contacts.map(({ id, person }) =>
                                person ? (
                                    <PersonBox key={id} selected={false} person={person} />
                                ) : null
                            )}
                        </SelectableItemGridContainer>
                    </div>
                ))}

            {!data && (
                <Button onClick={traceContacts} waiting={waiting}>
                    Trace Contacts
                </Button>
            )}

            {data && (
                <PrimaryButton onClick={designateExposures} waiting={designatingExposures}>
                    Designate Traced Contacts As Exposed
                </PrimaryButton>
            )}
        </div>
    );
}

interface Props {
    disease: DiseaseSingle;
    diseaseCase: DiseaseCaseSingle;
    onAfterDesignatedExposed: (exposedCount: number) => void;
}

export default EmbeddedContactTracing;
