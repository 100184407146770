import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { ActivityWithRegistrations, Engage360Filters } from '../../values/types';

const slice = createFetchSlice(
    'engageActivitySignups',
    {},
    getInitialState<ActivityWithRegistrations[]>()
);

export function loadEngageActivitySignups(filters: Engage360Filters, page: number, options = {}) {
    return slice.load({
        uri: `/activities/signups`,
        config: { params: { ...filters, page } },
        ...options,
    });
}

const engageActivitySignups = slice.reducer;
const engageActivitySignupsSelector = slice.selector;
const engageActivitySignupsReset = slice.actions.reset;

export { engageActivitySignupsSelector, engageActivitySignupsReset };

export const engageActivitySignupsDataSelector = createSelector(
    engageActivitySignupsSelector,
    v => v.data
);

export default engageActivitySignups;
