import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { formatDateTime, humanizeElapsedSecondsRoundedToMinutes } from '../../utils/date';
import { Overlap } from '../../store/reducers/contactTracing';

const Table = styled.table`
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    thead tr {
        color: white;
        font-weight: bold;
    }

    td {
        padding: 1rem;
    }

    tbody tr {
        background-color: #292829;
    }

    tbody tr:nth-of-type(even) {
        background-color: #222222;
    }

    tbody tr:not(:first-of-type) td {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    tbody tr:not(:last-of-type) td {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

// Expanded Table when results are expanded
function ContactTable({ data }: Props) {
    const formattedData = useMemo(() => {
        return data.map((row, index) => ({
            key: index,
            locationName: row.location?.name ?? '(Unknown)',
            elapsedTime: humanizeElapsedSecondsRoundedToMinutes(row.overlapSeconds),
            startDate: formatDateTime(row.startDate),
            endDate: formatDateTime(row.endDate),
        }));
    }, [data]);

    return (
        <Table>
            <thead>
                <tr>
                    <td>Dwell Time Overlap</td>
                    <td>Location</td>
                    <td>Start Time</td>
                    <td>End Time</td>
                </tr>
            </thead>
            <tbody>
                {formattedData.map(row => (
                    <tr key={row.key}>
                        <td className="first-td-radius">{row.elapsedTime}</td>
                        <td>{row.locationName}</td>
                        <td>{row.startDate}</td>
                        <td>{row.endDate}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

interface Props {
    data: Array<Overlap>;
}

export default ContactTable;
