import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { ChannelWithWatches, Engage360Filters } from '../../values/types';

/**
 * Engage360 TV - Channels with watch counts
 */

const slice = createFetchSlice(
    'engageChannelsTopWatched',
    {},
    getInitialState<ChannelWithWatches[]>()
);

export function loadEngageChannelsTopWatched(filters: Engage360Filters, options = {}) {
    return slice.load({
        uri: `/channels/top`,
        config: { params: filters },
        ...options,
    });
}

const engageChannelsTopWatched = slice.reducer;
const engageChannelsTopWatchedSelector = slice.selector;
const engageChannelsTopWatchedReset = slice.actions.reset;

export { engageChannelsTopWatchedSelector, engageChannelsTopWatchedReset };

export const engageChannelsTopWatchedDataSelector = createSelector(
    engageChannelsTopWatchedSelector,
    v => v.data
);

export const engageChannelsTopWatchedAmendedSelector = createSelector(slice.selector, state => ({
    ...state,
    amendedData: state.data && state.data.map((v, index) => ({ ...v, rank: index + 1 })),
}));

export default engageChannelsTopWatched;
