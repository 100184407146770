import ChartTitle from '../ChartTitle';
import Engage360Chart, { useColorizeLabelValues } from '../../Chart/Engage360Chart';
import Select from '../../Select';
import { orderBy } from 'lodash-es';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useSelector } from '../../../store';
import {
    engageRepairsByRoomAndIssueSelector,
    loadEngageRepairsByRoomAndIssue,
} from '../../../store/reducers/engageRepairsByRoomAndIssue';
import { LabelValue } from '../../../values/types';
import { engage360FiltersSelector } from '../../../store/globalSelectors';
import ConditionalFlex from '../../ConditionalFlex';
import { useTheme } from '../../../styles/theme';

export function RepairsByRoomAndIssue() {
    const dispatch = useAppDispatch();
    const engage360Filters = useSelector(engage360FiltersSelector);
    const theme = useTheme();

    useEffect(() => {
        dispatch(loadEngageRepairsByRoomAndIssue(engage360Filters));
    }, [dispatch, engage360Filters]);
    const data = useSelector(engageRepairsByRoomAndIssueSelector).data;

    const dataByRoom = useColorizeLabelValues(
        useMemo(() => {
            return (
                data &&
                data.map(roomRow => ({
                    label: roomRow.roomName,
                    value: roomRow.issues
                        .map(issueCell => issueCell.count)
                        .reduce((count, acc) => acc + count, 0),
                }))
            );
        }, [data])
    );

    const rooms = useMemo(() => dataByRoom.map(v => v.label).sort(), [dataByRoom]);
    const [selectedRoom, setSelectedRoom] = useState<string>('');

    const roomSelectOptions = useMemo(() => {
        return [
            { label: 'ALL ROOMS', value: '' },
            ...rooms.map(room => ({ label: room.toUpperCase(), value: room })),
        ];
    }, [rooms]);

    const dataByIssue = useColorizeLabelValues(
        useMemo(() => {
            const ret: LabelValue[] = [];
            const byIssue: Record<string, LabelValue> = {};

            if (!data) {
                return ret;
            }

            for (const roomRow of data) {
                // Room filter.
                if (selectedRoom && roomRow.roomName !== selectedRoom) {
                    continue;
                }

                for (const { issueName, count } of roomRow.issues) {
                    let issue = byIssue[issueName];
                    if (!issue) {
                        // Create a new "issue" since it doesn't exist yet.
                        issue = { label: issueName, value: 0 };
                        ret.push(issue);
                        byIssue[issueName] = issue;
                    }
                    issue.value += count;
                }
            }

            return orderBy(ret, ['value'], ['desc']);
        }, [data, selectedRoom]),
        dataByRoom.length
    );

    return (
        <ConditionalFlex>
            <div style={{ flex: 1, overflow: 'hidden' }}>
                <ChartTitle>Room Repairs Summary</ChartTitle>
                <Engage360Chart data={dataByRoom} name="Repair Requests" />
            </div>
            <div style={{ flex: 1, overflow: 'hidden', position: 'relative' }}>
                <ChartTitle>Room Repairs Issue</ChartTitle>
                <Engage360Chart data={dataByIssue} name="Repair Requests" />
                <div style={{ position: 'absolute', top: 0, right: 0, width: '15rem' }}>
                    <Select
                        options={roomSelectOptions}
                        onChange={setSelectedRoom}
                        value={selectedRoom}
                        backgroundColor={theme.background}
                    />
                </div>
            </div>
        </ConditionalFlex>
    );
}
