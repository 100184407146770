import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { wizardGray } from '../Wizard/WizardStep';
import ContactTable from './ContactTable';
import { WINDOW_WIDTH_PHONE_LANDSCAPE } from '../ResponsiveProvider/ResponsiveProvider';
import { Contact } from '../../store/reducers/contactTracing';
import SubjectPicture from '../SubjectPicture';
import { userCircleIcon } from '../../styles/fontLibrary';

const Container = styled.div`
    display: flex;
    padding: 1.5rem 0.5rem;

    @media screen and (max-width: ${WINDOW_WIDTH_PHONE_LANDSCAPE}px) {
        display: block;
    }
`;

export function getPictureOfContact({ person }: Contact) {
    return person ? (
        <SubjectPicture
            subject={{
                polymorphicType: 'person',
                id: person.id,
                name: person.fullName,
            }}
        />
    ) : (
        <FontAwesomeIcon size="1x" icon={userCircleIcon} />
    );
}

function ContactTracingResultExpanded({ rowData }: Props) {
    const isResident = !rowData.person?.isCaregiver;
    return (
        <Container>
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    height: '100%',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div style={{ fontSize: '4em' }}>{getPictureOfContact(rowData)}</div>
                    <div style={{ height: '1rem' }} />
                    <div style={{ fontWeight: 'bold', fontSize: '1.25rem' }}>
                        {rowData.person?.fullName}
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: wizardGray,
                        marginTop: '1rem',
                    }}
                >
                    <div>
                        <FontAwesomeIcon icon={isResident ? faUsers : faUserMd} size="2x" />
                    </div>
                    <div style={{ height: '1rem' }} />
                    <div>{isResident ? 'Resident' : 'Caregiver'}</div>
                </div>
            </div>
            <div style={{ flex: 4 }}>
                <ContactTable data={rowData.overlaps} />
            </div>
        </Container>
    );
}

type Props = {
    rowData: Contact;
};

export default ContactTracingResultExpanded;
