import React, { useMemo } from 'react';
import { personTypeInfoMap } from '../values/appConfig';
import PersonPhoto from './PersonPhoto';
import theme from '../styles/theme';
import { Person } from '../values/types';
import styled from '@emotion/styled';
import { matchPath, useRouteMatch } from 'react-router';
import { createLinkToPerson } from '../router/routeConfig';
import { Link } from 'react-router-dom';

const BigName = styled.div`
    font-size: 2rem;
    font-weight: bold;
    color: white;
`;

/**
 * Info about a person that goes on the top left corner of some pages.
 */
function PersonInfoCorner({ person }: Props) {
    const personInfo = personTypeInfoMap[person.isCaregiver ? 'caregiver' : 'resident'];
    const routeMatch = useRouteMatch();
    const linkToPerson: string | null = useMemo(() => {
        const personLink = createLinkToPerson(person);
        return matchPath(personLink, routeMatch) ? null : personLink;
    }, [person, routeMatch]);

    let ret = (
        <>
            <div style={{ display: 'flex' }}>
                <PersonPhoto photo={person.profilePhoto?.smallUrl} size="4rem" />
                <div style={{ width: '2rem' }} />
                <div style={{ color: theme.primaryLight }}>
                    {personInfo.name}

                    <BigName>
                        {person.fullName}{' '}
                        {person.facilityPersonId ? (
                            <span>
                                [<span>ID:</span> {person.facilityPersonId}]
                            </span>
                        ) : (
                            ''
                        )}
                    </BigName>
                </div>
            </div>

            <div style={{ height: '1rem' }} />
        </>
    );
    if (linkToPerson) {
        ret = <Link to={linkToPerson}>{ret}</Link>;
    }
    return ret;
}

interface Props {
    person: Person;
}

export default PersonInfoCorner;
