import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { Engage360Filters, RepairsSummary } from '../../values/types';

const slice = createFetchSlice('engageRepairsSummary', {}, getInitialState<RepairsSummary>());

export function loadEngageRepairsSummary(filters: Engage360Filters, options = {}) {
    return slice.load({
        uri: `/repairs/summary`,
        config: {
            params: filters,
        },
        ...options,
    });
}

const engageRepairsSummary = slice.reducer;
const engageRepairsSummarySelector = slice.selector;
const engageRepairsSummaryReset = slice.actions.reset;

export { engageRepairsSummarySelector, engageRepairsSummaryReset };

export const engageRepairsSummaryDataSelector = createSelector(
    engageRepairsSummarySelector,
    v => v.data
);

export default engageRepairsSummary;
