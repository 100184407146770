import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { Facility } from '../../values/types';

const slice = createFetchSlice('facilities', {}, getInitialState<Facility[]>());

export function loadFacilities(options = {}) {
    return slice.load({
        uri: `/facilities`,
        ...options,
    });
}

const facilities = slice.reducer;
const facilitiesSelector = slice.selector;
const facilitiesReset = slice.actions.reset;

export { facilitiesSelector, facilitiesReset };

export const facilitiesDataSelector = createSelector(facilitiesSelector, v => v.data);

export default facilities;
