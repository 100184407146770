import { Gender, LocationStatus } from '../values/types';
import { createFormData } from '../utils/form';
import axiosInstance, { URI_PEOPLE } from '../axios';

type PersonFormData = {
    facilityPersonId: string;
    isCaregiver: boolean | '1' | '0';
    name: string;
    middleInitial: string;
    secondName: string;
    gender: Gender;
    locationStatus: LocationStatus;
    homeLocationKey: number | null;
    quarantineLocationKey: number | null;
    dateOfBirth?: string | null;
    profilePhoto?: File;
};

/**
 * Upsert an entire person.
 */
export async function upsertPerson(personId: null, data: PersonFormData);
// In the case of an update, all properties are optional.
export async function upsertPerson(personId: number, data: Partial<PersonFormData>);
export async function upsertPerson(personId: number | null, data: Partial<PersonFormData>) {
    const formData = createFormData(data);

    const uri = personId ? `${URI_PEOPLE}/${personId}` : URI_PEOPLE;
    return axiosInstance.post(uri, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}
