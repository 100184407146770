import { defineRoute } from './routeBuilder';

// Note: Want to easily find the absolute path belonging to a route name?
//  Then see the commented debug tool at the bottom.

const home = defineRoute('');

const caseManagement = defineRoute('case-management');
const diseases = caseManagement.extend('diseases');
const diseaseCreate = diseases.extend('create');
const diseaseSingle = diseases.extend(':disease');
const diseaseEdit = diseaseSingle.extend('edit');

const diseaseCaseCreate = caseManagement.extend('disease-cases/create');
const diseaseCaseSingle = caseManagement.extend('disease-cases/:diseaseCaseUuid');
const diseaseCaseEdit = diseaseCaseSingle.extend('edit');

const contactTracing = defineRoute('contact-tracing');
const contactTracingSelectPerson = contactTracing.extend('select-person');
const contactTracingSelectDateRange = contactTracing.extend('select-date-range');
const contactTracingApplyQuickFilters = contactTracing.extend('apply-quick-filters');
const contactTracingChooseContactLevel = contactTracing.extend('choose-contact-level');
const contactTracingResults = contactTracing.extend('results');

const alarms = defineRoute('events');

const persons = defineRoute(':personType(residents|staff)');
const personsCreate = persons.extend('create');
const personsCreateManual = personsCreate.extend('manual');
const personsBulkUpload = personsCreate.extend('bulk-upload');
const personsSingle = persons.extend(':personId');
const personsEdit = personsSingle.extend('edit');

const locations = defineRoute('locations');
const locationEdit = locations.extend('edit/:locationId');
const locationCreate = locations.extend('locationCreate');

const engage360 = defineRoute('engage360');
const engage360Tv = engage360.extend('tv');
const engage360Activities = engage360.extend('activities');
const engage360ActivitiesPrint = engage360Activities.extend(':activityUuid/print');
const engage360Repairs = engage360.extend('repairs');

const caseManagementReports = defineRoute('reports');

const routes = {
    home,
    caseManagement,
    diseases,
    diseaseCreate,
    diseaseSingle,
    diseaseEdit,
    diseaseCaseCreate,
    diseaseCaseSingle,
    diseaseCaseEdit,
    persons,
    personsCreate,
    personsCreateManual,
    personsBulkUpload,
    personsSingle,
    personsEdit,
    alarms,
    contactTracing,
    contactTracingSelectPerson,
    contactTracingSelectDateRange,
    contactTracingApplyQuickFilters,
    contactTracingChooseContactLevel,
    contactTracingResults,
    locations,
    locationCreate,
    locationEdit,
    caseManagementReports,
    engage360,
    engage360Tv,
    engage360Activities,
    engage360ActivitiesPrint,
    engage360Repairs,
};

export { routes };

// Side-effect to set the routes to the window for easy access to debug the routes.
window['routeDebug'] = Object.entries(routes).map(([name, route]) => ({
    name,
    fullPath: route.fullPath,
}));

// Type this in the browser console to debug the routes:
//console.table(window['routeDebug']);
