import React, { useCallback, useEffect } from 'react';
import pluralize from 'pluralize';
import { Title } from './common';
import CasesVsQuarantinedChart from '../Chart/CasesVsQuarantinedChart';
import DiseaseSummary from './Disease/DiseaseSummary';
import styled from '@emotion/styled';
import DiseaseBox from './Disease/DiseaseBox';
import { match, useHistory } from 'react-router';
import { useAppDispatch, useSelector } from '../../store';
import {
    diseaseShowReset,
    diseaseShowSelector,
    loadDiseaseShow,
} from '../../store/reducers/diseaseShow';
import BigSpinner from '../BigSpinner/BigSpinner';
import { contactLevelInfoByLevel } from '../../values/appConfig';
import { EditBox } from '../../styles/shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { DiseasePageRouteParams } from '../../pages/DiseasePage';
import { generatePath, Link } from 'react-router-dom';
import { PrimaryButtonLink } from '../Button/Button';
import { LocationState } from '../../values/types';
import { NOT_FOUND } from '../../utils/errorHandling';
import { routes } from '../../router/routes';
import { facilityConfigSelector } from '../../store/reducers/facilityConfig';

const GappedFlex = styled.div`
    display: flex;
    @supports (display: grid) {
        display: grid;
        grid-template-columns: 1fr 1fr 1.25fr 1.75fr 0.75fr;
        grid-gap: 2rem;
    }

    > * {
        :not(:first-of-type) {
            margin-left: 2rem;

            @supports (display: grid) {
                margin-left: 0;
            }
        }
    }
`;

function DiseaseShowPage({ match }: Props) {
    const dispatch = useAppDispatch();
    const history = useHistory<LocationState>();
    const { isCentralOfficeAdmin } = useSelector(facilityConfigSelector);
    const goToNewDiseaseCasePage = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();
            history.push(routes.diseaseCaseCreate.fullPath, {
                diseaseCaseEditState: {
                    diseaseUuid: match.params.disease,
                },
            });
        },
        [history, match.params.disease]
    );
    useEffect(() => {
        dispatch(loadDiseaseShow(match.params.disease));
        return () => {
            dispatch(diseaseShowReset());
        };
    }, [dispatch, match.params.disease]);
    const { waiting, data, error } = useSelector(diseaseShowSelector);

    useEffect(() => {
        if (error?.type === NOT_FOUND) {
            history.push(routes.caseManagement.fullPath);
        }
    }, [error, history]);

    if (waiting || !data) {
        return <BigSpinner />;
    }

    const {
        quarantineTimeframeDays,
        trailingContactMinutes,
        contagiousPeriodPriorDays,
        specialInstructions,
        minOverlapMinutes,
        contagiousPeriodPostDays,
        name,
        note,
        maxContactLevel,
        infoSources,
    } = data;

    const contactLevelInfo = contactLevelInfoByLevel[maxContactLevel];

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title>Disease: {name}</Title>
                <div style={{ flex: 1 }} />
                <PrimaryButtonLink
                    to={routes.diseaseCaseCreate.fullPath}
                    onClick={goToNewDiseaseCasePage}
                >
                    + Log a Case
                </PrimaryButtonLink>
            </div>
            <div style={{ display: 'flex' }}>
                <DiseaseSummary style={{ flex: 2 }} disease={data} />
                <div style={{ flex: 3, zoom: 0.6 }}>
                    <CasesVsQuarantinedChart
                        diseaseName={name}
                        diseaseUuid={match.params.disease}
                    />
                </div>
            </div>
            <h2>Contact Tracing Exposure Parameters</h2>
            <GappedFlex>
                <DiseaseBox
                    style={{ flex: 1 }}
                    top={`${minOverlapMinutes} min`}
                    bottom="Min Dwell Time Overlap"
                />
                <DiseaseBox
                    style={{ flex: 1 }}
                    top={`${trailingContactMinutes} min`}
                    bottom="Trailing Contacts Period"
                />
                <DiseaseBox
                    style={{ flex: 1 }}
                    top={
                        <span style={{ color: contactLevelInfo.color }}>
                            {contactLevelInfo.label}
                        </span>
                    }
                    bottom="Max Contact Level"
                />
                <DiseaseBox
                    style={{ flex: 1 }}
                    top={`${contagiousPeriodPriorDays} days prior/${contagiousPeriodPostDays} days post`}
                    bottom="Contagious Period"
                />
                {isCentralOfficeAdmin && (
                    <Link to={generatePath(routes.diseaseEdit.fullPath, match.params)}>
                        <EditBox>
                            <FontAwesomeIcon size="3x" icon={faPencil} />
                            <span>Edit Disease</span>
                        </EditBox>
                    </Link>
                )}
            </GappedFlex>
            <div style={{ height: '1rem' }} />
            <h2>Protocols</h2>
            <GappedFlex>
                <DiseaseBox
                    style={{ flex: 1 }}
                    top={`${quarantineTimeframeDays} days`}
                    bottom="Quarantine Timeframe"
                />
                {specialInstructions ? (
                    <DiseaseBox
                        style={{ flex: 2, gridColumn: 'span 2' }}
                        top={specialInstructions}
                        bottom="Special Instructions"
                    />
                ) : null}
            </GappedFlex>
            {infoSources.length ? (
                <div>
                    <div style={{ height: '1rem' }} />
                    <h2>Info {pluralize('Source', infoSources.length)}</h2>

                    {infoSources.map((v, index) => {
                        // Prepend http:// to the URL if there is no protocol on it.
                        const url = /:\/\//.test(v.url) ? v.url : `http://${v.url}`;
                        return (
                            <a key={index} href={url} target="_blank" rel="noopener noreferrer">
                                <DiseaseBox
                                    style={{ marginRight: '1rem' }}
                                    top={<span style={{ fontSize: '0.8em' }}>{url}</span>}
                                    bottom={v.displayName}
                                />
                            </a>
                        );
                    })}
                </div>
            ) : null}

            <div style={{ height: '2rem' }} />
            <div style={{ color: 'white', fontSize: '1.2rem' }}>{note}</div>
            <div style={{ height: '3rem' }} />
        </div>
    );
}

interface Props {
    match: match<DiseasePageRouteParams>;
}

export default DiseaseShowPage;
