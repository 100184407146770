import React, { useEffect, useMemo } from 'react';
import TvPage from './Engage360/TvPage';
import { match, matchPath, Redirect, Route, Switch } from 'react-router';
import { routes } from '../router/routes';
import RepairsPage from './Engage360/RepairsPage';
import ActivitiesPage from './Engage360/ActivitiesPage';
import { useLayoutCustomization } from '../context/LayoutContext';
import TabNavBar from '../components/TabNavBar';
import { useAppDispatch, useSelector } from '../store';
import { engageResidentsReset, loadEngageResidents } from '../store/reducers/engageResidents';
import { useApi } from '../utils/apiRouting';
import { stringify } from 'qs';
import { engage360FiltersSelector } from '../store/globalSelectors';

const _routes = [
    {
        path: routes.engage360Activities.fullPath,
        name: 'Activities',
        component: ActivitiesPage,
    },
    {
        path: routes.engage360Repairs.fullPath,
        name: 'Repairs',
        component: RepairsPage,
    },
    { path: routes.engage360Tv.fullPath, name: 'TV', component: TvPage },
];

export default function Engage360HomePage({ match }: Props) {
    const dispatch = useAppDispatch();

    const { apiBaseUrl, getBaseQuery } = useApi();
    const engage360Filters = useSelector(engage360FiltersSelector);

    const getDownloadUrl = useMemo(() => {
        const stringifiedQuery = stringify({
            ...getBaseQuery(),
            ...engage360Filters,
            format: 'xlsx',
        });
        if (matchPath(match.path, routes.engage360Tv.fullPath)) {
            return () => `${apiBaseUrl}/api/channels/top?${stringifiedQuery}`;
        } else if (matchPath(match.path, routes.engage360Repairs.fullPath)) {
            return () => `${apiBaseUrl}/api/repairs?${stringifiedQuery}`;
        } else if (matchPath(match.path, routes.engage360Activities.fullPath)) {
            return () => `${apiBaseUrl}/api/activities/signups?${stringifiedQuery}`;
        }

        return undefined;
    }, [apiBaseUrl, engage360Filters, getBaseQuery, match.path]);

    useLayoutCustomization({ filters: 'engage360', getDownloadUrl });

    const routeElements = useMemo(
        () =>
            _routes.map(route => (
                <Route
                    path={route.path}
                    key={route.path}
                    render={() => (
                        <div style={{ padding: '2rem' }}>
                            <route.component />
                        </div>
                    )}
                />
            )),
        []
    );

    useEffect(() => {
        dispatch(loadEngageResidents());
        return () => {
            dispatch(engageResidentsReset());
        };
    }, [dispatch]);

    return (
        <div>
            <TabNavBar tabs={_routes} />
            <Switch>
                {routeElements}

                <Redirect to={_routes[0].path} />
            </Switch>
        </div>
    );
}

interface Props {
    match: match;
}
