import React, { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { DiseaseCaseSingleRouteParams } from '../router/caseManagementRoutes';
import PersonInfoCorner from '../components/PersonInfoCorner';
import { useAppDispatch, useSelector } from '../store';
import {
    diseaseCaseByUuidMapSelector,
    diseaseCasesDataSelector,
} from '../store/reducers/diseaseCases';
import { personsWaitingSelector } from '../store/reducers/reports';
import DiseaseCasesAndCount from '../components/DiseaseCasesAndCount';
import { FullPageBigSpinner } from '../components/BigSpinner/BigSpinner';
import ErrorOnPage from '../components/ErrorOnPage/ErrorOnPage';
import { Link, useHistory } from 'react-router-dom';
import { EditBox } from '../styles/shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import styled from '@emotion/styled';
import { isDiseaseCaseExposure } from '../values/appConfig';
import ExposureSingleRow from '../components/DiseaseCase/ExposureSingleRow';
import {
    diseaseCaseShowReset,
    diseaseCaseShowSelector,
    loadDiseaseCaseShow,
} from '../store/reducers/diseaseCaseShow';
import {
    diseaseShowReset,
    diseaseShowSelector,
    loadDiseaseShow,
} from '../store/reducers/diseaseShow';
import ProtocolStepsCompletion from '../components/ProtocolSteps/ProtocolStepsCompletion';
import { NOT_FOUND } from '../utils/errorHandling';
import { personsByIdSelector } from '../store/reducers/persons';
import { routes } from '../router/routes';

const Spacer = styled.div`
    height: 3rem;
`;

function DiseaseCaseShowPage() {
    const history = useHistory();
    const routeMatch = useRouteMatch<DiseaseCaseSingleRouteParams>();
    const dispatch = useAppDispatch();
    const diseaseCaseUuidMap = useSelector(diseaseCaseByUuidMapSelector);
    const personsWaiting = useSelector(personsWaitingSelector);
    const personIdMap = useSelector(personsByIdSelector);
    const diseaseCaseState = useSelector(diseaseCaseShowSelector);
    const diseaseCase = diseaseCaseState.data;
    const diseaseSingleState = useSelector(diseaseShowSelector);
    const diseaseSingle = diseaseSingleState.data;
    const person = diseaseCase ? personIdMap?.[diseaseCase.PersonKey] : null;

    useEffect(() => {
        dispatch(loadDiseaseCaseShow(routeMatch.params.diseaseCaseUuid, true));
        return () => {
            dispatch(diseaseCaseShowReset());
        };
    }, [dispatch, routeMatch.params.diseaseCaseUuid]);

    const diseaseUuid = diseaseCase?.diseaseUuid;
    useEffect(() => {
        if (!diseaseUuid) {
            return;
        }

        dispatch(loadDiseaseShow(diseaseUuid));
        return () => {
            dispatch(diseaseShowReset());
        };
    }, [diseaseUuid, dispatch]);

    const diseaseCasesData = useSelector(diseaseCasesDataSelector);

    const diseaseCasesForPersonCount = useMemo(() => {
        if (!person) {
            return 0;
        }
        return (diseaseCasesData || []).filter(
            v => v.PersonKey === person.id && !v.protocolCompletedAt && !v.isExposure
        ).length;
    }, [diseaseCasesData, person]);

    // The DiseaseCasesAndCount component accepts an array of disease cases as an argument.
    // But we're only passing the one disease case, because that's what we're focusing on.
    const diseaseCases = useMemo(() => {
        return diseaseCase ? [diseaseCase] : [];
    }, [diseaseCase]);

    const rootDiseaseCase = diseaseCaseUuidMap[diseaseCase?.rootContactDiseaseCaseUuid ?? ''];
    const exposedToPerson = personIdMap?.[rootDiseaseCase?.PersonKey ?? 0];

    const isExposure = diseaseCase ? isDiseaseCaseExposure(diseaseCase) : false;

    const boxesAfterDiseaseBox = useMemo(() => {
        if (!diseaseCase) {
            return null;
        }

        return (
            <Link to={`${routeMatch.url}/${routes.diseaseCaseEdit.relativePath}`}>
                <EditBox style={{ width: '10rem' }}>
                    <FontAwesomeIcon size="3x" icon={faPencil} />
                    Edit {isExposure ? 'Exposure' : 'Case'}
                </EditBox>
            </Link>
        );
    }, [diseaseCase, isExposure, routeMatch.url]);

    const isNotFound = diseaseCaseState.error?.type === NOT_FOUND;

    useEffect(() => {
        if (isNotFound) {
            history.push(routes.caseManagement.fullPath);
        }
    }, [history, isNotFound]);

    if (isNotFound) {
        return null;
    }

    if (
        !diseaseCasesData ||
        personsWaiting ||
        diseaseCaseState.waiting ||
        diseaseSingleState.waiting ||
        !diseaseSingleState.data
    ) {
        return <FullPageBigSpinner />;
    }

    if (!diseaseCase) {
        return <ErrorOnPage text="Could not find this disease case." />;
    }

    if (!diseaseSingle) {
        return <ErrorOnPage text="Could not find this disease." />;
    }

    if (!person) {
        return <ErrorOnPage text="Could not find the patient for this disease case." />;
    }

    return (
        <div>
            <PersonInfoCorner person={person} />
            <h3>{isExposure ? 'Exposure' : 'Active Case'}</h3>
            {isExposure ? (
                <ExposureSingleRow
                    exposure={diseaseCase}
                    disease={diseaseSingle}
                    parentDiseaseCase={rootDiseaseCase}
                    exposedToPerson={exposedToPerson}
                >
                    {boxesAfterDiseaseBox}
                </ExposureSingleRow>
            ) : (
                <DiseaseCasesAndCount
                    count={diseaseCasesForPersonCount}
                    isExposures={isExposure}
                    diseaseCases={diseaseCases}
                    additionalBoxes={boxesAfterDiseaseBox}
                />
            )}
            <Spacer />
            <ProtocolStepsCompletion disease={diseaseSingle} diseaseCase={diseaseCase} />
        </div>
    );
}

export default DiseaseCaseShowPage;
