import React from 'react';
import { ContactTracingTitle, ContinueButton } from './common';
import { Box as _Box } from '../../styles/shared';
import styled from '@emotion/styled';
import { useHistory } from 'react-router';
import { routeForApplyQuickFilters } from './contactTracingRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { setContactTracingDateRange } from '../../store/actions/contactTracing';
import DateSelector from '../../components/DateSelector/DateSelector';
import { RootState } from '../../store';

const Box = styled(_Box)`
    display: inline-block;
    padding: 2rem;
`;

function SelectDateRangePage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const {from, to} = useSelector((state: RootState) => state.contactTracing.dateRange);

    return (
        <div>
            <ContactTracingTitle>Select a date range</ContactTracingTitle>

            <Box>
                <DateSelector
                    sideBySideLarge
                    from={from}
                    to={to}
                    onDateRangeChange={(dateRange) => {
                        dispatch(setContactTracingDateRange(dateRange));
                    }}
                />
                <div style={{height: '1rem'}} />
                <ContinueButton onClick={() => history.push(routeForApplyQuickFilters.path)} />
            </Box>
        </div>
    );
}

export default SelectDateRangePage;
