import styled from '@emotion/styled';

const SelectableItemGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-row-gap: 0.5rem;
    grid-column-gap: 1.5rem;

    .isIE & {
        > * {
            margin-bottom: 0.5rem;
            display: block;
        }
    }
`;

export default SelectableItemGridContainer;
