import styled from '@emotion/styled';
import { faStickyNote } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable, { iconHeight } from '../../components/DataTable/DataTable';
import ExpandedTable from '../../components/DataTable/ExpandedTable';
import useAlarmsGetDownloadUrl from '../../components/hooks/useAlarmsGetDownloadUrl';
import useEffectUnlessFiltersSame from '../../components/hooks/useEffectUnlessFiltersSame';
import Pagination from '../../components/Pagination/Pagination';
import PaginationResultNumbers from '../../components/PaginationResultNumbers/PaginationResultNumbers';
import { useResponsive } from '../../components/ResponsiveProvider/ResponsiveProvider';
import SubjectPicture from '../../components/SubjectPicture';
import { useLayoutCustomization } from '../../context/LayoutContext';
import * as actions from '../../store/actions/index';
import { css } from '../../utils/ide';
import { getAlarmTypeByKey, getDispositionByType } from '../../values/appConfig';
import { alarmSubjects } from '../../values/types';

const Container = styled.div(
    ({ singleColumnLayout }) => css`
        box-sizing: border-box;
        width: 100%;
        padding: 0 ${singleColumnLayout ? '0.5rem' : '2rem'} 2rem;
    `
);

const Events = () => {
    const { singleColumnLayout } = useResponsive();
    const dispatch = useDispatch();
    const { data, error, filters, active, loading, meta } = useSelector(state => state.reports);
    const { currentPage, pageCount } = filters;

    useEffectUnlessFiltersSame(() => {
        dispatch(actions.getAlarmsReport());
    });

    const getDownloadUrl = useAlarmsGetDownloadUrl();
    useLayoutCustomization({ filters: 'alarms', getDownloadUrl, sorting: true, search: true });

    const changeActive = useCallback(val => dispatch(actions.getAlarmDetailReport(val)), [
        dispatch,
    ]);

    const getTrExtraAttributes = row => ({ 'data-id': row.alarmId });

    const getExpandedRowComponent = useCallback(
        (rowData, activeData, columns, width) => {
            const checkActive = id => activeData && +activeData.id === id;

            return (
                <ExpandedTable
                    onClick={() => changeActive(rowData.id)}
                    show={checkActive(rowData.id) && activeData.open}
                    listItemData={rowData}
                    data={activeData?.data}
                    loading={activeData?.loading}
                    colSpan={columns}
                    width={width}
                    trExtraAttributes={getTrExtraAttributes(rowData)}
                />
            );
        },
        [changeActive]
    );

    if (error) {
        throw error;
    }

    return (
        <Container singleColumnLayout={singleColumnLayout}>
            <div>
                <DataTable
                    data={data}
                    headerConfig={headerConfig}
                    getTrExtraAttributes={getTrExtraAttributes}
                    getExpandedRowComponent={getExpandedRowComponent}
                    changeActive={changeActive}
                    active={active}
                    loading={loading}
                    setSorting={val => dispatch(actions.setFilter('sort', val))}
                    filters={filters}
                />
            </div>
            <PaginationResultNumbers
                currentPage={currentPage}
                pageCount={pageCount}
                total={meta.total}
                to={meta.to}
                setPerPage={val => dispatch(actions.setFilter('pageCount', val))}
            />
            <Pagination
                currentPage={currentPage}
                pageCount={pageCount}
                lastPage={meta.last_page}
                total={meta.total}
                setPage={val => dispatch(actions.setFilter('page', val))}
            />
        </Container>
    );
};

export default Events;

export const headerConfig = [
    {
        label: '',
        getValue() {
            return '';
        },
        tdStyle: { paddingLeft: '0.3rem' },
    },
    {
        label: '',
        getValue({ subject }) {
            return (
                <span style={{ fontSize: '2.2rem' }}>
                    <SubjectPicture subject={subject} />
                </span>
            );
        },
        tdStyle: { width: '1rem', paddingRight: 0 },
        textAlign: 'center',
    },
    {
        label: 'Initiator',
        getValue({ subject }) {
            if (!subject) {
                return <em>System</em>;
            }
            if (alarmSubjects.includes(subject.polymorphicType)) {
                if (subject.name) {
                    return subject.name;
                }
            }
            return <em>Unknown</em>;
        },
        format: 'string',
        textAlign: 'left',
    },
    {
        label: 'Type',
        getValue({ alarmType }) {
            return getAlarmTypeByKey(alarmType);
        },
        format: 'icon-and-name',
    },
    { label: 'Location', getValue: 'area', format: 'string' },
    { label: 'Opened', getValue: 'creationTime', format: 'date', sortIcon: 'amount' },
    {
        label: 'Service',
        tdStyle: { width: '4rem' },
        getValue({ dispositionType }) {
            return dispositionType ? getDispositionByType(dispositionType) : null;
        },
        format: 'icon-and-name',
    },
    {
        label: 'Notes',
        tdStyle: { width: '4rem' },
        getValue({ dispositionNote }) {
            if (!dispositionNote) {
                return null;
            }

            return (
                <Tippy content={`“${dispositionNote}”`} placement="bottom">
                    <span>
                        <FontAwesomeIcon icon={faStickyNote} style={{ fontSize: iconHeight }} />
                    </span>
                </Tippy>
            );
        },
    },
    { label: 'Response', getValue: 'responseTime', format: 'seconds', sortIcon: 'numeric' },
];
