import React, {useMemo} from 'react';
import {node} from 'prop-types';
import ValueCard from '../ValueCard/ValueCard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {H2} from '../../styles/shared';
import {getDispositionByType} from '../../values/appConfig';
import {take} from 'lodash-es';

function MostCommonServices({data = []}) {
    const items = useMemo(() => {
        return take(data, 5).map((item) => ({...getDispositionByType(item.group)}))
    }, [data]);

    return (
        <div>
            <H2>Most Common Services</H2>
            <ValueCard
                topContent={items.map(({icon}) => <FontAwesomeIcon
                    key={Math.random()}
                    icon={icon}
                />)}
                bottomContent={items.map(({name}, index) =>
                    <span
                        style={{opacity: 0.56, fontWeight: 'normal', fontSize: '1rem'}}
                        key={index}
                    >{name}</span>)}
            /></div>
    );
}

MostCommonServices.propTypes = {
    children: node,
};

export default MostCommonServices;
