import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Title } from '../components/CaseManagement/common';
import theme from '../styles/theme';
import { useForm } from 'react-hook-form';
import { generatePath, useHistory, useLocation, useRouteMatch } from 'react-router';
import { DiseaseCaseSingle, DiseaseCaseStatus, LocationState, Person } from '../values/types';
import { useAppDispatch, useSelector } from '../store';
import { diseasesAsSelectOptionsSelector } from '../store/reducers/diseases';
import { GroupLabel, InputComponentsContainer } from '../components/Form/formCommon';
import Select from '../components/Select';
import FieldErrors from '../components/FieldErrors';
import styled from '@emotion/styled';
import { personTypeInfoMap } from '../values/appConfig';
import { locationsByIdSelector, personsWaitingSelector } from '../store/reducers/reports';
import { BigButton, ButtonLink, PrimaryButton } from '../components/Button/Button';
import {
    DiseaseCaseEditRouteState,
    DiseaseCaseSingleRouteParams,
} from '../router/caseManagementRoutes';
import axiosInstance, { ResponseEnvelope, URI_DISEASE_CASES } from '../axios';
import { displaySuccess } from '../utils/toast';
import { handleError, reportError } from '../utils/errorHandling';
import PageBox from '../components/PageBox';
import {
    diseaseCaseShowReset,
    diseaseCaseShowSelector,
    loadDiseaseCaseShow,
} from '../store/reducers/diseaseCaseShow';
import { formatDateTimeLocal } from '../utils/date';
import RadioWithLabel from '../components/Radio/RadioWithLabel';
import BigSpinner, { FullPageBigSpinner, MediumSpinner } from '../components/BigSpinner/BigSpinner';
import { formatISO9075, parseISO } from 'date-fns';
import DiseaseCaseEditTests, {
    DiseaseCaseTestFormItem,
} from '../components/DiseaseCase/DiseaseCaseEditTests';
import { features } from '../values/env';
import { loadAllDiseaseCases } from '../store/actions/global';
import ModalBox from '../components/ModalBox';
import {
    loadPersons,
    personsByIdSelector,
    residentsAsArraySelector,
    residentsSelector,
} from '../store/reducers/persons';
import ErrorOnPage from '../components/ErrorOnPage/ErrorOnPage';
import { locationsDataSelector } from '../store/reducers/locations';
import { upsertPerson } from '../api/persons';
import { routes } from '../router/routes';
import { facilityConfigSelector } from '../store/reducers/facilityConfig';

const Spacer = styled.div`
    height: 3rem;
`;

const RadioOptionsContainer = styled.div`
    display: flex;
    align-items: center;

    > div:not(:first-of-type) {
        margin-left: 2rem;
    }
`;

export type DiseaseCaseForm = {
    diseaseUuid: string | null;
    personKey: number | null;
    isIsolatedAtHomeLocation: '1' | '';
    status: DiseaseCaseStatus;
    tests: DiseaseCaseTestFormItem[];
    updatedAt: string;
};

type ModalWarning = null | 'newCase' | 'exposureWithPositiveTest';

function DiseaseCaseEditPage() {
    const dispatch = useAppDispatch();
    const diseaseCaseEditState: DiseaseCaseEditRouteState =
        useLocation<LocationState>().state?.diseaseCaseEditState || {};
    const diseaseUuid = diseaseCaseEditState.diseaseUuid ?? null;
    const personId = diseaseCaseEditState.personId ?? null;
    const history = useHistory();
    const { facilityKey } = useSelector(facilityConfigSelector);
    const personByIdMap = useSelector(personsByIdSelector);
    const residentsAsArray = useSelector(residentsAsArraySelector);
    const residents = useSelector(residentsSelector);

    const defaultValues: DiseaseCaseForm = {
        diseaseUuid,
        personKey: personId,
        isIsolatedAtHomeLocation: '1',
        tests: [],
        status: 'active case',
        updatedAt: '',
    };
    const form = useForm({
        defaultValues,
        shouldUnregister: false, // Keeps "tests" as an (empty) array even while empty.
    });
    const { formState, getValues, handleSubmit, register, reset, setError, setValue, watch } = form;
    const errors = formState.errors;
    const match = useRouteMatch<Partial<DiseaseCaseSingleRouteParams>>();
    const diseaseCaseUuid = match.params.diseaseCaseUuid;
    register('diseaseUuid', { required: 'You must select a disease.' });
    register('personKey', { required: 'You must select a person.' });
    register('status');

    const watched = watch(['diseaseUuid', 'isCaregiver', 'personKey', 'isIsolatedAtHomeLocation']);
    const diseaseOptions = useSelector(diseasesAsSelectOptionsSelector);
    const locationsById = useSelector(locationsByIdSelector);
    const diseaseCaseState = useSelector(diseaseCaseShowSelector);
    const existingDiseaseCase = diseaseCaseState.data;
    const personsWaiting = useSelector(personsWaitingSelector);
    const locationsData = useSelector(locationsDataSelector);

    // Local form quarantine location key.
    const [quarantineLocationKey, setQuarantineLocationKey] = useState(null as null | number);

    const person: Person | undefined = useMemo(() => {
        return personByIdMap?.[watched.personKey ?? ''];
    }, [personByIdMap, watched.personKey]);

    useEffect(() => {
        setQuarantineLocationKey(person?.quarantineLocationKey ?? null);
    }, [person]);

    // For the list of possible quarantine locations except the home one.
    const locationsExceptHome = useMemo(() => {
        return (
            locationsData &&
            locationsData
                .filter(location => location.id !== person?.homeLocationKey)
                .map(location => ({ value: location.id, label: location.name }))
        );
    }, [locationsData, person]);

    useEffect(() => {
        if (!locationsExceptHome) {
            return;
        }

        if (!person) {
            return;
        }

        const quarantineLocationThatIsNotHome = locationsExceptHome.find(
            location => location.value === person.quarantineLocationKey
        );

        if (!quarantineLocationThatIsNotHome && locationsExceptHome.length) {
            // Make sure the first possible isolation location is selected to ensure
            // the select always shows a non-null value.
            setQuarantineLocationKey(locationsExceptHome[0].value);
        }
    }, [locationsExceptHome, person]);

    useEffect(() => {
        if (!diseaseCaseUuid) {
            return;
        }
        dispatch(loadDiseaseCaseShow(diseaseCaseUuid));
        return () => {
            dispatch(diseaseCaseShowReset());
        };
    }, [diseaseCaseUuid, dispatch]);

    // Load from the API.
    useEffect(() => {
        if (!existingDiseaseCase) {
            return;
        }

        if (personsWaiting) {
            return;
        }

        const { tests, PersonKey, ...restData } = existingDiseaseCase;
        const form: DiseaseCaseForm = {
            personKey: PersonKey,
            isIsolatedAtHomeLocation:
                !person?.quarantineLocationKey ||
                person.quarantineLocationKey === person.homeLocationKey
                    ? '1'
                    : '',
            tests: tests.map(({ isPositive, testedAt, resultReadyAt }) => ({
                isPositive: isPositive ? '1' : '',
                resultReadyAt: formatDateTimeLocal(new Date(resultReadyAt)),
                testedAt: formatDateTimeLocal(new Date(testedAt)),
            })),
            ...restData,
        };
        reset(form);
    }, [diseaseUuid, existingDiseaseCase, person, personsWaiting, reset]);

    const peopleOptions = useMemo(() => {
        return [
            {
                value: null,
                label: `(Select a ${personTypeInfoMap.resident.name})`,
            },
            ...residentsAsArray.map(v => {
                const homeLocationName =
                    locationsById[v.homeLocationKey ?? '']?.name ?? 'no home location';
                return {
                    label: `${v.fullName} (${homeLocationName})`,
                    value: v.id,
                };
            }),
        ];
    }, [locationsById, residentsAsArray]);

    useEffect(() => {
        if (!peopleOptions.find(option => option.value === watched.personKey)) {
            setValue('personKey', null);
        }
    }, [peopleOptions, setValue, watched.personKey]);

    const backUrl = useMemo(() => {
        if (diseaseCaseEditState.diseaseUuid) {
            return generatePath(routes.diseaseSingle.fullPath, {
                disease: diseaseCaseEditState.diseaseUuid,
            });
        }

        return routes.caseManagement.fullPath;
    }, [diseaseCaseEditState.diseaseUuid]);

    const [saving, setSaving] = useState(false);
    const [modalSaveWarningStatus, setIsModalOpen] = useState<ModalWarning>(null);

    const closeCancelModal = () => {
        setIsModalOpen(null);
    };

    const onPersonChange = useCallback(
        (personId: number | null) => {
            setValue('personKey', personId, {
                shouldValidate: true,
            });
        },
        [setValue]
    );

    const onDelete = useCallback(async () => {
        if (!window.confirm('Are you sure you want to delete this disease case?')) {
            return false;
        }
        try {
            await axiosInstance.delete(`${URI_DISEASE_CASES}/${diseaseCaseUuid}`);
            displaySuccess('Successfully deleted the disease case.');
            dispatch(loadAllDiseaseCases());
            history.push(routes.caseManagement.fullPath);
        } catch (e) {
            handleError(e);
        }
    }, [diseaseCaseUuid, dispatch, history]);

    const save = useCallback(async () => {
        setIsModalOpen(null);
        if (!person) {
            reportError('Person was not found.');
            return;
        }
        try {
            setSaving(true);

            const _quarantineLocationKey =
                watched.isIsolatedAtHomeLocation === '1'
                    ? person.homeLocationKey
                    : quarantineLocationKey;
            await upsertPerson(person.id, {
                quarantineLocationKey: _quarantineLocationKey,
            });
            const reloadPersonsPromise = dispatch(loadPersons());

            const existingDiseaseTestsCount = existingDiseaseCase?.tests.length ?? 0;
            const { tests, status, ...restValues } = getValues();
            const method = diseaseCaseUuid ? 'PUT' : 'POST';
            const url = diseaseCaseUuid
                ? `${URI_DISEASE_CASES}/${diseaseCaseUuid}`
                : URI_DISEASE_CASES;
            const testsData = tests.map(
                ({ testedAt, resultReadyAt, isPositive }: DiseaseCaseTestFormItem) => ({
                    testedAt: testedAt ? formatISO9075(parseISO(testedAt)) : null,
                    resultReadyAt: resultReadyAt ? formatISO9075(parseISO(resultReadyAt)) : null,
                    isPositive: !!isPositive,
                })
            );
            const anyPositiveTests = tests.some(test => test.isPositive);
            const response = await axiosInstance.request<ResponseEnvelope<DiseaseCaseSingle>>({
                method,
                url,
                data: {
                    facilityKey,
                    // Promote exposure to active case if there is at least one positive case.
                    status: anyPositiveTests && status === 'exposure' ? 'active case' : status,
                    ...restValues,
                },
            });
            const uuid = response.data.data.uuid;

            // Are tests dirty? Need to also check the length difference, because removing an item
            // does not seem to mark the array as dirty.
            if (formState.dirtyFields.tests || existingDiseaseTestsCount !== testsData.length) {
                await axiosInstance.post(`${URI_DISEASE_CASES}/${uuid}/tests`, {
                    tests: testsData,
                });
            }

            // Make sure we've awaited reloaded the list of people.
            // This is to make sure Isolation Location would show the accurate value.
            await reloadPersonsPromise;

            displaySuccess('You have successfully saved the disease case.');
            dispatch(loadAllDiseaseCases()); // Reload the list of disease cases.
            if (!diseaseCaseUuid) {
                history.push(backUrl);
            } else {
                dispatch(loadDiseaseCaseShow(diseaseCaseUuid!));
            }
        } catch (e) {
            handleError(e, { setError });
        } finally {
            setSaving(false);
        }
    }, [
        backUrl,
        diseaseCaseUuid,
        dispatch,
        existingDiseaseCase,
        facilityKey,
        formState.dirtyFields.tests,
        getValues,
        history,
        person,
        quarantineLocationKey,
        setError,
        watched.isIsolatedAtHomeLocation,
    ]);

    const onSubmit = useCallback(
        (values: DiseaseCaseForm) => {
            if (!diseaseCaseUuid) {
                setIsModalOpen('newCase');
                return;
            }

            if (!existingDiseaseCase) {
                // This shouldn't happen, but let's put this here to make TypeScript not think
                // this can be null in later lines.
                return;
            }

            if (existingDiseaseCase.status === 'exposure') {
                if (values.tests.some(test => test.isPositive)) {
                    setIsModalOpen('exposureWithPositiveTest');
                    return;
                }
            }

            save();
        },
        [diseaseCaseUuid, existingDiseaseCase, save]
    );

    if (residents?.length === 0) {
        return (
            <ErrorOnPage text="There are no residents in the system for this facility. Please add residents to log a case." />
        );
    }

    if (!personByIdMap) {
        return <BigSpinner />;
    }

    return (
        <div>
            <div style={{ textAlign: 'center' }}>
                <Title style={{ marginBottom: '1rem' }}>
                    {diseaseCaseUuid ? 'Edit Case' : 'Log a Case'}
                </Title>
                <div style={{ fontStyle: 'italic', color: theme.primaryLight }}>
                    All fields required.
                </div>
                <div style={{ height: '3rem' }} />
            </div>
            <PageBox style={{ position: 'relative' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <GroupLabel>Resident Name</GroupLabel>
                    <InputComponentsContainer>
                        <div style={{ width: '30rem' }}>
                            <Select
                                options={peopleOptions}
                                value={watched.personKey}
                                onChange={onPersonChange}
                            />
                            <FieldErrors errors={errors?.personKey} />
                        </div>
                    </InputComponentsContainer>
                    <Spacer />
                    <GroupLabel>Disease</GroupLabel>
                    <div style={{ width: '20rem' }}>
                        <Select
                            options={diseaseOptions}
                            value={watched.diseaseUuid}
                            onChange={v =>
                                setValue('diseaseUuid', v, {
                                    shouldValidate: true,
                                })
                            }
                        />
                    </div>
                    <FieldErrors errors={errors?.diseaseUuid} />

                    <Spacer />

                    <DiseaseCaseEditTests form={form} />

                    <Spacer />

                    <GroupLabel>Isolation Location</GroupLabel>
                    <div>
                        <RadioOptionsContainer>
                            <div>
                                <RadioWithLabel
                                    name="isIsolatedAtHomeLocation"
                                    value="1"
                                    register={register}
                                    label={`Home - ${
                                        locationsById[person?.homeLocationKey ?? '']?.name ??
                                        'Unknown'
                                    }`}
                                />
                            </div>
                            {locationsExceptHome?.length ? (
                                <>
                                    <div>
                                        <RadioWithLabel
                                            name="isIsolatedAtHomeLocation"
                                            value=""
                                            register={register}
                                            label={
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    Other:{' '}
                                                </div>
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{ width: '20rem', marginLeft: '1rem' }}
                                        onClick={() => setValue('isIsolatedAtHomeLocation', '')}
                                    >
                                        {locationsExceptHome ? (
                                            <Select
                                                value={quarantineLocationKey}
                                                options={locationsExceptHome}
                                                onChange={value => {
                                                    setQuarantineLocationKey(value);
                                                }}
                                            />
                                        ) : (
                                            <MediumSpinner />
                                        )}
                                    </div>
                                </>
                            ) : null}
                        </RadioOptionsContainer>
                    </div>

                    <input type="hidden" name="status" ref={register} />
                    <FieldErrors errors={[errors?.status]} />

                    <input type="hidden" name="updatedAt" ref={register} />
                    <FieldErrors errors={[errors?.updatedAt]} />

                    <Spacer />
                    <div style={{ textAlign: 'center' }}>
                        <PrimaryButton type="submit" waiting={saving || personsWaiting}>
                            {diseaseCaseUuid ? 'Update Case' : 'Create New Case'}
                        </PrimaryButton>
                        {diseaseCaseUuid && features.debug ? (
                            <BigButton
                                style={{ marginLeft: '3rem' }}
                                flavor="danger"
                                onClick={onDelete}
                            >
                                Delete
                            </BigButton>
                        ) : null}
                        <ButtonLink style={{ marginLeft: '3rem' }} flavor="link" to={backUrl}>
                            Cancel
                        </ButtonLink>
                    </div>
                </form>
                {(diseaseCaseState.waiting || personsWaiting || saving) && (
                    <FullPageBigSpinner absolute />
                )}
            </PageBox>
            <ModalBox isOpen={!!modalSaveWarningStatus} onRequestClose={closeCancelModal}>
                {modalSaveWarningStatus === 'newCase' ? (
                    <>
                        <h1>Are you sure you want to create a new case?</h1>
                        <p>
                            Once created, Isolation will be pending and alarms will be triggered for
                            Attending Staff to perform protocols and perform contact tracing.
                        </p>
                        <p>
                            Once Attending Staff confirms isolation protocol is complete, isolation
                            status will update.
                        </p>
                    </>
                ) : (
                    <>
                        <h1>You have added a positive test to this exposure.</h1>

                        <p>Saving this exposure will promote it to an active case.</p>
                    </>
                )}
                <div>
                    <div style={{ height: '3rem' }} />
                    <div style={{ textAlign: 'center' }}>
                        <PrimaryButton flavor="highlighted" onClick={save}>
                            {diseaseCaseUuid ? 'Update Case' : 'Create New Case'}
                        </PrimaryButton>
                        <span
                            style={{
                                marginLeft: '3rem',
                                color: theme.primaryBlue,
                                cursor: 'pointer',
                            }}
                            onClick={closeCancelModal}
                        >
                            Cancel
                        </span>
                    </div>
                </div>
            </ModalBox>
        </div>
    );
}

export default DiseaseCaseEditPage;
