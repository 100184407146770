import React, { useMemo } from 'react';
import { AlarmSubject } from '../values/types';
import { getOwnerObjByType } from '../values/appConfig';
import { getInitials } from '../utils/person';
import Icon from './Icon';

/**
 * Picture of a Topic (person, location, or asset).
 * @param props
 * @constructor
 */
export default function SubjectPicture({ subject }: Props) {
    const initials = useMemo(() => {
        return subject && getInitials(subject);
    }, [subject]);

    if (subject === null) {
        return null;
    }

    if (subject.polymorphicType === 'person' && subject.name) {
        // Initials.

        return (
            <svg viewBox="0 0 64 64" className="svg-inline--fa">
                <circle cx="32" cy="32" r="30" stroke="white" fill="none" strokeWidth="3" />
                <text
                    className="avatar-icon"
                    fill="white"
                    fontSize="26"
                    textAnchor="middle"
                    x="32"
                    y="42"
                >
                    {initials}
                </text>
            </svg>
        );
    }

    const iconAndName = getOwnerObjByType(subject?.polymorphicType);

    if (!iconAndName) {
        return null;
    }

    return <Icon {...iconAndName} />;
}

interface Props {
    subject: AlarmSubject;
}
