import React, { HTMLAttributes, ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '../../utils/ide';
import { Box } from '../../styles/shared';

const Container = styled.div(
    (props: { color?: string }) => css`
        font-size: 3.25rem;
        box-sizing: border-box;
        text-align: center;
        font-weight: bold;
        & > div {
            color: ${props.color || null};
        }
        path {
            fill: ${props.color || null};
        }
    `
);

const InnerContainer = styled(Box)(
    () => css`
        height: 8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    `
);

const ValueContainer = styled.div`
    display: flex;
    //noinspection CssOverwrittenProperties
    justify-content: space-evenly;
    //noinspection CssOverwrittenProperties
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 0.5rem;
`;

const Title = styled.div`
    width: 100%;
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
`;

/**
 * Little rounded corner black box typically for holding an icon, a value, and text.
 * You can pass a single topContent/bottomContent,
 * or an array of them if you want to show multiple next to each other in the same box.
 */
const ValueCard = (props: Props) => {
    const topContents = !Array.isArray(props.topContent) ? [props.topContent] : props.topContent;
    const bottomContents = !Array.isArray(props.bottomContent)
        ? [props.bottomContent]
        : props.bottomContent;

    return (
        <Container {...props}>
            <InnerContainer>
                {topContents.map((topContent, index) => (
                    <div key={index} style={{ flex: 1 }}>
                        <ValueContainer>{topContent}</ValueContainer>
                        <Title>{bottomContents[index]}</Title>
                    </div>
                ))}
            </InnerContainer>
        </Container>
    );
};

interface Props extends HTMLAttributes<HTMLDivElement> {
    topContent: ReactNode | ReactNode[];
    bottomContent: ReactNode | ReactNode[];
}

export default ValueCard;
