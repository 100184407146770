import React, { HTMLAttributes, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Box } from '../../../styles/shared';

const StyledBox = styled(Box)`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 8rem;
    padding: 1rem;
    text-align: center;
`;

const Top = styled.div`
    font-size: 1.75rem;
    font-weight: bold;
`;

const Bottom = styled.div`
    font-size: 1.25rem;
`;

function DiseaseBox({ bottom, top, ...restProps }: Props) {
    return (
        <StyledBox {...restProps}>
            <Top>{top}</Top>
            <Bottom>{bottom}</Bottom>
        </StyledBox>
    );
}

type Props = HTMLAttributes<HTMLDivElement> & {
    top: ReactNode;
    bottom: ReactNode;
};

export default DiseaseBox;
