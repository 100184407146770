import React from 'react';
import styled from '@emotion/styled';
import PaginateArrow from "./PaginateArrow";
import PaginatePages from "./PaginatePages";
import PropTypes from 'prop-types';
import {
    WINDOW_WIDTH_MEDIUM_DESKTOP,
    WINDOW_WIDTH_TABLET,
} from '../ResponsiveProvider/ResponsiveProvider';

const Container = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    @media (max-width: ${WINDOW_WIDTH_MEDIUM_DESKTOP}px) {
        left: 200px;
    }
    @media (max-width: ${WINDOW_WIDTH_TABLET}px) {
        left: 0;
    }
`;

const InnerContainer = styled.div`
    display: flex;
    div {
        margin: 0 6px;
    }
`;

const Pagination = (props) => {
    const {currentPage, setPage, lastPage, to} = props;
    return (
        <Container>
            <InnerContainer>
                <PaginateArrow left onClick={() => setPage(currentPage - 1)} disabled={props.currentPage <=1 }/>
                <PaginatePages totalPages={lastPage} currentPage={currentPage} to={to} setPage={setPage}/>
                <PaginateArrow right onClick={() => setPage(currentPage + 1)} disabled={props.currentPage >= lastPage}/>
            </InnerContainer>
        </Container>
    )
};

Pagination.propTypes = {
    currentPage: PropTypes.number,
    pageCount: PropTypes.number,
    lastPage: PropTypes.number,
    total: PropTypes.number,
    setPage: PropTypes.func.isRequired
};

export default Pagination;
