import React, { useMemo } from 'react';
import {
    RouteGroup,
    useTopRouteAndGroupMatch,
    useTopRouteConfig,
} from '../../../router/routeConfig';
import MenuItem from './MenuItem';
import { styled } from '../../../styles/theme';
import { css } from '../../../utils/ide';
import { Link } from 'react-router-dom';

const Group = styled.span<{ groupActive: boolean }>(
    ({ groupActive, theme }) => css`
        padding: 1rem 0;
        display: block;
        text-align: center;
        color: ${groupActive ? theme.primaryLight : 'inherit'};

        &:hover {
            color: ${theme.primaryLight};
        }
    `
);

const GroupItemsContainer = styled.div`
    max-height: 0;
    overflow: hidden;
`;

/**
 * For left menu items grouped (under a logo).
 */
export default function MenuGroup({ group }: Props) {
    const topRoutes = useTopRouteConfig();
    const topRouteAndGroupMatch = useTopRouteAndGroupMatch();
    const groupActive = group.groupKey === topRouteAndGroupMatch?.group?.groupKey;

    const firstChildLeafOfGroup = useMemo(() => {
        const foundGroup = topRoutes.find(r => r.isGroup && r.groupKey === group.groupKey) as
            | RouteGroup
            | undefined;

        const foundFirstChild = foundGroup?.items[0];

        return foundFirstChild?.component ? foundFirstChild : undefined;
    }, [group.groupKey, topRoutes]);

    const groupElement = useMemo(() => {
        return (
            <Group groupActive={groupActive}>
                <group.GroupImage style={{ height: '0.5rem' }} />
            </Group>
        );
    }, [groupActive]);

    const wrappedGroupElement = firstChildLeafOfGroup ? (
        <Link to={firstChildLeafOfGroup.path}>{groupElement}</Link>
    ) : (
        groupElement
    );

    return (
        <>
            <div className="group">{wrappedGroupElement}</div>
            <GroupItemsContainer
                style={{ maxHeight: groupActive ? `${group.items.length * 6}rem` : 0 }}
            >
                {group.items.map((item, innerIndex) => (
                    <MenuItem key={innerIndex} {...item} />
                ))}
            </GroupItemsContainer>
        </>
    );
}

interface Props {
    group: RouteGroup;
}
