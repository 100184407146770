import React, { ComponentProps, CSSProperties, useCallback, useMemo, useState } from 'react';
import ReactSelect, { FocusEventHandler, StylesConfig } from 'react-select';
import { noop } from 'lodash-es';

/**
 * react-select has the value of a field wrapped in {value: 'somevalue'}. This function extracts it.
 * @param {object} value
 * @return {string}
 */
function extractSelectValue<T>({ value }: SelectOption<T>) {
    return value;
}

export type SelectOption<T> = { label: string | JSX.Element | number; value: T };
// Either the value itself, or the value along with a label.
// The former is simpler, but the latter also works if the provided value is not among the options.
type ValueOrSelectOption<T> = T | SelectOption<T> | null | undefined;

export function useLocalSelectState<T>(startingValue: T) {
    const [value, setValue] = useState(startingValue);

    return {
        value,
        onChange: value => {
            setValue(value);
        },
    };
}

/**
 * Simpler interface to react-select.
 */
function UnstyledSelect<T>(props: UnstyledSelectProps<T>) {
    const flatOptions = props.options
        .map(groupOrOptions =>
            'options' in groupOrOptions ? groupOrOptions.options : [groupOrOptions]
        )
        .flat();
    const extractSelectOption = useCallback(
        (value: ValueOrSelectOption<T>): SelectOption<T> | null => {
            if (value === void 0 || value === null) {
                return null;
            }
            return typeof value === 'object' && 'label' in value && 'value' in value
                ? value
                : flatOptions.find(option => option.value === value) ?? null;
        },
        [flatOptions]
    );

    // react-select expects a value to be a {label, value} object, or an array of such.
    const reactSelectValue = useMemo(
        () =>
            props.value !== void 0 && props.value !== null
                ? props.isMulti
                    ? props.value.map(extractSelectOption)
                    : extractSelectOption(props.value)
                : null,
        [extractSelectOption, props.isMulti, props.value]
    );

    // For select, we must destructure an object with a "value" key containing the actual value.
    const reactSelectOnChange = input =>
        (props.onChange || noop)(
            props.isMulti ? (input || []).map(extractSelectValue) : extractSelectValue(input)
        );

    const commonProps: ComponentProps<typeof ReactSelect> = {
        isMulti: props.isMulti,
        options: props.options,
        value: reactSelectValue,
        onChange: reactSelectOnChange,
        styles: props.styles,
        placeholder: props.placeholder,
    };

    return (
        <div style={props.containerStyle}>
            <ReactSelect {...commonProps} />
        </div>
    );
}

type CommonProps<T> = {
    name?: string;
    onBlur?: FocusEventHandler;
    containerStyle?: CSSProperties;
    error?: string | string[];
    options: Array<SelectOption<T> | { label: string; options: SelectOption<T>[] }>;
    styles?: StylesConfig;
    placeholder?: string;
};

type SingleProps<T> = CommonProps<T> & {
    isMulti?: false;
    value?: ValueOrSelectOption<T>;
    onChange: (value: T) => void;
};

type MultiProps<T> = CommonProps<T> & {
    isMulti: true;
    value?: ValueOrSelectOption<T>[];
    onChange: (values: T[]) => void;
};

export type UnstyledSelectProps<T> = SingleProps<T> | MultiProps<T>;

export default UnstyledSelect;
