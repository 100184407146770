import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import DateSelectorCalendar from './DateSelectorCalendar/DateSelectorCalendar';
import DateInput from './DateInput/DateInput';
import { FromTo } from '../../store/reducers/reports';
import Button from '../Button/Button';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import addDays from 'date-fns/addDays';
import { format } from 'date-fns';

const PresetRangeFlexContainer = styled.div`
    margin: 0 auto;
    max-width: 40rem;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

const DateButton = styled(Button)`
    width: 32%;
    font-size: 0.9em;
`;

const settings: { [key: string]: () => [Date, Date] } = {
    today: () => [new Date(), new Date()],
    currentMonth: () => [startOfMonth(new Date()), endOfMonth(new Date())],
    lastMonth: () => [
        addMonths(startOfMonth(new Date()), -1),
        addMonths(endOfMonth(new Date()), -1),
    ],
    yesterday: () => [addDays(new Date(), -1), addDays(new Date(), -1)],
    last7: () => [addDays(new Date(), -7), new Date()],
    last30: () => [addDays(new Date(), -30), new Date()],
};

const DateSelector = ({ close, from, to, sideBySideLarge, onDateRangeChange }: Props) => {
    const set = useCallback(
        (term: string) => {
            const [fromDate, toDate] = settings[term]();
            const range = {
                from: format(fromDate, 'yyyy-MM-dd'),
                to: format(toDate, 'yyyy-MM-dd'),
            };
            onDateRangeChange(range);
            close && close();
        },
        [close, onDateRangeChange]
    );

    return (
        <div>
            <DateInput from={from} to={to} />

            <div style={{ margin: '0 auto' }}>
                <DateSelectorCalendar
                    from={from}
                    to={to}
                    onChange={onDateRangeChange}
                    sideBySideLarge={sideBySideLarge}
                />
            </div>

            <div style={{ height: '1.5rem' }} />

            <PresetRangeFlexContainer style={{ justifyContent: 'space-between' }} onClick={close}>
                <DateButton onClick={() => set('today')}>Today</DateButton>
                <DateButton onClick={() => set('currentMonth')}>This Month</DateButton>
                <DateButton onClick={() => set('lastMonth')}>Last Month</DateButton>
            </PresetRangeFlexContainer>

            <PresetRangeFlexContainer style={{ justifyContent: 'space-between' }}>
                <DateButton onClick={() => set('yesterday')}>Yesterday</DateButton>
                <DateButton onClick={() => set('last7')}>Last 7d</DateButton>
                <DateButton onClick={() => set('last30')}>Last 30d</DateButton>
            </PresetRangeFlexContainer>
        </div>
    );
};

interface Props extends FromTo {
    close?: () => void;
    onDateRangeChange: (range: FromTo) => void;
    sideBySideLarge?: boolean;
}

export default DateSelector;
