import React from 'react';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';
import darkTheme from '../../styles/theme';
import { createTooltip } from '../Recharts/Recharts';
import ResizeDetector from 'react-resize-detector';
import { array } from 'prop-types';
import styled from '@emotion/styled';
import { operateOnSize, getLineHeight, remToNumber } from '../../utils/css';

const legendItemMarginBottom = '0.2rem';
const legendPaddingTop = '1rem';

const Container = styled.div`
    .recharts-legend-item {
        margin-bottom: 0.2rem;
    }
`;

function DashboardPieChart({ data }) {
    // Calculate the legend height to add that to the total height of the chart.
    const legendHeight =
        data.length * (remToNumber(legendItemMarginBottom) + getLineHeight()) +
        remToNumber(legendPaddingTop);

    return (
        <Container>
            <ResizeDetector
                handleWidth
                children={({ width: size }) => {
                    if (!size) {
                        return <div key="no-size" />;
                    }
                    const outerRadius = size / 2 - remToNumber('3.5rem');
                    return (
                        <ResponsiveContainer width="100%" height={size + legendHeight}>
                            <PieChart>
                                <Pie
                                    data={data}
                                    dataKey="count"
                                    stroke="transparent"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={outerRadius}
                                    innerRadius={operateOnSize(outerRadius, num => num * 0.75)}
                                    startAngle={90}
                                    endAngle={-270}
                                    nameKey="name"
                                    isAnimationActive={false} // https://github.com/recharts/recharts/issues/1135
                                    label={({
                                        cx,
                                        cy,
                                        midAngle,
                                        innerRadius,
                                        outerRadius,
                                        percent,
                                    }) => {
                                        const RADIAN = Math.PI / 180;
                                        const radius =
                                            15 + innerRadius + (outerRadius - innerRadius);
                                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                        const y = cy + radius * Math.sin(-midAngle * RADIAN);

                                        if (percent <= 0.01) {
                                            // Do not show 1% values and lower.
                                            return;
                                        }

                                        return (
                                            <text
                                                x={x}
                                                y={y}
                                                fontWeight={'bold'}
                                                fill={darkTheme.primaryLight}
                                                textAnchor={x > cx ? 'start' : 'end'}
                                                dominantBaseline="central"
                                            >
                                                {Math.round(percent * 100)}%
                                            </text>
                                        );
                                    }}
                                    labelLine={false}
                                >
                                    {data.map(({ name, value, color }, index) => (
                                        <Cell key={`cell-${index}`} fill={color} />
                                    ))}
                                </Pie>
                                <Legend
                                    layout="vertical"
                                    iconType="circle"
                                    iconSize={remToNumber('1.42rem')}
                                    itemStyle={{ marginTop: '2rem' }}
                                    wrapperStyle={{ paddingTop: legendPaddingTop }}
                                    formatter={value => (
                                        <span
                                            style={{
                                                color: darkTheme.primaryLight,
                                                paddingLeft: '0.5rem',
                                                position: 'relative',
                                                top: '0.1rem',
                                            }}
                                        >
                                            {value}
                                        </span>
                                    )}
                                />
                                {createTooltip({ itemStyle: { color: darkTheme.primaryLight } })}
                            </PieChart>
                        </ResponsiveContainer>
                    );
                }}
            />
        </Container>
    );
}
DashboardPieChart.propTypes = {
    data: array,
};

export default DashboardPieChart;
