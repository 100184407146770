/**
 * Fake tagged template utility to trick PhpStorm's Styled Components plugin into
 * syntax highlighting CSS.
 * What's returned is identical to if you used a non-tagged template literal.
 *
 * @return {string}
 */
export function css(outsides, ...insides) {
    let ret = '';
    for (let i = 0; i < outsides.length; i++) {
        ret += outsides[i] + (insides[i] || '');
    }
    return ret;
}
