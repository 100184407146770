import React from 'react';
import ChartTitle from '../ChartTitle';
import DataTableSimple from '../../DataTableSimple';
import { ChannelWithWatchesAndRank } from '../../../values/types';

export default function ChannelRank({ channelRank }: Props) {
    return (
        <>
            <ChartTitle>Channel Rank</ChartTitle>
            <DataTableSimple
                data={channelRank}
                idProperty="callSign"
                headerConfig={[
                    {
                        label: '#',
                        getValue: r => r.rank,
                    },
                    {
                        label: 'Call Sign',
                        getValue: r => r.callSign,
                        key: 'callSign',
                        sortable: true,
                    },
                    {
                        label: 'Channel Name',
                        getValue: r => r.name,
                        key: 'name',
                        sortable: true,
                    },
                    {
                        label: 'Occurrences',
                        getValue: r => r.watches,
                        textAlign: 'center',
                        key: 'watches',
                        sortable: true,
                        defaultSortDescending: true,
                    },
                ]}
            />
        </>
    );
}

interface Props {
    channelRank: ChannelWithWatchesAndRank[];
}
