import React, { useCallback, useEffect, useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis } from 'recharts';
import darkTheme, { blue5, red } from '../../styles/theme';
import { CasesVsQuarantinedCategory, CasesVsQuarantinedDatum } from '../../values/types';
import ReactResizeDetector from 'react-resize-detector';
import { format, parse, sub } from 'date-fns';
import Select from '../Select';
import { useForm } from 'react-hook-form';
import axiosInstance, { ResponseEnvelope, URI_CHARTS } from '../../axios';
import { handleError } from '../../utils/errorHandling';
import { formatISODate, formatMonthDay } from '../../utils/date';
import { FromTo } from '../../store/reducers/reports';
import { stringify } from 'qs';
import { keyBy } from 'lodash-es';

const definitions: Array<{
    category: CasesVsQuarantinedCategory;
    color: string;
    label: string;
}> = [
    {
        category: 'casesTotal',
        color: red,
        label: 'Cases',
    },
    {
        category: 'quarantinedTotal',
        color: blue5,
        label: 'Quarantined',
    },
];

const frequencies = [
    { value: 'month', label: 'Monthly' },
    { value: 'week', label: 'Weekly' },
    { value: 'day', label: 'Daily', includesToday: true },
];

const frequencyOptions = frequencies.map(frequency =>
    // Include a star (*) in the label if it doesn't include today's data.
    !frequency.includesToday ? { ...frequency, label: frequency.label + '*' } : frequency
);

const frequencyByValueMap = keyBy(frequencies, 'value');

function CasesVsQuarantinedChart({ diseaseName, diseaseUuid, facilityIds, fromTo }: Props) {
    const [casesVsQuarantinedData, setCasesVsQuarantinedData] = useState<CasesVsQuarantinedDatum[]>(
        []
    );

    const defaultValues = {
        frequency: 'day',
    };
    const { register, setValue, watch } = useForm({
        defaultValues,
    });
    register('frequency');
    const watched = watch(['frequency']);

    const updateChart = useCallback(async () => {
        let from: Date;
        switch (watched.frequency) {
            case 'day':
                from = sub(new Date(), { months: 1 });
                break;
            case 'week':
                from = sub(new Date(), { months: 3 });
                break;
            case 'month':
                from = sub(new Date(), { months: 12 });
                break;
            default:
                // won't happen
                from = sub(new Date(), { months: 6 });
                break;
        }
        try {
            const query = {
                diseaseUuids: [diseaseUuid],
                ...(facilityIds ? { facilityKeys: facilityIds } : {}),
                frequency: watched.frequency,
                personType: 'all',
                from: fromTo?.from ?? formatISODate(from),
                to: fromTo?.to,
            };

            let facilityIdsString = '';
            if (facilityIds?.length) {
                facilityIds.forEach(
                    facilityId => (facilityIdsString += `&facilityKeys[]=${facilityId}`)
                );
            }

            const stats = await axiosInstance.get<ResponseEnvelope<CasesVsQuarantinedDatum[]>>(
                `${URI_CHARTS}/disease-stats${stringify(query, {
                    addQueryPrefix: true,
                    arrayFormat: 'brackets',
                    encode: false,
                })}`
            );

            setCasesVsQuarantinedData(stats.data.data);
        } catch (e) {
            handleError(e);
        }
    }, [diseaseUuid, facilityIds, fromTo, watched.frequency]);

    useEffect(() => {
        updateChart();
    }, [updateChart]);

    const customizedAxisTick = useCallback(
        (props): any => {
            const { x, y, payload } = props;
            const textParts = String(payload.value ?? '').split(' ');

            switch (watched.frequency) {
                case 'day':
                case 'week':
                    return (
                        <g transform={`translate(${x},${y})`}>
                            <text
                                x={0}
                                y={0}
                                dx={-33}
                                dy={10}
                                transform="rotate(-35)"
                                fill={darkTheme.primaryLight}
                            >
                                <tspan textAnchor="middle" x="0" style={{ fontSize: '1rem' }}>
                                    {payload.value}
                                </tspan>
                            </text>
                        </g>
                    );

                default:
                    return (
                        <g transform={`translate(${x},${y})`}>
                            <text x={0} y={0} dy={24} fill={darkTheme.primaryLight}>
                                <tspan textAnchor="middle" x="0" style={{ fontSize: '1rem' }}>
                                    {textParts[0]}
                                </tspan>
                                <tspan
                                    textAnchor="middle"
                                    x="0"
                                    dy="17"
                                    style={{ fontSize: '1rem' }}
                                >
                                    {textParts[1]}
                                </tspan>
                            </text>
                        </g>
                    );
            }
        },
        [watched.frequency]
    );

    const xAxisDataKey = useCallback(
        (datum: CasesVsQuarantinedDatum) => {
            switch (watched.frequency) {
                case 'month':
                    const yearMonth = datum.date;
                    const monthPart = yearMonth.substring(5, 7);
                    const monthFirstLetter = format(parse(monthPart, 'MM', new Date()), 'MMM');
                    if (datum === casesVsQuarantinedData[0] || yearMonth.substring(5, 7) === '01') {
                        return `${monthFirstLetter} ${yearMonth.substring(0, 4)}`;
                    }
                    return monthFirstLetter;

                default:
                    return formatMonthDay(datum.date);
            }
        },
        [casesVsQuarantinedData, watched.frequency]
    );

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2>Cases vs Quarantined{diseaseName ? ` for ${diseaseName}` : ''}</h2>
                <div style={{ flex: 1 }} />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <div style={{ width: '20rem', marginRight: '1rem' }}>
                        <Select
                            options={frequencyOptions}
                            value={watched.frequency}
                            onChange={v => {
                                setValue('frequency', v);
                            }}
                        />
                    </div>
                    {!frequencyByValueMap[watched.frequency].includesToday ? (
                        <div style={{ margin: '1rem 0' }}>
                            * Note: Historical values are calculated once an hour. To see real time
                            values, please view daily.
                        </div>
                    ) : null}
                </div>
            </div>
            <ReactResizeDetector
                handleWidth
                render={({ width }) => {
                    if (!width) {
                        return <div key="noWidth" />;
                    }
                    return (
                        <LineChart
                            data={casesVsQuarantinedData}
                            width={width}
                            height={Math.max(300, width / 5)}
                            margin={{ top: 5, right: 15, bottom: 5, left: 25 }}
                        >
                            <XAxis
                                dataKey={xAxisDataKey}
                                stroke={darkTheme.primaryLight}
                                height={60}
                                axisLine={false}
                                tickLine={false}
                                dx={20}
                                interval="preserveStartEnd"
                                minTickGap={-30}
                                tick={customizedAxisTick}
                            />
                            <YAxis
                                stroke={darkTheme.primaryLight}
                                width={30}
                                axisLine={false}
                                tickLine={false}
                                allowDecimals={false}
                                tick={{ fontSize: '0.8rem' }}
                            />
                            <Legend
                                align="left"
                                wrapperStyle={{
                                    paddingLeft: '1rem',
                                }}
                                formatter={value => (
                                    <span
                                        style={{
                                            fontSize: '0.8rem',
                                            color: darkTheme.primaryLight,
                                            marginRight: '2.5rem',
                                        }}
                                    >
                                        {value}
                                    </span>
                                )}
                            />
                            <CartesianGrid strokeWidth={0.5} />
                            {definitions.map(def => (
                                <Line
                                    type="monotone"
                                    name={def.label}
                                    key={def.category}
                                    dataKey={def.category}
                                    stroke={def.color}
                                    dot={false}
                                    strokeWidth={3}
                                />
                            ))}
                        </LineChart>
                    );
                }}
            />
        </div>
    );
}

interface Props {
    diseaseUuid: string;
    diseaseName?: string;
    facilityIds?: Number[];
    fromTo?: FromTo;
}

export default CasesVsQuarantinedChart;
