import React, { CSSProperties, useMemo } from 'react';
import { HeaderConfigItem } from '../DataTableSimple';
import styled from '@emotion/styled';
import theme from '../../styles/theme';
import { identity } from 'lodash-es';

const Tbody = styled.tbody`
    tr:nth-of-type(odd) {
        background-color: ${theme.backgroundSecondaryOpacity};
    }

    font-weight: bold;
`;

const Td = styled.td`
    padding: 1rem;
`;

export default function DataTableBodyPart<T>({
    sortedData,
    headerConfig,
    getKey,
    tdStyle,
    waiting,
}: Props<T>) {
    const rows = useMemo(() => {
        if (!sortedData) {
            return Array(10)
                .fill(null)
                .map((_, index) => (
                    <tr key={index}>
                        {headerConfig.map((_, headerIndex) => (
                            <Td key={headerIndex}>&nbsp;</Td>
                        ))}
                    </tr>
                ));
        }

        return sortedData.map((row, index) => (
            <Row
                row={row}
                key={getKey(row)}
                headerConfig={headerConfig}
                tdStyle={tdStyle}
                index={index}
                waiting={waiting}
            />
        ));
    }, [getKey, headerConfig, sortedData, tdStyle, waiting]);

    return <Tbody>{rows}</Tbody>;
}

type Props<T> = {
    sortedData: T[] | null;
    headerConfig: HeaderConfigItem<T>[];
    tdStyle: CSSProperties;
    getKey: (row: T) => string | number;
    waiting?: boolean;
};

function Row<T>({ row, index, key, headerConfig, tdStyle, waiting }: RowProps<T>) {
    const cells = useMemo(
        () =>
            headerConfig.map((headerConfigItem, headerIndex) => {
                return (
                    <Cell
                        key={headerConfigItem.key ?? index}
                        row={row}
                        index={index}
                        headerConfigItem={headerConfigItem}
                        waiting={waiting}
                        tdStyle={tdStyle}
                    />
                );
            }),
        [headerConfig, index, row, tdStyle, waiting]
    );
    return <tr key={key}>{cells}</tr>;
}

type RowProps<T> = {
    row: T;
    key: string | number;
    index: number;
    headerConfig: HeaderConfigItem<T>[];
    tdStyle?: CSSProperties;
    waiting?: boolean;
};

function Cell<T>({ row, waiting, headerConfigItem, index, tdStyle }: CellProps<T>) {
    const {
        textAlign,
        tdStyle: columnTdStyle,
        getValue,
        formatValue = identity,
    } = headerConfigItem;

    const formattedValue = useMemo(() => {
        return formatValue(getValue(row, index));
    }, [formatValue, getValue, index, row]);

    return (
        <Td key={headerConfigItem.key ?? index} style={{ textAlign, ...tdStyle, ...columnTdStyle }}>
            <span
                style={{
                    visibility: waiting ? 'hidden' : 'visible',
                }}
            >
                {formattedValue}
            </span>
        </Td>
    );
}

type CellProps<T> = {
    row: T;
    index: number;

    // This will determine which column/cell to use from the row.
    headerConfigItem: HeaderConfigItem<T>;

    textAlign?: CSSProperties['textAlign'];
    waiting?: boolean;
    // Default Td style for all cells.
    tdStyle?: CSSProperties;
};
