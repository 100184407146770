import {
    bathIcon,
    faClinicMedical,
    faComment,
    faConciergeBell,
    faDizzy,
    faFireExtinguisher,
    faPills,
    faStreetView,
    faTasks,
    faTired,
    frownIcon,
    personCarryIcon,
    restroomIcon,
    trashIcon,
    tshirtIcon,
    tvIcon,
    userCircleIcon,
    wheelChairIcon,
} from '../styles/fontLibrary';
import { FlipProp, IconProp } from '@fortawesome/fontawesome-svg-core';
import { faUserMd } from '@fortawesome/pro-regular-svg-icons';
import {
    ContactLevel,
    ContactLevelLabel,
    DiseaseCase,
    Gender,
    LocationStatus,
    PersonType,
    personTypes,
} from './types';
import { blue5, green, red } from '../styles/theme';
import { keyBy, mapKeys, sortBy } from 'lodash-es';
import {
    faBatteryQuarter,
    faClipboardCheck,
    faCommentTimes,
    faCube,
    faDoorClosed,
    faDoorOpen,
    faHeadSideMask,
    faKey,
    faMapMarkerAlt,
    faMapMarkerExclamation,
    faMedkit,
    faPortalEnter,
    faPortalExit,
    faQuestionCircle,
    faSensorFire,
    faServer,
    faShieldAlt,
    faSignalStream,
    faStopwatch,
    faThumbsDown,
    faThumbsUp,
    faTimes,
    faUsers,
    faVirus,
    faWatchFitness,
    faWifi,
    faWindowFrame,
    faWindowFrameOpen,
} from '@fortawesome/pro-light-svg-icons';
import pullStationImage from '../assets/img/alarmIcons/pullstation.png';
import wallStationImage from '../assets/img/alarmIcons/wallstation.png';

export type MyIcon =
    // URL to an image.
    | string
    // FontAwesomeIcon.
    | (IconProp & { type?: never })
    // FontAwesomeIcon with customizations.
    | {
          type: 'customizedFontAwesomeIcon';
          icon: IconProp;
          props: { flip?: FlipProp };
      };

type IconAndName = {
    icon: MyIcon;
    name: string;
};

type KeyAndName<K> = { key: K; name: string };

export type IconNameKey<K> = IconAndName & { key: K };

const dispositionMap: { [key: string]: IconAndName } = {
    bathing: {
        name: 'Bathing',
        icon: bathIcon,
    },
    dizzy: {
        name: 'Dizzy',
        icon: faDizzy,
    },
    fall: {
        name: 'Fall',
        icon: faStreetView,
    },
    'getting dressed': {
        name: 'Getting Dressed',
        icon: tshirtIcon,
    },
    'in pain': {
        name: 'In Pain',
        icon: faTired,
    },
    'item retrieval': {
        name: 'Item Retrieval',
        icon: personCarryIcon,
    },
    medication: {
        name: 'Medication',
        icon: faPills,
    },
    'nurse attention': {
        name: 'Nurse attention',
        icon: faClinicMedical,
    },
    'observed on floor': {
        name: 'Observed on Floor',
        icon: faStreetView,
    },
    other: {
        name: 'Other',
        icon: faQuestionCircle,
    },
    question: {
        name: 'Question',
        icon: faQuestionCircle,
    },
    restroom: {
        name: 'Restroom',
        icon: restroomIcon,
    },
    'room service': {
        name: 'Room Service',
        icon: faConciergeBell,
    },
    'smoke or fire': {
        name: 'Smoke or Fire',
        icon: faFireExtinguisher,
    },
    'social request': {
        name: 'Social Request',
        icon: faComment,
    },
    'task request': {
        name: 'Task Request',
        icon: faTasks,
    },
    technology: {
        name: 'Technology',
        icon: tvIcon,
    },
    transfer: {
        name: 'Transfer',
        icon: wheelChairIcon,
    },
    trash: {
        name: 'Trash',
        icon: trashIcon,
    },
    'not feeling well': {
        name: 'Not Feeling Well',
        icon: frownIcon,
    },
    'witnessed fall': {
        name: 'Witnessed Fall',
        icon: faStreetView,
    },
    default: {
        name: 'Unknown',
        icon: faQuestionCircle,
    },
};

const alarmTypeIcons: IconAndName[] = [
    { name: 'Pendant', icon: faWatchFitness },
    { name: 'Wall station', icon: wallStationImage },
    { name: 'Pull station', icon: pullStationImage },
    { name: 'Smoke detector', icon: faSensorFire },
    { name: 'Assurance missed', icon: faTimes },
    { name: 'Assurance reminder', icon: faStopwatch },
    { name: 'Quarantine', icon: faHeadSideMask },
    { name: 'Entered location', icon: faPortalEnter },
    {
        name: 'Exited location',
        icon: {
            type: 'customizedFontAwesomeIcon',
            icon: faPortalExit,
            props: { flip: 'horizontal' },
        },
    },
    { name: 'LMP violation', icon: faShieldAlt },
    { name: 'Unexpected movement', icon: faThumbsDown },
    { name: 'Expected movement', icon: faThumbsUp },
    { name: 'Quarantine violation', icon: faVirus },
    { name: 'Occupancy exceeded', icon: faUsers },
    { name: 'Door opened', icon: faDoorOpen },
    { name: 'Door closed', icon: faDoorClosed },
    { name: 'Window opened', icon: faWindowFrameOpen },
    { name: 'Window closed', icon: faWindowFrame },
    { name: 'Network', icon: faWifi },
    { name: 'Power', icon: faBatteryQuarter },
    { name: 'Supervision', icon: faCommentTimes },
    { name: 'Unknown', icon: faQuestionCircle },
];

export const alarmDomainIcons: IconAndName[] = [
    { name: 'Emergency call', icon: faMedkit },
    { name: 'Assurance', icon: faClipboardCheck },
    { name: 'Location violation', icon: faMapMarkerExclamation },
    { name: 'Access violation', icon: faKey },
    { name: 'Motion', icon: faSignalStream },
    { name: 'System', icon: faServer },
];

function normalizeKey(key: string) {
    return key.toLowerCase();
}

// Creates a mapping between normalized names as keys, and the names and icon as values.
function createIconMap(iconAndNames: IconAndName[]): Record<string, IconAndName> {
    return mapKeys(keyBy(iconAndNames, 'name'), value => normalizeKey(value.name));
}

const alarmTypeIconMap = createIconMap(alarmTypeIcons);

const alarmDomainIconMap = createIconMap(alarmDomainIcons);

export type subjectType = 'location' | 'asset' | 'person';

export const alarmTypes: IconNameKey<string>[] = sortBy(
    Object.values(alarmTypeIconMap).map(alarmType => ({ key: alarmType.name, ...alarmType })),
    'name'
);

export type PersonInfo = IconNameKey<PersonType> & {
    namePlural: string;
    nameOptionalPlural: string;
};

export const genderInfoMap: Record<Gender, { name: string; key: Gender }> = {
    m: {
        key: 'm',
        name: 'Male',
    },
    f: {
        key: 'f',
        name: 'Female',
    },
    o: {
        key: 'o',
        name: 'Other',
    },
};

export const personTypeInfoMap: { [personType in PersonType]: PersonInfo } = {
    resident: {
        key: 'resident',
        name: 'Resident',
        namePlural: 'Residents',
        nameOptionalPlural: 'Resident(s)',
        icon: faUsers,
    },
    caregiver: {
        key: 'caregiver',
        name: 'Staff',
        namePlural: 'Staff',
        nameOptionalPlural: 'Staff',
        icon: faUserMd,
    },
};

export const personTypeInfos = personTypes.map(personType => personTypeInfoMap[personType]);

/**
 * Gets the disposition of the given type, handling unknowns.
 */
export function getDispositionByType(dispositionType: string): IconAndName {
    const disposition = dispositionMap[dispositionType];
    return { ...dispositionMap.default, ...disposition };
}

export function getAlarmTypeByKey(alarmType?: string): IconAndName {
    return {
        icon: (alarmTypeIconMap[normalizeKey(String(alarmType))] || alarmTypeIconMap.unknown).icon,
        name: alarmType ?? 'N/A',
    };
}

export function getAlarmDomainByKey(alarmDomain?: string): IconAndName {
    return (
        alarmDomainIconMap[normalizeKey(String(alarmDomain))] || {
            icon: faQuestionCircle,
            name: alarmDomain ?? 'Unknown',
        }
    );
}

export function getOwnerObjByType(type?: subjectType): IconAndName | null {
    switch (type) {
        case 'person':
            return { name: 'Person', icon: userCircleIcon };
        case 'location':
            return { name: 'Location', icon: faMapMarkerAlt };
        case 'asset':
            return { name: 'Asset', icon: faCube };
        default:
            return null;
    }
}

type ContactLevelInfo = { level: ContactLevel; label: ContactLevelLabel; color: string };
export const contactLevels: Array<ContactLevelInfo> = [
    {
        level: 1,
        label: 'Direct Contact',
        color: red,
    },
    {
        level: 2,
        label: '2nd Level Contact',
        color: 'yellow',
    },
    {
        level: 3,
        label: '3rd Level Contact',
        color: green,
    },
    {
        level: 4,
        label: '4th Level Contact',
        color: blue5,
    },
];
export const contactLevelInfoMap = keyBy(contactLevels, 'level') as Record<
    ContactLevel,
    ContactLevelInfo
>;

export const locationStatusInfoMap: Record<LocationStatus, KeyAndName<LocationStatus>> = {
    'on campus': { key: 'on campus', name: 'On Campus' },
    hospital: { key: 'hospital', name: 'Hospital' },
    rehab: { key: 'rehab', name: 'Rehab' },
    'transition suite': { key: 'transition suite', name: 'Transition Suite' },
    other: { key: 'other', name: 'Other' },
};

export const maxContactLevel: ContactLevel = 4;
export const embeddedContactTracingMaxLevel: ContactLevel = 2;

export const embeddedContactTracingLevels = contactLevels.filter(
    v => v.level <= embeddedContactTracingMaxLevel
);

export const contactLevelInfoByLevel = keyBy(contactLevels, 'level') as Record<
    ContactLevel,
    ContactLevelInfo
>;

export function newPersonTypeCounts() {
    return {
        total: 0,
        resident: 0,
        caregiver: 0,
        visitor: 0,
    };
}

export function isDiseaseCaseExposure(diseaseCase: DiseaseCase) {
    return diseaseCase.status === 'exposure';
}

export function isDiseaseCaseActive(diseaseCase: DiseaseCase) {
    return diseaseCase.status === 'active case';
}

export const PAGE_SIZE = 100;
