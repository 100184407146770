import React from 'react';
import pluralize from 'pluralize';
import { CasesOrExposed } from '../../values/types';
import ValueCard from '../ValueCard/ValueCard';
import { red } from '../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faVirus } from '@fortawesome/pro-regular-svg-icons';
import { isIE } from '../../utils/css';

function CurrentCasesExposuresBox({ amount, casesOrExposed }: Props) {
    const isCases = casesOrExposed === 'cases';
    const color = amount ? red : undefined;
    return (
        <ValueCard
            style={{ width: isIE ? '15rem' : undefined }}
            topContent={
                <>
                    <div style={{ color }}>
                        <span>
                            {isCases ? (
                                <FontAwesomeIcon icon={faVirus} />
                            ) : (
                                // TODO: show small virus icon on the top left to express exposures.
                                // https://treehousetechgroup.atlassian.net/10086a61-44b5-4516-9b11-e8e59723c333
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                            )}
                        </span>
                        <span style={{ marginLeft: '2rem' }}>{amount}</span>
                    </div>
                </>
            }
            bottomContent={
                <span style={{ color }}>
                    {isCases
                        ? `Current ${pluralize('Case', amount)}`
                        : `Current ${pluralize('Exposure', amount)}`}
                </span>
            }
        />
    );
}

interface Props {
    casesOrExposed: CasesOrExposed;
    amount: number;
}

export default CurrentCasesExposuresBox;
