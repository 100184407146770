import React, { useState, createContext, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash-es';

export const WINDOW_WIDTH_PHONE_LANDSCAPE = 576;
export const WINDOW_WIDTH_TABLET = 768;
export const WINDOW_WIDTH_SMALL_DESKTOP = 992;
export const WINDOW_WIDTH_MEDIUM_DESKTOP = 1200;

const Context = createContext({
    width: window.innerWidth,
});

/**
 * Hook for reading the width of the window.
 *
 * @return {{windowWidth: number, singleColumnLayout: boolean}}
 */
export function useResponsive() {
    return useContext(Context);
}

/**
 * Provider for keeping track of the screen width.
 *
 * @param children
 * @return {*}
 * @constructor
 */
function ResponsiveProvider({ children }) {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function calculateInnerWidth() {
            setWidth(window.innerWidth);
        }

        const throttledCalculateInnerWidth = throttle(calculateInnerWidth, 200);
        window.addEventListener('resize', throttledCalculateInnerWidth);
        return () => {
            window.removeEventListener('resize', throttledCalculateInnerWidth);
        };
    });

    return (
        <Context.Provider
            value={{
                windowWidth: width,
                singleColumnLayout: width < WINDOW_WIDTH_TABLET,
            }}
        >
            {children}
        </Context.Provider>
    );
}

ResponsiveProvider.propTypes = {
    children: PropTypes.node,
};

export default ResponsiveProvider;
