//noinspection DuplicatedCode

import {
    faAnalytics,
    faArrowRight,
    faCheckSquare,
    faList,
    faProjectDiagram as fasProjectDiagram,
    faSearch,
    faTimes,
    faUserCircle,
} from '@fortawesome/pro-solid-svg-icons';

import {
    faBath,
    faCalendarAlt,
    faCheckCircle,
    faChevronCircleDown,
    faChevronCircleUp,
    faChevronDown,
    faCircle,
    faClinicMedical,
    faClipboardCheck,
    faComment,
    faConciergeBell,
    faDizzy,
    faDoorOpen,
    faFileDownload,
    faFilter,
    faFireExtinguisher,
    faFireSmoke,
    faFrown,
    faMapMarkerAlt,
    faMedkit,
    faPersonCarry,
    faPills,
    faProjectDiagram,
    faQuestionCircle,
    faRestroom,
    faServer,
    faSortAmountDown,
    faStreetView,
    faTasks,
    faTimesCircle,
    faTired,
    faTrashAlt,
    faTshirt,
    faTvRetro,
    faWheelchair,
    faWindowFrameOpen,
} from '@fortawesome/pro-regular-svg-icons';

import { faAnalytics as falAnalytics } from '@fortawesome/pro-light-svg-icons';

export const calendarIcon = faCalendarAlt;
export const filterIcon = faFilter;
export const fileDownloadIcon = faFileDownload;
export const searchIcon = faSearch;
export const analyticsIcon = faAnalytics;
export const inactiveAnalyticsIcon = falAnalytics;
export const listIcon = faList;
export const wheelChairIcon = faWheelchair;
export const bathIcon = faBath;
export const trashIcon = faTrashAlt;
export const tvIcon = faTvRetro;
export const personCarryIcon = faPersonCarry;
export const arrowRightIcon = faArrowRight;
export const medkitIcon = faMedkit;
export const fireSmokeIcon = faFireSmoke;
export const serverIcon = faServer;
export const userCircleIcon = faUserCircle;
export const clipboardCheckIcon = faClipboardCheck;
export const tshirtIcon = faTshirt;
export const frownIcon = faFrown;
export const questionCircleIcon = faQuestionCircle;
export const restroomIcon = faRestroom;
export const timesIcon = faTimes;
export const timesCircleIcon = faTimesCircle;
export const chevronDownIcon = faChevronDown;
export const checkSquareIcon = faCheckSquare;
export const circleIcon = faCircle;
export const checkCircleIcon = faCheckCircle;
export const sortAmountDownIcon = faSortAmountDown;
export const chevronCircleDownIcon = faChevronCircleDown;
export const chevronCircleUpIcon = faChevronCircleUp;
export const projectDiagramIcon = faProjectDiagram;
export const projectDiagramSolidIcon = fasProjectDiagram;
export {
    faClinicMedical,
    faComment,
    faConciergeBell,
    faDizzy,
    faDoorOpen,
    faFireExtinguisher,
    faMapMarkerAlt,
    faPills,
    faStreetView,
    faTasks,
    faTired,
    faWindowFrameOpen,
};
