import React, { useMemo } from 'react';
import ResizeDetector from 'react-resize-detector';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PeakShiftsAndHours from '../../components/PeakShiftsAndHours/PeakShiftsAndHours';
import styled from '@emotion/styled';
import theme, {
    blue2,
    blue3,
    blue4,
    green,
    lime,
    orange,
    purple,
    red,
    redOrange,
    yellow,
} from '../../styles/theme';
import AlarmTotals from '../../components/AlarmTotals/AlarmTotals';
import useEffectUnlessFiltersSame from '../../components/hooks/useEffectUnlessFiltersSame';
import { useLayoutCustomization } from '../../context/LayoutContext';
import MostCommonServices from '../../components/Dashboard/MostCommonServices';
import MostFrequentCallers from '../../components/Dashboard/MostFrequentCallers';
import AlarmActivityByHour from '../../components/AlarmActivityByHour/AlarmActivityByHour';
import DashboardPieChart from '../../components/DashboardPieChart/DashboardPieChart';
import { H2 } from '../../styles/shared';
import HomeCircleGauge from './HomeCircleGauge';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import useAlarmsGetDownloadUrl from '../../components/hooks/useAlarmsGetDownloadUrl';

const SpinnerOverlay = styled.div`
    position: absolute;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    text-align: center;
`;

const typePieColors = [
    blue2,
    purple,
    green,
    blue3,
    '#E4DB00',
    red,
    lime,
    orange,
    blue2,
    yellow,
    'pink',
    redOrange,
];

const residentVsSystemColorAndLabelMap = {
    person: {
        name: 'Resident-Initiated',
        color: blue2,
    },
    location: {
        name: 'Location-generated',
        color: blue4,
    },
    asset: {
        name: 'System',
        color: green,
    },
    unknown: {
        name: 'Unknown',
        color: 'black',
    },
};

const Home = () => {
    // This was only here for consoling. Depending on how we handle the endpoint,
    // We probably don't need this
    // const {data} = useSelector(state => state.reports);
    const dashboard = useSelector((state: any) => state.dashboard);
    const { data: dashboardData, error } = dashboard;
    const barData = dashboardData?.alarmBarChartData || [];
    const residentVsSystemData = dashboardData?.residentVsSystem || [];
    const dispatch = useDispatch();

    useEffectUnlessFiltersSame(() => {
        dispatch(actions.getAlarmsReport());
        dispatch(actions.getDashboard());
    });

    const getDownloadUrl = useAlarmsGetDownloadUrl();
    useLayoutCustomization({ filters: 'alarms', getDownloadUrl });

    const alarmTypesData = useMemo(() => {
        const allKeys = Object.keys(dashboardData?.alarmCountsByType || []);
        const alarmCounts = dashboardData?.alarmCountsByType;
        return allKeys
            .filter(k => alarmCounts[k])
            .sort((a, b) => alarmCounts[b] - alarmCounts[a])
            .map((k, i) => {
                return {
                    name: k,
                    count: dashboardData.alarmCountsByType[k],
                    color: typePieColors[i % typePieColors.length],
                };
            });
    }, [dashboardData]);

    if (error) {
        throw error;
    }

    return (
        <ResizeDetector
            handleWidth
            render={({ width = Infinity }) => (
                <div style={{ position: 'relative', padding: '2.75rem' }}>
                    {dashboardData && (
                        <>
                            <div style={{ display: width >= 1200 ? 'flex' : 'block' }}>
                                <div
                                    style={{
                                        flex: 512,
                                        marginRight: width >= 1200 ? '3.2rem' : 0,
                                        marginBottom: '2rem',
                                    }}
                                >
                                    <AlarmTotals />
                                </div>
                                <div style={{ flex: 1096, marginBottom: '2rem' }}>
                                    <MostCommonServices
                                        data={dashboardData?.mostCommonDispositions}
                                    />
                                    <div style={{ height: '2.5rem' }} />
                                    <MostFrequentCallers
                                        data={dashboardData?.mostFrequentCallers}
                                    />
                                </div>
                            </div>
                            <ResizeDetector
                                handleWidth
                                render={({ width }) => (
                                    <AlarmActivityByHour data={barData} width={width} />
                                )}
                            />
                            <PeakShiftsAndHours width={width} />
                            <div style={{ height: '3.5rem' }} />
                            {/*/ Pie charts */}
                            <div
                                style={{
                                    display: width >= 1000 ? 'flex' : 'block',
                                    textAlign: 'center',
                                    overflow: 'hidden',
                                }}
                            >
                                <div
                                    style={{
                                        flex: 5,
                                        overflow: 'hidden',
                                        marginBottom: '4rem',
                                        paddingTop: '1rem',
                                    }}
                                >
                                    <H2>Alarm Types</H2>
                                    <DashboardPieChart data={alarmTypesData} />
                                </div>
                                <div
                                    style={{
                                        flex: 6,
                                        overflow: 'hidden',
                                        marginBottom: '4rem',
                                        padding: width >= 1000 ? '0 2rem' : 0,
                                    }}
                                >
                                    <H2>Response Times</H2>
                                    <HomeCircleGauge
                                        data={dashboardData?.alarmCountsByResponseTime || []}
                                    />
                                </div>
                                <div style={{ flex: 5, overflow: 'hidden', marginBottom: '4rem' }}>
                                    <H2>Resident vs System</H2>
                                    <DashboardPieChart
                                        data={residentVsSystemData
                                            .sort((a, b) => b.count - a.count)
                                            .map(({ group, count }) => ({
                                                ...residentVsSystemColorAndLabelMap[group],
                                                count,
                                            }))}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {dashboard.loading && (
                        <SpinnerOverlay>
                            <FontAwesomeIcon
                                icon={faCircleNotch}
                                color={theme.gray700}
                                size="5x"
                                spin
                            />
                        </SpinnerOverlay>
                    )}
                </div>
            )}
        />
    );
};

export default Home;
