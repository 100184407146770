import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { URI_DISEASES } from '../../axios';
import { Disease } from '../../values/types';
import { stringify } from 'qs';

const slice = createFetchSlice('reportsDiseases', {}, getInitialState<Disease[]>());

export function loadReportsReportsDiseases(facilityKeys: number[]) {
    const query: { facilityKeys?: string } = {};
    if (facilityKeys) {
        query.facilityKeys = facilityKeys.join(',');
    }
    const queryString = stringify(query, {
        addQueryPrefix: true,
    });
    return slice.load({
        uri: URI_DISEASES + queryString,
        stealth: true,
    });
}

const reportsDiseases = slice.reducer;
const reportsDiseasesSelector = slice.selector;
const reportsDiseasesReset = slice.actions.reset;

export { reportsDiseasesSelector, reportsDiseasesReset };

export const reportsDiseasesDataSelector = createSelector(reportsDiseasesSelector, v => v.data);

export default reportsDiseases;
