import React, { HTMLAttributes, ReactNode } from 'react';
import ResizeDetector from 'react-resize-detector';

// Default cutoff width. Ideal if there are two flex items.
export const defaultCutOffWidth = 800;

/**
 * <div> with "display: flex" if the width is wide enough.
 * This is for displaying items (e.g. charts) side by side.
 *
 * @param ifWidthAtLeast
 * @param children
 * @param restProps
 * @constructor
 */
export default function ConditionalFlex({ ifWidthAtLeast, children, ...restProps }: Props) {
    return (
        <ResizeDetector handleWidth>
            {({ width }: { width: number }) => (
                <div
                    style={{
                        display: (ifWidthAtLeast ?? defaultCutOffWidth) <= width ? 'flex' : 'block',
                    }}
                    {...restProps}
                >
                    {children}
                </div>
            )}
        </ResizeDetector>
    );
}

type Props = HTMLAttributes<HTMLDivElement> & {
    ifWidthAtLeast?: number;
    children?: ReactNode;
};
