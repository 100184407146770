import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import TopMenu from './TopMenu';
import { LayoutProvider } from '../../context/LayoutContext';
import { facilitiesDataSelector, loadFacilities } from '../../store/reducers/facilities';
import { useAppDispatch, useSelector } from '../../store';
import LayoutNavAndMain from './LayoutNavAndMain';
import { FullPageBigSpinner } from '../BigSpinner/BigSpinner';
import { KeycloakProfile } from 'keycloak-js';
import { keyBy } from 'lodash-es';
import axiosInstance from '../../axios';
import { Facility } from '../../values/types';
import { ExpectedErrorContainer } from '../../AppBoundary';
import NoFacilityAccessWarning from '../ErrorOnPage/NoFacilityAccessWarning';
import { loadAllDiseaseCases, resetAllDiseaseCases } from '../../store/actions/global';
import { loadPersons, personsReset } from '../../store/reducers/persons';
import { loadLocations, locationsReset } from '../../store/reducers/locations';
import {
    facilityConfigLoadedSelector,
    facilityConfigReplace,
    facilityConfigReset,
    facilityConfigSelector,
} from '../../store/reducers/facilityConfig';
import { useTopRouteAndGroupMatch } from '../../router/routeConfig';
import NoFacilitiesFoundWarning from '../ErrorOnPage/NoFacilitiesFoundWarning';

const originalTitle = document.title;

const Layout = (props: Props) => {
    const {
        facilitiesData,
        setCurrentFacilityKey,
        currentFacility,
        keycloakAttributes,
    } = useLayout(props);
    // If we don't check for this, the user may be prematurely redirected away from a route they
    // otherwise would have access to if the facility's config had a chance to load.
    const facilityConfigLoaded = useSelector(facilityConfigLoadedSelector);

    // Manage the <title>.
    const routeMatch = useTopRouteAndGroupMatch();
    useEffect(() => {
        if (!routeMatch || !routeMatch.routeLeafNode.component) {
            document.title = originalTitle;
            return;
        }
        document.title = `${originalTitle} - ${routeMatch.routeLeafNode.label}`;
    }, [routeMatch]);

    return (
        <LayoutProvider>
            <div
                style={{
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                }}
            >
                <TopMenu
                    facilities={facilitiesData || []}
                    currentFacilityKey={currentFacility?.id}
                    setCurrentFacilityKey={setCurrentFacilityKey}
                />
                {facilitiesData?.length === 0 ? (
                    <NoFacilitiesFoundWarning keycloakAttributes={keycloakAttributes} />
                ) : !facilitiesData || !facilityConfigLoaded ? (
                    <FullPageBigSpinner />
                ) : facilitiesData?.length ? (
                    <LayoutNavAndMain />
                ) : (
                    <ExpectedErrorContainer>
                        <NoFacilityAccessWarning />
                    </ExpectedErrorContainer>
                )}
            </div>
        </LayoutProvider>
    );
};

function useLayout({ keycloakProfile }: Props) {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(loadFacilities());
    }, [dispatch]);
    const facilitiesData = useSelector(facilitiesDataSelector);
    const facilities = facilitiesData ?? [];
    const firstFacilityKey = facilities[0]?.id;
    const [_currentFacilityKey, _setCurrentFacilityKey] = useState<number | undefined>();
    const setCurrentFacilityKey = useCallback((facilityKey: number) => {
        _setCurrentFacilityKey(facilityKey);
        localStorage.facilityKey = facilityKey;
    }, []);
    const facilityByKeyMap: Record<string, Facility | undefined> = useMemo(() => {
        return keyBy(facilities, 'id');
    }, [facilities]);
    const facilityConfig = useSelector(facilityConfigSelector);

    const currentFacility = useMemo(() => {
        return (
            // Explicitly selected facility.
            facilityByKeyMap[_currentFacilityKey ?? ''] ??
            // Facility last saved in localStorage.
            facilityByKeyMap[localStorage.facilityKey] ??
            // First Facility in the list.
            facilityByKeyMap[firstFacilityKey]
        );
    }, [_currentFacilityKey, facilityByKeyMap, firstFacilityKey]);
    const facilityId = currentFacility?.id;
    const centralOfficeUuid = currentFacility?.centralOffice?.uuid;
    useEffect(() => {
        if (!facilityId) {
            return;
        }
        // (Re)-load these when the current/selected facility changes.
        dispatch(loadLocations());
        dispatch(loadPersons());
        if (centralOfficeUuid && facilityConfig.isCaseManagementEnabled) {
            dispatch(loadAllDiseaseCases());
        }
        return () => {
            dispatch(locationsReset());
            dispatch(personsReset());
            dispatch(resetAllDiseaseCases());
        };
    }, [centralOfficeUuid, dispatch, facilityConfig.isCaseManagementEnabled, facilityId]);

    useEffect(() => {
        if (!currentFacility) {
            dispatch(facilityConfigReset());
            return;
        }
        dispatch(
            facilityConfigReplace({
                isStandalone: currentFacility.isStandalone,
                isAlarmsEnabled: !!currentFacility.isAlarmsEnabled,
                isContactTracingEnabled: !!currentFacility.isContactTracingEnabled,
                isCaseManagementEnabled: !!currentFacility.isCaseManagementEnabled,
                isEngage360Enabled: !!currentFacility.isEngage360Enabled,
                facilityKey: currentFacility.id,
                hasCentralOffice: !!currentFacility.centralOffice,
                isCentralOfficeAdmin: !!keycloakProfile.attributes.centralOfficeUuid?.length,
            })
        );
    }, [currentFacility, dispatch, keycloakProfile.attributes.centralOfficeUuid]);

    useLayoutEffect(() => {
        if (facilityId) {
            // Indicate to the API to use this as the selected facility key.
            axiosInstance.defaults.headers.common['X-Selected-Facility-Key'] = facilityId;
        }
    }, [facilityId]);

    return {
        keycloakAttributes: keycloakProfile.attributes,
        facilitiesData,
        setCurrentFacilityKey,
        currentFacility,
    };
}

export type KeycloakAttributes = {
    centralOfficeUuid?: string[];
    communityId?: string[];
    engageCommunityId?: string[];
};

interface Props {
    keycloakProfile: KeycloakProfile & {
        attributes: KeycloakAttributes;
    };
}

export default Layout;
