import { Tooltip, TooltipProps } from 'recharts';
import { boxStyle } from '../../styles/shared';
import darkTheme from '../../styles/theme';
import React, { SVGProps } from 'react';

/**
 * Creates a reselect tooltip with some defaults that goes well with the theme of the application.
 */
export function createTooltip({ cursor, ...restProps }: Partial<TooltipProps<any, any>> = {}) {
    const _cursor = (() => {
        const _cursor: SVGProps<SVGElement> = {
            stroke: darkTheme.backgroundSecondary,
            fill: darkTheme.backgroundSecondary,
        };

        if (!cursor) {
            return _cursor;
        }

        if (typeof cursor === 'boolean' || React.isValidElement(cursor)) {
            return cursor;
        }

        return { ..._cursor, ...cursor };
    })();

    return (
        <Tooltip
            contentStyle={{ ...boxStyle, padding: '1rem', lineHeight: 1.4, color: 'white' }}
            labelStyle={{ fontWeight: 'bold', color: 'white' }}
            cursor={_cursor}
            {...restProps}
        />
    );
}
