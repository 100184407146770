import React, { useCallback, useMemo, useState } from 'react';
import theme, { red } from '../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faProjectDiagram } from '@fortawesome/pro-regular-svg-icons';
import { contactLevelInfoByLevel } from '../../values/appConfig';
import DirectContactsTable from './DirectContactsTable';
import { DiseaseCaseSingle, DiseaseSingle } from '../../values/types';
import EmbeddedContactTracing from './EmbeddedContactTracing';
import { loadAllDiseaseCases } from '../../store/actions/global';
import { loadDiseaseCasesFiltered } from '../../store/reducers/diseaseCasesFiltered';
import { useAppDispatch, useSelector } from '../../store';
import ExistingDirectContactsTables from './ExistingDirectContactsTables';
import ResultModal from '../Modal/ResultModal';
import { PrimaryButton } from '../Button/Button';
import { facilityConfigSelector } from '../../store/reducers/facilityConfig';

function ProtocolContactTracing({ disease, diseaseCase, markContactTracingAsCompleted }: Props) {
    const { isContactTracingEnabled } = useSelector(facilityConfigSelector);
    const dispatch = useAppDispatch();

    // Whether "designate exposed" has been performed during this component's lifetime.
    const [designatedExposed, setDesignatedExposed] = useState(false);

    const [showDesignatedExposedModalWithCount, setShowDesignatedExposedModalWithCount] = useState(
        null as null | number
    );

    const rootDiseaseCaseUuid = useMemo(() => {
        return diseaseCase ? diseaseCase.rootContactDiseaseCaseUuid ?? diseaseCase.uuid : '';
    }, [diseaseCase]);

    const onAfterCreateExposures = useCallback(() => {
        // Refresh the list of disease cases and the direct contacts too.
        dispatch(loadAllDiseaseCases());
        dispatch(loadDiseaseCasesFiltered('rootContactDiseaseCaseUuid', rootDiseaseCaseUuid));
    }, [dispatch, rootDiseaseCaseUuid]);

    const onDesignateExposuresResultModalClose = () => setShowDesignatedExposedModalWithCount(null);
    return (
        <div>
            <span style={{ color: red, fontSize: '1.25rem' }}>
                <FontAwesomeIcon icon={faProjectDiagram} style={{ marginRight: '1rem' }} />
                <strong style={{ opacity: 0.8 }}>Contact Tracing</strong>{' '}
            </span>
            <span style={{ color: theme.primaryLight, marginLeft: '1rem' }}>{`>${
                disease.minOverlapMinutes
            } min overlap / ${contactLevelInfoByLevel[disease.maxContactLevel].label}`}</span>
            <div style={{ height: '1rem' }} />

            {diseaseCase.contactTracingRequired ? (
                <>
                    <ExistingDirectContactsTables diseaseCase={diseaseCase} />

                    {isContactTracingEnabled && !designatedExposed && (
                        <EmbeddedContactTracing
                            disease={disease}
                            diseaseCase={diseaseCase}
                            onAfterDesignatedExposed={numberOfExposures => {
                                setShowDesignatedExposedModalWithCount(numberOfExposures);
                                onAfterCreateExposures();
                                setDesignatedExposed(true);
                                markContactTracingAsCompleted();
                            }}
                        />
                    )}
                    {!isContactTracingEnabled && (
                        <DirectContactsTable
                            diseaseCase={diseaseCase}
                            rootDiseaseCaseUuid={rootDiseaseCaseUuid}
                            onAfterCreateExposures={onAfterCreateExposures}
                            minOverlapMinutes={disease.minOverlapMinutes}
                        />
                    )}
                </>
            ) : (
                "This contact level of this exposure is at the disease's maximum contact level."
            )}

            <ResultModal
                isOpen={showDesignatedExposedModalWithCount !== null}
                onRequestClose={onDesignateExposuresResultModalClose}
                buttons={
                    <PrimaryButton onClick={onDesignateExposuresResultModalClose}>OK</PrimaryButton>
                }
                success
                titleText={`${showDesignatedExposedModalWithCount} Resident(s) designated exposed.`}
                text="Isolation and quarantine will now show as pending for those exposed."
            />
        </div>
    );
}

interface Props {
    disease: DiseaseSingle;
    diseaseCase: DiseaseCaseSingle;
    markContactTracingAsCompleted: () => void;
}

export default ProtocolContactTracing;
