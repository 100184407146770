import React, { useMemo } from 'react';
import AppBoundary from '../../AppBoundary';
import LeftMenu from './LeftMenu/LeftMenu';
import SearchAndFiltersBar from './SearchAndFiltersBar/SearchAndFiltersBar';
import ContentRoutes from '../../ContentRoutes';
import { useResponsive } from '../ResponsiveProvider/ResponsiveProvider';
import styled from '@emotion/styled';
import { css } from '../../utils/ide';
import { useTopRouteConfig } from '../../router/routeConfig';

const Container = styled.div(
    () => css`
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        flex: 1;
        overflow-x: hidden;
    `
);

function LayoutNavAndMain() {
    const windowSize = useResponsive();
    const layoutShouldBeInSingleColumn = windowSize.singleColumnLayout;

    const routeConfig = useTopRouteConfig();

    const routeConfigFlat = useMemo(() => {
        return routeConfig.map(item => (item.isGroup ? item.items : [item])).flat();
    }, [routeConfig]);

    return (
        <div
            style={{
                display: layoutShouldBeInSingleColumn ? 'block' : 'flex',
                justifyContent: 'stretch',
                flex: 1,
            }}
        >
            <AppBoundary>
                <LeftMenu routeConfig={routeConfig} fullWidth={layoutShouldBeInSingleColumn} />
                <Container>
                    <SearchAndFiltersBar />
                    <ContentRoutes routeConfig={routeConfigFlat} />
                </Container>
            </AppBoundary>
        </div>
    );
}

export default LayoutNavAndMain;
