import React, { useMemo } from 'react';
import { Box } from '../../styles/shared';
import styled from '@emotion/styled';
import theme from '../../styles/theme';
import { formatStatisticNumber, NumberFormat } from '../../utils/numbers';
import { Link, LinkProps } from 'react-router-dom';

const Container = styled(Box)`
    padding: 2rem 0;
`;

const Title = styled.h2`
    padding: 0 2rem 2rem;
    margin: 0;
    font-size: 1.75rem;
`;

const StyledItem = styled.div`
    flex: 1;
    text-align: center;
    font-weight: bold;
    padding: 0 2rem;

    :not(:last-of-type) {
        border-right: 0.2rem solid ${theme.primary};
    }
`;

const ValuePart = styled.div`
    font-size: 3.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
`;

const LabelPart = styled.div`
    opacity: 0.56;
`;

function Item({ value, label, dataType }: StatItem) {
    const formattedValue = useMemo(() => {
        return formatStatisticNumber(value, dataType);
    }, [dataType, value]);

    return (
        <StyledItem>
            <ValuePart>{formattedValue}</ValuePart>
            <LabelPart>{label}</LabelPart>
        </StyledItem>
    );
}

/**
 * Box with multiple columns with statistic numbers and labels for Engage 360.
 */
export default function EngageStatisticsBox({ stats, title, to }: Props) {
    const box = (
        <Container>
            <Title>{title}</Title>
            <div style={{ display: 'flex', overflowX: 'auto', overflowY: 'hidden' }}>
                {stats.map((item, index) => (
                    <Item {...item} key={index} />
                ))}
            </div>
        </Container>
    );

    return to ? <Link to={to}>{box}</Link> : box;
}

type StatItem = {
    value: number | null;
    label: string;
    dataType?: NumberFormat;
};

interface Props {
    title: string;
    stats: Array<StatItem>;
    to?: LinkProps['to'];
}
