import React, { useMemo } from 'react';
import { ContactTracingTitle, ContinueButton, TitledBox } from './common';
import styled from '@emotion/styled';
import { CheckboxWithLabel } from '../../components/CheckboxWithLabel/CheckboxWithLabel';
import Grid from '../../components/Grid/Grid';
import Button from '../../components/Button/Button';
import TrailingContacts from './ApplyQuickFilters/TrailingContacts';
import { useHistory } from 'react-router';
import { routeForChooseContactLevel } from './contactTracingRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { IncludableContact } from '../../values/types';
import { without } from 'lodash-es';
import {
    setContactTracingQuickFiltersMinimumOverlap,
    setContactTracingQuickFiltersToInclude,
} from '../../store/actions/contactTracing';
import { WINDOW_WIDTH_PHONE_LANDSCAPE } from '../../components/ResponsiveProvider/ResponsiveProvider';

const FlexContainer = styled.div`
    display: flex;
    width: 100%;

    > div:not(:first-of-type) {
        margin-left: 2rem;
    }

    @media screen and (max-width: ${WINDOW_WIDTH_PHONE_LANDSCAPE}px) {
        display: block;

    > div:not(:first-of-type) {
        margin-left: 0;
        margin-top: 2rem;
    }
`;

const checkboxConfig: Array<{ value: IncludableContact; label: string }> = [
    {
        value: 0,
        label: 'Residents',
    },
    {
        value: 1,
        label: 'Staff',
    },
];

const overlapButtonOptionValues = [
    { value: 0, label: '0 min' },
    { value: 1, label: '1 min' },
    { value: 3, label: '3 min' },
    { value: 5, label: '5 min' },
    { value: 10, label: '10 min' },
    { value: 20, label: '20 min' },
    { value: 30, label: '30 min' },
    { value: 45, label: '45 min' },
    { value: 60, label: '1 hr' },
];

const OptionButtonGrid = styled(Grid)``;

function ApplyQuickFiltersPage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const quickFilters = useSelector((state: RootState) => state.contactTracing.quickFilters);

    const checkboxes = useMemo(() => {
        return checkboxConfig.map(checkbox => {
            const checkeds = quickFilters.toInclude;
            const value = checkbox.value;
            const checked = checkeds.includes(value);
            return {
                ...checkbox,
                checked,
                // Toggle.
                onClick: () =>
                    dispatch(
                        setContactTracingQuickFiltersToInclude(
                            checked ? without(checkeds, value) : [...checkeds, value]
                        )
                    ),
            };
        });
    }, [dispatch, quickFilters.toInclude]);

    const nextButtonActive = useMemo(() => {
        return quickFilters.toInclude.length && quickFilters.minimumTimeOverlap !== null;
    }, [quickFilters.minimumTimeOverlap, quickFilters.toInclude.length]);

    return (
        <div>
            <ContactTracingTitle>Apply quick filters</ContactTracingTitle>
            <div>These can be adjusted later as needed</div>
            <div style={{ height: '2rem' }} />
            <FlexContainer>
                <TitledBox title="Contacts to Include" style={{ flex: 2 }}>
                    <div>
                        {checkboxes.map(({ label, value, checked, onClick }) => (
                            <div key={value} style={{ margin: '2rem' }}>
                                <CheckboxWithLabel
                                    dataValue={value}
                                    checked={checked}
                                    onChange={onClick}
                                    name={label}
                                    label={label}
                                />
                            </div>
                        ))}
                    </div>
                </TitledBox>
                <TitledBox title="Minimum Dwell Time Overlap" style={{ flex: 3 }}>
                    <OptionButtonGrid columns={3} gridGap="1rem">
                        {overlapButtonOptionValues.map(({ value, label }) => {
                            return (
                                <Button
                                    key={value}
                                    onClick={() =>
                                        dispatch(setContactTracingQuickFiltersMinimumOverlap(value))
                                    }
                                    flavor={
                                        value === quickFilters.minimumTimeOverlap
                                            ? 'highlighted'
                                            : void 0
                                    }
                                >
                                    {label}
                                </Button>
                            );
                        })}
                    </OptionButtonGrid>
                </TitledBox>
                <TrailingContacts style={{ flex: 2 }} />
            </FlexContainer>
            <div style={{ height: '2rem' }} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ContinueButton
                    onClick={() => history.push(routeForChooseContactLevel.path)}
                    disabled={!nextButtonActive}
                />
            </div>
        </div>
    );
}

export default ApplyQuickFiltersPage;
