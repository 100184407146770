export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const GET_ALARMS_DATA_START = 'GET_ALARMS_DATA_START';
export const GET_ALARMS_DATA_SUCCESS = 'GET_ALARMS_DATA_SUCCESS';
export const GET_ALARMS_DATA_FAIL = 'GET_ALARMS_DATA_FAIL';

export const GET_ALARMS_START = 'GET_ALARMS_START';
export const GET_ALARMS_SUCCESS = 'GET_ALARMS_SUCCESS';
export const GET_ALARMS_FAIL = 'GET_ALARMS_FAIL';

export const GET_ALARMS_REPORT_START = 'GET_ALARMS_REPORT_START';
export const GET_ALARMS_REPORT_SUCCESS = 'GET_ALARMS_REPORT_SUCCESS';
export const GET_ALARMS_REPORT_FAIL = 'GET_ALARMS_REPORT_FAIL';

export const GET_ALARM_DETAIL_REPORT_START = 'GET_ALARM_DETAIL_REPORT_START';
export const GET_ALARM_DETAIL_REPORT_SUCCESS = 'GET_ALARM_DETAIL_REPORT_SUCCESS';
export const GET_ALARM_DETAIL_REPORT_FAIL = 'GET_ALARM_DETAIL_REPORT_FAIL';

export const CLOSE_ALARM_REPORT_DETAIL = 'CLOSE_ALARM_REPORT_DETAIL';
export const CLEAR_ALARM_REPORT_DETAIL = 'CLEAR_ALARM_REPORT_DETAIL';

export const SET_FILTERS = 'SET_FILTERS';
export const PATCH_FILTERS = 'PATCH_FILTERS';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const SET_PAGE_COUNT = 'SET_PAGE_COUNT';
export const SET_SORTING = 'SET_SORTING';

export const GET_DASHBOARD_START = 'GET_DASHBOARD_START';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAIL = 'GET_DASHBOARD_FAIL';

export const RESET_CONTACT_TRACING = 'RESET_CONTACT_TRACING';
export const SET_CONTACT_TRACING_TRACE = 'SET_CONTACT_TRACING_TRACE';
export const SET_CONTACT_TRACING_ID = 'SET_CONTACT_TRACING_ID';
export const SET_CONTACT_TRACING_DATE_RANGE = 'SET_CONTACT_TRACING_DATE_RANGE';
export const SET_CONTACT_TRACING_QUICK_FILTERS_TO_INCLUDE =
    'SET_CONTACT_TRACING_QUICK_FILTERS_TO_INCLUDE';
export const SET_CONTACT_TRACING_QUICK_FILTERS_MINIMUM_OVERLAP =
    'SET_CONTACT_TRACING_QUICK_FILTERS_MINIMUM_OVERLAP';
export const SET_CONTACT_TRACING_QUICK_FILTERS_TRAILING_CONTACTS =
    'SET_CONTACT_TRACING_QUICK_FILTERS_TRAILING_CONTACTS';
export const SET_CONTACT_TRACING_QUICK_FILTERS_TRAILING_CONTACTS_MINUTES =
    'SET_CONTACT_TRACING_QUICK_FILTERS_TRAILING_CONTACTS_MINUTES';
export const SET_CONTACT_TRACING_CONTACT_LEVEL = 'SET_CONTACT_TRACING_CONTACT_LEVEL';

export const RESET_REPORTS = 'RESET_REPORTS';
