import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { Engage360Filters, Repair } from '../../values/types';

const slice = createFetchSlice('engageRepairs', {}, getInitialState<Repair[]>());

export function loadEngageRepairs(filters: Engage360Filters, page: number, options = {}) {
    return slice.load({
        uri: `/repairs`,
        config: {
            params: {
                ...filters,
                ...(page > 1 ? { page } : {}),
            },
        },
        ...options,
    });
}

const engageRepairs = slice.reducer;
const engageRepairsSelector = slice.selector;
const engageRepairsReset = slice.actions.reset;

export { engageRepairsSelector, engageRepairsReset };

export const engageRepairsDataSelector = createSelector(engageRepairsSelector, v => v.data);

export default engageRepairs;
