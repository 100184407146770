import React, { ReactNode, useMemo } from 'react';
import { DiseaseCase, DiseaseSingle, Person } from '../../values/types';
import styled from '@emotion/styled';
import { Box, BoxLink } from '../../styles/shared';
import { faExclamationTriangle, faVirus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PersonPhoto from '../PersonPhoto';
import { formatDateTime } from '../../utils/date';
import { red } from '../../styles/theme';
import { wizardGray } from '../Wizard/WizardStep';
import { useSelector } from '../../store';
import { locationsByIdSelector } from '../../store/reducers/reports';
import { generatePath } from 'react-router';
import { css } from '../../utils/ide';
import { formatIsolationLocationName } from '../../utils/diseaseCase';
import { routes } from '../../router/routes';

const Container = styled.div`
    height: 8rem;
    display: flex;

    > *:not(:last-child) {
        margin-right: 1rem;
    }
`;

const exposureBoxCss = css`
    display: flex;
    justify-content: space-around;
    width: 50rem;
    color: ${wizardGray};
`;

const ExposureBoxLink = styled(BoxLink)`
    ${exposureBoxCss};
`;

const ExposureBox = styled(Box)`
    ${exposureBoxCss};
`;

const BoxColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`;

function ExposureSingleRow({
    disease,
    exposure,
    exposedToPerson,
    parentDiseaseCase,
    children,
}: Props) {
    const formattedDate = useMemo(() => {
        return formatDateTime(new Date(exposure.exposedAt ?? exposure.createdAt));
    }, [exposure.createdAt, exposure.exposedAt]);
    const locationByIdMap = useSelector(locationsByIdSelector);
    const exposedPersonQuarantineLocation =
        locationByIdMap[exposedToPerson?.quarantineLocationKey ?? 0];
    const to = useMemo(() => {
        return parentDiseaseCase
            ? generatePath(routes.diseaseCaseSingle.fullPath, {
                  diseaseCaseUuid: parentDiseaseCase.uuid,
              })
            : null;
    }, [parentDiseaseCase]);

    const exposureBoxInnerContent = (
        <>
            <BoxColumn style={{ textAlign: 'center', color: red }}>
                <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    style={{ opacity: 0.5, fontSize: '2rem' }}
                />
                <strong>Exposure</strong>
            </BoxColumn>
            <BoxColumn style={{ flex: 'none' }}>to</BoxColumn>
            <BoxColumn>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'white',
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                    }}
                >
                    {exposedToPerson?.name ?? 'Unknown'}{' '}
                    <PersonPhoto
                        photo={exposedToPerson?.profilePhoto?.smallUrl}
                        size="2rem"
                        style={{ marginLeft: '1rem' }}
                    />
                </div>
                <div>{formattedDate}</div>
            </BoxColumn>
            <BoxColumn>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'white',
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                    }}
                >
                    <FontAwesomeIcon
                        icon={faVirus}
                        style={{ opacity: 0.5, fontSize: '2rem', marginRight: '1rem' }}
                    />{' '}
                    {disease.name}
                </div>
                <strong style={{ color: 'white' }}>
                    {formatIsolationLocationName(exposedToPerson, exposedPersonQuarantineLocation)}
                </strong>
            </BoxColumn>
        </>
    );

    const exposureBox = to ? (
        <ExposureBoxLink to={to}>{exposureBoxInnerContent}</ExposureBoxLink>
    ) : (
        <ExposureBox>{exposureBoxInnerContent}</ExposureBox>
    );

    return (
        <Container>
            {exposureBox}
            {children}
        </Container>
    );
}

interface Props {
    exposure: DiseaseCase;
    disease: DiseaseSingle;
    parentDiseaseCase?: DiseaseCase;
    exposedToPerson?: Person;
    children: ReactNode;
}

export default ExposureSingleRow;
