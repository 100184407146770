import React from 'react';
import {node, object} from 'prop-types';

/**
 * Component for aligning text above a vertical line pointing upward or downward.
 * It attempts to make the multiline start from where the vertical line is pointing,
 * making sure the text is centered at the vertical line.
 *
 * @param children
 * @param position {object} top, left etc. positioning of this component compared to its parent.
 *  It is based on whether there's a "bottom" or "top" positioning given whether it's the
 *  bottom or top border of this component that would need to be kept in the same place.
 * @returns {*}
 * @constructor
 */
function AlignedText({ children, position }) {
    const alignFrom = 'top' in position ? 'bottom' : 'top';

    return (
        <div style={{position: 'absolute', ...position, width: '33%', lineHeight: 1.75}}>
            <div style={{position: 'absolute', [alignFrom]: '0.3rem', left: '-50%', width: '100%'}}>{children}</div>
        </div>
    );
}

AlignedText.propTypes = {
    children: node,
    position: object,
};

export default AlignedText;
