import React, { CSSProperties, ReactNode } from 'react';
import ReactModal from 'react-modal';
import { Box } from '../styles/shared';

const customStyles: ReactModal.Styles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'transparent',
        border: '0',
    },
    overlay: {
        backgroundColor: `rgba(0, 0, 0, 0.5)`,
    },
};

function ModalBox(props: Props) {
    const { children, style, ...restProps } = props;
    return (
        <ReactModal style={customStyles} {...restProps}>
            <Box style={{ padding: '3rem', ...(style ?? {}) }}>{children}</Box>
        </ReactModal>
    );
}

interface Props extends Omit<ReactModal.Props, 'style'> {
    children?: ReactNode;
    style?: CSSProperties;
}

export default ModalBox;
