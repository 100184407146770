import React from 'react';
import styled from "@emotion/styled";
import {css} from '../../utils/ide';
import PropTypes from 'prop-types';
import {noop} from 'lodash-es';

const Container = styled.div`
  margin: 1em 0;
`;

const PerPageChoice = styled.span(({isCurrent}) => css`
    ${isCurrent ? 
    `cursor: pointer;` :
    ''
    };
`);
  
const perPageChoices = [25, 50, 100, 200];


/** "Showing 51 - 100 of 2,481 results" */
function PaginationResultNumbers({ currentPage, pageCount, total, setPerPage, to }) {

    const firstReultNumber = 1 + (pageCount * (currentPage - 1));
    return (
        <Container>
            Showing {firstReultNumber} - {to} of {total} results.
            Page size: {perPageChoices.map(choice => {
                const notCurrent = choice !== pageCount;
                return (
                    <PerPageChoice
                        key={choice}
                        className={notCurrent ? 'link-like' : ''}
                        tabIndex={notCurrent ? "0" : void 0}
                        onClick={notCurrent ? (() => setPerPage(choice)) : noop}
                    >
                        {choice}
                    </PerPageChoice>
                );
            }).reduce((acc, elem) => {
            return acc === null ? [elem] : [...acc, ', ', elem]
        }, null)}
        </Container>
    );
}

PaginationResultNumbers.propTypes = {
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    total: PropTypes.number,
    to: PropTypes.number,
    setPerPage: PropTypes.func
};

export default PaginationResultNumbers;
