import React, { CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyIcon } from '../values/appConfig';

/**
 * FontAwesome or Image icon.
 */
export default function Icon({ icon, name, height, style = {} }: Props) {
    let innerIcon;
    if (typeof icon === 'string') {
        innerIcon = <img src={icon} alt={name} style={{ height: '1em', ...style }} />;
    } else if (icon.type === 'customizedFontAwesomeIcon') {
        innerIcon = <FontAwesomeIcon icon={icon.icon} style={style} {...icon.props} />;
    } else {
        innerIcon = <FontAwesomeIcon icon={icon} style={style} />;
    }

    return (
        <span title={name} style={{ fontSize: height, ...style }}>
            {innerIcon}
        </span>
    );
}

interface Props {
    icon: MyIcon;
    name: string;
    height?: string;
    style?: CSSProperties;
}
