import {
    analyticsIcon,
    inactiveAnalyticsIcon,
    listIcon,
    projectDiagramIcon,
    projectDiagramSolidIcon,
} from '../styles/fontLibrary';
import {
    faChartBar,
    faDoorClosed,
    faUserMd,
    faUsers,
    faVirus,
} from '@fortawesome/pro-regular-svg-icons';
import { faVirus as fasVirus } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { ComponentType, useMemo } from 'react';
import Events from '../pages/Events/Events';
import Home from '../pages/Home/Home';
import CaseManagementPage from '../pages/CaseManagement/CaseManagementPage';
import LocationsPage from '../pages/LocationsPage';
import ContactTracing from '../pages/ContactTracing/ContactTracing';
import { generatePath, match, matchPath, useLocation } from 'react-router';
import { Person } from '../values/types';
import CaseManagementReportsPage from '../pages/CaseManagementReportsPage';
import Engage360HomePage from '../pages/Engage360HomePage';
import { ReactComponent as Engage360Logo } from '../assets/img/logos/engage360.svg';
import { ReactComponent as Enrich360Logo } from '../assets/img/logos/enrich360.svg';
import { ReactComponent as Ensure360Logo } from '../assets/img/logos/ensure360.svg';
import { routes } from './routes';
import PersonsPage from '../pages/PersonsPage';
import { getRoutePersonType, PersonsRouteParams } from './caseManagementRoutes';
import { useSelector } from '../store';
import { diseaseCasesNeedAttentionDataSelector } from '../store/reducers/diseaseCasesNeedAttention';
import { memoize } from 'lodash-es';
import { ReactComponent as DefaultLogo } from '../../assets/img/logos/ensure360.svg';
import { FacilityConfig, facilityConfigSelector } from '../store/reducers/facilityConfig';

type RouteLeafBase = {
    isGroup?: false;
    path: string;
    params?: { [paramName: string]: string | number | boolean | undefined }; // Route params.
};

export type VisibleMenuItem = RouteLeafBase & {
    component: ComponentType<{ match: match<any> }>;
    label?: string; // This is required if you want the item to appear in the left menu.
    icon?: IconDefinition;
    inactiveIcon?: IconDefinition;
    badgeCount?: number;
};

type RedirectItem = RouteLeafBase & {
    component?: never;
    redirect: string;
};

export type RouteGroup = {
    isGroup: true;
    groupKey: string;
    GroupImage: typeof DefaultLogo; // Logo.
    items: RouteLeafNode[];
};

// An item can either point to a component, or express a redirect.
export type RouteLeafNode = VisibleMenuItem | RedirectItem;

// A route item can either be a leaf node with a route/redirect, or a group of leaf nodes.
export type RouteConfigItem = RouteLeafNode | RouteGroup;

export function createLinkToPerson(person: Person | undefined) {
    return person
        ? generatePath(routes.personsSingle.fullPath, {
              personType: getRoutePersonType(person.isCaregiver),
              personId: person.id,
          })
        : '';
}

/**
 * Grabs the configuration for the left menu and the top level routes.
 */
function getTopRouteConfig(
    config: FacilityConfig,
    caseManagementBadgeCount: number | undefined = 0
) {
    const { isStandalone, hasCentralOffice, isAlarmsEnabled } = config;
    const items: RouteConfigItem[] = [];
    const ensure360AlarmRoutes: RouteLeafNode[] = [];
    const enrich360caseManagmentRoutes: RouteLeafNode[] = [];
    const engage360TvAndActivitiesRoutes: RouteLeafNode[] = [];

    function addAlarmsRoutes() {
        if (isStandalone) {
            return;
        }
        ensure360AlarmRoutes.push({
            label: 'Dashboard',
            path: routes.home.fullPath,
            icon: analyticsIcon,
            inactiveIcon: inactiveAnalyticsIcon,
            component: Home,
        });
        ensure360AlarmRoutes.push({
            label: 'Event Detail',
            path: '/events',
            icon: listIcon,
            component: Events,
        });
    }

    if (isAlarmsEnabled) {
        addAlarmsRoutes();
    }

    if (config.isCaseManagementEnabled && hasCentralOffice) {
        enrich360caseManagmentRoutes.push({
            label: 'Dashboard',
            path: routes.caseManagement.fullPath,
            inactiveIcon: faVirus,
            icon: fasVirus,
            component: CaseManagementPage,
            badgeCount: caseManagementBadgeCount,
        });
        enrich360caseManagmentRoutes.push({
            label: 'Residents',
            path: routes.persons.fullPath,
            params: { personType: 'residents' } as PersonsRouteParams,
            icon: faUsers,
            component: PersonsPage,
        });
        enrich360caseManagmentRoutes.push({
            label: 'Staff',
            path: routes.persons.fullPath,
            params: { personType: 'staff' } as PersonsRouteParams,
            icon: faUserMd,
            component: PersonsPage,
        });

        if (isStandalone) {
            enrich360caseManagmentRoutes.push({
                label: 'Locations',
                path: routes.locations.fullPath,
                icon: faDoorClosed,
                component: LocationsPage,
            });
        }

        enrich360caseManagmentRoutes.push({
            label: 'Report',
            path: routes.caseManagementReports.fullPath,
            icon: faChartBar,
            component: CaseManagementReportsPage,
        });
    }

    if (!isStandalone && config.isContactTracingEnabled) {
        ensure360AlarmRoutes.push({
            label: 'Contact Tracing',
            path: routes.contactTracing.fullPath,
            inactiveIcon: projectDiagramIcon,
            icon: projectDiagramSolidIcon,
            component: ContactTracing,
        });
    }

    if (config.isEngage360Enabled) {
        engage360TvAndActivitiesRoutes.push({
            path: routes.engage360.fullPath,
            label: 'TV & Activities',
            component: Engage360HomePage,
        });
    }

    items.push({
        isGroup: true,
        groupKey: 'enrich360',
        GroupImage: Enrich360Logo,
        items: enrich360caseManagmentRoutes,
    });
    items.push({
        isGroup: true,
        groupKey: 'ensure360',
        GroupImage: Ensure360Logo,
        items: ensure360AlarmRoutes,
    });
    items.push({
        isGroup: true,
        groupKey: 'engage360',
        GroupImage: Engage360Logo,
        items: engage360TvAndActivitiesRoutes,
    });

    if (!items) {
        addAlarmsRoutes();
    }

    return items;
}

const getTopRouteConfigMemoized = memoize(getTopRouteConfig);

export function useTopRouteConfig() {
    const facilityConfig = useSelector(facilityConfigSelector);
    const caseManagementBadgeCount = useSelector(diseaseCasesNeedAttentionDataSelector)?.length;

    return useMemo(() => getTopRouteConfigMemoized(facilityConfig, caseManagementBadgeCount), [
        caseManagementBadgeCount,
        facilityConfig,
    ]);
}

type MatchedRouteAndGroup = {
    routeLeafNode: RouteLeafNode;
    group: RouteGroup | null;
} | null;

/**
 * Gets the top level route and group that matches the current URI.
 *
 * NULL means no match.
 */
export function useTopRouteAndGroupMatch(): MatchedRouteAndGroup {
    const uri = useLocation().pathname;
    const topRouteConfig = useTopRouteConfig();

    return useMemo(() => {
        return matchGroupByRouteConfigItem(uri, topRouteConfig, null);
    }, [topRouteConfig, uri]);
}

function matchGroupByRouteConfigItem(
    uri: string,
    routeConfigItems: RouteConfigItem[],
    group: RouteGroup | null
): MatchedRouteAndGroup {
    for (const routeItem of routeConfigItems) {
        if (routeItem.isGroup) {
            const result = matchGroupByRouteConfigItem(uri, routeItem.items, routeItem);
            if (result) {
                // A route match was found. Keep returning it.
                return result;
            }

            continue;
        }

        // We need to resolve the path; replace placeholders with values.
        const resolvedPath = generatePath(routeItem.path, routeItem.params);

        if (matchPath(uri, { path: resolvedPath, exact: routeItem.path === '/' })) {
            // Route match!.
            return { routeLeafNode: routeItem, group };
        }
    }

    // No match.
    return null;
}
