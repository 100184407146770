import Keycloak from 'keycloak-js';

export const keycloakRealm = 'sentrics';
export const keycloakUrl = 'https://iam.evacompute.com/auth';
export const keycloakClientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID || 'enrich-360';
const baseUrl = `${window.location.protocol}//${
    window.location.hostname + (window.location.port ? ':' + window.location.port : '')
}`;
export const logoutUrl = `${keycloakUrl}/realms/${keycloakRealm}/protocol/openid-connect/logout?redirect_uri=${baseUrl}`;

const keycloak = Keycloak({
    realm: keycloakRealm,
    url: keycloakUrl,
    clientId: keycloakClientId,
});

export function logout() {
    keycloak.logout();
    window.location.href = logoutUrl;
}

export default keycloak;
