import { debuggingEnabled } from '../values/env';
import { captureException } from '@sentry/browser';
import axios from 'axios';
import { displayError } from './toast';
import { addServerErrors } from './form';
import { BulkUploadValidateFailureByRow, SetError } from '../values/types';
import { convertBulkUploadFailuresToFailuresByRow } from './bulkUpload';

const genericErrorMessage = 'An error has occurred.';

export const VALIDATION_FAILURE = 'validation_failure';
export const BULK_UPLOAD_FAILURE = 'bulk_upload_failure';
export const FILE_TOO_LARGE = 'file_too_large';
export const NOT_FOUND = 'not_found';
export const REQUEST_CANCELLED = 'request_cancelled';

type NormalizedErrorBase = {
    type?: string;
    message: string;
    normalizedError: true;
};

type ErrorWithoutData = NormalizedErrorBase & {
    type?: typeof FILE_TOO_LARGE | typeof NOT_FOUND | typeof REQUEST_CANCELLED;
    data?: never;
};

type ValidationError<T> = NormalizedErrorBase & {
    type: typeof VALIDATION_FAILURE;
    data: { [P in keyof T]?: string[] };
};

type BulkUploadFailures = NormalizedErrorBase & {
    type: typeof BULK_UPLOAD_FAILURE;
    data: BulkUploadValidateFailureByRow[];
};

export type NormalizedError<T = unknown> =
    | ErrorWithoutData
    | ValidationError<T>
    | BulkUploadFailures;

/**
 * Normalizes (ajax) errors to a normalized format that's also serializable by Redux.
 */
export function normalizeError<T>(error): NormalizedError<T> {
    if (error && error.normalizedError) {
        // Already normalized.
        return error;
    }

    const ret: NormalizedError<T> = { message: genericErrorMessage, normalizedError: true };
    let response, status, data;
    if (axios.isCancel(error)) {
        ret.type = REQUEST_CANCELLED;
        ret.message = error.message;
        return ret;
    }
    // No response or response status.
    if (typeof error !== 'object' || !(response = error.response) || !(status = response.status)) {
        return ret;
    }

    if (status === 413) {
        ret.type = FILE_TOO_LARGE;
        ret.message = 'The uploaded file was too large.';
    }

    if (!(data = response.data)) {
        return ret;
    }

    if (data.message) {
        ret.message = data.message;
    }

    if (status === 404) {
        ret.type = NOT_FOUND;
    }

    if (status === 422) {
        if (!data.message && data.data.details) {
            // This is the sign that this is a bulk upload failures error that's custom and not
            // in the standard Laravel format.
            return {
                normalizedError: true,
                type: BULK_UPLOAD_FAILURE,
                message: 'There were errors when validating the spreadsheet for bulk upload.',
                data: convertBulkUploadFailuresToFailuresByRow(data.data.details),
            };
        }

        return {
            normalizedError: true,
            message: ret.message,
            type: VALIDATION_FAILURE,
            data: data.errors,
        };
    }

    return ret;
}

export const NO_FACILITY_ACCESS = 'no_facility_access';

export function reportError(error) {
    if (debuggingEnabled) {
        //eslint-disable-next-line no-console
        console.error(error);
    }
    captureException(error);
}

export type CustomErrorHandler<Form> = (
    normalizedError: NormalizedError<Form>,
    originalError: any
) => boolean;

type HandleErrorOptions<Form> = {
    setError?: SetError<Form>;
    customHandler?: CustomErrorHandler<Form>;
};

/**
 * Handles an error and then returns it. This function should be used with most new ajax requests.
 *
 * An error message is displayed, is logged to Sentry (unless it's a common, expected error),
 * and validation errors are set if the react-hook-form `setError` function is provided.
 *
 * @param error {any}
 * @param options
 * @param options.setError An optional `setError()` function provided by the useForm hook to
 *  set validation errors automatically.
 * @param options.customHandler A custom handler of the error. If provided and it returns TRUE, then
 *  the default error handling does not happnen.
 */
export function handleError<Form>(
    error: any,
    { setError, customHandler }: HandleErrorOptions<Form> = {}
) {
    const normalizedError = normalizeError<Form>(error);

    if (customHandler) {
        if (customHandler(normalizedError, error)) {
            return normalizedError;
        }
    }

    displayError(normalizedError.message);
    if (normalizedError.type === VALIDATION_FAILURE && setError) {
        addServerErrors(normalizedError.data, setError);
        // Consider the validation error as handled and do not report it.
        return normalizedError;
    }
    if (normalizedError.type === NOT_FOUND) {
        // Don't report these kinds of errors.
        return normalizedError;
    }
    reportError(error);

    return normalizedError;
}
