/**
 * All disease cases for the current facility.
 */
import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { URI_DISEASE_CASES } from '../../axios';
import { DiseaseCase, DiseaseCaseWithTests } from '../../values/types';
import { groupBy, keyBy } from 'lodash-es';
import { isDiseaseCaseActive, isDiseaseCaseExposure } from '../../values/appConfig';

const slice = createFetchSlice('diseaseCases', {}, getInitialState<DiseaseCaseWithTests[]>());

export function loadDiseaseCases() {
    return slice.load({
        uri: `${URI_DISEASE_CASES}?include=tests`,
        stealth: true,
    });
}

const diseaseCases = slice.reducer;
const diseaseCasesSelector = slice.selector;
const diseaseCasesReset = slice.actions.reset;

export { diseaseCasesSelector, diseaseCasesReset };

export const diseaseCasesDataSelector = createSelector(diseaseCasesSelector, v => v.data);

export const diseaseCasesExposuresSelector = createSelector(
    diseaseCasesDataSelector,
    diseaseCases => diseaseCases && diseaseCases.filter(isDiseaseCaseExposure)
);

export const diseaseCasesActiveSelector = createSelector(
    diseaseCasesDataSelector,
    diseaseCases => diseaseCases && diseaseCases.filter(isDiseaseCaseActive)
);

export const diseaseCaseByUuidMapSelector = createSelector(
    diseaseCasesDataSelector,
    (
        diseaseCases
    ): {
        [diseaseUuid: string]: DiseaseCase | undefined;
    } => {
        return keyBy(diseaseCases || [], 'uuid');
    }
);

export const diseaseCasesByPersonIdSelector = createSelector(
    diseaseCasesDataSelector,
    (diseaseCases): { [personId: number]: DiseaseCase[] } => {
        return groupBy(diseaseCases, 'PersonKey');
    }
);

export default diseaseCases;
