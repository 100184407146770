import { RoutePersonType } from '../values/types';

export function getRoutePersonType(isCaregiver: boolean): RoutePersonType {
    return isCaregiver ? 'staff' : 'residents';
}

export type PersonsRouteParams = {
    personType: RoutePersonType;
};

export type PersonSingleRouteParams = PersonsRouteParams & {
    personId: string;
};

export type PersonCreateOrEditRouteParams = PersonsRouteParams & {
    personId?: string;
};

export type DiseaseCaseEditRouteState = {
    diseaseUuid?: string;
    personId?: number;
};

export interface DiseaseCaseSingleRouteParams {
    diseaseCaseUuid: string;
}
