import styled from '@emotion/styled';
import { convertHex } from '../../utils/css';
import { css } from '../../utils/ide';
import theme, { red } from '../../styles/theme';
import { NavLink } from 'react-router-dom';
import React, { ButtonHTMLAttributes } from 'react';

const backgrounds: Record<Flavor, string> = {
    highlighted: theme.primaryBlue,
    link: 'transparent',
    danger: red,
    default: convertHex(theme.primaryLight, 0.56),
};

const defaultColor = 'white';

const colors: Partial<Record<Flavor, string>> = {
    link: theme.primaryBlue,
};

const defaultBorder = `0`;

const borders: Partial<Record<Flavor, string>> = {
    link: '0',
};

const buttonCss: (props: Props) => string = ({ flavor = 'default', theme, size }) => {
    const height = `${size ? 2.4 : '1.6'}em`;
    return css`
        position: relative;
        text-decoration: none;
        border-radius: ${height};
        padding: 0.3rem 1rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: ${borders[flavor] ?? defaultBorder};
        margin-right: 2px;
        cursor: pointer;
        line-height: ${height};
        background: ${flavor ? backgrounds[flavor] : convertHex(theme.primaryLight, 0.56)};
        color: ${colors[flavor] ?? defaultColor};

        &[disabled] {
            opacity: 0.56;
            cursor: default;
        }
    `;
};
const StyledButton = styled.button<Props>(buttonCss);
StyledButton.defaultProps = { type: 'button' };
function Button(props: ButtonHTMLAttributes<unknown> & Props & { waiting?: boolean }) {
    const { waiting, children, ...restProps } = props;
    const newProps = { ...restProps };
    // If the button is "loading", make sure to disable it as well.
    if (waiting) {
        newProps.disabled = true;
    }

    return <StyledButton {...newProps}>{waiting ? 'Loading...' : children}</StyledButton>;
}

// Button that's a react-router Link
export const ButtonLink = styled(NavLink)<Props>(buttonCss);

export const ButtonLabel = styled.label<Props>(
    props => `
    ${buttonCss(props)};
`
);

export const bigButtonDefaultProps: ButtonHTMLAttributes<HTMLButtonElement> & Partial<Props> = {
    size: 1,
    type: 'button',
};
export const primaryButtonDefaultProps = {
    ...(bigButtonDefaultProps as any),
    flavor: 'highlighted',
};

export const bigButtonCss = css`
    min-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: auto;
`;

export const BigButton = styled(Button)(() => bigButtonCss);
BigButton.defaultProps = bigButtonDefaultProps;

export const PrimaryButton = styled(BigButton)``;
PrimaryButton.defaultProps = primaryButtonDefaultProps;

export const PrimaryButtonLink = styled(ButtonLink)`
    ${bigButtonCss};
`;
PrimaryButtonLink.defaultProps = primaryButtonDefaultProps;

type Flavor = 'danger' | 'highlighted' | 'link' | 'default';

type Props = {
    flavor?: Flavor;
    theme?: typeof theme | any; // TODO: improve on typing here.
    size?: 0 | 1;
};

export default Button;
