import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from '../../store/actions';
import DateSelector from './DateSelector';

/**
 * Date Selector that shows up as an overlay for filtering alarms.
 */
const FilterDateSelector = ({close, sideBySideLarge}: Props) => {
    const dispatch = useDispatch();
    const {from, to} = useSelector((state: any) => state.reports.filters);

    const onDateRangeChange = useCallback(obj => {
        dispatch(setFilter('filters', obj));
    }, [dispatch]);

    return (
        <DateSelector
            close={close}
            from={from}
            to={to}
            onDateRangeChange={onDateRangeChange}
            sideBySideLarge={sideBySideLarge}
        />
    )
};

interface Props {
    close?: () => void,
    sideBySideLarge?: boolean,
}

export default FilterDateSelector;
