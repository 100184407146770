import React, { useMemo } from 'react';
import { BulkUploadImportResult } from '../store/reducers/bulkUploadImport';
import styled from '@emotion/styled';
import { PersonInfo } from '../values/appConfig';
import { green, red } from '../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';

const TextLine = styled.div`
    font-size: 1.5rem;
    margin: 1rem;
    font-weight: bold;
`;

function BulkUploadResult({ result, personInfo }: Props) {
    const success = useMemo(() => {
        return result.rowsProcessed && !result.rowsFailed;
    }, [result.rowsFailed, result.rowsProcessed]);

    return (
        <div>
            {
                <TextLine>
                    Successfully added {result.rowsProcessed} {personInfo.nameOptionalPlural}
                </TextLine>
            }
            {result.rowsFailed ? (
                <TextLine style={{ color: red }}>
                    Failed to add {result.rowsFailed} {personInfo.nameOptionalPlural}
                </TextLine>
            ) : null}
            <div style={{ textAlign: 'center', marginTop: '3rem' }}>
                <FontAwesomeIcon
                    icon={success ? faCheck : faTimes}
                    color={success ? green : red}
                    size="10x"
                />
            </div>
        </div>
    );
}

interface Props {
    personInfo: PersonInfo;
    result: BulkUploadImportResult;
}

export default BulkUploadResult;
