import React, { useEffect, useState } from 'react';
import { TablePageContainer, Title } from '../../components/Engage360/engage360Common';
import theme from '../../styles/theme';
import DataTableSimple from '../../components/DataTableSimple';
import { useAppDispatch, useSelector } from '../../store';
import { engageRepairsSelector, loadEngageRepairs } from '../../store/reducers/engageRepairs';
import { upperFirst } from 'lodash-es';
import { formatDateTime, humanizeElapsedSeconds } from '../../utils/date';
import { engage360FiltersSelector } from '../../store/globalSelectors';
import ShowFiltersToggle from '../../components/DataTableSimple/ShowFiltersToggle';

export default function RepairsTablePage() {
    const dispatch = useAppDispatch();
    const engage360Filters = useSelector(engage360FiltersSelector);
    useEffect(() => {
        dispatch(loadEngageRepairs(engage360Filters, 1));
    }, [dispatch, engage360Filters]);
    const { data, waiting } = useSelector(engageRepairsSelector);

    const showFiltersState = useState(false);

    return (
        <TablePageContainer>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, overflow: 'hidden' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Title>Repairs</Title>
                        <div style={{ flex: 1 }} />
                        <ShowFiltersToggle showFiltersState={showFiltersState} />
                    </div>
                    <div style={{ height: '1rem' }} />
                    <div
                        style={{
                            position: 'relative',
                            minHeight: '8rem',
                            overflowX: 'auto',
                            width: '100%',
                        }}
                    >
                        <DataTableSimple
                            data={data}
                            waiting={waiting}
                            idProperty="uuid"
                            showFilters={showFiltersState[0]}
                            headerTdStyle={{
                                fontWeight: 'bold',
                                fontSize: '1.25rem',
                                textAlign: 'center',
                            }}
                            tdStyle={{
                                color: theme.primaryLight,
                                textAlign: 'center',
                            }}
                            headerConfig={[
                                {
                                    getValue: r => r.resident.name,
                                    label: 'Resident',
                                    tdStyle: { fontWeight: 'normal' },
                                    key: 'resident',
                                    sortable: true,
                                    filterable: true,
                                },
                                {
                                    getValue: r => r.location.name,
                                    label: 'Apartment',
                                    key: 'apartment',
                                    sortable: true,
                                    filterable: true,
                                },
                                {
                                    getValue: r => r.item,
                                    label: 'Room',
                                    key: 'room',
                                    sortable: true,
                                    filterable: true,
                                },
                                {
                                    getValue: r => r.issue,
                                    label: 'Issue',
                                    key: 'issue',
                                    sortable: true,
                                    filterable: true,
                                },
                                {
                                    getValue: r => formatDateTime(r.externalCreatedAt),
                                    label: 'Request Date',
                                    key: 'requestDate',
                                    sortable: v => v.externalCreatedAt,
                                    defaultSortDescending: true,
                                },
                                {
                                    getValue: r =>
                                        r.closedAt
                                            ? new Date(r.closedAt).getTime() -
                                              new Date(r.externalCreatedAt).getTime()
                                            : 0,
                                    label: 'Elapsed Time',
                                    key: 'elapsedTime',
                                    sortable: true,
                                    defaultSortDescending: true,
                                    formatValue: value => {
                                        return typeof value === 'number' && value
                                            ? humanizeElapsedSeconds(value / 1000)
                                            : '--';
                                    },
                                },
                                {
                                    getValue: r => r.status,
                                    formatValue: value => {
                                        const status = String(value);
                                        const displayStatus = upperFirst(status);
                                        return status !== 'open' ? (
                                            <span style={{ opacity: 0.56 }}>{displayStatus}</span>
                                        ) : (
                                            displayStatus
                                        );
                                    },
                                    label: 'Status',
                                    key: 'status',
                                    sortable: v => {
                                        if (v.status === 'open') {
                                            return '__1';
                                        }
                                        if (v.status === 'closed') {
                                            return '__2';
                                        }

                                        return v.status;
                                    },
                                    filterable: true,
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </TablePageContainer>
    );
}
