import styled from '@emotion/styled';
import theme from '../../styles/theme';

const ChartTitle = styled.div`
    color: ${theme.gray600};
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
`;

export default ChartTitle;
