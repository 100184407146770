import React, {useMemo} from 'react';
import {green, blue2, purple} from "../../styles/theme";
import {object} from 'prop-types';
import DashboardPieChart from '../../components/DashboardPieChart/DashboardPieChart';

function getLegendText(minuteMin, minuteMax) {
    if (!minuteMax) {
        return `Over ${minuteMin} Minutes`;
    } else if (!minuteMin) {
        return `${minuteMax} Minutes Or Less`;
    }
    return `${minuteMin}-${minuteMax} Minutes`;
}

const colors = [green, blue2, purple];

// This is configurable.
const minuteBoundaries = [5, 7];

const HomeCircleGauge = ({data, ...restProps}) => {

    const chartData = useMemo(() => {
        const totals = [];
        let chartData = [];
        let previousBoundary = 0;

        // Calculate the pie chart data of the boundaries based from the counts of time ranges available
        for (let i = 0; i <= minuteBoundaries.length; i++) {
            const minuteBoundary = minuteBoundaries[i];
            let count = data.counts.slice(previousBoundary, minuteBoundary)
                .reduce((acc, current) => current.count + acc, 0);
            totals.unshift(count);
            previousBoundary = minuteBoundary;
        }
        // Over 10 mins counts.
        totals[0] += data.outOfGroups;

        // Generate the legends below the chart.
        let _previousBoundary = 0;
        for (let i = 0; i <= minuteBoundaries.length; i++) {
            const minuteBoundary = minuteBoundaries[i];
            const reverseIndex = minuteBoundaries.length - i;
            chartData.unshift({
                name: getLegendText(_previousBoundary, minuteBoundary),
                count: totals[reverseIndex],
                color: colors[reverseIndex],
            })
            _previousBoundary = minuteBoundary;
        }
        chartData = chartData.filter(({count}) => count);
        return chartData;
    }, [data]);

    return (
            <div {...restProps}>
                <DashboardPieChart data={chartData} />
            </div>
    )
};

HomeCircleGauge.propTypes = {
    data: object,
};

export default HomeCircleGauge;
