import { useCallback } from 'react';
import { stringify } from 'qs';
import { useApi } from '../../utils/apiRouting';
import { useSelector } from 'react-redux';
import { filterQuerySelector } from '../../store/actions/reports';

export default function useAlarmsGetDownloadUrl() {
    const { apiBaseUrl, getBaseQuery } = useApi();
    const filterQuery = useSelector(filterQuerySelector);

    return useCallback(
        () =>
            `${apiBaseUrl}/api/alarms/report?${stringify({
                ...getBaseQuery(),
                ...filterQuery,
                format: 'xlsx',
            })}`,
        [apiBaseUrl, filterQuery, getBaseQuery]
    );
}
