import React, { useCallback, useMemo } from 'react';
import FiltersItems from '../FiltersItems';
import { string, oneOfType, arrayOf } from 'prop-types';
import { isEqual } from 'lodash-es';
import { css } from '../../../../utils/ide';
import { convertHex } from '../../../../utils/css';
import styled from '@emotion/styled';
import { RangeSelector } from './FilterRangeType/RangeSelector';
import { useDispatch, useSelector } from 'react-redux';
import { useStateOrExternal } from '../../../hooks/useStateOrExternal';
import { patchFilterItem } from '../../../../store/actions/reports';

export const RangeDisplayContainer = styled.div`
    display: flex;
    > div:last-child {
        margin-left: 1rem;
    }
`;
const RangeDisplayValueContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        flex: 1;
        text-align: center;
        padding: 0.5rem;
        border-radius: 1rem;
        border: 0.05rem solid ${convertHex(theme.primaryLight, 0.56)};
    `
);

export function RangeDisplayValue({ text, value }) {
    return (
        <RangeDisplayValueContainer>
            <span style={{ opacity: 0.56, marginRight: '0.5rem' }}>{text}</span>
            <span style={{ fontWeight: 'bold', flex: 1 }}>{value}</span>
        </RangeDisplayValueContainer>
    );
}

/**
 * @param title
 * @param filterKey {string|string[]} String for a filter key to use (redux reducer/state), or
 *  an array of strings for fake data.
 * @param min
 * @param max
 * @returns {*}
 * @constructor
 */
function FilterRangeType({ title, filterKey, range: [min, max] }) {
    const dispatch = useDispatch();
    const selected = useSelector(state => state.reports.filters[filterKey]);

    // There's an extra value in the real range that represents infinity.
    const realRange = [min, max + 1];

    const stateOptions = useMemo(() => {
        if (!filterKey) {
            return { initialState: null };
        }

        return {
            getState() {
                return selected;
            },
            setState(value) {
                dispatch(patchFilterItem(filterKey, value));
            },
        };
    }, [dispatch, filterKey, selected]);

    let [selectedRange, setSelectedRange] = useStateOrExternal(stateOptions);
    if (!selectedRange) {
        selectedRange = [...realRange];
    }

    const formatNumber = useCallback(
        value => {
            return value >= realRange[1] ? `${max}+` : `${value}`;
        },
        [max, realRange]
    );

    let selectedNames = '';
    if (!isEqual(selectedRange, realRange)) {
        selectedNames = `${formatNumber(selectedRange[0])} - ${formatNumber(
            selectedRange[1]
        )} minutes`;
    }

    return (
        <FiltersItems
            title={title}
            selectedText={selectedNames}
            render={() => (
                <>
                    <RangeSelector
                        selectedRange={selectedRange}
                        setSelectedRange={setSelectedRange}
                        realRange={realRange}
                    />
                    <RangeDisplayContainer>
                        <RangeDisplayValue
                            text="Min"
                            value={formatNumber(selectedRange[0]) + ' min'}
                        />
                        <RangeDisplayValue
                            text="Max"
                            value={formatNumber(selectedRange[1]) + ' min'}
                        />
                    </RangeDisplayContainer>
                </>
            )}
        />
    );
}

FilterRangeType.propTypes = {
    title: string,
    filterKey: oneOfType([string, arrayOf(string)]),
};

export default FilterRangeType;
