import React, { useEffect } from 'react';
import ActivityAttendance from '../../components/Engage360/Activities/ActivityAttendance';
import LocationUtilization from '../../components/Engage360/Activities/LocationUtilization';
import ActivitiesSignups from '../../components/Engage360/Activities/ActivitiesSignups';
import { Route, Switch } from 'react-router';
import { routes } from '../../router/routes';
import ActivitySignupPrintPage from './ActivitySignupPrintPage';
import { useAppDispatch, useSelector } from '../../store';
import { useQueryState } from '../../utils/filter';
import { loadEngageActivitySignups } from '../../store/reducers/engageActivitySignups';
import { engage360FiltersSelector } from '../../store/globalSelectors';
import {
    engageActivitySummarySelector,
    loadEngageActivitySummary,
} from '../../store/reducers/engageActivitySummary';
import {
    engageActivityByHourAndWeekSelector,
    loadEngageActivityByHourAndWeek,
} from '../../store/reducers/engageActivityByHourAndWeek';
import ActivityByHourAndWeek from '../../components/Engage360/Activities/ActivityByHourAndWeek';
import BigSpinner from '../../components/BigSpinner/BigSpinner';

export default function ActivitiesPage() {
    const dispatch = useAppDispatch();
    const engage360Filters = useSelector(engage360FiltersSelector);
    const summary = useSelector(engageActivitySummarySelector);
    const byHourAndWeek = useSelector(engageActivityByHourAndWeekSelector);
    const [page, setPage] = useQueryState('page');

    useEffect(() => {
        dispatch(loadEngageActivityByHourAndWeek(engage360Filters));
    }, [dispatch, engage360Filters]);
    useEffect(() => {
        dispatch(loadEngageActivitySignups(engage360Filters, Number(page ?? 1)));
    }, [dispatch, engage360Filters, page]);
    useEffect(() => {
        dispatch(loadEngageActivitySummary(engage360Filters));
    }, [dispatch, engage360Filters]);

    return (
        <Switch>
            <Route
                path={routes.engage360ActivitiesPrint.fullPath}
                component={ActivitySignupPrintPage}
            />
            <Route>
                <div>
                    {!summary.data || !byHourAndWeek.data ? <BigSpinner /> : null}

                    {summary.data ? <ActivityAttendance data={summary.data} /> : null}
                    <div style={{ height: '3rem' }} />

                    {byHourAndWeek.data ? (
                        <ActivityByHourAndWeek data={byHourAndWeek.data} />
                    ) : null}

                    <LocationUtilization />

                    <ActivitiesSignups page={Number(page)} setPage={setPage} />
                </div>
            </Route>
        </Switch>
    );
}
