import React, { CSSProperties } from 'react';
import { TitledBox } from '../common';
import styled from '@emotion/styled';
import Radio from '../../../components/Radio/Radio';
import { remToNumber } from '../../../utils/css';
import { wizardGray } from '../../../components/Wizard/WizardStep';
import { blue5 } from '../../../styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {
    setContactTracingQuickFiltersTrailingContacts,
    setContactTracingQuickFiltersTrailingContactsMinutes,
} from '../../../store/actions/contactTracing';

const lineHeight = '2rem';
const radioSize = '1.6rem';

const positionOffset = (remToNumber(lineHeight) - remToNumber(radioSize)) / 2;

const ParagraphLabel = styled.label`
    position: relative;
    padding-left: 3rem;
    cursor: pointer;
    line-height: ${lineHeight};
    
    :not(:first-of-type) {
        margin-top: 2rem;
    }
`;

const StyledRadio = styled(Radio)`
    font-size: ${radioSize};
`;

const FancyInlineInput = styled.input`
    width: 2rem;
    border: 0;
    border-bottom: 0.1rem solid ${wizardGray};
    color: ${blue5};
    font-size: 1.2rem;
    font-weight: bold;
    background-color: transparent;
    text-align: center;
`;
FancyInlineInput.defaultProps = {
    type: 'input',
}

function ParagraphLabelWithRadio({ children, radio }) {
    return (
        <ParagraphLabel>
            <div style={{position: 'absolute', left: 0, top: positionOffset}}>
                {radio}
            </div>
            {children}
        </ParagraphLabel>
    );
}

function TrailingContacts({ style }: Props) {
    const dispatch = useDispatch();
    const quickFilters = useSelector((state: RootState) => state.contactTracing.quickFilters);

    return (
        <TitledBox title="Trailing Contacts" style={style}>
            <ParagraphLabelWithRadio
                radio={<StyledRadio
                    selected={quickFilters.trailingContacts}
                    value={true}
                    onChange={() => dispatch(setContactTracingQuickFiltersTrailingContacts(true))}
                />}
            >Include contacts that arrived within <FancyInlineInput
                value={quickFilters.trailingContactsMinutes || ''}
                onChange={(evt) => (evt.currentTarget.value === '' || Number(evt.currentTarget.value)) && dispatch(
                    setContactTracingQuickFiltersTrailingContactsMinutes(evt.currentTarget.value ? Number(evt.currentTarget.value) : null)
                )}
                onClick={() => dispatch(setContactTracingQuickFiltersTrailingContacts(true))}
            /> minutes after the traced
                departed a location.</ParagraphLabelWithRadio>
            <ParagraphLabelWithRadio
                radio={<StyledRadio
                    selected={quickFilters.trailingContacts}
                    value={false}
                    onChange={() => dispatch(setContactTracingQuickFiltersTrailingContacts(false))}
                />}
            >Do not include trailing contacts.</ParagraphLabelWithRadio>
        </TitledBox>
    );
}

interface Props {
    style?: CSSProperties,
}

export default TrailingContacts;
