import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Title } from '../components/CaseManagement/common';
import { genderInfoMap, locationStatusInfoMap, PersonInfo } from '../values/appConfig';
import { SelectOption } from '../components/UnstyledSelect';
import {
    Gender,
    genders,
    LocationStatus,
    locationStatuses,
    Person,
    RouteParams,
} from '../values/types';
import Select from '../components/Select';
import { wizardGray } from '../components/Wizard/WizardStep';
import Input from '../components/Input/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUpload, faVirus } from '@fortawesome/pro-solid-svg-icons';
import theme from '../styles/theme';
import RadioWithLabel from '../components/Radio/RadioWithLabel';
import PersonPhoto from '../components/PersonPhoto';
import { Controller, useForm } from 'react-hook-form';
import { ButtonLink, PrimaryButton } from '../components/Button/Button';
import { match, useHistory, useRouteMatch } from 'react-router';
import { useSelector } from '../store';
import axiosInstance, { ResponseEnvelope, URI_PEOPLE } from '../axios';
import FieldErrors from '../components/FieldErrors';
import { displaySuccess } from '../utils/toast';
import { useDispatch } from 'react-redux';
import { handleError } from '../utils/errorHandling';
import { GroupLabel, InputComponentsContainer } from '../components/Form/formCommon';
import PageBox from '../components/PageBox';
import {
    destructureDate,
    getDateDayOptionsSelect,
    getDateMonthOptionsSelect,
    getDateYearOptionsSelect,
} from '../utils/date';
import { loadPersons } from '../store/reducers/persons';
import { locationsDataSelector } from '../store/reducers/locations';
import { MediumSpinner } from '../components/BigSpinner/BigSpinner';
import { upsertPerson } from '../api/persons';
import { routes } from '../router/routes';
import { generatePath } from 'react-router-dom';
import { PersonCreateOrEditRouteParams } from '../router/caseManagementRoutes';
import { facilityConfigSelector } from '../store/reducers/facilityConfig';

const Spacer = styled.div`
    height: 3rem;
`;

const GenderOptionsContainer = styled.div`
    display: flex;

    > label:not(:first-of-type) {
        margin-left: 2rem;
    }
`;

const genderOptions: Array<SelectOption<Gender>> = genders.map(gender => ({
    value: gender,
    label: genderInfoMap[gender].name,
}));

type Form = {
    isCaregiver: '1' | '0';
    gender: Gender;
    locationStatus: LocationStatus;
    homeLocationKey: number | null;
    quarantineLocationKey: number | null;
    name: string;
    secondName: string;
    middleInitial: string;
    facilityPersonId: string;
    dateOfBirth: string;
    profilePhoto?: FileList;
};

const locationStatusOptions = locationStatuses.map(locationStatus => ({
    value: locationStatus,
    label: locationStatusInfoMap[locationStatus].name,
}));

function ManualPersonEditPage(props: Props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const routeMatch = useRouteMatch<RouteParams>();
    const { isStandalone } = useSelector(facilityConfigSelector);
    const personId = Number(routeMatch.params.personId);
    const defaultValues: Form = {
        isCaregiver: props.personInfo.key === 'caregiver' ? '1' : '0',
        gender: 'o',
        locationStatus: 'on campus',
        homeLocationKey: null,
        quarantineLocationKey: null,
        name: '',
        secondName: '',
        middleInitial: '',
        facilityPersonId: '',
        dateOfBirth: '',
    };
    const { control, errors, register, setValue, reset, watch, handleSubmit, setError } = useForm({
        defaultValues,
    });
    const [existingProfilePhoto, setExistingProfilePhoto] = useState(null as null | string);
    register('dateOfBirth');

    useEffect(() => {
        if (personId) {
            axiosInstance
                .get<ResponseEnvelope<Person>>(`${URI_PEOPLE}/${personId}`)
                .then(response => {
                    const { profilePhoto, isCaregiver, ...responseData } = response.data.data;
                    reset({ ...responseData, isCaregiver: isCaregiver ? '1' : '0' });
                    if (profilePhoto) {
                        setExistingProfilePhoto(profilePhoto.largeUrl);
                    }
                    if (!responseData.locationStatus) {
                        setValue('locationStatus', defaultValues.locationStatus);
                    }
                })
                .catch(handleError);
        }
    }, [defaultValues.locationStatus, personId, reset, setValue]);

    const watched = watch(['isCaregiver', 'name', 'profilePhoto', 'dateOfBirth']);

    const { year: dateOfBirthYear, month: dateOfBirthMonth, day: dateOfBirthDay } = useMemo(() => {
        return watched.dateOfBirth
            ? destructureDate(watched.dateOfBirth)
            : { year: 'XXXX', month: 'XX', day: 'XX' };
    }, [watched.dateOfBirth]);

    const handleGenderChange = e => {
        setValue('gender', e.target.value);
    };

    useEffect(() => {
        register('gender'); // custom register.
    }, [register]);

    const locations = useSelector(locationsDataSelector);
    const locationOptions = useMemo(() => {
        return (
            locations &&
            locations.map(
                ({ id, name }): SelectOption<number> => ({
                    value: id,
                    label: name,
                })
            )
        );
    }, [locations]);
    const locationOptionsIncludingEmpty = useMemo(() => {
        return locationOptions && [{ label: '(None)', value: null }, ...locationOptions];
    }, [locationOptions]);

    const [saving, setSaving] = useState(false);

    const onSubmit = useCallback(
        async (data: Form) => {
            try {
                setSaving(true);
                const { profilePhoto, dateOfBirth, ...restData } = data;
                await upsertPerson(personId ?? null, {
                    ...restData,
                    // only send the date if it's complete
                    // in order to received the right error message from the API
                    ...(dateOfBirth && dateOfBirth.indexOf('XX') === -1 ? { dateOfBirth } : {}),
                    ...(profilePhoto?.[0] ? { profilePhoto: profilePhoto[0] } : {}),
                });
                dispatch(loadPersons()); // Reload list of people.
                displaySuccess('You have saved successfully.');
                if (!personId) {
                    history.push(`${generatePath(routes.persons.fullPath, routeMatch.params)}`);
                }
            } catch (e) {
                handleError(e, { setError });
            } finally {
                setSaving(false);
            }
        },
        [dispatch, history, personId, routeMatch.params, setError]
    );

    const { isCentralOfficeAdmin } = useSelector(facilityConfigSelector);

    const isCaregiver = watched.isCaregiver === '1';

    return (
        <div>
            <Title style={{ textAlign: 'center' }}>
                {personId
                    ? `Edit ${props.personInfo.name}`
                    : `Add ${props.personInfo.name}${isCentralOfficeAdmin ? ' (Manual)' : ''}`}
            </Title>
            <PageBox>
                <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ display: 'flex' }}>
                        {isStandalone ? (
                            <div style={{ flex: 1 }}>
                                <GroupLabel>Unique Identifier</GroupLabel>
                                <InputComponentsContainer>
                                    <div style={{ width: '15rem' }}>
                                        <Input
                                            name="facilityPersonId"
                                            ref={register}
                                            placeholder="Unique Identifier"
                                        />
                                    </div>
                                </InputComponentsContainer>
                                <FieldErrors errors={[errors.facilityPersonId]} />
                            </div>
                        ) : (
                            <div style={{ flex: 1 }}>
                                <GroupLabel>Name</GroupLabel>
                                <input type="hidden" name="name" ref={register} />
                                <div style={{ margin: '1rem 0', display: 'inline-block' }}>
                                    {watched.name}
                                </div>
                                <FieldErrors
                                    errors={[errors.name, errors.secondName, errors.middleInitial]}
                                />
                            </div>
                        )}
                        <div style={{ flex: 1 }}>
                            <input type="hidden" name="isCaregiver" ref={register} />
                            <FieldErrors errors={errors.isCaregiver} />
                            <div
                                style={{
                                    flex: 1,
                                    display: !isCaregiver ? 'block' : 'none',
                                }}
                            >
                                <GroupLabel>Location Status</GroupLabel>
                                <InputComponentsContainer>
                                    <div style={{ width: '15rem' }}>
                                        <Controller
                                            name="locationStatus"
                                            control={control}
                                            defaultValue={defaultValues.locationStatus}
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Select
                                                    name={name}
                                                    onBlur={onBlur}
                                                    options={locationStatusOptions}
                                                    value={value}
                                                    onChange={v => onChange(v)}
                                                />
                                            )}
                                        />
                                    </div>
                                </InputComponentsContainer>
                                <FieldErrors errors={errors.locationStatus} />
                            </div>
                        </div>
                    </div>
                    {isStandalone && (
                        <>
                            <Spacer />
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <GroupLabel>Name</GroupLabel>
                                    <InputComponentsContainer>
                                        <div style={{ width: '15rem' }}>
                                            <Input
                                                name="name"
                                                ref={register}
                                                placeholder="First Name"
                                            />
                                        </div>
                                        <div style={{ width: '10rem' }}>
                                            <Input
                                                name="middleInitial"
                                                ref={register}
                                                placeholder="Middle Initials"
                                            />
                                        </div>
                                        <div style={{ width: '15rem', marginRight: '1rem' }}>
                                            <Input
                                                name="secondName"
                                                ref={register}
                                                placeholder="Last Name"
                                            />
                                        </div>
                                    </InputComponentsContainer>
                                    <FieldErrors
                                        errors={[
                                            errors.name,
                                            errors.secondName,
                                            errors.middleInitial,
                                        ]}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <Spacer />
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <GroupLabel>Date of Birth</GroupLabel>
                            <InputComponentsContainer>
                                <div style={{ width: '10rem' }}>
                                    <Select
                                        options={getDateMonthOptionsSelect()}
                                        value={dateOfBirthMonth}
                                        placeholder={'Month...'}
                                        onChange={v => {
                                            setValue(
                                                'dateOfBirth',
                                                `${dateOfBirthYear}-${v}-${dateOfBirthDay}`,
                                                {
                                                    shouldValidate: true,
                                                }
                                            );
                                        }}
                                    />
                                </div>
                                <div style={{ width: '7rem' }}>
                                    <Select
                                        options={getDateDayOptionsSelect()}
                                        value={dateOfBirthDay}
                                        placeholder={'Day...'}
                                        onChange={v => {
                                            setValue(
                                                'dateOfBirth',
                                                `${dateOfBirthYear}-${dateOfBirthMonth}-${v}`,
                                                {
                                                    shouldValidate: true,
                                                }
                                            );
                                        }}
                                    />
                                </div>
                                <div style={{ width: '8rem' }}>
                                    <Select
                                        options={getDateYearOptionsSelect()}
                                        value={dateOfBirthYear}
                                        placeholder={'Year...'}
                                        onChange={v => {
                                            setValue(
                                                'dateOfBirth',
                                                `${v}-${dateOfBirthMonth}-${dateOfBirthDay}`,
                                                {
                                                    shouldValidate: true,
                                                }
                                            );
                                        }}
                                    />
                                </div>
                            </InputComponentsContainer>
                            <FieldErrors errors={[errors.dateOfBirth]} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <GroupLabel>Gender</GroupLabel>
                            <GenderOptionsContainer>
                                {genderOptions.map(genderOption => (
                                    <RadioWithLabel
                                        key={genderOption.value}
                                        name="gender"
                                        register={register}
                                        value={genderOption.value}
                                        onChange={handleGenderChange}
                                        label={genderOption.label}
                                    />
                                ))}
                                <div style={{ flex: 1 }} />
                            </GenderOptionsContainer>
                            <FieldErrors errors={errors.gender} />
                        </div>
                    </div>
                    <Spacer />
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <GroupLabel>Photo</GroupLabel>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <PersonPhoto
                                    photo={watched.profilePhoto?.[0] ?? existingProfilePhoto}
                                    size="6rem"
                                />
                                <label
                                    htmlFor="profilePhoto"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        color: theme.primaryBlue,
                                        marginLeft: '1rem',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <FontAwesomeIcon
                                        size="2x"
                                        icon={faUpload}
                                        style={{ marginBottom: '0.5rem' }}
                                    />
                                    <div>Upload Photo</div>
                                    {watched.profilePhoto?.[0] && (
                                        <div style={{ color: wizardGray }}>
                                            <em>{watched.profilePhoto[0].name}</em>
                                        </div>
                                    )}
                                </label>
                                <input
                                    id="profilePhoto"
                                    hidden
                                    type="file"
                                    name="profilePhoto"
                                    ref={register}
                                    accept=".gif,.jpeg,.jpg,.png"
                                />
                            </div>
                            <FieldErrors errors={errors.profilePhoto} />
                        </div>
                        <div
                            style={{
                                flex: 1,
                                display: !isCaregiver ? 'block' : 'none',
                            }}
                        >
                            <GroupLabel>Home location</GroupLabel>
                            <InputComponentsContainer>
                                <div>
                                    <FontAwesomeIcon size="3x" icon={faHome} />
                                </div>
                                <div style={{ width: '20rem' }}>
                                    {locationOptions ? (
                                        <Controller
                                            name="homeLocationKey"
                                            control={control}
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Select
                                                    name={name}
                                                    onBlur={onBlur}
                                                    options={locationOptions}
                                                    value={value}
                                                    onChange={v => onChange(v)}
                                                />
                                            )}
                                        />
                                    ) : (
                                        <MediumSpinner />
                                    )}
                                </div>
                            </InputComponentsContainer>
                            <FieldErrors errors={errors.homeLocationKey} />
                        </div>
                        <div
                            style={{
                                flex: 1,
                                display: !isCaregiver ? 'block' : 'none',
                            }}
                        >
                            <GroupLabel>Quarantine location</GroupLabel>
                            <InputComponentsContainer>
                                <div>
                                    <FontAwesomeIcon size="3x" icon={faVirus} />
                                </div>
                                <div style={{ width: '20rem' }}>
                                    {locationOptionsIncludingEmpty ? (
                                        <Controller
                                            name="quarantineLocationKey"
                                            control={control}
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Select
                                                    name={name}
                                                    onBlur={onBlur}
                                                    options={locationOptionsIncludingEmpty}
                                                    value={value}
                                                    onChange={v => onChange(v)}
                                                />
                                            )}
                                        />
                                    ) : (
                                        <MediumSpinner />
                                    )}
                                </div>
                            </InputComponentsContainer>
                            <FieldErrors errors={errors.quarantineLocationKey} />
                        </div>
                    </div>
                    <Spacer />
                    <div style={{ textAlign: 'center' }}>
                        <PrimaryButton type="submit" waiting={saving}>
                            {`${personId ? 'Update' : 'Add'} ${props.personInfo.name}`}
                        </PrimaryButton>
                        <ButtonLink
                            style={{ marginLeft: '3rem' }}
                            flavor="link"
                            to={props.parentRouteMatch.url}
                        >
                            Cancel
                        </ButtonLink>
                    </div>
                </form>
            </PageBox>
        </div>
    );
}

interface Props {
    personInfo: PersonInfo;
    parentRouteMatch: match<PersonCreateOrEditRouteParams>;
}

export default ManualPersonEditPage;
