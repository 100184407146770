import React from 'react';
import FieldErrors from '../FieldErrors';
import { GroupLabel, InputComponentsContainer } from '../Form/formCommon';
import Input from '../Input/Input';
import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import Button from '../Button/Button';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { green, red } from '../../styles/theme';
import { useFieldArray } from 'react-hook-form';
import { DiseaseCaseForm } from '../../pages/DiseaseCaseEditPage';
import { UseFormMethods } from 'react-hook-form/dist/types';

const PositiveLabel = styled(FontAwesomeIcon)`
    // Positive results are bad mmkay.
    color: ${red};
    font-size: 2rem;
`;

const NegativeLabel = styled(PositiveLabel)`
    color: ${green};
`;

const IsPositiveCheckbox = styled.input`
    position: absolute;
    left: -10000px;

    &:checked ~ ${NegativeLabel} {
        display: none;
    }
    &:not(:checked) ~ ${PositiveLabel} {
        display: none;
    }
`;

export type DiseaseCaseTestFormItem = {
    testedAt: string;
    resultReadyAt: string;
    isPositive: '1' | '';
};

/**
 * The multi disease case test editor on the disease case edit page.
 */
function DiseaseCaseEditTests({ form }: Props) {
    const { control, errors, register, watch } = form;
    const testsFieldArray = useFieldArray<DiseaseCaseTestFormItem>({ control, name: 'tests' });

    return (
        <div>
            <InputComponentsContainer>
                <div style={{ flex: 1 }}>
                    <GroupLabel>Tested On</GroupLabel>
                </div>
                <div style={{ flex: 1 }}>
                    <GroupLabel>Result On</GroupLabel>
                </div>
                <div style={{ flex: 1, textAlign: 'center' }}>
                    <GroupLabel>Result</GroupLabel>
                </div>
                <div style={{ flex: 1 }} />
            </InputComponentsContainer>
            {testsFieldArray.fields.map(({ id, isPositive, testedAt, resultReadyAt }, index) => {
                const testErrors = errors?.tests?.[index];
                const namePrefix = `tests[${index}].`;
                const watchedTestedAt = watch(`tests[${index}].testedAt`);
                const watchedResultReadyAt = watch(`tests[${index}].resultReadyAt`);

                return (
                    <InputComponentsContainer style={{ marginBottom: '1rem' }} key={id}>
                        <div style={{ flex: 1 }}>
                            <div style={{ width: '20rem' }}>
                                <Input
                                    type="datetime-local"
                                    name={`${namePrefix}testedAt`}
                                    defaultValue={testedAt}
                                    ref={register({
                                        required: 'This field is required.',
                                        max: `${watchedResultReadyAt}`,
                                    })}
                                    max={watchedResultReadyAt}
                                />
                            </div>
                            <FieldErrors errors={testErrors?.testedAt} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <div style={{ width: '20rem' }}>
                                <Input
                                    type="datetime-local"
                                    name={`${namePrefix}resultReadyAt`}
                                    defaultValue={resultReadyAt}
                                    ref={register({
                                        required: 'This field is required.',
                                        min: `${watchedTestedAt}`,
                                    })}
                                    min={watchedTestedAt}
                                />
                            </div>
                            <FieldErrors errors={testErrors?.resultReadyAt} />
                        </div>
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <label className="clickable">
                                <IsPositiveCheckbox
                                    type="checkbox"
                                    name={`${namePrefix}isPositive`}
                                    ref={register()}
                                    defaultChecked={!!isPositive}
                                    value="1"
                                />
                                <PositiveLabel icon={faPlusCircle} title="Positive" />
                                <NegativeLabel icon={faMinusCircle} title="Negative" />
                            </label>
                            <FieldErrors errors={testErrors?.isPositive} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <div>
                                <Button
                                    onClick={() => testsFieldArray.remove(index)}
                                    style={{ width: '5rem' }}
                                >
                                    remove
                                </Button>
                            </div>
                        </div>
                    </InputComponentsContainer>
                );
            })}
            <Button
                onClick={() =>
                    testsFieldArray.append({
                        isPositive: '1',
                        resultReadyAt: '',
                        testedAt: '',
                    })
                }
            >
                + Add Test Result
            </Button>
        </div>
    );
}

interface Props {
    form: UseFormMethods<DiseaseCaseForm>;
}

export default DiseaseCaseEditTests;
