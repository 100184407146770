import { createSelector } from '@reduxjs/toolkit';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { diseasesByUuidSelector } from './reducers/diseases';
import { personsByPersonTypeSelector, reportsFiltersSelector } from './reducers/reports';
import {
    diseaseCasesActiveSelector,
    diseaseCasesByPersonIdSelector,
    diseaseCasesExposuresSelector,
} from './reducers/diseaseCases';
import { DiseaseCase, Person, PersonType, PersonTypeCounts, personTypes } from '../values/types';
import { newPersonTypeCounts } from '../values/appConfig';
import { isEqual } from 'lodash-es';
import { personsByIdSelector } from './reducers/persons';

// create a "selector creator" that uses lodash.isequal instead of ===
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export function newEmptyCaseStatistics() {
    return {
        cases: newPersonTypeCounts(),
        exposures: newPersonTypeCounts(),
    };
}

export function newEmptyOpenWithPendingQuarantine() {
    return {
        open: newEmptyCaseStatistics(),
        pendingQuarantine: newEmptyCaseStatistics(),
    };
}

export function newEmptyPersonTypeArrays(): Record<PersonType, []> {
    return {
        caregiver: [],
        resident: [],
    };
}

type DiseaseStatistics = {
    cases: PersonTypeCounts;
    exposures: PersonTypeCounts;
};

type OpenWithPendingQuarantine = {
    open: DiseaseStatistics;
    pendingQuarantine: DiseaseStatistics;
};

export const caseStatisticsByDiseaseUuidSelector = createSelector(
    diseaseCasesActiveSelector,
    diseaseCasesExposuresSelector,
    diseasesByUuidSelector,
    personsByIdSelector,
    (diseaseCasesActive, exposures, diseaseByIdMap, personByIdMap) => {
        const ret: {
            [diseaseUuid: string]: OpenWithPendingQuarantine;
        } = {};

        const map: Array<[keyof DiseaseStatistics, DiseaseCase[] | null]> = [
            ['cases', diseaseCasesActive],
            ['exposures', exposures],
        ];

        for (const [key, diseaseCases] of map) {
            if (!diseaseCases) {
                continue;
            }
            for (const diseaseCase of diseaseCases) {
                const diseaseUuid = diseaseCase.diseaseUuid;
                if (!ret[diseaseUuid]) {
                    ret[diseaseUuid] = newEmptyOpenWithPendingQuarantine();
                }
                const diseaseStatistics = ret[diseaseUuid]!;
                const disease = diseaseByIdMap[diseaseCase.diseaseUuid];
                if (!disease) {
                    continue;
                }

                const person = personByIdMap?.[diseaseCase.PersonKey];
                if (!person) {
                    continue;
                }

                const personTypeKey = person.isCaregiver ? 'caregiver' : 'resident';
                ++diseaseStatistics.open[key].total;
                ++diseaseStatistics.open[key][personTypeKey];
                if (!diseaseCase.quarantinedAt) {
                    ++diseaseStatistics.pendingQuarantine[key].total;
                    ++diseaseStatistics.pendingQuarantine[key][personTypeKey];
                }
            }
        }

        return ret;
    }
);

export const personsByTypeWithoutCasesSelector = createSelector(
    personsByPersonTypeSelector,
    diseaseCasesByPersonIdSelector,
    (personsByPersonType, diseaseCasesByPersonId) => {
        const ret: Record<PersonType, Person[]> = newEmptyPersonTypeArrays();
        for (const personType of personTypes) {
            for (const person of personsByPersonType[personType]) {
                if (diseaseCasesByPersonId[person.id]?.length) {
                    continue;
                }

                // This person does not have any disease cases, so add him to the list.
                ret[personType].push(person);
            }
        }
        return ret;
    }
);

export const castManagementReportsFiltersSelector = createDeepEqualSelector(
    reportsFiltersSelector,
    filters => ({
        facilityIds: filters.facilityIds,
        from: filters.from,
        to: filters.to,
    })
);

export const engage360FiltersSelector = createDeepEqualSelector(
    reportsFiltersSelector,
    filters => ({
        from: filters.from,
        to: filters.to,
        ...(filters.engageResidentUuids
            ? {
                  engageResidentUuids: filters.engageResidentUuids,
              }
            : {}),
    })
);
