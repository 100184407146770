import React from 'react';
import Radio, { RadioProps } from './Radio';
import styled from '@emotion/styled';

const Label = styled.label`
    margin: 1rem auto;
    display: flex;
    cursor: pointer;
    align-items: center;
`;

function RadioWithLabel(props: Props) {
    const { label, ...restProps } = props;
    return (
        <Label style={{ color: restProps.color }}>
            <Radio {...restProps} />
            <span style={{ color: restProps.color }}>{label}</span>
        </Label>
    );
}

interface Props extends RadioProps {
    label: string | JSX.Element | number;
}

export default RadioWithLabel;
