import React, { CSSProperties, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import styled from '@emotion/styled';

const PhotoBackground = styled.div`
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
`;

function PersonPhoto({ size, photo, style = {} }: Props) {
    const url = useMemo(() => {
        if (!photo) {
            return null;
        }
        if (typeof photo === 'string') {
            return photo;
        }
        return URL.createObjectURL(photo);
    }, [photo]);

    if (url) {
        return (
            <PhotoBackground
                style={{
                    height: size ?? '1rem',
                    width: size ?? '1rem',
                    backgroundImage: `url(${url})`,
                    ...style,
                }}
            />
        );
    }

    return <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: size ?? '100%', ...style }} />;
}

interface Props {
    size?: string;
    photo?: File | string | null;
    style?: CSSProperties;
}

export default PersonPhoto;
