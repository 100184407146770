import React from 'react';
import { PersonType } from '../../../values/types';
import { Box } from '../../../styles/shared';
import styled from '@emotion/styled';
import { personTypeInfoMap } from '../../../values/appConfig';
import { red } from '../../../styles/theme';
import { wizardGray } from '../../Wizard/WizardStep';
import { formatStatisticNumberForPersonType } from '../../../utils/numbers';
import Icon from '../../Icon';

const Container = styled(Box)`
    flex: 1;
    color: ${wizardGray};

    &:not(:last-of-type) {
        margin-right: 3rem;
    }
`;

const Inner = styled.div`
    padding: 1rem 2rem;
`;

const TitleArea = styled.div`
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: white;
`;

type EntryObject = {
    text: string;
    amount: number | null;
    personType: PersonType;
};

function LeftDataWithBigNumber({ amount, text, personType }: EntryObject) {
    return (
        <div
            style={{
                flex: 1,
                boxSizing: 'border-box',
                display: 'flex',
                fontWeight: 'bold',
                alignItems: 'center',
                color: amount ? red : 'inherit',
            }}
        >
            <div style={{ flex: 1, fontSize: '1.25rem' }}>{text}</div>
            <div style={{ flex: 1, fontSize: '4rem' }}>
                {formatStatisticNumberForPersonType(amount, personType)}
            </div>
        </div>
    );
}

function RightDataWithSmallNumber({ amount, text, personType }: EntryObject) {
    return (
        <div
            style={{
                flex: 1,
                color: amount ? red : wizardGray,
            }}
        >
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxSizing: 'border-box',
                    borderLeft: '0.2rem solid ' + wizardGray,
                }}
            >
                <div
                    style={{
                        fontSize: '2rem',
                        width: '4rem',
                        fontWeight: 'bold',
                    }}
                >
                    {formatStatisticNumberForPersonType(amount, personType)}
                </div>
                <div style={{ width: '5rem', fontSize: '1.25rem' }}>{text}</div>
            </div>
        </div>
    );
}

function CasesBox({
    personType,
    total,
    positiveCases,
    exposedPeople,
    pendingQuarantine,
    pendingIsolation,
}: Props) {
    const personTypeObj = personTypeInfoMap[personType];
    return (
        <Container>
            <Inner>
                <TitleArea>
                    <Icon
                        icon={personTypeObj.icon}
                        name={personTypeObj.name}
                        height="2em"
                        style={{ marginRight: '1rem' }}
                    />
                    {personTypeObj.namePlural} ({total} Total)
                </TitleArea>
                <div style={{ height: '2rem' }} />
                <div style={{ display: 'flex' }}>
                    <LeftDataWithBigNumber
                        text="Active Cases"
                        personType={personType}
                        amount={positiveCases}
                    />
                    <RightDataWithSmallNumber
                        text="Pending Isolation"
                        personType={personType}
                        amount={pendingIsolation}
                    />
                </div>
                <div style={{ height: '1rem' }} />
                <div style={{ display: 'flex' }}>
                    <LeftDataWithBigNumber
                        text="Total Exposures"
                        personType={personType}
                        amount={exposedPeople}
                    />
                    <RightDataWithSmallNumber
                        text="Pending Quarantine"
                        personType={personType}
                        amount={pendingQuarantine}
                    />
                </div>
            </Inner>
        </Container>
    );
}

interface Props {
    personType: PersonType;
    total: number | null;
    positiveCases: number | null;
    exposedPeople: number | null;
    pendingIsolation: number | null;
    pendingQuarantine: number | null;
}

export default CasesBox;
