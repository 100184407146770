import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

/**
 * Configuration for the current facility.
 */

const initialState = {
    isStandalone: true,
    isAlarmsEnabled: false,
    isContactTracingEnabled: false,
    isCaseManagementEnabled: false,
    isEngage360Enabled: false,
    facilityKey: 0,
    // Whether this facility has a central office.
    isCentralOfficeAdmin: false,

    hasCentralOffice: false,
};

export type FacilityConfig = typeof initialState;

const name = 'facilityConfig';

const facilityConfigSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        reset: () => initialState,
        replace: (_, configAction: PayloadAction<FacilityConfig>) => {
            return configAction.payload;
        },
    },
});

const facilityConfig = facilityConfigSlice.reducer;

export const facilityConfigReset = facilityConfigSlice.actions.reset;
export const facilityConfigReplace = facilityConfigSlice.actions.replace;

export const facilityConfigSelector = (state: RootState) => state[name];
// Whether the current state of the facility config is strictly equal to the default.
// This selector basically is so you'd know if the pessimistic default permissions are in use.
export const facilityConfigLoadedSelector = (state: RootState) => state[name] !== initialState;

export default facilityConfig;
