import { format, formatISO, lightFormat, parseISO } from 'date-fns';
import { padStart, range } from 'lodash-es';
import pluralize from 'pluralize';

const formatISODateOptions = { representation: 'date' } as const;

/**
 * Formats a date to a string format like '2012-12-31'
 */
export function formatISODate(date: Date): string {
    return formatISO(date, formatISODateOptions);
}

/**
 * E.g. '2012-12-31T12:00:00'
 */
export function formatISODateWithT(date: Date) {
    return lightFormat(date, "yyyy-MM-dd'T'HH:mm:ss");
}

/**
 * e.g. '3/23/2021'
 */
export function formatDate(apiDate: string | Date): string {
    return new Intl.DateTimeFormat().format(new Date(apiDate));
}

/**
 * e.g. 'Mar 23, 2021'
 */
export function formatDateTextMonth(apiDate: string): string {
    return new Intl.DateTimeFormat('en-us', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    }).format(parseISO(apiDate));
}

export function formatTime(apiDate: string): string {
    return lightFormat(new Date(apiDate), 'h:mm a');
}

export function formatHour(hour: number) {
    return lightFormat(parseISO(`2019-11-06T${String(hour).padStart(2, '0')}:00:00`), 'h a');
}

export function formatDateTime(apiDate: string | Date): string {
    return format(new Date(apiDate), 'MMM d - h:mm a');
}

// Formats the date like "Jan 1".
export function formatMonthDay(apiDate: string): string {
    return format(parseISO(apiDate), 'MMM d');
}

/**
 * For HTML5 datetime-local fields.
 */
export function formatDateTimeLocal(date: Date) {
    return lightFormat(date, "yyyy-MM-dd'T'HH:mm");
}

/**
 * Converts elapsed time to a human readable format.
 */
export function humanizeElapsedSeconds(value: number | null | undefined) {
    if (!value) {
        return null;
    }
    value = Math.ceil(value);

    const timeComponents: string[] = [];

    const secs = value % 60;
    if (secs) {
        timeComponents.unshift(`${secs} s`);
    }
    value = (value - secs) / 60;
    const mins = value % 60;
    if (mins) {
        timeComponents.unshift(`${mins} min`);
    }
    value = (value - mins) / 60;
    const hours = value;
    if (hours) {
        timeComponents.unshift(`${hours} hrs`);
    }

    return timeComponents.join(' ');
}

export function humanizeElapsedSecondsRoundedToMinutes(seconds) {
    const roundedMinutes = Math.round(seconds / 60);
    return roundedMinutes
        ? humanizeElapsedSeconds(roundedMinutes * 60)
        : humanizeElapsedSeconds(seconds);
}

/**
 * @param apiDate yyyy-mm-dd formatted date
 */
export function destructureDate(apiDate: string): { year: string; month: string; day: string } {
    return {
        year: apiDate.substring(0, 4),
        month: apiDate.substring(5, 7),
        day: apiDate.substring(8, 10),
    };
}

export function getDateDayOptionsSelect() {
    const dateOfBirthDayOptions: { value: string; label: string }[] = [];
    for (const day of range(1, 32)) {
        dateOfBirthDayOptions.push({
            value: padStart(day.toString(), 2, '0'), // days < 10 start with a '0'
            label: day.toString(),
        });
    }
    return dateOfBirthDayOptions;
}

export function getMinuteOptionsSelect() {
    const minuteOptions: { value: string; label: string }[] = [];

    for (const minute of range(0, 59)) {
        minuteOptions.push({
            value: minute.toString(),
            label: minute.toString() + ' min',
        });
    }
    return minuteOptions;
}

export function getHourOptionsSelect(start = 0, end = 12) {
    const hourOptions: { value: string; label: string }[] = [];

    for (const hour of range(start, end)) {
        hourOptions.push({
            value: hour.toString(),
            label: hour.toString() + `${pluralize(' hr', hour)}`,
        });
    }
    return hourOptions;
}

export function getDateMonthOptionsSelect() {
    const monthOptions: { value: string; label: string }[] = [
        { value: '01', label: 'January' },
        { value: '02', label: 'February' },
        { value: '03', label: 'March' },
        { value: '04', label: 'April' },
        { value: '05', label: 'May' },
        { value: '06', label: 'June' },
        { value: '07', label: 'July' },
        { value: '08', label: 'August' },
        { value: '09', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' },
    ];
    return monthOptions;
}

export function getDateYearOptionsSelect(start = 1900, end?) {
    if (!end) {
        end = new Date().getFullYear() + 1;
    }
    const yearOptions: { value: string; label: string }[] = [];
    for (const year of range(start, end)) {
        yearOptions.push({
            value: year.toString(),
            label: year.toString(),
        });
    }
    return yearOptions;
}
