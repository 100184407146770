import React, { forwardRef, ReactNode, useCallback, useMemo } from 'react';
import pluralize from 'pluralize';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import DataTable, { HeaderConfig } from './DataTable/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { personTypeInfoMap } from '../values/appConfig';
import { DiseaseCase, DiseaseCaseTest, DiseaseCaseWithTests, PersonType } from '../values/types';
import styled from '@emotion/styled';
import { diseasesByUuidSelector } from '../store/reducers/diseases';
import { useSelector } from '../store';
import PersonPhoto from './PersonPhoto';
import { generatePath, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { formatDate } from '../utils/date';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faMinusCircle, faPlusCircle, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { green, red } from '../styles/theme';
import { personsByIdSelector } from '../store/reducers/persons';
import { locationsByIdSelector } from '../store/reducers/reports';
import { formatIsolationLocationName } from '../utils/diseaseCase';
import Icon from './Icon';
import { routes } from '../router/routes';
import BigSpinner from './BigSpinner/BigSpinner';

export type Case = {
    id: number;
    name: string;
    disease: string;
    testResultDate: string;
    isolation: string;
    remainingIsolation: string;
    location: string;
    responsibleStaff: string;
};

export const DataTableTitle = styled.h2`
    text-align: left;
`;

export function hasPositiveTests(diseaseCase: DiseaseCaseWithTests): boolean {
    for (const test of diseaseCase.tests) {
        if (test.isPositive) return true;
    }
    return false;
}
export function isInQuarantine(diseaseCase: DiseaseCaseWithTests): boolean {
    return !!diseaseCase.quarantinedAt && !isComplete(diseaseCase);
}
export function isComplete(diseaseCase: DiseaseCaseWithTests): boolean {
    return !!diseaseCase.quarantinedTill && new Date(diseaseCase.quarantinedTill) < new Date();
}

function CasesDataTable({ data, personType, title, forSinglePerson }: Props) {
    const personTypeDefinition = personTypeInfoMap[personType];
    const diseaseByUuid = useSelector(diseasesByUuidSelector);
    const personById = useSelector(personsByIdSelector);
    const locationByIdMap = useSelector(locationsByIdSelector);
    const history = useHistory();
    const createLink = useCallback(
        (diseaseCaseUuid: string) =>
            generatePath(routes.diseaseCaseSingle.fullPath, {
                diseaseCaseUuid,
            }),
        []
    );

    // for Tippy.js to work
    const TestsResultsIcon = forwardRef(
        (props: { icon: IconDefinition; isPositive: boolean }, ref: any) => {
            return (
                <FontAwesomeIcon
                    forwardedRef={ref}
                    icon={props.isPositive ? faPlusCircle : faMinusCircle}
                    style={{ color: props.isPositive ? red : green, marginRight: '0.5rem' }}
                />
            );
        }
    );

    const formatDaysInQuarantine = useCallback(
        (diseaseCase: DiseaseCase) => {
            if (!diseaseCase.quarantinedAt) {
                return <em>Pending Quarantine</em>;
            }

            const disease = diseaseByUuid[diseaseCase.diseaseUuid];
            if (!disease) {
                return <em>Unknown</em>;
            }

            if (diseaseCase.remainingQuarantineDays) {
                const days = pluralize('day', disease ? disease.quarantineTimeframeDays : 0);
                return `${disease.quarantineTimeframeDays - diseaseCase.remainingQuarantineDays}/${
                    disease.quarantineTimeframeDays
                } ${days}`;
            }

            return <FontAwesomeIcon icon={faCheck} />;
        },
        [diseaseByUuid]
    );

    function TestsResults(props: { tests: DiseaseCaseTest[] }) {
        return (
            <div>
                {props.tests.map(test => (
                    <Tippy
                        key={test.uuid}
                        theme="light-border"
                        content={
                            <>
                                <div>Test Taken: {formatDate(test.testedAt)}</div>
                                <div>Test Result: {formatDate(test.resultReadyAt)}</div>
                            </>
                        }
                    >
                        <TestsResultsIcon icon={faPlusCircle} isPositive={test.isPositive} />
                    </Tippy>
                ))}
            </div>
        );
    }

    const headerConfig: Array<HeaderConfig<DiseaseCaseWithTests>> = useMemo(() => {
        const dataTableTitle = title ?? (
            <DataTableTitle style={{ marginLeft: '3rem' }}>
                <Icon {...personTypeDefinition} /> {personTypeDefinition.namePlural}
            </DataTableTitle>
        );
        return [
            ...(!forSinglePerson
                ? // Only include this one if it's not for a single person.
                  [
                      {
                          label: dataTableTitle,
                          getValue: v => (
                              <Link // For accessibility. Because otherwise clicking the <tr> navigates too.
                                  to={createLink(v.uuid)}
                                  onClick={evt => {
                                      // Do not double-visit the link, because clicking on the <tr> also
                                      // navigates you to the same page.
                                      evt.stopPropagation();
                                  }}
                                  style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      padding: '1rem',
                                      color: 'inherit',
                                  }}
                              >
                                  <div style={{ marginRight: '1rem' }}>
                                      <PersonPhoto
                                          photo={personById?.[v.PersonKey]?.profilePhoto?.smallUrl}
                                          size="2rem"
                                      />
                                  </div>
                                  {personById?.[v.PersonKey]?.fullName ?? 'N/A'}
                              </Link>
                          ),
                      },
                  ]
                : []),
            {
                label: 'Disease',
                getValue: v => {
                    const ret = diseaseByUuid[v.diseaseUuid]?.name ?? 'N/A';
                    return forSinglePerson ? (
                        <Link
                            to={createLink(v.uuid)}
                            onClick={evt => {
                                // Do not double-visit the link, because clicking on the <tr> also
                                // navigates you to the same page.
                                evt.stopPropagation();
                            }}
                            style={{
                                color: 'inherit',
                            }}
                        >
                            {ret}
                        </Link>
                    ) : (
                        ret
                    );
                },
                tdStyle: { width: '15%' },
            },
            {
                label: 'Tests',
                getValue: v => {
                    return <TestsResults tests={v.tests} />;
                },
                tdStyle: { width: '10%' },
            },
            {
                label: 'Days In Quarantine',
                getValue(diseaseCase) {
                    return formatDaysInQuarantine(diseaseCase);
                },
                tdStyle: { width: '10%' },
            },
            {
                label: 'Quarantine Location',
                getValue(v) {
                    const isPendingQuarantine = !isComplete(v) && !isInQuarantine(v);
                    const person = personById?.[v.PersonKey];
                    return isPendingQuarantine ? (
                        <em>Pending Quarantine</em>
                    ) : (
                        formatIsolationLocationName(
                            person,
                            person?.quarantineLocationKey
                                ? locationByIdMap[person.quarantineLocationKey]
                                : undefined
                        )
                    );
                },
                tdStyle: { width: '15%' }, // fontStyle: 'italic'
            },
            {
                label: 'Closed',
                getValue: v =>
                    v.quarantinedTill && new Date(v.quarantinedTill) < new Date() ? (
                        <FontAwesomeIcon icon={faCheck} />
                    ) : (
                        <span>-</span>
                    ),
                tdStyle: { width: '10%' },
            },
        ];
    }, [
        createLink,
        diseaseByUuid,
        forSinglePerson,
        formatDaysInQuarantine,
        locationByIdMap,
        personById,
        personTypeDefinition,
        title,
    ]);

    if (!personById) {
        return <BigSpinner />;
    }

    return (
        <DataTable<DiseaseCaseWithTests, 'uuid'>
            data={data}
            headerConfig={headerConfig}
            idProperty="uuid"
            changeActive={diseaseCaseUuid => {
                if (!diseaseCaseUuid) {
                    return;
                }
                const link = createLink(String(diseaseCaseUuid));
                history.push(link);
            }}
        />
    );
}

interface Props {
    data: DiseaseCaseWithTests[];
    personType: PersonType;
    title?: ReactNode;
    forSinglePerson: boolean;
}

export default CasesDataTable;
