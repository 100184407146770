export function convertHex(hex, opacity) {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r},${g},${b}, ${opacity})`;
}

const numberPartRegex = /([\d.]+)/;
export const nonNumberRegexp = /[^\d.]*/g;

/**
 * Perform a (math) operation of a size (that can have a unit in it) with the help of a callback.
 * @param size
 * @param operationCb {function} This function takes just the number part as an argument, and the
 *  resulting number result is expected to be returned from it.
 * @returns {string} The resulting number including its unit.
 */
export function operateOnSize(size, operationCb) {
    if (size === undefined || (typeof size === 'number' && isNaN(size))) {
        return size;
    }
    size = String(size);
    const numberPart = extractNumber(size);
    const result = operationCb(Number(numberPart));
    return size.replace(numberPart, result);
}

export function extractNumber(size) {
    return size.match(numberPartRegex)[1];
}

/**
 * Returns a rem value as pixels as a number.
 * @param remAmount
 * @returns {number}
 */
export function remToNumber(remAmount) {
    if (typeof remAmount === 'number') {
        return remAmount;
    }
    const htmlFontSize = Number((window.getComputedStyle(
        document.documentElement,
        null,
    ).getPropertyValue('font-size')).replace(nonNumberRegexp, ''));
    return Number(remAmount.replace(nonNumberRegexp, '')) * htmlFontSize;
}

export function getLineHeight() {
    return Number(window.getComputedStyle(
        document.documentElement,
        null,
    ).getPropertyValue('line-height').replace(nonNumberRegexp, ''));
}

export function calculateColumnWidths(availableWidth, columns, gap) {
    return (availableWidth - (columns - 1) * gap) / columns;
}

// If the browser is Internet Explorer.
export const isIE = !!window.document.documentMode;
