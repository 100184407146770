import { useEffect, useMemo, useState } from 'react';
import { UseState } from '../../values/thirdPartyTypes';
import { HeaderConfigItem, HeaderConfigItemWithKey, PrimitiveValue } from '../DataTableSimple';
import { keyBy, orderBy, uniq } from 'lodash-es';
import { reportError } from '../../utils/errorHandling';

// The column key and the direction.
type SortSetting = [string, boolean];

export type SortObject = {
    defaultDescending: boolean;
    currentSort: SortSetting | null;
    setCurrentSort: (newSort: SortSetting | null) => void;
};

type Filter = { key: string; values: PrimitiveValue[] };

export type FilterObject = {
    filteredDataState: UseState<Filter[]>;
    isFilterOpen: boolean;
};

type SortingFilteringArgument<T> = {
    headerConfig: HeaderConfigItem<T>[];
    data: T[] | null;
};

export function useSortingFiltering<T>({ headerConfig, data }: SortingFilteringArgument<T>) {
    const headerConfigWithKeys = useMemo(
        () => headerConfig.filter(v => typeof v.key === 'string'),
        [headerConfig]
    ) as HeaderConfigItemWithKey<T>[];
    const currentSortState = useState<SortSetting | null>(null);
    const [currentSort] = currentSortState;

    const headerConfigByKey = useMemo(() => keyBy(headerConfigWithKeys, 'key'), [
        headerConfigWithKeys,
    ]);

    const filteredDataSettingsState = useState<Array<Filter>>([]);
    const [filteredDataSettings, setFilteredDataSettings] = filteredDataSettingsState;
    const filteredDataSettingsByKey = useMemo(() => {
        const ret: Record<string, PrimitiveValue[]> = {};
        for (const obj of filteredDataSettings) {
            ret[obj.key] = obj.values;
        }
        return ret;
    }, [filteredDataSettings]);
    // Only those filtered data settings that have at least 1 value selected.
    const filteredDataSettingsWithValues = useMemo(() => {
        return filteredDataSettings.filter(v => v.values.length);
    }, [filteredDataSettings]);

    useEffect(() => {
        // If the data changes, reset the local filters.
        setFilteredDataSettings([]);
    }, [data, setFilteredDataSettings]);

    const possibleFilterValuesByKey = useMemo(() => {
        const ret: Record<string, PrimitiveValue[]> = {};
        for (const headerConfigItem of headerConfig) {
            if (!headerConfigItem.filterable) {
                continue;
            }
            const key = headerConfigItem.key;
            const headerByKey = headerConfig.find(
                v => v.key === key
            )! as HeaderConfigItemWithKey<T>;
            ret[key] = data ? uniq(data.map(headerByKey.getValue)) : [];
        }
        return ret;
    }, [data, headerConfig]);

    const filteredData = useMemo(() => {
        if (!filteredDataSettingsWithValues.length || !data) {
            return data;
        }

        const ret: typeof data = data.filter((row, rowIndex) => {
            if (row === null) {
                return true;
            }

            for (const settings of filteredDataSettingsWithValues) {
                const headerConfigItem = headerConfigByKey[settings.key];
                if (
                    headerConfigItem &&
                    !settings.values.includes(headerConfigItem.getValue(row, rowIndex))
                ) {
                    // Fails one of the filter checks. Do not include this row.
                    return false;
                }
            }

            return true;
        });

        return ret;
    }, [data, filteredDataSettingsWithValues, headerConfigByKey]);

    const sortedData = useMemo(() => {
        if (!filteredData) {
            return filteredData;
        }

        if (!currentSort) {
            return filteredData;
        }

        const [sortKey, sortDirection] = currentSort;

        for (const headerConfigItem of headerConfig) {
            if (headerConfigItem.key !== sortKey) {
                continue;
            }

            return orderBy(
                filteredData,
                [
                    typeof headerConfigItem.sortable === 'function'
                        ? headerConfigItem.sortable
                        : headerConfigItem.getValue,
                ],
                [sortDirection ? 'desc' : 'asc']
            ) as T[];
        }

        reportError('Could not sort the data.');

        return filteredData;
    }, [currentSort, filteredData, headerConfig]);

    return {
        sortedData,
        filteredDataSettingsState,
        filteredDataSettings,
        setFilteredDataSettings,
        filteredDataSettingsByKey,
        possibleFilterValuesByKey,
        currentSortState,
    };
}

// Fake Wrapper used to infer return type of useSortingFiltering();
class Wrapper<T> {
    // wrapped has no explicit return type so we can infer it
    wrapped(e: T) {
        return useSortingFiltering<T>(e as any);
    }
}

// Return type of useSortingFiltering();
export type SortingFiltering<T> = ReturnType<Wrapper<T>['wrapped']>;
