import React, { ReactNode, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';
import { css } from '../../../utils/ide';
import { convertHex } from '../../../utils/css';
import { chevronDownIcon } from '../../../styles/fontLibrary';
import { blue5, ThemeProps } from '../../../styles/theme';
import { faConstruction } from '@fortawesome/pro-solid-svg-icons';

const Container = styled.div(
    ({
        theme,
        open,
        openOrHasFilters,
    }: ThemeProps & { open: boolean; openOrHasFilters: boolean }) => css`
        border: 1px solid ${openOrHasFilters ? blue5 : convertHex(theme.primaryLight, 0.56)};
        box-sizing: border-box;
        border-radius: 0.8rem;
        padding: 1rem;
        margin-bottom: 1rem;
        ${!open &&
        css`
            cursor: pointer;
            min-height: 3.6rem;
        `};
        ${!openOrHasFilters &&
        css`
            cursor: pointer;
        `};
    `
);

/**
 * List of entity which are also checkbox filters.
 */
const FiltersItems = ({ title, render, selectedText, underConstruction }: Props) => {
    const [open, setOpen] = useState(false);
    const openOrHasSelection = open || !!selectedText;

    return (
        <Container
            openOrHasFilters={openOrHasSelection}
            open={open}
            onClick={() => !open && setOpen(true)}
        >
            <div style={{ cursor: open ? 'pointer' : '' }} onClick={() => open && setOpen(false)}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '1.5rem',
                        fontSize: '1.2rem',
                    }}
                >
                    <div>{title}</div>
                    <div style={{ flex: 1 }} />
                    <FontAwesomeIcon
                        icon={chevronDownIcon}
                        style={{
                            transform: open ? 'rotate(180deg)' : 'none',
                            transition: 'transform 0.15s',
                            opacity: 0.56,
                        }}
                    />
                </div>
                <div
                    style={{
                        fontSize: '0.7rem',
                        opacity: 0.56,
                        marginTop: open || selectedText ? '0.2rem' : '0',
                        minHeight: open ? '1.15em' : undefined,
                    }}
                >
                    {selectedText}
                </div>
            </div>
            <div
                onClick={event => {
                    event.stopPropagation();
                }}
                style={{ display: open ? 'block' : 'none' }}
            >
                {underConstruction && (
                    <span style={{ color: 'yellow' }}>
                        <FontAwesomeIcon icon={faConstruction} /> This filter is under construction
                    </span>
                )}
                {open && render()}
            </div>
        </Container>
    );
};

interface Props {
    title: string;
    render: () => ReactNode;
    selectedText: string | null;
    underConstruction?: boolean;
}

export default FiltersItems;
