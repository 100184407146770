import React from 'react';
import { BulkUploadValidateFailureByRow } from '../values/types';
import theme, { red } from '../styles/theme';
import styled from '@emotion/styled';

const Row = styled.div`
    color: ${red};
    font-weight: bold;
    margin-top: 1rem;
`;

const ColumnReference = styled.span`
    color: ${theme.primaryLight};
    font-weight: bold;
`;

const ErrorText = styled.span``;

function BulkUploadFailures({ failuresByRow, mapHeadingToFieldLabel }: Props) {
    return (
        <div style={{ maxHeight: '30rem', overflow: 'auto' }}>
            <div
                style={{ color: red, fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}
            >
                There were validation errors in the uploaded spreadsheet.
            </div>
            <div>Please see below for what must be corrected...</div>
            {failuresByRow.map(({ row, fieldErrors }) => (
                <div key={row}>
                    <Row style={{ color: red, fontWeight: 'bold' }}>Row {row}</Row>
                    {fieldErrors.map(fieldError => (
                        <div key={fieldError.attribute}>
                            <div>
                                {
                                    fieldError.attribute ? (
                                        <div>
                                            Column:{' '}
                                            <ColumnReference>
                                                "{mapHeadingToFieldLabel[fieldError.attribute]}"
                                                -&gt; "{fieldError.attribute}"
                                            </ColumnReference>
                                            , Value:{' '}
                                            <ColumnReference>"{fieldError.value}"</ColumnReference>
                                            ...
                                        </div>
                                    ) : null /* It's an error for the entire row; no column ref. */
                                }
                                <div>
                                    {fieldError.errors.map((error, index) => (
                                        <ErrorText key={index}>{error}</ErrorText>
                                    ))}{' '}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

interface Props {
    failuresByRow: BulkUploadValidateFailureByRow[];
    mapHeadingToFieldLabel: { [heading: string]: string | undefined };
}

export default BulkUploadFailures;
