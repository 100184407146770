import { Contact, ContactTracingResults } from '../store/reducers/contactTracing';
import { AmendedContact, ContactLevel } from '../values/types';
import { sum } from 'lodash-es';

function average(nums: number[]) {
    return nums.length ? sum(nums) / nums.length : 0;
}

export function amendContact(contact: Contact) {
    return {
        ...contact,
        overlapSeconds: Math.ceil(average(contact.overlaps.map(v => v.overlapSeconds))),
    };
}

/**
 * Amends the results we got from the API with...
 *
 * 1. Making sure the first contact level that _should_ be there but isn't because there are no
 *  contacts, that it appears as an empty array. This is to be able to show a warning message
 *  on the FE to express that there are no contacts for the N level.
 * 2. Adds some extra data to the contacts data.
 */
export function amendResults(
    results: ContactTracingResults | null,
    contactLevel: ContactLevel,
    subjectId: number
) {
    const ret: Array<{
        contactLevel: ContactLevel;
        contacts: AmendedContact[];
    }> = [];
    if (!results) {
        return ret;
    }
    for (let i = 0; i < contactLevel; i++) {
        const contactLevelContacts = results[i] ? results[i].contacts : [];

        const resultAmended: AmendedContact[] = contactLevelContacts
            // Filter out self from contacts, though ideally contact with self shouldn't ever appear
            .filter(contact => contact.person?.id !== subjectId)
            .map(amendContact);

        ret.push({
            contactLevel: (i + 1) as ContactLevel,
            contacts: resultAmended,
        });
        if (!contactLevelContacts.length) {
            // Break out of the loop; if there aren't any contacts on this level, there won't
            // be contacts in higher levels either.
            break;
        }
    }
    return ret;
}
