import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { Location } from '../../values/types';

const slice = createFetchSlice('locations', {}, getInitialState<Location[]>());

export function loadLocations(options = {}) {
    return slice.load({
        uri: `/locations`,
        ...options,
    });
}

const locations = slice.reducer;
const locationsSelector = slice.selector;
const locationsReset = slice.actions.reset;

export { locationsSelector, locationsReset };

export const locationsDataSelector = createSelector(locationsSelector, v => v.data);

export default locations;
