import React from 'react';
import { NavLink } from 'react-router-dom';
import { styled, ThemeProps } from '../styles/theme';
import { css } from '../utils/ide';
import ReactResizeDetector from 'react-resize-detector';
import { remToNumber } from '../utils/css';

const Container = styled.div(
    props => css`
        background-color: ${props.theme.backgroundSecondary};
    `
);

const borderRadius = '1.5rem';

const wideWidth = '25rem';

const TabNavLink = styled(NavLink)(
    ({ theme, smaller }: ThemeProps & { smaller: boolean }) => css`
        ${!smaller
            ? css`
                  width: ${wideWidth};
              `
            : css`
                  padding: 0 2rem;
              `};
        display: inline-flex;
        height: 4rem;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 1.25rem;

        &.active {
            background-color: ${theme.backgroundMediumContrast};
            border-top-left-radius: ${borderRadius};
            border-top-right-radius: ${borderRadius};
            color: ${theme.primaryLight};
        }
    `
);

export default function TabNavBar({ tabs }: Props) {
    return (
        <ReactResizeDetector handleWidth>
            {({ width }) => {
                const shouldBeSmaller = remToNumber(wideWidth) * tabs.length + 10 > width ?? 0;

                return (
                    <Container>
                        {tabs.map(tab => (
                            <TabNavLink
                                key={tab.path}
                                to={tab.path}
                                draggable={false}
                                smaller={shouldBeSmaller}
                            >
                                {tab.name}
                            </TabNavLink>
                        ))}
                    </Container>
                );
            }}
        </ReactResizeDetector>
    );
}

type Tab = {
    name: React.ReactNode;
    path: string;
};

type Props = {
    tabs: Tab[];
};
