import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { remToNumber } from '../../utils/css';
import { blue5 } from '../../styles/theme';
import { Link } from 'react-router-dom';

export const wizardGray = '#989DA5';

const unnumberedPinSize = '1.5rem'; // Gray unnumbered pin.

const smallFontSize = '1.25rem';

const Container = styled.span`
    position: absolute;
    background-color: ${wizardGray};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
`;

function WizardStep({step, currentStep, lastStep, left, top, route}: Props) {
    lastStep = Math.max(currentStep, lastStep);
    const oldPinSize = remToNumber(unnumberedPinSize) * 1.5;
    const currentPinSize = remToNumber(unnumberedPinSize) * 1.75;

    let size: number|string = unnumberedPinSize;
    let color = blue5;
    let showNumber = false;
    let bold = false;
    let fontSize;
    if (currentStep === step) {
        size = currentPinSize;
        showNumber = true;
        bold = true;
        fontSize = remToNumber(smallFontSize) * 1.25;
    } else if (lastStep < step) {
        color = wizardGray;
    } else {
        size = oldPinSize;
        showNumber = true;
    }
    const halfSize = remToNumber(size) / 2;

    const style: CSSProperties = {
        left: left - halfSize,
        top: top - halfSize,
        width: size,
        height: size,
        backgroundColor: color,
        fontWeight: bold ? 'bold' : void 0,
        fontSize,
    };

    const contained = <Container style={style}>{showNumber ? step : null}</Container>;
    if (route && step <= lastStep && step !== currentStep) {
        return <Link to={route}>{contained}</Link>;
    }
    return contained;
}

interface Props {
    currentStep: number,
    lastStep: number,
    step: number,
    left: number,
    top: number,
    route?: string,
}

export default WizardStep;
