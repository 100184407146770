import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { URI_CONTACT_TRACING } from '../../axios';
import { ContactTracingResults, QuickFilters } from './contactTracing';
import { FromTo } from './reports';
import { ContactLevel } from '../../values/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../index';
import { amendResults } from '../../utils/contactTracing';

const slice = createFetchSlice(
    'contactTracingResults',
    {
        setContactTracingLevel: (state, { payload }: PayloadAction<ContactLevel>) => {
            state.contactLevel = payload;
        },
        setSubjectId: (state, { payload }: PayloadAction<number>) => {
            state.subjectId = payload;
        },
    },
    { ...getInitialState<ContactTracingResults>(), contactLevel: 1 as ContactLevel, subjectId: 0 }
);

export function loadContactTracingResults(
    idToTrack: number,
    dateRange: FromTo,
    quickFilters: QuickFilters,
    contactLevel: ContactLevel
) {
    const queryString = new URLSearchParams({
        id: String(idToTrack),
        types: quickFilters.toInclude.join(','),
        fromDate: dateRange.from,
        toDate: dateRange.to,
        levels: String(contactLevel),
        minOverlapMinutes: String(Number(quickFilters.minimumTimeOverlap)),
        extend: String(quickFilters.trailingContacts ? quickFilters.trailingContactsMinutes : 0),
    }).toString();

    return (dispatch: AppDispatch) => {
        dispatch(slice.actions.setContactTracingLevel(contactLevel));
        dispatch(slice.actions.setSubjectId(idToTrack));
        dispatch(
            slice.load({
                uri: `${URI_CONTACT_TRACING}?${queryString}`,
            })
        );
    };
}

const contactTracingResults = slice.reducer;
const contactTracingResultsSelector = slice.selector;
const contactTracingResultsReset = slice.actions.reset;

export { contactTracingResultsSelector, contactTracingResultsReset };

export const contactTracingResultsDataSelector = createSelector(
    contactTracingResultsSelector,
    v => v.data
);

export const contactTracingResultsDataAmendedSelector = createSelector(
    contactTracingResultsSelector,
    state => amendResults(state.data, state.contactLevel, state.subjectId)
);

export default contactTracingResults;
