import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { locationStatusInfoMap, PersonInfo } from '../values/appConfig';
import { match, useHistory, useRouteMatch } from 'react-router';
import { LocationState, RouteParams } from '../values/types';
import { useAppDispatch, useSelector } from '../store';
import { locationsByIdSelector, personsWaitingSelector } from '../store/reducers/reports';
import { FullPageBigSpinner } from '../components/BigSpinner/BigSpinner';
import styled from '@emotion/styled';
import { EditBox, GappedFlex, PropertyBox } from '../styles/shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { generatePath, Link } from 'react-router-dom';
import ValueCard from '../components/ValueCard/ValueCard';
import { faHouse, faUser } from '@fortawesome/pro-regular-svg-icons';
import { differenceInYears, parseISO } from 'date-fns';
import { wizardGray } from '../components/Wizard/WizardStep';
import CasesOrExposedTable from '../components/CasesOrExposedTable';
import PersonInfoCorner from '../components/PersonInfoCorner';
import DiseaseCasesAndCount from '../components/DiseaseCasesAndCount';
import {
    diseaseCasesFilteredDataSelector,
    diseaseCasesFilteredReset,
    loadDiseaseCasesFiltered,
} from '../store/reducers/diseaseCasesFiltered';
import { isIE } from '../utils/css';
import { formatDate } from '../utils/date';
import { red } from '../styles/theme';
import { PrimaryButtonLink } from '../components/Button/Button';
import { personsByIdSelector } from '../store/reducers/persons';
import { routes } from '../router/routes';
import { PersonsRouteParams } from '../router/caseManagementRoutes';

const BoxTop = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

function PersonShowPage({ personInfo, parentRouteMatch }: Props) {
    const history = useHistory<LocationState>();
    const dispatch = useAppDispatch();
    const match = useRouteMatch<RouteParams>();
    const personId = Number(match.params.personId);
    const personByIdMap = useSelector(personsByIdSelector);
    const personsWaiting = useSelector(personsWaitingSelector);
    const person = personByIdMap?.[personId];
    const backUri = parentRouteMatch.url;
    const locationByIdMap = useSelector(locationsByIdSelector);
    const location = locationByIdMap[person?.homeLocationKey ?? ''];
    const diseaseCasesAll = useSelector(diseaseCasesFilteredDataSelector) || [];

    useEffect(() => {
        if (!match.params.personId) {
            return;
        }
        dispatch(loadDiseaseCasesFiltered('personKey', match.params.personId));
        return () => {
            dispatch(diseaseCasesFilteredReset());
        };
    }, [dispatch, match.params.personId]);

    const homeLocationName = useMemo(() => {
        if (!location) {
            return 'N/A';
        }
        return location.name;
    }, [location]);

    const birthDate = useMemo(() => {
        if (!person) {
            return new Date();
        }

        if (!person.dateOfBirth) {
            return null;
        }

        return parseISO(person.dateOfBirth);
    }, [person]);

    const age = useMemo(() => {
        return birthDate ? differenceInYears(new Date(), birthDate) : 'N/A';
    }, [birthDate]);

    useLayoutEffect(() => {
        if (!person) {
            return;
        }
        if (person.isCaregiver) {
            // Disease cases is not supported for staff.
            history.replace(generatePath(routes.personsEdit.fullPath, match.params));
        }
    }, [history, match.params, match.url, person]);

    const displayBirthDate = useMemo(() => {
        if (!birthDate) {
            return 'N/A';
        }
        return formatDate(birthDate);
    }, [birthDate]);

    const goToNewDiseaseCasePage = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();
            history.push(routes.diseaseCaseCreate.fullPath, {
                diseaseCaseEditState: {
                    personId: Number(match.params.personId),
                },
            });
        },
        [history, match.params.personId]
    );

    if (!person) {
        if (personsWaiting) {
            return <FullPageBigSpinner />;
        }

        // Person not found. Go back.
        history.replace(backUri);
        return null;
    }
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <PersonInfoCorner person={person} />
                <div style={{ flex: 1 }} />
                <PrimaryButtonLink
                    to={routes.diseaseCaseCreate.fullPath}
                    onClick={goToNewDiseaseCasePage}
                >
                    + Log a Case
                </PrimaryButtonLink>
            </div>
            <h3>Summary</h3>
            <GappedFlex>
                <ValueCard
                    style={{ width: isIE ? '15rem' : undefined }}
                    topContent={
                        <BoxTop>
                            <FontAwesomeIcon
                                color={wizardGray}
                                style={{ fontSize: '2.5rem', marginRight: '2rem' }}
                                icon={faUser}
                            />
                            {age}
                        </BoxTop>
                    }
                    bottomContent={<div>Age ({displayBirthDate})</div>}
                />
                <ValueCard
                    style={{ width: isIE ? '30rem' : undefined }}
                    topContent={
                        <BoxTop>
                            <FontAwesomeIcon
                                color={wizardGray}
                                style={{ fontSize: '2.5rem', marginRight: '2rem' }}
                                icon={faHouse}
                            />
                            {homeLocationName}
                        </BoxTop>
                    }
                    bottomContent={'Home Location'}
                />
                <PropertyBox>
                    <div style={{ fontSize: '2rem' }}>
                        {locationStatusInfoMap[person.locationStatus ?? 'on campus'].name}
                    </div>
                    <div>Location Status</div>
                </PropertyBox>
                <Link to={generatePath(routes.personsEdit.fullPath, match.params)}>
                    <EditBox style={{ width: '10rem' }}>
                        <FontAwesomeIcon size="3x" icon={faPencil} />
                        Edit File
                    </EditBox>
                </Link>
            </GappedFlex>
            <div style={{ height: '1rem' }} />
            <h3>Health Status</h3>
            <DiseaseCasesAndCount diseaseCases={diseaseCasesAll} />
            <div style={{ height: '3rem' }} />
            <h2 style={{ fontSize: '2rem', color: diseaseCasesAll.length ? red : undefined }}>
                History ({diseaseCasesAll.length})
            </h2>
            <CasesOrExposedTable
                forSinglePerson
                personType={personInfo.key}
                diseaseCases={diseaseCasesAll}
            />
        </div>
    );
}

interface Props {
    personInfo: PersonInfo;
    parentRouteMatch: match<PersonsRouteParams>;
}

export default PersonShowPage;
