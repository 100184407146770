import React from 'react';
import Grid from '../components/Grid/Grid';
import {number} from 'prop-types';

function ValueCardGrid({columns = 1, ...restProps}) {
    return (
        <Grid columns={columns} gridGap="1.6rem" {...restProps} />
    );
}

ValueCardGrid.propTypes = {
    columns: number,
};

export default ValueCardGrid;
