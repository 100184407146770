import * as H from 'history';
import { DiseaseCaseEditRouteState } from '../router/caseManagementRoutes';
import { Contact } from '../store/reducers/contactTracing';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { FromTo } from '../store/reducers/reports';

// The "to" property for links in React Router.
export type ReactRouterTo<S = H.LocationState> =
    | H.LocationDescriptor<S>
    | ((location: H.Location<S>) => H.LocationDescriptor<S>);

export type HasId<IdProperty extends string = 'id', IdType = number | string> = {
    [prop in IdProperty]: IdType;
};

export type ProfilePhoto = {
    largeUrl: string;
    mediumUrl: string;
    smallUrl: string;
};

export interface PartialPerson {
    id: number;
    name: string;
    secondName?: string;
    profilePhoto?: ProfilePhoto;
}

export interface Person extends PartialPerson {
    id: number;
    name: string;
    secondName: string;
    middleInitial: string;
    fullName: string;
    locationStatus: LocationStatus;
    facilityPersonId: string;
    isCaregiver: boolean;
    dateOfBirth: string;
    gender: Gender;
    homeLocationKey: number | null;
    quarantineLocationKey: number | null;
    profilePhoto?: ProfilePhoto;
}

export type Location = {
    id: number;
    name: string;
};

export const locationStatuses = [
    'on campus',
    'hospital',
    'rehab',
    'transition suite',
    'other',
] as const;

export type LocationStatus = typeof locationStatuses[number];

export type Facility = {
    id: number;
    centralOffice?: {
        uuid: string;
        name: string;
    };
    locale: string;
    name: string;
    // If the following is TRUE, this facility does not get alarms, events, persons and locations
    // populated via ETL. i.e. this facility is not paying for Sentrics' devices that emit the data.
    // Thus this facility needs to be able to create persons/locations/diseases etc. manually.
    isStandalone: boolean;

    isAlarmsEnabled?: boolean;
    isContactTracingEnabled?: boolean;
    isCaseManagementEnabled?: boolean;
    isEngage360Enabled?: boolean | null;
};

export type FacilityWithStatistics = Facility & {
    statistics: {
        residentsTotal: number;
        staffTotal: number;
        positiveCasesResidentsCount: number;
        positiveCasesStaffCount: number;
        exposedResidentsCount: number;
        exposedStaffCount: number;
        pendingIsolationResidentsCount: number;
        pendingIsolationStaffCount: number;
        pendingQuarantineResidentsCount: number;
        pendingQuarantineStaffCount: number;
    };
};

export const IncludableContactResident = 0;
export const IncludableContactStaff = 1;
export const IncludableContactAsset = 2;

export type IncludableContact =
    | typeof IncludableContactResident
    | typeof IncludableContactStaff
    | typeof IncludableContactAsset;

export type ContactLevel = 1 | 2 | 3 | 4;
export type ContactLevelLabel =
    | 'Direct Contact'
    | '2nd Level Contact'
    | '3rd Level Contact'
    | '4th Level Contact';

export type AmendedContact = Contact & { overlapSeconds: number };

// The person types as they appear in the API.
export const personTypes = ['resident', 'caregiver'] as const;
export const personTypesExceptVisitor = ['resident', 'caregiver'] as const;
export type PersonType = typeof personTypes[number];
// The person type as it appears in the FE URL.
export type RoutePersonType = 'residents' | 'staff';

export const genders = ['m', 'f', 'o'] as const;
export type Gender = typeof genders[number];
export type CasesVsQuarantinedCategory = 'casesTotal' | 'quarantinedTotal';

export const casesAndExposed = ['cases', 'exposed'] as const;
export type CasesOrExposed = typeof casesAndExposed[number];

type DiseaseStatistics = {
    positiveCasesCount: number;
    exposedCount: number;
    pendingIsolationCount: number;
    pendingQuarantineCount: number;

    totalTestsResidents: number;
    totalTestsStaff: number;
    totalNegativeTestsResidents: number;
    totalNegativeTestsStaff: number;

    // Inactive-ness
    isInactive: boolean;
    recoveredResidentsCount: number;
    lastActive: string | null;
};

export type Disease = {
    uuid: string;
    name: string;
    minOverlapMinutes: number;
    trailingContactMinutes: number;
    maxContactLevel: ContactLevel;
    contagiousPeriodPriorDays: number;
    contagiousPeriodPostDays: number;
    quarantineTimeframeDays: number;
    specialInstructions: string;
    note: string;

    statistics: DiseaseStatistics;

    createdAt: string;
    updatedAt: string;
};

export type DiseaseSingle = Disease & {
    infoSources: InfoSource[];
    protocolSteps: ProtocolStep[];
};

export type DiseaseCaseStatus = 'exposure' | 'active case' | 'closed';

export type DiseaseCaseTest = {
    isPositive: boolean;
    resultReadyAt: string;
    testedAt: string;
    createdAt: string;
    updatedAt: string;
} & HasId<'uuid'>;

export type DiseaseCase = {
    uuid: string;
    isExposure: boolean;
    FacilityKey: number;
    PersonKey: number; // personId.
    createdAt: string;
    diseaseUuid: string;
    updatedAt: string;
    status: DiseaseCaseStatus;
    rootContactLevel: ContactLevel | null;
    protocolCompletedAt: string | null;

    quarantinedAt: string | null;
    quarantinedTill: string | null;
    remainingQuarantineDays: number | null;

    contactTracingCompletedAt: string | null;
    exposureDurationSeconds: number | null;
    exposureLocationKey: number | null;
    exposedAt: string | null;
} & HasId<'uuid'>;

export type DiseaseCaseWithTests = DiseaseCase & {
    tests: DiseaseCaseTest[];
};

export type DiseaseCaseWithParent = DiseaseCase & {
    rootContactDiseaseCaseUuid: string | null;
    parentUuid: string | null;
    tests: DiseaseCaseTest[];
};

export type DiseaseCaseSingle = DiseaseCaseWithParent & {
    tests: DiseaseCaseTest[];
    protocolCompletion: { protocolStepId: number }[];
    symptoms: { symptomId: number }[];
    contactTracingRequired: boolean;
    contactTracingCompleted: boolean;
};

export type PersonTypeCounts = Record<PersonType | 'total', number>;

export type InfoSource = {
    url: string;
    displayName: string;
};

export type Symptom = {
    id: number;
    name: string;
};

export type ProtocolStep = {
    id: number;
    title: string;
    description: string;
    hasListOfSymptoms: boolean;
    symptoms: Symptom[];
};

export type ProtocolCompletion = {
    symptoms?: Symptom[];
    steps?: { protocolStepId: number }[];
    contactTracingCompleted: boolean;
};

// The possible fields to map to for bulk upload.
// This list may need to be updated if the list of possible fields changed.
export type BulkUploadField =
    | 'Facility'
    | 'FacilityPersonId'
    | 'Name'
    | 'SecondName'
    | 'MiddleInitial'
    | 'DateOfBirth'
    | 'Gender'
    | 'Location';

export type BulkUploadValidateFailure = {
    row: number;
    attribute: BulkUploadField;
    errors: string[];
    value: string;
};

export type BulkUploadValidateFailureByRow = {
    row: number;
    fieldErrors: Array<{
        attribute: string;
        errors: string[];
        value: string;
    }>;
};

export const alarmSubjects = ['person', 'location', 'asset'] as const;
export type AlarmSubjectType = typeof alarmSubjects[number];

export type AlarmSubject = null | {
    polymorphicType: AlarmSubjectType; // Topic
    id: number;
    name: string;
};

export type Alarm = {
    id: number;
    alarmId: string;
    alarmType: string; // Type
    area: string;
    closeTime: string | null;
    closeTimeUtc: string | null;
    creationTime: string | null;
    creationTimeUtc: string | null;
    dispositionType: null;
    domain: string; // Domain
    responseTime: null | number;
    subject: AlarmSubject; // Topic can be extracted from subject.
};

export type Channel = {
    callSign: string;
    name: string;
};

export type ChannelWithWatches = Channel & { watches: number };
export type ChannelWithWatchesAndRank = ChannelWithWatches & { rank: number };

// Chart data.
export type LabelValue = {
    label: string;
    value: number;
};

export type EngageResident = HasId<'uuid'> & {
    name: string;
};

export type EngageRegistration = {
    date: string;
};

export type EngageRegistrationWithResident = EngageRegistration & {
    resident: EngageResident;
};

export type Activity = HasId<'uuid'> & {
    name: string;
    place: string;
    category: string;
    tags: string[];
    startedAt: string;
    createdAt: string;
};

export type ActivitySummary = {
    totalRegistrations: number;
    ratioOfResidentsRegistered: number | null;
    ratioOfActivitiesWithRegistrations: number | null;
    registrationsPerResident: number | null;
};

export type ActivityWithRegistrations = Activity & {
    registrations: EngageRegistrationWithResident[];
};

export type ActivitiesByHourAndWeek = number[][];

export type RepairsSummary = {
    requests_total: number;
    requests_completed: number;
    requests_outstanding: number;
    average_repairs_per_residence: number | null;
    average_days_to_complete_repairs: number;
};

export type Repair = HasId<'uuid'> & {
    status: 'open' | 'closed' | 'cancelled';
    closedAt: string | null;
    item: string;
    issue: string;
    externalCreatedAt: string;
    locationKey: number;
    location: Location;
    residentId: number;
    resident: EngageResident;
};

export type RepairByIssueAndPlace = {
    roomName: string;
    issues: Array<{ issueName: string; count: number }>;
};

export type Engage360Filters = FromTo & { engageResidentUuids?: string[] };

// Represents a datum in the Cases vs Quarantined chart.
export type CasesVsQuarantinedDatum = {
    date: string;
} & { [category in CasesVsQuarantinedCategory]: number };

export type RouteParams = {
    personType?: PersonType;
    personId?: string;
    locationId?: string;
};

export type LocationState = {
    diseaseCaseEditState?: DiseaseCaseEditRouteState;
};

// The type of react-hook-form's setError function.
export type SetError<Form> = UseFormMethods<Form>['setError'];
