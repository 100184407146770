import React from 'react';
import { ContactLevel, DiseaseCase } from '../../values/types';
import { Table, Th } from '../DataTable/DataTable';
import { maxContactLevel } from '../../values/appConfig';
import ExistingDirectContactsTableRow from './ExistingDirectContactsTableRow';
import { height } from '../DataTable/shared';

function ExistingDirectContactsTable({ flattenedExposureTree, baseContactLevel }: Props) {
    return (
        <Table>
            <thead>
                <tr style={{ height }}>
                    <Th colSpan={maxContactLevel - 1} />
                    <Th>Contact</Th>
                    <Th>Date/Time</Th>
                    <Th>Overlap</Th>
                    <Th>Location</Th>
                </tr>
            </thead>
            <tbody>
                {flattenedExposureTree.map(v => (
                    <ExistingDirectContactsTableRow
                        key={v.uuid}
                        baseContactLevel={baseContactLevel}
                        exposure={v}
                    />
                ))}
            </tbody>
        </Table>
    );
}

interface Props {
    baseContactLevel: ContactLevel | null;
    flattenedExposureTree: DiseaseCase[];
}

export default ExistingDirectContactsTable;
