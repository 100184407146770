import React, { ReactNode } from 'react';
import { Box } from '../styles/shared';
import theme, { red } from '../styles/theme';

function Alert({ children }: Props) {
    return (
        <Box style={{ padding: '3rem', backgroundColor: red, color: theme.primaryLight }}>
            {children}
        </Box>
    );
}

interface Props {
    children: ReactNode;
}

export default Alert;
