import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { EngageResident } from '../../values/types';

const slice = createFetchSlice('engageResidents', {}, getInitialState<EngageResident[]>());

export function loadEngageResidents(options = {}) {
    return slice.load({
        uri: `/engage-residents`,
        ...options,
    });
}

const engageResidents = slice.reducer;
const engageResidentsSelector = slice.selector;
const engageResidentsReset = slice.actions.reset;

export { engageResidentsSelector, engageResidentsReset };

export const engageResidentsDataSelector = createSelector(engageResidentsSelector, v => v.data);

export default engageResidents;
