import React, { useLayoutEffect, useMemo } from 'react';
import PersonBox from './SelectResident/PersonBox';
import { ContactTracingTitle } from './common';
import { Link } from 'react-router-dom';
import { routeForSelectDateRange } from './contactTracingRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setContactTracingId } from '../../store/actions/contactTracing';
import SelectableItemGridContainer from '../../components/SelectableItemGridContainer';
import { reportsSelector } from '../../store/reducers/reports';

function SelectPersonPage() {
    const { residents, caregivers, people } = useSelector(reportsSelector);
    const { whatTrace, idToTrack } = useSelector((state: RootState) => state.contactTracing);
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        if (!whatTrace) {
        }
    }, [whatTrace]);

    const poepleToShow = useMemo(() => {
        if (whatTrace === 'residents') {
            return residents;
        } else if (whatTrace === 'caregivers') {
            return caregivers;
        }
        return people;
    }, [caregivers, people, residents, whatTrace]);

    const noun = useMemo(() => {
        return whatTrace === 'caregivers' ? 'Staff Member' : 'Resident';
    }, [whatTrace]);

    return (
        <div>
            <ContactTracingTitle>Select a {noun}</ContactTracingTitle>
            <SelectableItemGridContainer>
                {poepleToShow.map(person => (
                    <Link
                        key={person.id}
                        to={routeForSelectDateRange.path}
                        onClick={() => dispatch(setContactTracingId(person.id))}
                    >
                        <PersonBox person={person} selected={person.id === idToTrack} />
                    </Link>
                ))}
            </SelectableItemGridContainer>
        </div>
    );
}

export default SelectPersonPage;
