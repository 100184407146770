/**
  Pagination component wrapping the react-paginate library,
  following design made for Case Management
 */

import React from 'react';
import styled from '@emotion/styled';
import ReactPaginate from 'react-paginate';
import { MetaPagination } from '../../store/sliceCreators/fetchSliceCreator';
import { clamp } from 'lodash-es';

const Container = styled.div`
    height: 2.9rem;

    ul > li {
        display: inline-block;
        cursor: default;
        color: black;
        background: #469cd8;
        zoom: 1;
        margin: 0.2rem;
        padding: 0.2rem 0.1rem;
        border-radius: 3px;
    }
    ul > li > a {
        padding: 0.3rem 0.4rem;
    }
    ul > li.active {
        background: white;
    }
    ul > li:not(.disabled):not(.active) > a {
        cursor: pointer;
    }
`;

type SetCurrentPageFn = (page: number) => void;

interface Props {
    page?: number | string;
    totalCount: number;
    pageSize: number;
    setCurrentPage: SetCurrentPageFn;
}

function Paginate2(props: Props) {
    const { totalCount, pageSize, setCurrentPage, page } = props;

    function handlePageClick(data: { selected: number }) {
        // react-paginate index start at 0 but we use 1
        setCurrentPage(data.selected + 1);
    }

    const pageCount = Math.ceil(totalCount / pageSize);

    return (
        <Container>
            {totalCount > pageSize && (
                <ReactPaginate
                    pageCount={pageCount}
                    forcePage={page ? clamp(Number(page), 1, pageCount) - 1 : undefined}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    previousLabel={'<'}
                    nextLabel={'>'}
                    activeClassName={'active'}
                    activeLinkClassName={'active'}
                    disabledClassName={'disabled'}
                />
            )}
        </Container>
    );
}

export default Paginate2;

interface Paginate2ByMetaProps {
    page?: number | string;
    meta: MetaPagination | null;
    onPageChange: SetCurrentPageFn;
}

export function Paginate2ByMeta({ page, meta, onPageChange }: Paginate2ByMetaProps) {
    if (!meta) {
        return null;
    }

    return (
        <Paginate2
            totalCount={meta.total}
            pageSize={meta.per_page}
            setCurrentPage={onPageChange}
            page={page}
        />
    );
}
