import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {groupPages} from '../../utils/pagination';
import {css} from '../../utils/ide';

const Container = styled.div(({theme}) => css`
    background: ${theme.background};
    height: 20px;
    padding: 16px 20px;
    border-radius: 20px;
    box-sizing: border-box;
    box-shadow: ${theme.shadow4};
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${theme.primaryLight};
`);

const PageIcon = styled.div(({theme, active}) => css`
    width: 26px;
    height: 26px;
    background: ${active ? theme.primary : 'transparent'};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: ${active ? 'white' : 'inherit'};
    cursor: pointer;
    user-select: none;
    &:hover {
        background: ${active ? theme.primary : theme.gray300};
        color: white;
    }
`);

const PaginatePages = (props) => {
    const pageGroups = groupPages(props.totalPages, props.currentPage).map(pages => {
        return pages.map(i => <PageIcon key={i} active={i === props.currentPage} onClick={() => props.setPage(i)}>{i}</PageIcon>)
    });
    const displayedPages = [];
    for (let i = 0; i < pageGroups.length; i++) {
        if (i > 0) {
            // The ...s between ranges of pages.
            displayedPages.push(<div key={i}>...</div>);
        }
        displayedPages.push(pageGroups[i]);
    }
    return (
        <Container>{displayedPages}
        </Container>
    )
};

PaginatePages.propTypes = {
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    setPage: PropTypes.func
};

export default PaginatePages;
