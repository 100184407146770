import mixpanel from 'mixpanel-browser';
import history from './history';
const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

export function initMixpanel() {
  if (!mixpanelToken) {
    return;
  }
  mixpanel.init(mixpanelToken);
  trackHistory(history);
}

/**
 * Starts tracking history.
 * @param history
 */
function trackHistory(history) {
  history.listen(location => {
    reportPageVisit(location);
  });
}

/**
 * Unsets the user from mixpanel.
 */
export function resetMixpanel() {
  if (!mixpanelToken) {
    return;
  }

  mixpanel.reset();
}

/**
 * Sets the current user id to mixpanel and reports a visit to the current page.
 * @param id
 * @return Promise
 */
export function identifyUserIdAndReportPageVisit(id) {
  return new Promise((resolve, reject) => {

    if (!mixpanelToken) {
      reject();
      return;
    }

    mixpanel.identify(id);
    // We normally automatically report visits to pages, but that only works if the user had been
    // logged in. This is why after logging in we need to report the page visit manually once.
    reportPageVisit(history.location);
    resolve(mixpanel);
  });
}

/**
 * Reports to mixpanel the current page the user is visiting.
 * @param location
 */
function reportPageVisit(location) {
  if (!mixpanelToken) {
    return;
  }

  const message = `Visited page: ${location.pathname}${location.search}`;
  mixpanel.track(message);
}
