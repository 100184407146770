import { useAppDispatch, useSelector } from '../../../store';
import React, { useEffect } from 'react';
import {
    engageRepairsSummarySelector,
    loadEngageRepairsSummary,
} from '../../../store/reducers/engageRepairsSummary';
import EngageStatisticsBox from '../EngageStatisticsBox';
import { engage360FiltersSelector } from '../../../store/globalSelectors';

export default function RepairsSummary() {
    const dispatch = useAppDispatch();
    const engage360Filters = useSelector(engage360FiltersSelector);
    useEffect(() => {
        dispatch(loadEngageRepairsSummary(engage360Filters));
    }, [dispatch, engage360Filters]);
    const engageRepairsSummaryData = useSelector(engageRepairsSummarySelector).data;

    return (
        <>
            {engageRepairsSummaryData && (
                <EngageStatisticsBox
                    title="Summary"
                    stats={[
                        {
                            value: engageRepairsSummaryData.requests_total,
                            label: 'Repair Requests',
                        },
                        {
                            value: engageRepairsSummaryData.requests_completed,
                            label: 'Requests Completed',
                        },
                        {
                            value: engageRepairsSummaryData.requests_outstanding,
                            label: 'Requests Outstanding',
                        },
                        {
                            value: engageRepairsSummaryData.average_repairs_per_residence,
                            label: 'Average Repairs Per Residence',
                        },
                        {
                            value: engageRepairsSummaryData.average_days_to_complete_repairs,
                            label: 'Avg. Days To Complete Repairs',
                        },
                    ]}
                />
            )}
        </>
    );
}
