import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { Box } from '../../styles/shared';
import Button from '../../components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';

const Title = styled.h2`
    font-size: 2.5rem;
    margin: 0;
`;

export function ContactTracingTitle({children}) {
    return (
        <div>
            <Title>{children}</Title>
            <div style={{ height: '1rem' }} />
        </div>
    );
}

const BoxTitle = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
`;

const TitledBoxContainer = styled(Box)`
    padding: 1.5rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 18rem;
`;

export function ContinueButton(props) {
    return <Button
        size={1}
        highlighted
        style={{ width: '20rem', margin: 'auto' }}
        {...props}
    >Continue</Button>
}

export function BackToFiltersButton(props) {
    const {style = {}, ...restProps} = props;
    return (
        <Button
            size={1}
            highlighted
            style={{ width: '12rem', margin: 'auto', fontWeight: 'bold', ...style }}
            {...restProps}
        >
            <FontAwesomeIcon icon={faAngleLeft} style={{ marginRight: '1rem' }} size="2x" />
            <span style={{ fontSize: '1rem' }}>Back to Filters</span>
        </Button>
    );
}

export function TitledBox({ title, children, style }: TitledBoxProps) {
    return <TitledBoxContainer style={style}>
        <BoxTitle>{title}</BoxTitle>
        <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            {children}
        </div>
    </TitledBoxContainer>;
}

type TitledBoxProps = React.PropsWithChildren<{
    title: string,
    style?: CSSProperties,
}>
