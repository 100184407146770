import React, { useMemo } from 'react';
import { Bar, BarChart, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import darkTheme from '../../styles/theme';
import theme from '../../styles/theme';
import { createTooltip } from '../Recharts/Recharts';
import styled from '@emotion/styled';
import { LabelValue } from '../../values/types';

const Container = styled.div`
    color: ${theme.primaryLight};
    position: relative;
`;

const SideLabel = styled.div`
    writing-mode: tb-rl;
    text-align: center;
    text-transform: uppercase;
`;

const SideLabelText = styled.div`
    transform: rotate(180deg);
`;

const Title = styled.div`
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
`;

const colors = ['#00D5DD', theme.primaryBlue, '#9048F4', '#E8DB34', '#DB5B70', '#5BDB86'];

export default function Engage360Chart({ data, sideLabel, title, name, barLabels }: Props) {
    if (!data.length) {
        return (
            <Container>
                <div style={{ flex: 1 }}>No Data</div>
            </Container>
        );
    }

    return (
        <Container style={{ display: 'flex' }}>
            {sideLabel && (
                <SideLabel>
                    <SideLabelText>{sideLabel}</SideLabelText>
                </SideLabel>
            )}
            <div style={{ flex: 1, overflow: 'hidden' }}>
                <Title>{title}</Title>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={data} barCategoryGap="20%" margin={{ top: 20 }}>
                        <XAxis
                            dataKey="label"
                            stroke={darkTheme.primaryLight}
                            height={60}
                            tickLine={false}
                        />
                        <YAxis
                            stroke={darkTheme.primaryLight}
                            axisLine={false}
                            tickLine={false}
                            allowDecimals={false}
                        />
                        {createTooltip({ itemStyle: { color: 'white' } })}
                        <Bar
                            dataKey="value"
                            name={name}
                            radius={[8, 8, 0, 0] as any}
                            label={
                                barLabels
                                    ? ({
                                          fill: 'white',
                                          position: 'top',
                                          fontWeight: 'bold',
                                      } as any)
                                    : undefined
                            }
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={data[index].color} />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </Container>
    );
}

export type ChartDatum = LabelValue & {
    color?: string;
};

interface Props {
    data: ChartDatum[];
    sideLabel?: string;
    title?: string;
    // What to show in place of "value" in "value: ..." (tooltip).
    name?: string;
    barLabels?: boolean;
}

/**
 * Maps labels from LabelValues, using colors generated based on the hash of the label name.
 */
export function colorizeLabelValues(
    labelValues: LabelValue[] | undefined,
    startIndex: number = 0
): Array<LabelValue & { color: string }> {
    return labelValues
        ? labelValues.map(({ label, value }, index) => ({
              label,
              value,
              color: colors[(startIndex + index) % colors.length],
          }))
        : [];
}

/**
 * Re-returns the LabelValue[], but with added color.
 */
export function useColorizeLabelValues(labelValues?: LabelValue[] | null, startIndex = 0) {
    return useMemo(() => (labelValues ? colorizeLabelValues(labelValues, startIndex) : []), [
        labelValues,
        startIndex,
    ]);
}
