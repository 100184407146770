import styled from '@emotion/styled';
import { Box } from '../../styles/shared';
import { css } from '../../utils/ide';
import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
    useMemo,
    CSSProperties,
    FunctionComponent,
} from 'react';
import { noop } from 'lodash-es';
import { useResponsive } from '../ResponsiveProvider/ResponsiveProvider';
import { ThemeProps } from '../../styles/theme';

export const height = '5.2rem';

let lastMouseDownElement;
let lastMouseDownElementHandler;

export type OverlayComponentProps = {
    close: () => void;
    style?: CSSProperties;
};

const OverlayContainer = styled.div(
    ({ singleColumnLayout }: { singleColumnLayout: boolean }) => css`
        ${!singleColumnLayout &&
        css`
            width: 25.1rem;
        `};
    `
);

/**
 * Make a component into an overlay that's open-able by click.
 */
export function useOverlay(Component: FunctionComponent<OverlayComponentProps>) {
    // Global effect to check what the last element was where the user last pushed the mouse button.
    useEffect(() => {
        if (lastMouseDownElementHandler) {
            return;
        }
        lastMouseDownElementHandler = event => {
            lastMouseDownElement = event.target;
        };
        document.documentElement.addEventListener('mousedown', lastMouseDownElementHandler);
    }, []);

    const [isOpen, setOpen] = useState(false);
    const closeListenerRef = useRef(noop);
    const close = useCallback(() => {
        setOpen(false);
        document.removeEventListener('click', closeListenerRef.current);
    }, []);
    const open = useCallback(() => {
        if (isOpen) {
            return;
        }
        setOpen(true);
        const listener = event => {
            // If the click happens at, or started from an element with the below data attribute,
            // then don't close the overlay.
            if (
                event.target.closest('[data-overlay]') ||
                lastMouseDownElement.closest('[data-overlay]')
            ) {
                return;
            }
            close();
        };
        closeListenerRef.current = listener;
        document.addEventListener('click', listener);
    }, [close, isOpen]);
    const { singleColumnLayout } = useResponsive();
    let positionToWindowTop = false;
    if (singleColumnLayout) {
        positionToWindowTop = true;
    }
    const overlayElement = useMemo(() => {
        if (!isOpen) {
            return false;
        }

        const OverlayComponent = styled(Box)(
            ({ theme }: ThemeProps) => css`
                margin: 1rem;
                position: absolute;
                right: 0;
                top: ${positionToWindowTop ? '0' : height};
                display: flex;
                z-index: 10;
                padding: 1.5rem;
                background: ${theme.background};
                flex-direction: column;
                flex-shrink: 0;
                overflow: auto;

                ${singleColumnLayout &&
                css`
                    width: 100vw;
                    height: 100vh;
                    border-radius: 0;
                    margin: 0;
                    position: fixed;
                `}
            `
        );
        return (
            <OverlayComponent data-overlay={1}>
                <OverlayContainer singleColumnLayout={singleColumnLayout}>
                    <Component close={close} style={{ overflowY: 'auto' }} />
                </OverlayContainer>
            </OverlayComponent>
        );
    }, [close, isOpen, positionToWindowTop, singleColumnLayout]);
    return { open, isOpen, overlayElement };
}
