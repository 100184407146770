File.prototype.arrayBuffer = File.prototype.arrayBuffer || myArrayBuffer;
Blob.prototype.arrayBuffer = Blob.prototype.arrayBuffer || myArrayBuffer;

/**
 * Blob.prototype.arrayBuffer() polyfill for IE11.
 *
 * Borrowed from https://gist.github.com/hanayashiki/8dac237671343e7f0b15de617b0051bd
 * Modification: added support for promise rejection as well.
 */
function myArrayBuffer() {
    // this: File or Blob
    return new Promise((resolve, reject) => {
        let fr = new FileReader();
        fr.onload = () => {
            resolve(fr.result);
        };
        fr.onerror = () => {
            reject();
        };
        fr.readAsArrayBuffer(this);
    });
}
