import React, { useMemo } from 'react';
import { useSelector } from '../../store';
import { match } from 'react-router';
import { routes } from '../../router/routes';
import { FullPageBigSpinner } from '../../components/BigSpinner/BigSpinner';
import { engageActivitySignupsSelector } from '../../store/reducers/engageActivitySignups';
import { useHistory } from 'react-router-dom';
import ActivitySignup from '../../components/Engage360/Activities/ActivitySignup';

type Props = {
    match: match<{ activityUuid: string }>;
};

export default function ActivitySignupPrintPage({ match }: Props) {
    const history = useHistory();
    const { data } = useSelector(engageActivitySignupsSelector);

    const activity = useMemo(() => {
        return data && data.find(row => row.uuid === match.params.activityUuid);
    }, [data, match.params.activityUuid]);

    if (!data) {
        return <FullPageBigSpinner />;
    }

    if (!activity) {
        history.push(routes.engage360Activities.fullPath);
        return null;
    }

    return <ActivitySignup item={activity} print />;
}
