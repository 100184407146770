import React from 'react';
import styled from '@emotion/styled';
import MenuItem from './MenuItem';
import { css } from '../../../utils/ide';
import { RouteConfigItem } from '../../../router/routeConfig';
import MenuGroup from './MenuGroup';

const Container = styled.div(
    ({ theme, fullWidth }: { theme: any; fullWidth: boolean }) => css`
        display: flex;
        flex-direction: ${fullWidth ? 'row' : 'column'};
        ${!fullWidth &&
        css`
            width: 6.5rem;
        `};
        box-sizing: border-box;
        padding-top: ${!fullWidth ? '2rem' : 0};
        min-height: ${!fullWidth ? 'calc(100vh - 58px)' : 0};
        background: ${theme.background};
        flex-shrink: 0;

        .menu-item-container:not(:last-child) {
            margin-bottom: 0.3rem;
        }

        .group {
            margin-bottom: 1rem;

            &:not(:first-of-type) {
                margin-top: 3rem;
            }
        }

        @media print {
            display: none;
        }
    `
);

const LeftMenu = (props: Props) => {
    return (
        <Container fullWidth={props.fullWidth}>
            {props.routeConfig.map((item, index) =>
                item.isGroup ? (
                    item.items.length ? (
                        <MenuGroup key={`group-${item.groupKey}`} group={item} />
                    ) : null
                ) : (
                    <MenuItem key={index} {...item} />
                )
            )}
        </Container>
    );
};

interface Props {
    fullWidth: boolean;
    routeConfig: RouteConfigItem[];
}

export default LeftMenu;
