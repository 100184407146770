import React, {useRef, useEffect} from 'react';
import noUiSlider from 'nouislider';
import {default as PropTypes} from 'prop-types';
import 'nouislider/distribute/nouislider.css';
import '../../../../../styles/slider.css';

/**
 * @param realRange {[number, number]} A range of numbers of the two ends of the slider where the
 *  last number represents "anything above" as well.
 * @param selectedRange {[number, number]}
 * @param setSelectedRange {function}
 * @returns {*}
 * @constructor
 */
export function RangeSelector({realRange, selectedRange, setSelectedRange}) {
    const rangeContainerRef = useRef(null);

    useEffect(() => {
        if (!rangeContainerRef.current) {
            return;
        }

        const slider = noUiSlider.create(rangeContainerRef.current, {
            start: [...selectedRange],
            connect: true,
            range: {
                min: realRange[0],
                max: realRange[1],
            },

            step: 1,
        });
        slider.on('update', values => {
            setSelectedRange(values.map(value => Math.round(value)));
        });

        return () => {
            slider.destroy();
        };

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangeContainerRef.current]);

    return <div
        onClick={evt => evt.stopPropagation()}
        style={{height: '2rem', marginTop: '1rem'}}
    >
        <div style={{padding: '0 1rem'}}>
            <div ref={rangeContainerRef} />
        </div>
    </div>;
}

RangeSelector.propTypes = {
    onClick: PropTypes.func,
    ref: PropTypes.any,
};
