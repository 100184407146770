import {
    RESET_CONTACT_TRACING,
    SET_CONTACT_TRACING_CONTACT_LEVEL,
    SET_CONTACT_TRACING_DATE_RANGE,
    SET_CONTACT_TRACING_ID,
    SET_CONTACT_TRACING_QUICK_FILTERS_MINIMUM_OVERLAP,
    SET_CONTACT_TRACING_QUICK_FILTERS_TO_INCLUDE,
    SET_CONTACT_TRACING_QUICK_FILTERS_TRAILING_CONTACTS,
    SET_CONTACT_TRACING_QUICK_FILTERS_TRAILING_CONTACTS_MINUTES,
    SET_CONTACT_TRACING_TRACE,
} from '../actions/actionTypes';
import { ContactLevel, HasId, IncludableContact, Location, Person } from '../../values/types';
import { formatISODate } from '../../utils/date';
import subDays from 'date-fns/subDays';

export type WhatTrace = 'residents' | 'caregivers';

export type Overlap = {
    locationId: string;
    location?: Location;
    startDate: string;
    endDate: string;
    overlapSeconds: number;
};

export type Contact = HasId<'id', string> & {
    person?: Person;
    overlaps: Array<Overlap>;
};

export type ContactTracingResults = Array<{
    contactLevel: ContactLevel;
    contacts: Array<Contact>;
}>;

const initialState = {
    whatTrace: null as WhatTrace | null,
    idToTrack: null as null | number,
    dateRange: {
        from: formatISODate(subDays(new Date(), 14)),
        to: formatISODate(new Date()),
    },
    quickFilters: {
        toInclude: [] as Array<IncludableContact>,
        minimumTimeOverlap: null as number | null,
        trailingContacts: false,
        trailingContactsMinutes: 5,
    },
    contactLevel: 1 as ContactLevel,
    lastStep: 1,
    error: '',
};

export type QuickFilters = typeof initialState.quickFilters;

export default function reports(state = initialState, { type, payload }): typeof initialState {
    switch (type) {
        case RESET_CONTACT_TRACING:
            return initialState;
        case SET_CONTACT_TRACING_TRACE:
            return { ...state, whatTrace: payload };
        case SET_CONTACT_TRACING_ID:
            return { ...state, idToTrack: payload, lastStep: Math.max(state.lastStep, 2) };
        case SET_CONTACT_TRACING_DATE_RANGE:
            return { ...state, dateRange: payload, lastStep: Math.max(state.lastStep, 3) };
        case SET_CONTACT_TRACING_QUICK_FILTERS_TO_INCLUDE:
            return {
                ...state,
                quickFilters: { ...state.quickFilters, toInclude: payload },
                lastStep: Math.max(state.lastStep, 4),
            };
        case SET_CONTACT_TRACING_QUICK_FILTERS_MINIMUM_OVERLAP:
            return {
                ...state,
                quickFilters: { ...state.quickFilters, minimumTimeOverlap: payload },
                lastStep: Math.max(state.lastStep, 4),
            };
        case SET_CONTACT_TRACING_QUICK_FILTERS_TRAILING_CONTACTS:
            return {
                ...state,
                quickFilters: { ...state.quickFilters, trailingContacts: payload },
                lastStep: Math.max(state.lastStep, 4),
            };
        case SET_CONTACT_TRACING_QUICK_FILTERS_TRAILING_CONTACTS_MINUTES:
            return {
                ...state,
                quickFilters: { ...state.quickFilters, trailingContactsMinutes: payload },
                lastStep: Math.max(state.lastStep, 4),
            };
        case SET_CONTACT_TRACING_CONTACT_LEVEL:
            return { ...state, contactLevel: payload, lastStep: Math.max(state.lastStep, 5) };
        default:
            return state;
    }
}
