import React, { HTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { blue5 } from '../../styles/theme';
import { operateOnSize } from '../../utils/css';
import { css } from '../../utils/ide';

const size = '10rem';
const thickness = 0.2;
const innerSize = operateOnSize(size, pixels => (pixels / 2) * thickness);

const Spinner = styled.div`
    display: inline-block;
    position: relative;
    width: ${size};
    height: ${size};

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        border: ${innerSize} solid transparent;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: ${blue5} transparent transparent transparent;
    }
    div.outer {
        border-color: #1d697f;
    }
    div.inner-1 {
        animation-delay: -0.45s;
    }
    div.inner-2 {
        animation-delay: -0.3s;
    }
    div.inner-3 {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

function BigSpinner({ tall }: BigSpinnerProps) {
    return (
        <div
            style={{ textAlign: 'center', padding: '2rem 0', ...(tall ? { height: '100vh' } : {}) }}
        >
            <Spinner>
                <div className="outer" />
                <div className="inner inner-1" />
                <div className="inner inner-2" />
                <div className="inner inner-3" />
            </Spinner>
        </div>
    );
}

type BigSpinnerProps = {
    tall?: boolean;
};

export function MediumSpinner() {
    return (
        <div style={{ zoom: 0.25 }}>
            <BigSpinner />
        </div>
    );
}

const FullPageContainer = styled.div(
    (props: Props) => css`
        position: relative;
        ${props.absolute &&
        css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
        `};
        flex: 1;
        height: 100%;
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    `
);

export function FullPageBigSpinner(props: Props) {
    return (
        <FullPageContainer key="spinner" {...props}>
            <BigSpinner />
        </FullPageContainer>
    );
}

type Props = HTMLAttributes<HTMLDivElement> & {
    absolute?: boolean;
};

export default BigSpinner;
