import React, { CSSProperties, Fragment, ReactNode } from 'react';
import styled from '@emotion/styled';
import format from 'date-fns/format';
import BarLoader from '../BarLoader/BarLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from '../../utils/ide';
import { userCircleIcon } from '../../styles/fontLibrary';
import { DataTableTr, height } from './shared';
import { humanizeElapsedSeconds } from '../../utils/date';
import ResizeDetector from 'react-resize-detector';
import { HasId } from '../../values/types';
import { get, noop } from 'lodash-es';
import theme from '../../styles/theme';
import Icon from '../Icon';

export const Table = styled.table`
    margin: 0;
    border: 0;
    border-spacing: 0;
    width: 100%;
`;

export const Th = styled.th(
    ({ theme }: any) => css`
        font-weight: 400;
        padding: 8px 8px;
        text-align: center;
        svg {
            margin-right: 12px;
        }
        ${theme.boldFontCss};
    `
);

export const Td = styled.td(
    ({ textAlign = 'center' }: any) => css`
        padding: 6px 8px;
        text-align: ${textAlign};
        background: ${theme.background};
        tr:hover & {
            background: ${theme.backgroundSecondary};
        }
    `
);

export const iconHeight = '2.2em';

export type HeaderConfig<T> = {
    label?: ReactNode | ((data: T[]) => ReactNode);
    getValue: keyof T | string | ((row: T) => any);
    format?: 'icon-and-name' | 'icon' | 'string' | 'date' | 'seconds' | 'avatar' | 'react-node';
    textAlign?: 'left' | 'right' | 'center';
    tdStyle?: CSSProperties;
};

/**
 * @template T The type of each data item.
 * @template IdProperty The property that has the ID for the item. To change this from the default
 *  'id', set 'idProperty' in the props.
 */
export type DatatableProps<T extends HasId<IdProperty>, IdProperty extends string = 'id'> = {
    data: T[];
    active?: (HasId<IdProperty> & { loading?: boolean }) | null;
    headerConfig: Array<HeaderConfig<T>>;
    getTrExtraAttributes?: (row: T) => Record<string, string>;
    // Callback for what to do when the active row is changed e.g. when clicking on a row.
    // Passed value is NULL if just inactivating the active row.
    changeActive?: (id: T[IdProperty] | null) => void;
    loading?: boolean;
    className?: string;
    getExpandedRowComponent?: (
        d: T,
        active: HasId<IdProperty> | null,
        headerLength: number,
        width: number
    ) => JSX.Element | null;
    idProperty?: IdProperty;
};

export function TableRowActingAsMargin(props: { colSpan?: number }) {
    return (
        <tr>
            <td colSpan={props.colSpan}>&nbsp;</td>
        </tr>
    );
}

function DataTable<T extends HasId<IdProperty>, IdProperty extends string = 'id'>(
    props: DatatableProps<T, IdProperty>
) {
    const {
        data,
        active = null,
        headerConfig,
        getTrExtraAttributes,
        changeActive = noop,
        getExpandedRowComponent,
        loading,
        className,
        idProperty = 'id' as IdProperty,
    } = props;

    if (!headerConfig) return <div>Missing header config for data table</div>;
    const formatCell = (type, getValue, dataRow) => {
        const value = typeof getValue === 'function' ? getValue(dataRow) : get(dataRow, getValue);

        if (type === 'date') {
            return value ? format(new Date(value), 'MMM dd - h:mm a') : 'N/A';
        }
        if (type === 'seconds') {
            return humanizeElapsedSeconds(value) || '--';
        }
        if (type === 'icon-and-name') {
            return value ? <Icon icon={value.icon} name={value.name} height={iconHeight} /> : null;
        }
        if (type === 'avatar') {
            return <FontAwesomeIcon icon={userCircleIcon} style={{ fontSize: '2.5em' }} />;
        }

        return value;
    };

    const checkActive = id => active && String(active[idProperty]) === String(id);

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <Table className={className}>
                    {!loading ? (
                        <thead style={{ borderRadius: 10 }}>
                            <tr style={{ height }}>
                                {headerConfig.map((h, index) => (
                                    <Th key={index}>
                                        {typeof h.label === 'function' ? h.label(data) : h.label}
                                    </Th>
                                ))}
                            </tr>
                        </thead>
                    ) : null}
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan={headerConfig.length}>
                                    <div style={{ marginTop: 12 }}>
                                        <BarLoader show={loading} />
                                    </div>
                                </td>
                            </tr>
                        ) : null}

                        {data.map(d => (
                            <Fragment key={d[idProperty]}>
                                {(!checkActive(d[idProperty]) || active?.loading) && (
                                    <DataTableTr
                                        {...(getTrExtraAttributes ? getTrExtraAttributes(d) : {})}
                                        onClick={() => changeActive(d[idProperty])}
                                        style={{
                                            cursor: changeActive !== noop ? 'pointer' : undefined,
                                        }}
                                    >
                                        {headerConfig.map((c, index) => (
                                            <Td
                                                key={index}
                                                className={index === 0 ? 'first-td-radius' : ''}
                                                textAlign={c.textAlign}
                                                active={checkActive(d[idProperty])}
                                                style={c.tdStyle}
                                            >
                                                {formatCell(c.format, c.getValue, d)}
                                            </Td>
                                        ))}
                                    </DataTableTr>
                                )}
                                {getExpandedRowComponent ? (
                                    getExpandedRowComponent(d, active, headerConfig.length, width)
                                ) : (
                                    <TableRowActingAsMargin colSpan={headerConfig.length} />
                                )}
                            </Fragment>
                        ))}
                        {!data.length && (
                            <>
                                <DataTableTr key="empty">
                                    <td colSpan={headerConfig.length}>None</td>
                                </DataTableTr>
                                <TableRowActingAsMargin colSpan={headerConfig.length} />
                            </>
                        )}
                    </tbody>
                </Table>
            )}
        />
    );
}

export default DataTable;
