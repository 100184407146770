import React, { memo, useMemo } from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { css } from '../../../utils/ide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { red, ThemeProps } from '../../../styles/theme';
import { RouteLeafNode, VisibleMenuItem } from '../../../router/routeConfig';
import { generatePath, matchPath, useLocation } from 'react-router';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

const ContainerLink = styled(NavLink)(
    ({ theme }: ThemeProps) => css`
        display: inline-block;
        margin: 0 auto;
        color: ${theme.primary};

        &.active {
            color: ${theme.primaryLight};
            font-weight: bold;
        }
        &:hover {
            color: ${theme.primaryLight};
        }
        font-size: 0.8rem;
        padding: 0.8rem 0.5rem;
        cursor: pointer;
        text-align: center;
    `
);

const Badge = styled.span`
    background-color: ${red};
    color: white;
    font-weight: bold;
    border-radius: 0.75rem;
    padding: 0.325rem 0.5rem;
    position: absolute;
    top: -0.5rem;
    right: 0;
`;

function VisibleMenuItemComponent(props: VisibleMenuItem) {
    const { label, path, params, badgeCount } = props;
    const icon = props.icon;
    const inactiveIcon = props.inactiveIcon ?? icon;
    const p = useLocation().pathname;
    const uri = useMemo(() => generatePath(path, params), [params, path]);
    const active = useMemo(() => {
        return !!matchPath(p, { path: uri, exact: props.path === '/' });
    }, [p, props.path, uri]);

    return (
        <VisibleMenuItemView
            uri={uri}
            active={active}
            badgeCount={badgeCount}
            label={label}
            icon={icon}
            inactiveIcon={inactiveIcon}
        />
    );
}

type VisibleMenuItemViewProps = {
    uri: string;
    active: boolean;
    badgeCount: number | undefined;
    label: string | undefined;
    icon: IconDefinition | undefined;
    inactiveIcon: IconDefinition | undefined;
};

const VisibleMenuItemView = memo(function VisibleMenuItemView({
    uri,
    active,
    badgeCount,
    label,
    icon,
    inactiveIcon,
}: VisibleMenuItemViewProps) {
    return (
        <div style={{ textAlign: 'center' }}>
            <ContainerLink to={uri} exact={uri === '/'} className="menu-item-container">
                {icon && inactiveIcon ? (
                    <div style={{ position: 'relative' }}>
                        <FontAwesomeIcon
                            icon={active ? icon : inactiveIcon}
                            style={{ fontSize: '3rem' }}
                        />
                        {badgeCount ? <Badge>{badgeCount}</Badge> : null}
                    </div>
                ) : null}
                <div style={{ height: '0.5em' }} />
                {label}
            </ContainerLink>
        </div>
    );
});

function MenuItem(props: RouteLeafNode) {
    return props.component && props.label ? <VisibleMenuItemComponent {...props} /> : null;
}

export default MenuItem;
